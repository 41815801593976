import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import './index.css';
import { HIMSSBlue, HIMSSDarkBlue, SilverSand } from './modules/layout/styles/colors';
import { unregister } from './registerServiceWorker';
import Root from './root.container';
import configureStore from './store/configureStore';

const theme = createTheme({
  palette: {
    primary: HIMSSDarkBlue,
    secondary: HIMSSBlue,
    grey: SilverSand
  },
  typography: {
    fontSize: 12,
    fontFamily: '"Verlag Bold", "Helvetica", "Arial", sans-serif',
  },
  overrides: {
    MuiStepLabel: {
      label: {
        '$active &': {
          fontWeight: 'bold'
        },
      },
    },
    MuiButton: {
      root: {
        borderRadius: '20px',
        boxShadow: 'none',
        minWidth: '7.4rem !important',
      }
    },
    MuiInputBase: {
      input: {
        height: ''
      }
    }
  },
  components: {
    MuiTextField: {
      defaultProps:{
        variant: 'standard'
      }
    },
    MuiSelect: {
      defaultProps: {
        variant: 'standard'
      }
    }
  }
});

/* Setup the FontAwesome fonts library. */
library.add(fas);
const container = document.getElementById('root');
const root = createRoot(container);
function startApp() {
  root.render(
    <ThemeProvider theme={theme}>
        <Router>
          <Root store={store} />
        </Router>
    </ThemeProvider>
  );
}

// injectTapEventPlugin();
const store = configureStore();

startApp();

unregister();
