import { FilteringState, IntegratedFiltering, IntegratedSorting, SelectionState, SortingState } from '@devexpress/dx-react-grid';
import { Grid, TableFilterRow, TableHeaderRow, TableSelection, VirtualTable } from '@devexpress/dx-react-grid-material-ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AppBar, Avatar, Fab, Toolbar, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import AddIcon from '@mui/icons-material/Add';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { hasPermission } from '../auth/authOperations';
import { CREATE_ANSWERS } from '../auth/permissions';
import { getAnswers, uploadAnswers } from '@survey/common/dist/actions/answers.actions';
import LoadingOverlay from '../layout/loadingOverlay.component';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { handleToastMessage } from '../layout/layout.actions';
const styles = theme => ({
  toolBar: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  toolbarButtons: {
    marginLeft: 'auto',
    marginRight: -12
  },
  answer: {
    display: 'flex',
    flexDirection: 'row'
  },
  avatar: {
    margin: theme.spacing(1)
  },
  heading: {
    margin: theme.spacing(1)
  },
  button: {
    margin: theme.spacing(1)
  },
  paper: {
    padding: theme.spacing(2)
  }
});

const answersPredicate = (value, filter) => {
  let values = value.join().toLowerCase();
  let filters = filter.value.toLowerCase().split(',');

  let predicate = true;
  filters.forEach(element => {
    if (values.indexOf(element) === -1) {
      predicate = false;
    }
  });
  return predicate;
};

class AnswersContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: 'answerName', title: 'Answer Name' },
        { name: 'answerDescription', title: 'Answer Description' },
        { name: 'answers', title: 'Answers', getCellValue: row => (row.answers ? row.answers.map(i => `${i.label},`) : []) },
        { name: 'active', title: 'Active', getCellValue: row => (row.active ? 'Active' : 'Inactive') }
      ],
      tableColumnExtensions: [{ columnName: 'active', width: 100 }],
      integratedFilteringColumnExtensions: [{ columnName: 'answers', predicate: answersPredicate }],
      openImportDialog: false,
      selectedFile: null
    };

    this.addAnswer = this.addAnswer.bind(this);
    this.changeGridSelection = this.changeGridSelection.bind(this);
    this.handleClickOpen = this.handleClickOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleImport = this.handleImport.bind(this);
  }
  handleClickOpen() {
    this.setState({ openImportDialog: true });
  }
  handleClose() {
    this.setState({ openImportDialog: false });
  }
  handleImport() {
    const formData = new FormData();
    formData.append('file', this.state.selectedFile);
    this.props.uploadAnswers(formData);
    this.setState({ openImportDialog: false });
  }
  onFileChangeHandler = event => {
    this.setState({
      selectedFile: event.target.files[0]
    });
  };
  componentDidMount() {
    this.props.getAnswers();
  }

  addAnswer() {
    this.props.history.push(`/answers/new`);
  }

  changeGridSelection(selection) {
    let answer = this.props.answers[selection];
    this.props.history.push(`/answers/${answer.answerID}`);
  }

  render() {
    const { classes, permissions } = this.props;
    const { columns, tableColumnExtensions, integratedFilteringColumnExtensions } = this.state;
    if (this.props.answerUploadComplete) {
      this.props.handleToastMessage('Import complete', false);
    }
    return (
      <Fragment>
        <Dialog open={this.state.openImportDialog} onClose={this.handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Import Answers</DialogTitle>
          <DialogContent>
            <Button variant="contained" component="label">
              <input type="file" onChange={this.onFileChangeHandler} accept=".xlsx" />
            </Button>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={this.handleImport} color="primary">
              Import
            </Button>
          </DialogActions>
        </Dialog>
        <AppBar position="static" color="default">
          <Toolbar className={classes.toolBar}>
            <div className={classes.answer}>
              <Avatar className={classes.avatar}>
                <FontAwesomeIcon icon="list-ul" />
              </Avatar>
              <Typography variant="h6" color="inherit" style={{ alignSelf: 'center' }}>
                Answers
              </Typography>
            </div>
            {hasPermission(CREATE_ANSWERS, permissions) && (
              <span className={classes.toolbarButtons}>
                <Fab size="small" color="primary" onClick={this.handleClickOpen}>
                  <CloudUploadIcon />
                </Fab>
                <Fab size="small" color="primary" className={classes.button} onClick={this.addAnswer}>
                  <AddIcon />
                </Fab>
              </span>
            )}
          </Toolbar>
        </AppBar>
        {this.props.isLoading ? (
          <LoadingOverlay />
        ) : (
          <Grid rows={this.props.answers} columns={columns} selection={[1]}>
            <SelectionState onSelectionChange={selection => this.changeGridSelection(selection)} />
            <FilteringState defaultFilters={[]} />
            <IntegratedFiltering columnExtensions={integratedFilteringColumnExtensions} />
            <SortingState defaultSorting={[]} />
            <IntegratedSorting />
            <VirtualTable height={1000} columnExtensions={tableColumnExtensions} />
            <TableHeaderRow showSortingControls />
            <TableFilterRow />
            <TableSelection selectByRowClick highlightSelected showSelectionColumn={false} />
          </Grid>
        )}
      </Fragment>
    );
  }
}

function mapStateToProps(state, props) {
  return {
    answers: state.answers.get('answers'),
    isLoading: state.answers.get('isLoading'),
    permissions: state.auth.get('permissions')
  };
}

export default withStyles(styles)(
  connect(mapStateToProps, {
    getAnswers,
    uploadAnswers,
    handleToastMessage
  })(AnswersContainer)
);
