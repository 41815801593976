/* eslint array-callback-return: 0 */
import { Getter } from '@devexpress/dx-react-core';
import { CustomTreeData, GroupingState, IntegratedGrouping, SelectionState, TreeDataState } from '@devexpress/dx-react-grid';
import { Grid as DevGrid, Table, TableGroupRow, TableHeaderRow, TableSelection, TableTreeColumn, VirtualTable } from '@devexpress/dx-react-grid-material-ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  AppBar,
  Button,
  Checkbox,
  Dialog,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  List,
  ListItem,
  ListItemText,
  Paper,
  Step,
  StepButton,
  Stepper,
  Switch,
  TextField,
  Toolbar,
  Typography,
} from '@mui/material';
import { withStyles } from "@mui/styles";
import { clone, cloneDeep, find, findIndex, isEmpty, uniq } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component, PureComponent } from 'react';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { hasPermission } from '../auth/authOperations';
import { EDIT_SURVEY_TYPES } from '../auth/permissions';
import { handleToastMessage } from '../layout/layout.actions';
import PreviewSurveyType from '../surveys/previewSurveyType.container';
import ThankYouForm from './thankYouForm.component';
import { HIMSSBlue } from '../layout/styles/colors';
import { getAccountsByType } from '@survey/common/dist/actions/organizations.actions';
import CustomSelect from '@survey/common/dist/components/form-controls/CustomSelect';
import CustomTextField from '@survey/common/dist/components/form-controls/CustomTextField';
import { customValidationRules } from '@survey/common/dist/utilities/answerValidation';
import { filterQuestion } from '@survey/common/dist/utilities/filterQuestions';
import LoadingOverlay from '../layout/loadingOverlay.component';
const styles = (theme) => ({
  form: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(5),
    marginRight: theme.spacing(5),
  },
  formControl: {
    display: 'block',
    width: '100%',
    marginBottom: '1rem',
  },
  textField: {
    fontSize: 12,
  },
  button: {
    margin: theme.spacing(1),
  },
  card: {
    width: '10.5rem',
    height: '13.25rem',
    marginBottom: '1rem',
    '&:hover': {
      cursor: 'pointer',
    },
    display: 'flex',
    flexDirection: 'column',
  },
  selectedCard: {
    width: '10.5rem',
    height: '13.25rem',
    marginBottom: '1rem',
    '&:hover': {
      cursor: 'pointer',
    },
    display: 'flex',
    flexDirection: 'column',
    border: `2px solid ${HIMSSBlue['600']}`,
  },
  cardHeader: {
    height: '3rem',
    padding: '1rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  cardContent: {
    backgroundColor: theme.palette.grey['50'],
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexGrow: 1,
    padding: '1rem',
    borderBottomRightRadius: '.25rem',
    borderBottomLeftRadius: '.25rem',
  },
  actionContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '16px 40px',
  },
  paper: {
    padding: theme.spacing(2),
  },
  surveysPaper: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
  },
});

function getSteps() {
  return ['Setup', 'Questions', 'Technology Questions', 'Thank You', 'Preview'];
}

class PatchedTableSelection extends PureComponent {
  render() {
    const { rowSelectionEnabled, ...restProps } = this.props;

    return <TableSelection cellComponent={(props) => (this.props.rowSelectionEnabled(props.tableRow.row) ? <TableSelection.Cell {...props} /> : <Table.StubCell {...props} />)} {...restProps} />;
  }
}

const surveyTemplates = [
  { name: 'One Page', description: 'Used for smaller surveys, all pages and tabs are displayed on a single page', disabled: false },
  { name: 'Full Survey', description: 'Used for large surveys with multiple pages and tabs to display more questions', disabled: false },
];
const getRowId = (row) => row.rowId;
const rowSelectionEnabled = (row) => !row.isBranchingQuestion;
const getChildRows = (row, rootRows) => {
  return row ? (row.branchingQuestions && row.branchingQuestions.length ? row.branchingQuestions : null) : rootRows;
};

//Recursively find the right question by rowId traversing through branching questions
const findQuestion = (questions, rowId) => {
  if (!questions) {
    return;
  }

  for (const question of questions) {
    if (question.rowId === rowId) {
      return question;
    }

    const childQuestion = findQuestion(question.branchingQuestions, rowId);
    if (childQuestion) {
      return childQuestion;
    }
  }
};

const findAllBranchingQuestionIDs = (questions) => {
  let branchingQuestionIDs = [];

  questions.forEach((q) => {
    q.branchingQuestions &&
      q.branchingQuestions.forEach((bq) => {
        branchingQuestionIDs = branchingQuestionIDs.concat(bq.questionID);
      });
  });

  branchingQuestionIDs = uniq(branchingQuestionIDs);
  return branchingQuestionIDs;
};

const populateQuestionData = (q, surveyQuestion, questions) => {
  q.isRequired = false;
  if (surveyQuestion) {
    q.isRequired = surveyQuestion.isRequired;
    q.sortOrder = surveyQuestion.sortOrder;
  }

  let branchingQuestionIDs = [];
  Object.values(q.branchingQuestions).forEach((q) => {
    branchingQuestionIDs = branchingQuestionIDs.concat(q);
  });

  branchingQuestionIDs = uniq(branchingQuestionIDs);

  q.branchingQuestions = [];
  branchingQuestionIDs.forEach((id) => {
    let question = find(questions, { questionID: id });
    question = clone(question);

    if (question) {
      if (q.technologyName) {
        question.technologyName = q.technologyName;
        question.technologyID = q.technologyID;
      }
      question.isBranchingQuestion = true;
      let surveyBranchingQuestion = surveyQuestion ? find(surveyQuestion.branchingQuestions, { questionID: id }) : null;
      populateQuestionData(question, surveyBranchingQuestion, questions);
      q.branchingQuestions.push(question);
    }
  });
};

const sortQuestionFunc = (a, b) => {
  if (a.pageName === b.pageName) {
    if (a.tabName === b.tabName) {
      return a.sortOrder - b.sortOrder;
    }
    return a.tabName > b.tabName ? 1 : -1;
  }
  return a.pageName > b.pageName ? 1 : -1;
};

const sortQuestions = (questions) => {
  questions.sort(sortQuestionFunc);
  questions.forEach((q) => {
    sortQuestions(q.branchingQuestions);
  });
};

const sortTechQuestionFunc = (a, b) => {
  if (a.technologyName === b.technologyName) {
    return a.sortOrder - b.sortOrder;
  }
  return a.technologyName > b.technologyName ? 1 : -1;
};

const sortTechQuestions = (questions) => {
  questions.sort(sortTechQuestionFunc);
  questions.forEach((q) => {
    sortTechQuestions(q.branchingQuestions);
  });
};

const setupRowIds = (q, counter) => {
  q.rowId = counter;
  counter++;
  q.branchingQuestions.forEach((q) => {
    q.rowId = counter;
    counter = setupRowIds(q, counter);
  });
  return counter;
};

const getQuestionRows = (questions, tabs, pages, surveyType = {}) => {
  //Clone the questions array before we start modifying the question objects
  questions = cloneDeep(questions);

  let questionRows = cloneDeep(questions).filter((q) => {
    const tab = find(tabs, { tabId: q.tabID });

    if (tab) {
      q.tabName = tab.tabName;
      const page = find(pages, { pageId: tab.pageId });

      if (page) {
        q.pageName = page.pageName;
        return true;
      }
    }
    return false;
  });

  // Filter the questions by the surveyType data.
  questionRows = questionRows.filter((q) => filterQuestion(surveyType, q));

  //Recursively populate question data for the grid
  questionRows.forEach((q) => {
    q.isBranchingQuestion = false;
    let surveyQuestion = surveyType.questions ? surveyType.questions.find((a) => a.questionID === q.questionID) : null;
    populateQuestionData(q, surveyQuestion, questions);
  });

  // Remove all branching questions from top level object
  const branchingQuestionIDs = findAllBranchingQuestionIDs(questionRows);
  questionRows = questionRows.filter((q) => !branchingQuestionIDs.includes(q.questionID));

  sortQuestions(questionRows);

  //Manually add rowIds to each row so that we can have consistent rowIds across renders
  let counter = 0;
  questionRows.forEach((q) => {
    counter = setupRowIds(q, counter);
    counter++;
  });

  return questionRows;
};

const getTechQuestionRows = (technologyQuestions, technologies, surveyType = {}) => {
  //Clone the technologyQuestions array before we start modifying the question objects
  technologyQuestions = cloneDeep(technologyQuestions);

  let techQuestionRows = [];

  technologyQuestions.forEach((q, index) => {
    if (!q.technologies || q.technologies.length === 0) {
      technologies.forEach((t) => {
        techQuestionRows.push({ isBranchingQuestion: false, technologyName: t.technologyName, technologyID: t.technologyID, ...q });
      });
    } else {
      q.technologies.forEach((technologyID) => {
        const tech = find(technologies, { technologyID });

        if (tech) {
          techQuestionRows.push({ isBranchingQuestion: false, technologyName: tech.technologyName, technologyID: tech.technologyID, ...q });
        }
      });
    }
  });

  // Filter the questions by the surveyType data.
  techQuestionRows = techQuestionRows.filter((q) => {
    let f = filterQuestion(surveyType, q);
    if (!f) {
      console.log(q);
    }
    return f;
  });

  //Recursively populate question data for the grid
  techQuestionRows.forEach((q) => {
    q.isBranchingQuestion = false;
    let surveyQuestion = surveyType.technologyQuestions ? surveyType.technologyQuestions.find((a) => a.questionID === q.questionID) : null;
    populateQuestionData(q, surveyQuestion, technologyQuestions);
  });

  // Remove all branching questions from top level object
  const branchingQuestionIDs = findAllBranchingQuestionIDs(techQuestionRows);
  techQuestionRows = techQuestionRows.filter((q) => !branchingQuestionIDs.includes(q.questionID));

  // Sort questions by the sort order
  sortTechQuestions(techQuestionRows);

  //Manually add rowIds to each row so that we can have consistent rowIds across renders
  let counter = 0;
  techQuestionRows.forEach((q) => {
    counter = setupRowIds(q, counter);
    counter++;
  });

  return techQuestionRows;
};

const getQuestionSelection = (surveyType, questionRows) => {
  let selection = [];
  surveyType.questions.forEach((q) => {
    const selected = find(questionRows, { questionID: q.questionID });
    if (selected) {
      selection.push(selected.rowId);
    }
  });
  return selection;
};

const getTechQuestionSelection = (surveyType, techQuestionRows) => {
  let techSelection = [];
  surveyType.technologyQuestions.forEach((q) => {
    const selected = find(techQuestionRows, (row) => {
      return (!row.technologies || row.technologies.length === 0 || row.technologies.indexOf(q.technologyID) !== -1) && q.questionID === row.questionID && q.technologyID === row.technologyID;
    });

    if (selected) {
      techSelection.push(selected.rowId);
    }
  });
  return techSelection;
};

const years = Array(100)
  .fill()
  .map((item, index) => {
    let year = 2000 + index;
    return { label: year + '', value: year };
  });

class SurveyTypeForm extends Component {
  constructor(props) {
    super(props);

    const { surveyType, questions, technologyQuestions, technologies, tabs, pages } = props;

    let selection = [];
    let techSelection = [];

    const questionRows = getQuestionRows(questions, tabs, pages, surveyType);
    const techQuestionRows = getTechQuestionRows(technologyQuestions, technologies, surveyType);

    if (!isEmpty(surveyType)) {
      selection = getQuestionSelection(surveyType, questionRows);
      techSelection = getTechQuestionSelection(surveyType, techQuestionRows);
    }

    this.state = {
      name: surveyType && surveyType.name ? surveyType.name : '',
      active: surveyType && surveyType.active ? surveyType.active : true,
      countries: surveyType && surveyType.countries ? surveyType.countries : [],
      haEntityTypes: surveyType && surveyType.haEntityTypes ? surveyType.haEntityTypes : [],
      languageOptions: surveyType && surveyType.languageOptions ? surveyType.languageOptions : [],
      entityType: surveyType && surveyType.surveyType ? surveyType.surveyType : '',
      surveyTypeID: surveyType && surveyType.surveyTypeID ? surveyType.surveyTypeID.toString() : '',
      surveyTemplate: surveyType && surveyType.surveyTemplate ? surveyType.surveyTemplate : '',
      startYear: surveyType && surveyType.startYear ? years.find(year => year.value === surveyType.startYear) : null,
      endYear: surveyType && surveyType.endYear ? years.find(year => year.value === surveyType.endYear) : null,
      isInternalSurvey: surveyType && surveyType.isInternalSurvey ? surveyType.isInternalSurvey : false,
      isExternalSurvey: surveyType && surveyType.isExternalSurvey ? surveyType.isExternalSurvey : false,
      questions: surveyType && surveyType.questions ? surveyType.questions : [],
      technologyQuestions: surveyType && surveyType.technologyQuestions ? surveyType.technologyQuestions : [],
      thankyouMessage: surveyType && surveyType.thankyouMessage ? surveyType.thankyouMessage : '',
      activeStep: 0,
      scoringType: surveyType && surveyType.scoringType ? { label: surveyType.scoringType, value: surveyType.scoringType } : { label: 'None', value: 'None' },
      widgets: surveyType && surveyType.widgets ? surveyType.widgets : [],
      pageSortOrder: surveyType && surveyType.pageSortOrder ? surveyType.pageSortOrder : [],
      tabSortOrder: surveyType && surveyType.tabSortOrder ? surveyType.tabSortOrder : [],
      countrySelectedValues: surveyType && surveyType.countries ? this.props.countriesList.filter(country => surveyType.countries.includes(country.value)) : [],
      defaultEntityID: surveyType && surveyType.defaultEntityID ? surveyType.defaultEntityID : null,
      description: surveyType && surveyType.description ? surveyType.description : '',
      showExternalPreview: false,
      showInternalPreview: false,
      countriesList: [],
      accountList: [],
      columns: [
        { name: 'pageName', title: 'Page' },
        { name: 'tabName', title: 'Tab' },
        { name: 'questionDescription', title: 'Question Description', getCellValue: (row) => `${row.questionDescription} (${row.questionID})` },
        { name: 'controlType', title: 'Control Type' },
        { name: 'isQuestionRequired', title: 'Is Required' },
        { name: 'sortOrder', title: 'Sort Order' },
      ],
      techQuestionColumns: [
        { name: 'technologyName', title: 'Technology' },
        { name: 'questionDescription', title: 'Question Description', getCellValue: (row) => `${row.questionDescription} (${row.questionID})` },
        { name: 'controlType', title: 'Control Type' },
        { name: 'isQuestionRequired', title: 'Is Required' },
        { name: 'sortOrder', title: 'Sort Order' },
      ],
      selection,
      techSelection,
      techQuestionRows,
      questionRows,
    };

    this.changeSelection = (selection) => {
      this.setState({ selection });
    };
    this.changeTechSelection = (techSelection) => this.setState({ techSelection });

    [
      'onSubmit',
      'handleNext',
      'handleBack',
      'handleReset',
      'handleInputChange',
      'handleCheckChange',
      'handleIsRequiredChange',
      'groupRow',
      'groupCell',
      'selectAll',
      'deselectAll',
      'IsQuestionRequiredCell',
      'Cell',
      'handleSelectChange',
      'updateSelectedValueField',
      'convertOrganizationTypesToValues',
      'toggleInternalPreview',
      'toggleExternalPreview',
      'closePreview',
      'setSurveyTemplate',
      'updateQuestions',
    ].map((name) => {
      return (this[name] = this[name].bind(this));
    });
  }
  componentDidMount() {
    const { haEntityTypes } = this.state;
    if (haEntityTypes && haEntityTypes.length > 0) this.props.getAccountsByType(haEntityTypes);
  }
  static getDerivedStateFromProps(props, state) {
    let newState = { ...state };

    if (!state.countriesList.length && props.countries) {
      newState['countriesList'] = props.countries.map((country) => {
        if (country.countryID) {
          return {
            value: country.countryID,
            label: country.country
          };
        } else {
          return {
            value: country.geographicalCountryID,
            label: country.geographicalCountry,
          };
        }
      });
    }

    /* Add all of the custom validation rules */
    Object.keys(customValidationRules).forEach((ruleName) => {
      ValidatorForm.addValidationRule(ruleName, customValidationRules[ruleName]);
    });

    if (newState.startYear === '' && newState.surveyTypeID === '') {
      newState.startYear = new Date().getFullYear();
    }

    return { ...newState };
  }

  updateQuestion(q) {
    let question = {
      questionID: q.questionID,
      sortOrder: q.sortOrder,
      isRequired: q.isRequired,
      branchingQuestions: q.branchingQuestions.map((q) => {
        return this.updateQuestion(q);
      }),
    };

    if (q.technologyID) {
      question.technologyID = q.technologyID;
    }

    return question;
  }

  updateQuestions() {
    const { ...data } = this.state;

    if (this.state.selection.length) {
      data.questions = this.state.selection.map((selection) => {
        const q = find(data.questionRows, { rowId: selection });
        return this.updateQuestion(q);
      });
    } else {
      data.questions = [];
    }

    if (this.state.techSelection.length) {
      data.technologyQuestions = this.state.techSelection.map((selection) => {
        const q = find(data.techQuestionRows, { rowId: selection });
        return this.updateQuestion(q);
      });
    } else {
      data.technologyQuestions = [];
    }

    this.setState({ ...data });
  }

  closePreview() {
    this.setState({ showExternalPreview: false, showInternalPreview: false });
  }

  handleInputChange(event) {
    const { name, value } = event.target;
    const { scoringType } = this.state;
    const widgets = [...this.state.widgets];

    if (name.includes('widget')) {
      if ((scoringType.value !== 'None' && !widgets.length) || (scoringType.value === 'None' && widgets.length < 2)) {
        widgets.push({ content: '', image: '' });
      } else {
        if (name.includes('one')) {
          widgets.splice(0, 1);
        } else {
          widgets.splice(1, 1);
        }
      }

      this.setState({ widgets });
    } else if (name.includes('image-')) {
      if (name.includes('one')) {
        widgets[0][name.split('-')[0]] = value;
      } else {
        widgets[1][name.split('-')[0]] = value;
      }

      this.setState({ widgets });
    } else if (name.includes('content-')) {
      if (name.includes('one')) {
        widgets[0][name.split('-')[0]] = value;
      } else {
        widgets[1][name.split('-')[0]] = value;
      }

      this.setState({ widgets });
    } else {
      this.setState({
        [name]: value,
      });
    }
  }

  handleIsRequiredChange(props) {
    const { row } = props;
    const { questionRows, techQuestionRows } = this.state;

    if (row.technologyName) {
      let question = findQuestion(techQuestionRows, row.rowId);
      question.isRequired = !question.isRequired;
      //Updating by reference
      this.setState({ techQuestionRows });
    } else {
      let question = findQuestion(questionRows, row.rowId);
      question.isRequired = !question.isRequired;
      //Updating by reference
      this.setState({ questionRows });
    }
  }

  handleSortOrderChange(props, event) {
    const { row } = props;
    const { questionRows, techQuestionRows } = this.state;

    let value = event.target.value ? parseInt(event.target.value) : '';

    if (row.technologyName) {
      let question = findQuestion(techQuestionRows, row.rowId);
      question.sortOrder = value;
      //Updating by reference
      this.setState({ techQuestionRows });
    } else {
      let question = findQuestion(questionRows, row.rowId);
      question.sortOrder = value;
      //Updating by reference
      this.setState({ questionRows });
    }
  }

  handleSortOrderBlur(props) {
    const { row } = props;
    const { questionRows, techQuestionRows } = this.state;

    if (row.technologyName) {
      //Have to do the slice so it doesnt mutate state
      let rows = techQuestionRows.slice();
      sortTechQuestions(rows);
      this.setState({ techQuestionRows: rows });
    } else {
      //Have to do the slice so it doesnt mutate state
      let rows = questionRows.slice();
      sortQuestions(rows);
      this.setState({ questionRows: rows });
    }
  }

  handleNext(index) {
    let { activeStep, startYear, endYear, name, surveyTemplate, isExternalSurvey, isInternalSurvey, questionRows, techQuestionRows, selection, techSelection, widgets } = this.state;

    if (activeStep === 0) {
      if (name === '') {
        this.props.handleToastMessage('Survey Name must be filled.', true);
        return;
      } else if (startYear === null) {
        this.props.handleToastMessage('Start Year must be selected.', true);
        return;
      } else if (endYear !== null && startYear.value > endYear.value) {
        this.props.handleToastMessage('End Year must be equal to or greater than Start Year.', true);
        return;
      } else if (surveyTemplate === '') {
        this.props.handleToastMessage('A survey template must be selected', true);
        return;
      } else if (!isInternalSurvey && !isExternalSurvey) {
        this.props.handleToastMessage('A survey must be either an internal or external survey or both.', true);
        return;
      }

      // Update the questions based on any updates to the surveyType
      let { surveyType, questions, tabs, pages, technologies, technologyQuestions } = this.props;
      surveyType = { ...surveyType, ...this.state };

      questionRows = getQuestionRows(questions, tabs, pages, this.state.questions, surveyType);
      techQuestionRows = getTechQuestionRows(technologyQuestions, technologies, this.state.technologyQuestions, surveyType);

      if (!isEmpty(surveyType)) {
        selection = getQuestionSelection(surveyType, questionRows);
        techSelection = getTechQuestionSelection(surveyType, techQuestionRows);
      }

      this.setState({
        questionRows,
        techQuestionRows,
        selection,
        techSelection,
      });
    } else if (activeStep === 3) {
      if (!isEmpty(widgets)) {
        for (let i = 0; i < widgets.length; i++) {
          if (widgets[i].content === undefined || widgets[i].content === '') {
            this.props.handleToastMessage(`You must select a widget value for the ${widgets[i].type} widget`, true);
            return;
          }
        }
      }
    }

    this.updateQuestions();

    this.setState({
      activeStep: typeof index === 'number' ? index : activeStep + 1,
    });
  }

  handleBack() {
    const { activeStep } = this.state;

    if (activeStep === 0) {
      this.props.history.goBack();
    } else {
      this.setState({
        activeStep: activeStep - 1,
      });
    }
  }

  handleReset() {
    this.setState({
      activeStep: 0,
    });
  }

  toggleInternalPreview() {
    this.setState({ showInternalPreview: !this.state.showInternalPreview });
  }

  toggleExternalPreview() {
    this.setState({ showExternalPreview: !this.state.showExternalPreview });
  }

  handleCheckChange(name) {
    return (event) => {
      this.setState({ [name]: event.target.checked });
    };
  }

  handleSelectChange(name) {
    return (event, value) => {
      if (name === 'scoringType' && this.state.widgets.length === 2) {
        this.props.handleToastMessage('You can only display two out of the three widgets', true);
        return;
      }

      if (name.includes('image-')) {
        const widgets = [...this.state.widgets];
        const imageIndex = findIndex(widgets, { media: value.value });

        if ((imageIndex === 0 && name.includes('two')) || (imageIndex === 1 && name.includes('one'))) {
          this.props.handleToastMessage('You can not select the same widget for both widgets', true);
          return;
        } else if (name.includes('one')) {
          widgets[0].media = value.value;
        } else {
          widgets[1].media = value.value;
        }

        this.setState({
          widgets,
        });
      }

      this.setState({
        [name]: value,
      });
    };
  }

  IsQuestionRequiredCell(props) {
    const { row } = props;

    return (
      <VirtualTable.Cell {...props}>
        <Switch checked={row.isRequired} onChange={() => this.handleIsRequiredChange(props)} value="isQuestionRequired" />
      </VirtualTable.Cell>
    );
  }

  SortOrderCell(props) {
    const { row } = props;

    return (
      <VirtualTable.Cell {...props}>
        <TextField
          name="value"
          margin="dense"
          value={row.sortOrder}
          onChange={(event) => this.handleSortOrderChange(props, event)}
          onBlur={() => this.handleSortOrderBlur(props)}
          variant="outlined"
          type="number"
        />
      </VirtualTable.Cell>
    );
  }

  Cell(props) {
    const { column } = props;

    if (column.name === 'isQuestionRequired') {
      return this.IsQuestionRequiredCell(props);
    }

    if (column.name === 'sortOrder') {
      return this.SortOrderCell(props);
    }

    return <VirtualTable.Cell {...props} />;
  }

  groupCell({ colSpan, ...restProps }) {
    return <TableGroupRow.Cell {...restProps} colSpan={colSpan - 4} />;
  }

  groupRow({ row, ...restProps }) {
    const { selection, techSelection, questionRows, techQuestionRows } = this.state;
    let selectedCount = 0;

    if (row.groupedBy === 'pageName' || row.groupedBy === 'tabName') {
      if (row.groupedBy === 'pageName') {
        questionRows.forEach((q) => {
          if (q.pageName === row.value && selection.indexOf(q.rowId) !== -1) {
            selectedCount++;
          }
        });
      } else {
        let split = row.compoundKey.split('|');
        questionRows.forEach((q) => {
          if (q.pageName === split[0] && q.tabName === split[1] && selection.indexOf(q.rowId) !== -1) {
            selectedCount++;
          }
        });
      }
    } else {
      techQuestionRows.forEach((q) => {
        if (q.technologyName === row.value && techSelection.indexOf(q.rowId) !== -1) {
          selectedCount++;
        }
      });
    }

    return (
      <TableGroupRow.Row row={row}>
        {restProps.children}
        <VirtualTable.Cell colSpan={4} style={{ textAlign: 'right' }}>
          <span>( {selectedCount} Selected )</span>
          <Button
            color="primary"
            onClick={() => {
              this.selectAll(row);
            }}
          >
            Select All
          </Button>
          <Button
            color="primary"
            onClick={() => {
              this.deselectAll(row);
            }}
          >
            Deselect All
          </Button>
        </VirtualTable.Cell>
      </TableGroupRow.Row>
    );
  }

  selectAll(row) {
    const { questionRows, techQuestionRows, selection, techSelection } = this.state;

    if (row.groupedBy === 'pageName' || row.groupedBy === 'tabName') {
      if (row.groupedBy === 'pageName') {
        questionRows.forEach((q) => {
          if (q.pageName === row.value && selection.indexOf(q.rowId) === -1) {
            selection.push(q.rowId);
          }
        });
      } else {
        let split = row.compoundKey.split('|');
        questionRows.forEach((q) => {
          if (q.pageName === split[0] && q.tabName === split[1] && selection.indexOf(q.rowId) === -1) {
            selection.push(q.rowId);
          }
        });
      }

      this.changeSelection(selection);
    } else {
      techQuestionRows.forEach((q) => {
        if (q.technologyName === row.value && techSelection.indexOf(q.rowId) === -1) {
          techSelection.push(q.rowId);
        }
      });

      this.changeTechSelection(techSelection);
    }
  }

  convertEntityTypesToValues() {
    return this.props.entityTypes.reduce((list, type) => {
      if (type.entityTypeId === parseInt(this.state.entityType)) {
        list = {
          value: type.entityTypeId,
          label: type.name,
        };
      }
      return list;
    }, '');
  }

  deselectAll(row) {
    const { questionRows, techQuestionRows, selection, techSelection } = this.state;

    if (row.groupedBy === 'pageName' || row.groupedBy === 'tabName') {
      if (row.groupedBy === 'pageName') {
        questionRows.forEach((q) => {
          if (q.pageName === row.value && selection.indexOf(q.rowId) !== -1) {
            selection.splice(selection.indexOf(q.rowId), 1);
          }
        });
      } else {
        let split = row.compoundKey.split('|');
        questionRows.forEach((q) => {
          if (q.pageName === split[0] && q.tabName === split[1] && selection.indexOf(q.rowId) !== -1) {
            selection.splice(selection.indexOf(q.rowId), 1);
          }
        });
      }
      this.changeSelection(selection);
    } else {
      techQuestionRows.forEach((q) => {
        if (q.technologyName === row.value && techSelection.indexOf(q.rowId) !== -1) {
          techSelection.splice(techSelection.indexOf(q.rowId), 1);
        }
      });

      this.changeTechSelection(techSelection);
    }
  }

  onSubmit() {
    const { selection, techSelection, ...data } = this.state;
    const countries = [];

    data.countrySelectedValues &&
      data.countrySelectedValues.map((c) => {
        countries.push(c.value);
      });

    data.surveyType = data.entityType;
    data.countries = countries;
    data.endYear = data.endYear !== null ? data.endYear.value : null;
    data.startYear = data.startYear !== null ? data.startYear.value : null;

    if (!isEmpty(data.scoringType)) {
      data.scoringType = data.scoringType.value;
    }

    // Delete keys that only for local use
    [
      'activeStep',
      'countriesList',
      'countrySelectedValues',
      'columns',
      'questionRows',
      'techQuestionRows',
      'selection',
      'showInternalPreview',
      'showExternalPreview',
      'techQuestionColumns',
      'techSelection',
    ].map((k) => delete data[k]);

    if (data.active === null) {
      data.active = true;
    }

    /* If this is a new surveyType, pass in zero as the surveyTypeID */
    if (this.props.match.params.surveyTypeId === 'new') {
      data.surveyTypeID = 0;
    }
    this.props.onSubmit(data);
  }

  updateSelectedValueField(field) {
    return (event, val) => {
      const values = [...val.map((v) => v.value)];
      if (values) {
        this.props.getAccountsByType(values);
      }
      this.setState({ [field]: values });
    };
  }

  convertOrganizationTypesToValues(types) {
    return types.map((type) => {
      const found = this.props.organizationTypes.find((t) => t.organizationTypeID === type);
      if (found) {
        return { value: found.organizationTypeID, label: found.organizationTypeName };
      } else {
        return null;
      }
    });
  }

  setSurveyTemplate(surveyTemplate) {
    this.setState({ surveyTemplate });
  }
  //onEntityTypeChange(field) {
  //  return (value) => {
  //    console.log('onEntityTypeChange', field, value);
  //    this.setState({ [field]: value.value });
  //    const selectedEntityType = this.props.entityTypes.find((et) => et.entityTypeId == value.value);
  //    if (selectedEntityType) {
  //      this.props.getAccountsByType(selectedEntityType.haEntityTypes);
  //    }
  //  };
  //const test = await
  //}
  render() {
    const { classes, permissions, entityTypes, surveyTypeReferences, accountList, isLoading } = this.props;
    const { questionRows, columns, activeStep, selection, techQuestionRows, techQuestionColumns, techSelection, thankyouMessage, scoringType, widgets, isExternalSurvey, description } = this.state;
    const steps = getSteps();

    return isLoading ? (
      <LoadingOverlay />
    ) : (
      <div>
        <Stepper nonLinear activeStep={activeStep}>
          {steps.map((label, index) => {
            return (
              <Step key={label}>
                <StepButton onClick={() => this.handleNext(index)}>{label}</StepButton>
              </Step>
            );
          })}
        </Stepper>
        <ValidatorForm onSubmit={this.onSubmit} onError={this.props.onError} className={classes.form}>
          {activeStep === 0 && (
            <Grid container spacing={10}>
              <Grid item xs={12} md={6}>
                <FormControl className={classes.formControl}>
                  <TextField
                    className={classes.textField}
                    disabled={!hasPermission(EDIT_SURVEY_TYPES, permissions)}
                    key="Survey Name"
                    fullWidth
                    name="name"
                    label="Survey Name"
                    margin="dense"
                    value={this.state.name}
                    onChange={(event) => this.handleInputChange(event)}
                  />
                </FormControl>
                <FormControl className={classes.formControl}>
                  <CustomSelect
                    label="Survey Type"
                    value={this.convertEntityTypesToValues(this.props.entityTypes, this.state.entityType)}
                    onChange={(field) => {
                      return (event, val) => {
                        this.setState({ [field]: val ?  val.value : ''});
                      };
                    }}
                    disabled={!hasPermission(EDIT_SURVEY_TYPES, permissions)}
                    options={entityTypes.map((et) => ({
                      label: et.name,
                      value: et.entityTypeId.toString(),
                    }))}
                    name="entityType"
                  />
                </FormControl>

                <FormControl className={classes.formControl} style={{ display: this.state.entityType === '1' ? 'block' : 'none' }}>
                  <CustomSelect
                    options={this.props.organizationTypes.map((item) => ({
                      value: item.organizationTypeID,
                      label: item.organizationTypeName,
                    }))}
                    label="Organization Type(s)"
                    name="haEntityTypes"
                    disabled={!hasPermission(EDIT_SURVEY_TYPES, permissions)}
                    className={classes.select}
                    onChange={this.updateSelectedValueField}
                    value={this.convertOrganizationTypesToValues(this.state.haEntityTypes)}
                    isMulti={true}
                  />
                </FormControl>
                <FormControl className={classes.formControl}>
                  <CustomSelect
                    label="Default Entity"
                    value={accountList.find((a) => a.value === this.state.defaultEntityID)}
                    onChange={(field) => {
                      return (event, val) => {
                        this.setState({ [field]: val ? val.value : '' });
                      };
                    }}
                    options={[{ label: '', value: '' }, ...accountList]}
                    disabled={!hasPermission(EDIT_SURVEY_TYPES, permissions)}
                    name="defaultEntityID"
                  />
                </FormControl>
                <FormControl className={classes.formControl}>
                  <CustomSelect
                    label="Countries"
                    value={this.state.countrySelectedValues}
                    onChange={this.handleSelectChange}
                    options={this.state.countriesList}
                    disabled={!hasPermission(EDIT_SURVEY_TYPES, permissions)}
                    name="countrySelectedValues"
                    isMulti={true}
                    isClearable={true}
                  />
                </FormControl>
                <Grid item xs={12}>
                  <Typography gutterBottom variant="h5">
                    Survey Template
                  </Typography>
                  <Grid container justifyContent="space-around">
                    {surveyTemplates.map((template) => {
                      if (!template.disabled) {
                        return (
                          <Grid item key={template.name}>
                            <Paper
                              classes={{ root: template.name === this.state.surveyTemplate ? classes.selectedCard : classes.card }}
                              onClick={() => this.setSurveyTemplate(template.name)}
                              disabled={template.disabled}
                            >
                              <div className={classes.cardHeader}>{template.name}</div>
                              <div className={classes.cardContent}>
                                <Typography variant="caption">{template.description}</Typography>
                              </div>
                            </Paper>
                          </Grid>
                        );
                      }
                    })}
                  </Grid>
                  <Grid container spacing={8}>
                    <Grid item xs={12} md={6}>
                      <CustomSelect
                        label="Start Year"
                        value={this.state.startYear}
                        onChange={this.handleSelectChange}
                        options={years}
                        disabled={!hasPermission(EDIT_SURVEY_TYPES, permissions)}
                        name="startYear"
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <CustomSelect
                        label="End Year"
                        value={this.state.endYear}
                        onChange={this.handleSelectChange}
                        options={years}
                        disabled={!hasPermission(EDIT_SURVEY_TYPES, permissions)}
                        name="endYear"
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={6}>
                <Grid container style={{ height: '100%' }} direction="column">
                  <Grid item>
                    <FormGroup row style={{ justifyContent: 'space-around' }}>
                      <FormControlLabel
                        control={<Checkbox checked={this.state.isInternalSurvey} onChange={this.handleCheckChange('isInternalSurvey')} name="isInternalSurvey" color="primary" />}
                        label="Internal Survey"
                      />
                      <FormControlLabel
                        control={<Checkbox checked={this.state.isExternalSurvey} onChange={this.handleCheckChange('isExternalSurvey')} name="isExternalSurvey" color="primary" />}
                        label="External Survey"
                      />
                    </FormGroup>
                    <CustomTextField
                      required={false}
                      key="description"
                      name="description"
                      fullWidth
                      margin="dense"
                      multiline={true}
                      maxRows="10"
                      label="Description"
                      value={description}
                      onChange={(event) => this.setState({ description: event.target.value })}
                    />
                    <Paper className={classes.surveyTypesPaper}>
                      <Typography variant="subtitle1" component="div">
                        <div dangerouslySetInnerHTML={{ __html: description }} />
                      </Typography>
                    </Paper>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={6}>
                {surveyTypeReferences.length > 0 && (
                  <Paper className={classes.surveysPaper}>
                    <Typography variant="subtitle1">Surveys</Typography>
                    <List dense={true}>
                      {surveyTypeReferences.map((i, index) => (
                        <ListItem button key={index}>
                          <ListItemText primary={`${i.surveyName}: ${i.status}`} onClick={() => this.props.history.push(`/surveys/${i.surveyID}`)} />
                        </ListItem>
                      ))}
                    </List>
                  </Paper>
                )}
              </Grid>
            </Grid>
          )}
          {activeStep === 3 && (
            <ThankYouForm
              isExternalSurvey={isExternalSurvey}
              onChange={this.handleInputChange}
              onSelectChange={this.handleSelectChange}
              permissions={permissions}
              scoringType={scoringType}
              thankyouMessage={thankyouMessage}
              widgets={widgets}
            />
          )}
          {activeStep === 1 && (
            <Paper className={classes.paper}>
              <DevGrid rows={questionRows} columns={columns} getRowId={getRowId}>
                <GroupingState grouping={[{ columnName: 'pageName' }, { columnName: 'tabName' }]} />
                <IntegratedGrouping />
                <TreeDataState />
                <CustomTreeData getChildRows={getChildRows} />
                <SelectionState selection={selection} onSelectionChange={this.changeSelection} />
                <VirtualTable cellComponent={this.Cell} estimatedRowHeight={67} />
                <TableHeaderRow />
                <TableTreeColumn for="questionDescription" />
                <PatchedTableSelection rowSelectionEnabled={rowSelectionEnabled} />
                <TableGroupRow rowComponent={this.groupRow} cellComponent={this.groupCell} />
                <Getter
                  name="tableColumns"
                  computed={({ tableColumns }) => {
                    const groups = [...tableColumns.filter((c) => c.type.toString().match(/group/))];
                    groups[0].width = 15;
                    groups[1].width = 15;

                    const columns = [...tableColumns.filter((c) => !c.type.toString().match(/select/) && !c.type.toString().match(/group/))];
                    columns[1].width = 150; //controlType
                    columns[2].width = 100; //isQuestionRequired
                    columns[3].width = 100; //sortOrder

                    const select = [...tableColumns.filter((c) => c.type.toString().match(/select/))];
                    select[0].width = 100;

                    return [...groups, ...columns, ...select];
                  }}
                />
              </DevGrid>
            </Paper>
          )}
          {activeStep === 2 && (
            <Paper className={classes.paper}>
              <DevGrid rows={techQuestionRows} columns={techQuestionColumns} getRowId={getRowId}>
                <GroupingState grouping={[{ columnName: 'technologyName' }]} />
                <IntegratedGrouping />
                <TreeDataState />
                <CustomTreeData getChildRows={getChildRows} />
                <SelectionState selection={techSelection} onSelectionChange={this.changeTechSelection} />
                <VirtualTable cellComponent={this.Cell} estimatedRowHeight={67} />
                <TableHeaderRow />
                <TableTreeColumn for="questionDescription" />
                <PatchedTableSelection rowSelectionEnabled={rowSelectionEnabled} />
                <TableGroupRow rowComponent={this.groupRow} cellComponent={this.groupCell} />
                <Getter
                  name="tableColumns"
                  computed={({ tableColumns }) => {
                    const groups = [...tableColumns.filter((c) => c.type.toString().match(/group/))];
                    groups[0].width = 30;

                    const columns = [...tableColumns.filter((c) => !c.type.toString().match(/select/) && !c.type.toString().match(/group/))];
                    columns[1].width = 150; //controlType
                    columns[2].width = 100; //isQuestionRequired
                    columns[3].width = 100; //sortOrder

                    const select = [...tableColumns.filter((c) => c.type.toString().match(/select/))];
                    select[0].width = 100;

                    return [...groups, ...columns, ...select];
                  }}
                />
              </DevGrid>
            </Paper>
          )}
          {activeStep === 4 && (
            <div>
              Click below to see a preview of this survey type: <br />
              <br />
              <Button variant="contained" color="primary" onClick={() => this.toggleInternalPreview()} disabled={!this.state.isInternalSurvey} style={{ marginRight: '1rem' }}>
                Internal Survey
              </Button>
              <Button variant="contained" color="primary" onClick={() => this.toggleExternalPreview()} disabled={!this.state.isExternalSurvey}>
                External Survey
              </Button>
            </div>
          )}
          <div className={classes.actionContainer}>
            <Button variant="text" color="primary" onClick={this.handleBack} className={classes.button}>
              Back
            </Button>
            <Button type="submit" disabled={!hasPermission('EDIT_SURVEY_TYPES', permissions) || activeStep !== 4} variant="contained" color="primary" className={classes.button}>
              Save
            </Button>
            <Button disabled={activeStep >= 4} variant="contained" color="primary" onClick={this.handleNext} className={classes.button}>
              Next
            </Button>
          </div>
        </ValidatorForm>
        <Dialog fullScreen open={this.state.showExternalPreview || this.state.showInternalPreview} onClose={() => this.closePreview()}>
          <AppBar position="relative">
            <Toolbar>
              <Typography variant="h5" style={{ flex: 1, color: 'white' }}>
                SURVEY PREVIEW
              </Typography>
              <Button className={classes.button} onClick={() => this.closePreview()}>
                <FontAwesomeIcon icon="times" style={{ color: 'white', fontSize: '150%' }} />
              </Button>
            </Toolbar>
          </AppBar>
          <PreviewSurveyType
            internalSurvey={this.state.showInternalPreview}
            showPreview={this.state.showInternalPreview || this.state.showExternalPreview}
            surveyType={this.state}
            questions={this.props.questions}
            tabs={this.props.tabs}
            pages={this.props.pages}
            answers={this.props.answers}
            technologyQuestions={this.props.technologyQuestions}
            technologies={this.props.technologies}
            countriesList={this.props.countriesList}
            regionsList={this.props.regionsList}
            productsList={this.props.productsList}
            technologiesList={this.props.technologiesList}
            vendorsList={this.props.vendorsList}
            hospitals={[]}
            ambulatories={[]}
            subAcutes={[]}
            homeHealths={[]}
            freeStandingDataCenters={[]}
            inHospitalDataCenters={[]}
            untetheredAmbulatories={[]}
          />
        </Dialog>
      </div>
    );
  }
}

SurveyTypeForm.propTypes = {
  answers: PropTypes.array,
  surveyType: PropTypes.object,
  organizationTypes: PropTypes.array,
  pages: PropTypes.array,
  tabs: PropTypes.array,
  questions: PropTypes.array,
  permissions: PropTypes.array,
  countries: PropTypes.array,
  technologyQuestions: PropTypes.array,
  technologies: PropTypes.array,
  history: PropTypes.object,
  entityTypes: PropTypes.array,
  surveyTypeReferences: PropTypes.array,
};

function mapStateToProps(state, props) {
  return {
    accountList: state.organizations.get('accountList'),
    isLoading: state.organizations.get('isLoading'),
  };
}

export default withStyles(styles)(
  connect(mapStateToProps, {
    handleToastMessage,
    getAccountsByType,
  })(withRouter(SurveyTypeForm))
);
