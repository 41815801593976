import { Getter } from '@devexpress/dx-react-core';
import { EditingState } from '@devexpress/dx-react-grid';
import { Grid, Table, TableEditColumn, TableEditRow, TableHeaderRow } from '@devexpress/dx-react-grid-material-ui';
import { FormControl, Paper, TableCell } from '@mui/material';
import { withStyles } from "@mui/styles";
import { find, findIndex, isEmpty, sortBy, cloneDeep } from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import CustomSaveTableRow from '../formControls/CustomSaveTableRow';
import { getVendorsList } from '@survey/common/dist/actions/vendors.actions';
import { getProductsList } from '@survey/common/dist/actions/products.actions';
import CustomSelect from '@survey/common/dist/components/form-controls/CustomSelect';
import { getCountriesList, getRegionsList } from '@survey/common/dist/actions/countries.actions';
import { getTechnologiesList } from '@survey/common/dist/actions/technologies.actions';
import { getAnswers } from '@survey/common/dist/actions/answers.actions';
import PropTypes, { object } from 'prop-types';

const styles = (theme) => ({
  gridPaper: {
    width: '100%',
    position: 'relative',
  },
  disabledOverlay: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    left: 0,
    top: 0,
    backgroundColor: 'rgb(211,212,214,0.8)',
    zIndex: 1,
  },
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(.5),
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: theme.spacing(.25),
  },
  smallBtn: {
    padding: '0px',
    margin: '0px',
  },
});

const QuestionEditor = ({ questions, value, onValueChange }) => {
  let questionValue = value;

  if (typeof value === 'string' && value.length > 0) {
    questionValue = questions.find((a) => a.label === value);
  }

  return (
    <FormControl fullWidth={true}>
      <CustomSelect disabled={false} name="selectedQuestion" fullWidth={true} value={questionValue ? questionValue : ''} onChange={(e) => onValueChange(e)} options={questions} label="" />
    </FormControl>
  );
};

const AnswerEditor = ({ answers, selectedAnswers, onValueChange }) => {
  return (
    <FormControl fullWidth={true}>
      <CustomSelect disabled={false} name="selectedAnswers" fullWidth={true} isMulti={true} value={selectedAnswers} onChange={(e) => onValueChange(e)} options={answers} label="" />
    </FormControl>
  );
};
const AnswerKeyEditor = ({ answers, value, onValueChange }) => {
  let answerKeyValue = value;
  if (typeof value === 'string' && value.length > 0) {
    answerKeyValue = answers.find((a) => a.label === value);
  }
  return (
    <FormControl fullWidth={true}>
      <CustomSelect disabled={false} name="selectedAnswerKey" fullWidth={true} value={answerKeyValue ? answerKeyValue : ''} onChange={(e) => onValueChange(e)} options={answers} label="" />
    </FormControl>
  );
};

class AnswerKeysSelector extends Component {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        {
          name: 'question',
          title: 'Question',
        },
        {
          name: 'answers',
          title: 'Answers',
        },
        {
          name: 'answerKey',
          title: 'Answer Key',
        },
      ],
      editingRowIds: [],
      addedRows: [],
      rowChanges: {},
      selectedAnswers: [],
      formattedAnswerKeys: [],
    };

    if (this.props.questions) {
      this.state.questionsList = this.mapQuestionsToValues(this.props.questions);
    }
    if (this.props.answers) {
      this.state.answerKeyList = this.mapAnswersToValues(this.props.answers);
    }
    if (this.props.answerKeys) {
      this.state.formattedAnswerKeys = this.transformAnswerKeys(this.props.answerKeys);
    }

    [
      'mapAnswersToValues',
      'mapQuestionsToValues',
      'changeAddedRows',
      'changeEditingRowIds',
      'changeRowChanges',
      'selectCellEditor',
      'commitChanges',
      'updateValue',
      'transformAnswerKeys',
      'verifyQuestions',
      'saveRow',
    ].map((item) => (this[item] = this[item].bind(this)));
  }
  
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.answerKeys !== this.props.answerKeys) {
      const formattedAnswerKeys = this.transformAnswerKeys(this.props.answerKeys);

      this.setState({ formattedAnswerKeys });
    }
  }

  componentDidMount() {
    const { answers, countriesList, productsList, regionsList, technologiesList, vendorsList } = this.props;

    isEmpty(countriesList) && this.props.getCountriesList();
    isEmpty(productsList) && this.props.getProductsList();
    isEmpty(regionsList) && this.props.getRegionsList();
    isEmpty(technologiesList) && this.props.getTechnologiesList();
    isEmpty(vendorsList) && this.props.getVendorsList();
    //isEmpty(questions) && this.props.getQuestions();
    isEmpty(answers) && this.props.getAnswers();
  }

  mapQuestionsToValues(questions) {
    questions = sortBy(questions, [(question) => question.questionDescription.toLowerCase()]);
    const validQuestions = questions.reduce((all, q) => {
      if (q.answerID) all.push(q);
      return all;
    }, []);
    const questionsList = validQuestions.map((q) => ({
      value: q.questionID,
      label: `${q.questionDescription} (${q.questionID})`,
    }));

    return questionsList;
  }
  mapAnswersToValues(answers) {
    const sortedAnswers = sortBy(cloneDeep(answers), [(answer) => answer.answerName.toLowerCase()]);

    const answersList = sortedAnswers.map((a) => ({
      value: a.answerID,
      label: a.answerName,
    }));

    return answersList;
  }

  updateValue(field, onValueChange) {
    return (event, val) => {
      onValueChange(val);

      this.setState({
        [field]: val,
      });
      if (field === 'selectedQuestion') {
        var q = this.props.questions.find((q) => val && q.questionID === val.value);
        var answer = this.props.answers.find((a) => q && a.answerID === q.answerID);
        if (answer) {
          let dropdownAnswers = [];
          if (answer.type === 'API') {
            dropdownAnswers = this.props[answer.apiName];
          } else {
            dropdownAnswers = answer.answers;
          }
          this.setState({
            answers: dropdownAnswers,
          });
        }
      }
    };
  }

  saveRow(value) {
    if (!isEmpty(value.added) && this.state.addedRows.length) {
      if (this.verifyQuestions(this.state.addedRows)) {
        this.commitChanges({ added: [...this.state.addedRows], changed: null, deleted: null });
        this.setState({ addedRows: [], rowChanges: {}, selectedAnswers: [] });
      }
    } else if (!isEmpty(value.changed)) {
      const changed = this.state.editingRowIds.reduce((obj, val) => {
        obj[val] = { ...this.state.formattedAnswerKeys[val], ...this.state.rowChanges[val] };
        return obj;
      }, {});

      if (this.verifyQuestions(Object.values(changed))) {
        this.commitChanges({ added: null, changed, deleted: null });
        this.setState({ editingRowIds: [], selectedAnswers: [] });
      }
    } else {
      this.commitChanges({ added: null, changed: null, deleted: value.deleted });
    }
  }

  selectCellEditor({ column, value, onValueChange, row }) {
    const { selectedAnswers, answers } = this.state;
    if (column.name === 'question') {
      return (
        <TableCell style={{ padding: '0px 10px' }}>
          <QuestionEditor key={value} questions={this.mapQuestionsToValues(this.props.questions)} value={value} onValueChange={(field) => this.updateValue(field, onValueChange)} />
        </TableCell>
      );
    } else if (column.name === 'answerKey') {
      return (
        <TableCell style={{ padding: '0px 10px' }}>
          <AnswerKeyEditor answers={this.mapAnswersToValues(this.props.answers)} value={value} onValueChange={(field) => this.updateValue(field, onValueChange)} />
        </TableCell>
      );
    } else {
      return (
        <TableCell style={{ padding: '0px 10px' }}>
          <AnswerEditor answers={answers ? answers : []} selectedAnswers={selectedAnswers} onValueChange={(field) => this.updateValue(field, onValueChange)} />
        </TableCell>
      );
    }
  }

  /* Verify that any questions being added or updated have all of the requisite
     fields */
  verifyQuestions(questions) {
    let hasFailed = false;

    questions.forEach((question) => {
      if (!question.question || question.question.length < 1) {
        this.props.handleToastMessage('A question must be selected before saving changes!', true);
        hasFailed = true;
      } else if (!question.answerKey || question.answerKey.length < 1) {
        this.props.handleToastMessage('An Answer Key must be selected before saving changes!', true);
        hasFailed = true;
      } else if (!question.answers || question.answers.length < 1) {
        this.props.handleToastMessage('An answer must be selected before saving changes!', true);
        hasFailed = true;
      }
    });

    return !hasFailed;
  }

  changeAddedRows(addedRows) {
    if (addedRows.length === 1) {
      const initialized = addedRows.map((row) => (Object.keys(row).length ? row : {}));

      if (this.state.editingRowIds.length > 0) {
        this.setState({ addedRows: initialized, editingRowIds: [], selectedAnswers: [] });
      }

      this.setState({ addedRows: initialized, editingRowIds: [] });

      /* Set the editing state as true */
      this.props.toggleBranchingEditState(true);

      return;
    }

    /* Set the editing state as false */
    this.props.toggleBranchingEditState(false);

    this.setState({ addedRows: [], editingRowIds: [], selectedAnswers: [] });
  }

  changeEditingRowIds(editingRowIds) {
    const { selectedAnswers } = this.state;
    const { questions, answers, answerKeys } = this.props;
    if (editingRowIds.length > 0) {
      this.props.toggleBranchingEditState(true);
      const answerKey = answerKeys[editingRowIds[0]];
      const qEdit = questions.find((q) => q.questionID == answerKey.questionID);
      var answer = answers.find((a) => a.answerID === qEdit.answerID);
      let dropdownAnswers = [];
      if (answer) {
        if (answer.type === 'API') {
          dropdownAnswers = this.props[answer.apiName];
        } else {
          dropdownAnswers = answer.answers;
        }
      }
      const answerList = answerKey.answers.map((q) => {
        const dropdownAnswer = dropdownAnswers.find((ql) => ql.value == q);
        if (dropdownAnswer) {
          return dropdownAnswer;
        } else {
          return null;
        }
      });

      answerList.forEach((q) => {
        if (!selectedAnswers.includes(q)) selectedAnswers.push(q);
      });

      this.setState({ editingRowIds: [editingRowIds[0]], selectedAnswers, addedRows: [], answers: dropdownAnswers });
    } else {
      this.props.toggleBranchingEditState(false);
      this.setState({ editingRowIds: [], selectedAnswers: [] });
    }
  }

  changeRowChanges(rowChanges) {
    this.setState({ rowChanges });
  }

  commitChanges({ added, changed, deleted }) {
    console.log('added, changed, deleted', added, changed, deleted);
    let { answerKeys, answers, questions } = this.props;
    const questionList = this.mapQuestionsToValues(questions);
    const aList = this.mapAnswersToValues(answers);
    if (added) {
      added = added.reduce((list, item) => {
        // Only add the question if all the data is valid
        if (item.question && item.answers && item.answers.length > 0) {
          const q = questionList.find((a) => a.label === item.question.label);
          const aKey = aList.find((a) => a.label === item.answerKey.label);
          const answers = item.answers.map((q) => {
            return q.value;
          });

          if (q) {
            list.push({ questionID: q.value, answers, answerKey: aKey.value });
          }
        }

        return list;
      }, []);

      answerKeys = [...answerKeys, ...added];
    }

    if (changed) {
      Object.keys(changed).forEach((c) => {
        const answerChanged = typeof changed[c].answers[0] === 'object';
        const questionsChanged = typeof changed[c].question === 'object';
        const answerKeyChanged = typeof changed[c].answerKey === 'object';
        console.log('answerChanged, questionsChanged, answerKeyChanged', answerChanged, questionsChanged, answerKeyChanged);
        const answerKey = answerKeys[c];
        const question = questionList.find((a) => (questionsChanged ? a.label === changed[c].question.label : a.label === changed[c].question));
        const aKey = aList.find((a) => (answerKeyChanged ? a.label === changed[c].answerKey.label : a.label === changed[c].answerKey));
        answerKeys.answerKey = aKey.value;
        const answerList = answerChanged
          ? changed[c].answers.map((q) => q.value)
          : changed[c].answers.map((ql) => {
              const answer = find(this.state.answers, { label: ql });
              return answer ? answer.value : '';
            });

        if (question && answerList) {
          answerKey.answers = [...answerList];
        }

        //if (question && question.value != key) {
        //  delete c;
        //}
      });
    }

    if (deleted) {
      deleted.forEach((idx) => {
        answerKeys.splice(idx, 1);
      });
    }

    this.props.toggleBranchingEditState(false);
    this.props.updateAnswerKeys(answerKeys);
    this.setState({ formattedAnswerKeys: this.transformAnswerKeys(answerKeys) });
  }

  transformAnswerKeys(answerKeys) {
    const { questions, answers } = this.props;

    return answerKeys.reduce((all, answerKey) => {
      const questionList = this.mapQuestionsToValues(questions);
      const aList = this.mapAnswersToValues(answers);
      const question = questionList.find((a) => a.value == answerKey.questionID);

      const answer = aList.find((a) => a.value == answerKey.answerKey);
      const q = questions.find((q) => q.questionID == answerKey.questionID);
      if (question) {
        const answerNames = answerKey.answers.map((aID) => {
          const answerList = answers.find((a) => a.answerID === q.answerID);
          let dropdownAnswers = [];
          if (answerList.type === 'API') {
            dropdownAnswers = this.props[answerList.apiName];
          } else {
            dropdownAnswers = answerList.answers;
          }

          const answer = dropdownAnswers.find((q) => q.value == aID);

          if (answer) {
            return answer.label;
          } else {
            return '';
          }
        });

        all.push({ question: question.label, answers: [...answerNames], answerKey: answer ? answer.label : '' });
      }
      return all;
    }, []);
  }

  render() {
    const { classes } = this.props;
    const { columns, editingRowIds, formattedAnswerKeys, rowChanges, addedRows } = this.state;

    return (
      <Paper className={classes.gridPaper}>
        {this.props.disabled && <div className={classes.disabledOverlay} />}
        <Grid rows={formattedAnswerKeys} columns={columns} getRowId={this.getRowId}>
          <Table />
          <TableHeaderRow />
          <EditingState
            editingRowIds={editingRowIds}
            onEditingRowIdsChange={this.changeEditingRowIds}
            rowChanges={rowChanges}
            onRowChangesChange={this.changeRowChanges}
            addedRows={addedRows}
            onAddedRowsChange={this.changeAddedRows}
            onCommitChanges={this.saveRow}
          />
          <TableEditRow rowComponent={({ ...rowProps }) => <CustomSaveTableRow {...rowProps} />} cellComponent={this.selectCellEditor} />
          <TableEditColumn showAddCommand showEditCommand showDeleteCommand />
          <Getter name="tableColumns" computed={({ tableColumns }) => [...tableColumns.filter((c) => c.type !== 'editCommand'), { key: 'editCommand', type: 'editCommand', width: 0 }]} />
        </Grid>
      </Paper>
    );
  }
}
AnswerKeysSelector.propTypes = {
  answers: PropTypes.array.isRequired,
  questions: PropTypes.array.isRequired,
  answerKeys: PropTypes.array.isRequired,
};
const mapStateToProps = (state) => ({
  regionsList: state.countries.get('regionsList'),
  countriesList: state.countries.get('countriesList'),
  vendorsList: state.vendors.get('vendorsList'),
  productsList: state.products.get('productsList'),
  technologiesList: state.technologies.get('technologiesList'),
  //hospitals: state.entities.get('hospitals'),
  answers: state.answers.get('answers'),
  //questions: state.questions.get('questions'),
  isLoading: state.answers.get('isLoading') || state.questions.get('isLoading'),
});

export default withRouter(
  withStyles(styles, { withTheme: true })(
    connect(mapStateToProps, {
      getCountriesList,
      getRegionsList,
      getProductsList,
      getTechnologiesList,
      getVendorsList,
      getAnswers,
      //getQuestions
    })(AnswerKeysSelector)
  )
);
