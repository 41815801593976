import auth0 from 'auth0-js';
import { AUTH_CONFIG } from './auth0-variables';

class Auth {
  auth0 = new auth0.WebAuth({
    domain: AUTH_CONFIG.domain,
    clientID: AUTH_CONFIG.clientId,
    redirectUri: process.env.REACT_APP_AUTH0_CALLBACK ? process.env.REACT_APP_AUTH0_CALLBACK : AUTH_CONFIG.callbackUrl,
    audience: AUTH_CONFIG.audience,
    responseType: 'token id_token',
    scope: 'email openid',
    overrides: {
      __tenant: AUTH_CONFIG.overrides.tenant,
      __token_issuer: AUTH_CONFIG.overrides.token_issuer
    },
  });

  constructor(history, setAuthAction, clearAuthAction) {
    this.history = history;
    this.setAuthAction = setAuthAction;
    this.clearAuthAction = clearAuthAction;

    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
    this.handleAuthentication = this.handleAuthentication.bind(this);
  }

  login() {
    this.auth0.authorize();
  }

  handleAuthentication() {
    this.auth0.parseHash((err, authResult) => {
      if (authResult && authResult.accessToken && authResult.idToken) {
        this.authResult = authResult;
        this.setSession(authResult);

        console.log('AUTH RESULT!', authResult);
        this.setAuthAction(authResult.idToken);

        let redirect_uri = localStorage.getItem('redirect_uri');
        if (redirect_uri) {
          localStorage.removeItem('redirect_uri');
          this.history.replace(redirect_uri);
        } else {
          this.history.replace('/home');
        }
      } else if (err) {
        this.login(); 
        console.log(`ERROR: `, err);
      } else {
        this.login(); 
      }

    });
  }

  setSession(authResult) {
    // Set the time that the access token will expire at
    let expiresAt = JSON.stringify(authResult.expiresIn * 1000 + new Date().getTime());
    localStorage.setItem('access_token', authResult.accessToken);
    localStorage.setItem('id_token', authResult.idToken);
    localStorage.setItem('expires_at', expiresAt);
    // navigate to the home route
    // history.replace('/home');
  }

  logout() {
    // Clear access token and ID token from local storage
    localStorage.removeItem('access_token');
    localStorage.removeItem('id_token');
    localStorage.removeItem('expires_at');
    // navigate to the home route
    this.clearAuthAction();
    this.auth0.logout({returnTo: process.env.REACT_APP_AUTH0_CALLBACK, clientID: process.env.REACT_APP_AUTH0_CLIENT_ID});

  }
}

export default Auth;
