export const AUTH_CONFIG = {
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
  clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
  callbackUrl: 'http://localhost:3000/callback',
  audience: process.env.REACT_APP_AUTH0_AUDIENCE,
  overrides: {
    tenant: process.env.REACT_APP_AUTH0_TENANT,
    token_issuer: process.env.REACT_APP_AUTH0_TOKEN_ISSUER
  },
  //callbackUrl: process.env.REACT_APP_PROD_API ? 'https://surveybuilder.dev.himssanalytics.org/callback' : 'http://localhost:3000/callback',
  //callbackUrl: process.env.REACT_APP_PROD_API ? 'https://surveybuilder.staging.himssanalytics.org/callback' : 'http://localhost:3000/callback',
  //callbackUrl: process.env.REACT_APP_PROD_API ? 'https://surveybuilder.himssanalytics.org/callback' : 'http://localhost:3000/callback',
};
