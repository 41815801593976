import { Avatar, Chip, FormControl, InputLabel } from '@mui/material';
import { withStyles } from "@mui/styles";
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { find } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Confirm from '@survey/common/dist/components/dialogs/Confirm';
import ConfirmWithInput from '@survey/common/dist/components/dialogs/ConfirmWithInput';

const styles = ({ spacing, palette }) => ({
  chip: {
    margin: `1.25rem ${spacing(1/8)}rem 0 ${spacing(1/8)}rem`,
    minWidth: '2rem'
  },
  chipDisabled: {
    margin: spacing(1),
    cursor: 'initial',
    '&:hover': {
      backgroundColor: '#d3d4d6'
    }
  },
  chipWrapper: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  avatar: {
    backgroundColor: '#1a25ab',
    color: palette.common.white
  },
  formControl: {
    display: 'block',
    width: '100%',
    marginBottom: '1rem'
  }
});

class CustomAddList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dialogText: '',
      showConfirmDialog: false,
      showDeleteDialog: false
    };

    this.handleAddItem = this.handleAddItem.bind(this);
    this.handleDeleteItem = this.handleDeleteItem.bind(this);
    this.handleCloseAddDialog = this.handleCloseAddDialog.bind(this);
    this.handleCloseDeleteDialog = this.handleCloseDeleteDialog.bind(this);
    this.handleOpenDeleteDialog = this.handleOpenDeleteDialog.bind(this);
  }

  handleAddItem(value) {
    if (find(this.props.items, { name: value })) {
      return this.setState({ dialogText: 'The name you have entered is already in the list. Please change your input.' });
    }

    this.setState({
      showConfirmDialog: false,
      dialogText: ''
    });

    this.props.addItem(this.props.keyName, value);
  }

  handleDeleteItem() {
    this.setState({
      showDeleteDialog: false
    });

    this.props.deleteItem(this.props.keyName, this.state.itemValue);
  }

  handleCloseAddDialog() {
    this.setState({
      showConfirmDialog: false,
      dialogText: ''
    });
  }

  handleCloseDeleteDialog() {
    this.setState({
      showDeleteDialog: false
    });
  }

  handleOpenDeleteDialog(value) {
    this.setState({
      showDeleteDialog: true,
      itemValue: value
    });
  }

  render() {
    const { showConfirmDialog, showDeleteDialog } = this.state;
    const { classes, addText, deleteText, label, hasPermission, addDialogText, deleteDialogText, items } = this.props;

    return [
      <FormControl className={classes.formControl} style={{ marginBottom: 0 }} key="custom-add-list">
        <InputLabel shrink margin="dense" htmlFor="alternate-name">
          {label}
        </InputLabel>
        <div className={classes.chipWrapper}>
          {items &&
            items.map(item => (
              <Chip
                key={item.name}
                avatar={
                  <Avatar className={classes.avatar}>
                    <DeleteIcon />
                  </Avatar>
                }
                className={hasPermission ? classes.chip : classes.chipDisabled}
                label={item.name}
                onClick={() => {
                  return hasPermission && this.handleOpenDeleteDialog(item);
                }}
              />
            ))}
          {hasPermission && (
            <Chip
              avatar={
                <Avatar className={classes.avatar}>
                  <AddIcon />
                </Avatar>
              }
              className={hasPermission ? classes.chip : classes.chipDisabled}
              label={addText}
              onClick={() => {
                return hasPermission && this.setState({ showConfirmDialog: true });
              }}
            />
          )}
        </div>
      </FormControl>,
      <ConfirmWithInput
        key="confirm-with-input"
        contentText={this.state.dialogText ? this.state.dialogText : addDialogText}
        title={addText}
        onClose={this.handleCloseAddDialog}
        onConfirm={this.handleAddItem}
        open={showConfirmDialog}
        label={label}
      />,
      <Confirm key="confirm-delete" title={deleteText} onClose={this.handleCloseDeleteDialog} onConfirm={this.handleDeleteItem} contentText={deleteDialogText} open={showDeleteDialog} />
    ];
  }
}

CustomAddList.propTypes = {
  addText: PropTypes.string,
  deleteText: PropTypes.string,
  hasPermission: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  addDialogText: PropTypes.string,
  deleteDialogText: PropTypes.string,
  keyName: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
  addItem: PropTypes.func.isRequired,
  deleteItem: PropTypes.func.isRequired
};

export default withStyles(styles)(CustomAddList);
