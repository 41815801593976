import { combineReducers } from 'redux';
import auth from './modules/auth/auth.reducer';
import mappings from './modules/dataMappings/dataMappings.reducer';
import layout from './modules/layout/layout.reducer';
import surveyTypes from './modules/surveyTypes/surveyTypes.reducer';
import scoring from './modules/scoring/scoring.reducer';
import copyData from './modules/surveys/copyData/copyData.reducer';

import answers from '@survey/common/dist/reducers/answers.reducer';
import questions from '@survey/common/dist/reducers/questions.reducer';
import surveys from '@survey/common/dist/reducers/surveys.reducer';
import pages from '@survey/common/dist/reducers/pages.reducer';
import tabs from '@survey/common/dist/reducers/tabs.reducer';
import technologies from '@survey/common/dist/reducers/technologies.reducer';
import products from '@survey/common/dist/reducers/products.reducer';
import vendors from '@survey/common/dist/reducers/vendors.reducer';
import technologyQuestions from '@survey/common/dist/reducers/technologyQuestions.reducer';
import entities from '@survey/common/dist/reducers/entities.reducer';
import organizations from '@survey/common/dist/reducers/organizations.reducer';
import countries from '@survey/common/dist/reducers/countries.reducer';
import resources from '@survey/common/dist/reducers/resources.reducer';
const rootReducer = combineReducers({
  layout,
  auth,
  answers,
  countries,
  mappings,
  surveyTypes,
  scoring,
  questions,
  pages,
  tabs,
  surveys,
  vendors,
  products,
  technologies,
  technologyQuestions,
  entities,
  organizations,
  copyData,
  resources
});

export default rootReducer;
