import { Button, FormControl, Grid, TextField } from '@mui/material';
import { withStyles } from "@mui/styles";
import { findIndex } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { connect } from 'react-redux';
import { customValidationRules } from '../../utilities/answerValidation';
import { convertCountriesToValues, convertCountryIDsToCountryData, convertTechnologiesToValues, convertCountriesToCountryData } from '../../utilities/convertValues';
import { hasPermission } from '../auth/authOperations';
import { EDIT_PRODUCTS } from '../auth/permissions';
import CustomAddList from '../formControls/CustomAddList';
import CustomSelect from '@survey/common/dist/components/form-controls/CustomSelect';
import CustomTextField from '@survey/common/dist/components/form-controls/CustomTextField';
import LoadingOverlay from '../layout/loadingOverlay.component';

const styles = (theme) => ({
  form: {
    margin: `${theme.spacing(2)}px ${theme.spacing(5)}px`,
  },
  formControl: {
    display: 'block',
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  textField: {
    fontSize: 12,
  },
  productPaper: {
    marginTop: theme.spacing(3),
    padding: theme.spacing(2),
    color: 'rgba(0, 0, 0, 0.54)',
  },
  button: {
    margin: theme.spacing(1),
  },
});

const years = Array(100)
  .fill()
  .map((item, index) => {
    let year = 2000 + index;
    return { label: year + '', value: year };
  });

class ProductForm extends Component {
  constructor(props) {
    super(props);

    let { product } = props;

    this.state = {
      productID: product && product.productID ? product.productID : '',
      productName: product && product.productName ? product.productName : '',
      akaProductNames: product && product.akaProductNames ? product.akaProductNames : [],
      comments: product && product.comments ? product.comments : '',
      countries: product && product.countries ? product.countries : [],
      /*endYear: product && product.endYear ? product.endYear : '',*/
      /*startYear: product && product.startYear ? product.startYear : '',*/
      status: product && product.status ? product.status : '',
      technologySelectedValues: product && product.technologies ? product.technologies.map((t) => ({ value: t.technologyID, label: t.technologyName })) : [],
      countrySelectedValues: product && product.countries ? product.countries : [],
      technologies: product && product.technologies ? product.technologies : [],
      countriesList: [],
      isDirty: false,
    };

    ['onSubmit', 'handleSelectChange', 'handleInputChange', 'handleAddName', 'handleDeleteName', 'updateSelectedValueField', 'handleCountrySelectChange'].map(
      (name) => (this[name] = this[name].bind(this))
    );
  }

  static getDerivedStateFromProps(props, state) {
    let newState = { ...state };

    if (!state.countriesList.length && props.countries) {
      newState['countriesList'] = props.countries.map((country) => {
        if (country.countryID) {
          return { value: country.countryID, label: country.country };
        } else {
          return {
            value: country.geographicalCountryID,
            label: country.geographicalCountry,
          };
        }
      });
    }

    /* Add all of the custom validation rules */
    Object.keys(customValidationRules).forEach((ruleName) => {
      ValidatorForm.addValidationRule(ruleName, customValidationRules[ruleName]);
    });

    /*if (newState.startYear === '' && newState.vendorID === '') {
      newState.startYear = new Date().getFullYear();
    }*/

    return { ...newState };
  }

  onSubmit() {
    const { technologySelectedValues, countrySelectedValues, ...state } = this.state;

    //state.technologies = convertTechIDsToTechData(technologySelectedValues, this.props.technologies);
    state.technologies = technologySelectedValues.map((t) => ({ technologyID: t.value, technologyName: t.label }));
    //state.countries = convertCountryIDsToCountryData(countrySelectedValues, this.props.countries);
    state.countries = countrySelectedValues;

    delete state.isDirty;
    delete state.countriesList;

    /* Set some values to null if they are blank OR set to plain year value instead of object */
    /* ['endYear', 'startYear'].forEach(item => {
          if (state[item] === '') {
              state[item] = null;

          } else if (state[item] && state[item].value) {
              state[item] = state[item].value;
          }
      });*/

    state.vendorID = this.props.vendorID;

    this.props.onSubmit(state);
  }

  handleAddName(name, value) {
    this.setState({ [name]: [...this.state[name], { name: value }], isDirty: true });
  }

  handleDeleteName(name, value) {
    const namesCopy = [...this.state[name]];
    const nameIndex = findIndex(namesCopy, { name: value.name });

    namesCopy.splice(nameIndex, 1);

    this.setState({ [name]: namesCopy, isDirty: true });
  }

  handleInputChange(event) {
    const { name, value } = event.target;

    this.setState({ [name]: value });
  }

  handleSelectChange(name) {
    return async (value) => {
      switch (name) {
        default:
          this.setState({
            [name]: value,
            isDirty: true,
          });
      }
    };
  }

  updateSelectedValueField(field) {
    return (event, val) => {
      if (val instanceof Array) {
        this.setState({ [field]: [...val.map((v) => ({ value: v.value, label: v.label }))] });
      } else {
        this.setState({
          [field]: val,
        });
      }
    };
  }

  handleCountrySelectChange(field) {
    return async (event, values) => {
      this.setState({
        [field]: convertCountriesToCountryData(values, this.props.countries),
      });
    };
  }

  render() {
    const { classes, permissions, isLoading, onError, countries, handleBack, technologies } = this.props;
    const { countriesList } = this.state;

    return isLoading ? (
      <LoadingOverlay />
    ) : (
      <ValidatorForm onSubmit={this.onSubmit} onError={onError} className={classes.form}>
        <Grid container spacing={10}>
          <Grid item xs={12} md={6}>
            <FormControl className={classes.formControl}>
              <CustomTextField
                className={classes.textField}
                disabled={!hasPermission(EDIT_PRODUCTS, permissions)}
                key="Product Name"
                fullWidth
                name="productName"
                label="Product Name"
                value={this.state.productName ? this.state.productName : ''}
                onChange={(event) => this.handleInputChange(event)}
                required={true}
              />
            </FormControl>
            <div style={{ marginBottom: '1rem' }}>
              <CustomAddList
                id="aka-product-name"
                addText="Add Name"
                deleteText="Remove Name"
                hasPermission={hasPermission(EDIT_PRODUCTS, permissions)}
                keyName="akaProductNames"
                addItem={this.handleAddName}
                deleteItem={this.handleDeleteName}
                label="AKAProductName"
                addDialogText="Input the product name that you would like to add."
                deleteDialogText="Please confirm that you would like to remove this vendor name."
                items={this.state.akaProductNames}
              />
            </div>
            <div style={{ marginBottom: '.5rem' }}>
              <CustomSelect
                name="technologySelectedValues"
                label="Technologies"
                className={classes.select}
                value={this.state.technologySelectedValues}
                options={technologies.map((item) => ({
                  value: item.technologyID,
                  label: item.technologyName,
                }))}
                disabled={!hasPermission(EDIT_PRODUCTS, permissions)}
                onChange={this.updateSelectedValueField}
                isMulti={true}
                required={true}
              />
            </div>
            <div style={{ marginBottom: '.5rem' }}>
              <CustomSelect
                options={countriesList}
                label="Countries"
                name="countrySelectedValues"
                className={classes.select}
                isMulti={true}
                permission={true}
                onChange={this.handleCountrySelectChange}
                value={convertCountriesToValues(this.state.countrySelectedValues, countries)}
                disabled={!hasPermission(EDIT_PRODUCTS, permissions)}
              />
            </div>
            <FormControl className={classes.formControl}>
              <TextField
                disabled={!hasPermission(EDIT_PRODUCTS, permissions)}
                key="Comments"
                name="comments"
                fullWidth
                multiline
                maxRows="4"
                label="Comments"
                value={this.state.comments ? this.state.comments : ''}
                onChange={(event) => this.handleInputChange(event)}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid container style={{ height: '100%' }} direction="column">
              <Grid item style={{ flexGrow: 1 }}>
                {/* <Paper className={classes.productPaper}>
                    <Typography variant="h5" color="inherit" gutterBottom>
                      Product Associated To:
                    </Typography>
                  </Paper> */}
              </Grid>
            </Grid>
          </Grid>
          <Grid item style={{ width: '100%', paddingTop: 0, display: 'flex', justifyContent: 'flex-end' }}>
            <Button variant="text" color="primary" className={classes.button} onClick={handleBack}>
              Back
            </Button>
            <Button type="submit" disabled={!hasPermission(EDIT_PRODUCTS, permissions)} variant="contained" color="primary" className={classes.button}>
              Save
            </Button>
          </Grid>
        </Grid>
      </ValidatorForm>
    );
  }
}

ProductForm.propTypes = {
  product: PropTypes.object,
  onSubmit: PropTypes.func,
  vendorID: PropTypes.number,
};

function mapStateToProps(state, props) {
  return {
    isLoading: state.vendors.get('isLoading'),
    technologies: state.technologies.get('technologies'),
    countries: state.countries.get('countries'),
  };
}

export default withStyles(styles)(connect(mapStateToProps, {})(ProductForm));
