import React from 'react';

const SurveyHeader = ({ pageName, goBack }) => (
  <div style={{ color: '#78777a', fontSize: '30px' }}>
    {pageName === '' ? (
      `Your Survey Summary`
    ) : (
      <span>
        <span style={{ cursor: 'pointer' }} onClick={() => goBack()}>
          &lt; Home&nbsp;
        </span>
        | {pageName}
      </span>
    )}
  </div>
);
export default SurveyHeader;
