import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AppBar, Avatar, Paper, Toolbar, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import { find, isEmpty } from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import CISOMScoringTable from './cisomScoringTable.component';
import { getCisomScorings, createCisomScoring, updateCisomScoring, deleteCisomScoring } from './scoring.actions';
import { handleToastMessage } from '../layout/layout.actions';
import { getAnswers } from '@survey/common/dist/actions/answers.actions';
import { getQuestions } from '@survey/common/dist/actions/questions.actions';
import { getPages } from '@survey/common/dist/actions/pages.actions';
import { getTabs } from '@survey/common/dist/actions/tabs.actions';
import LoadingOverlay from '../layout/loadingOverlay.component';

const styles = theme => {
  return {
    toolBar: {
      display: 'flex',
      justifyContent: 'space-between'
    },
    survey: {
      display: 'flex',
      flexDirection: 'row'
    },
    avatar: {
      margin: theme.spacing(1)
    },
    heading: {
      margin: theme.spacing(1)
    },
    socialIcons: {
      display: 'flex',
      justifyContent: 'space-around'
    },
    paper: {
      padding: theme.spacing(2)
    },
    grid: {
      marginBottom: theme.spacing(2)
    },
    typography: {
      padding: theme.spacing(3)
    },
    pageHeader: {
      color: theme.palette.text.secondary,
      /* Need a negative margin because there are empty headers in the table. */
      marginBottom: '-2rem',
      marginTop: '1rem'
    }
  };
};

class CISOMScoringContainer extends Component {
  constructor(props) {
    super(props);
    isEmpty(this.props.answers) && this.props.getAnswers();
    isEmpty(this.props.questions) && this.props.getQuestions();
    isEmpty(this.props.pages) && this.props.getPages();
    isEmpty(this.props.tabs) && this.props.getTabs();
    isEmpty(this.props.cisomScoring) && this.props.getCisomScorings();
    this.state = {
      currentTab: 0
    };
    this.changeTab = this.changeTab.bind(this);
  }

  changeTab(event, value) {
    this.setState({ currentTab: value });
  }

  render() {
    const { classes, answers, questions, pages, tabs, handleToastMessage, cisomScoring, createCisomScoring, updateCisomScoring, deleteCisomScoring } = this.props;
    const noAPIQuestions = questions.filter(q => {
      if (q.answerID === null) {
        return false;
      }
      const answer = find(answers, { answerID: q.answerID });
      if (answer) {
        return answer.type !== 'API';
      }
      // If no answer was found and the answerID isn't null, some data linking is broken so don't show the question.
      return false;
    });

    const questionsCisomScoring = cisomScoring.filter(score => score.groupID !== null);

    return this.props.isLoading ? (
      <LoadingOverlay />
    ) : (
      <div>
        <AppBar position="static" color="default">
          <Toolbar className={classes.toolBar}>
            <div className={classes.survey}>
              <Avatar className={classes.avatar}>
                <FontAwesomeIcon icon="hospital" />
              </Avatar>
              <div className={classes.heading}>
                <Typography variant="h6" color="inherit" onClick={() => this.props.history.push(`/scoring`)}>
                  CISOM Survey Scoring Metrics
                </Typography>
              </div>
            </div>
          </Toolbar>
        </AppBar>
        <Paper className={classes.paper} square>
          <CISOMScoringTable
            answers={answers}
            questions={noAPIQuestions}
            pages={pages}
            tabs={tabs}
            handleToastMessage={handleToastMessage}
            scores={questionsCisomScoring}
            createScore={createCisomScoring}
            updateScore={updateCisomScoring}
            deleteScore={deleteCisomScoring}
          />
        </Paper>
      </div>
    );
  }
}

function mapStateToProps(state, props) {
  return {
    questions: state.questions.get('questions'),
    answers: state.answers.get('answers'),
    pages: state.pages.get('pages'),
    tabs: state.tabs.get('tabs'),
    cisomScoring: state.scoring.get('cisomScoring'),
    isLoading:
      state.questions.get('isLoading') ||
      state.technologyQuestions.get('isLoading') ||
      state.answers.get('isLoading') ||
      state.pages.get('isLoading') ||
      state.tabs.get('isLoading') ||
      state.technologies.get('isLoading') ||
      state.scoring.get('isCisomLoading')
  };
}

export default withStyles(styles)(
  connect(
    mapStateToProps,
    {
      getAnswers,
      getQuestions,
      getPages,
      getTabs,
      handleToastMessage,
      getCisomScorings,
      deleteCisomScoring,
      updateCisomScoring,
      createCisomScoring
    }
  )(CISOMScoringContainer)
);
