import { Button, FormControl, Grid, List, ListItem, ListItemText, Paper, TextField, Typography, FormControlLabel, Checkbox, FormLabel, Autocomplete } from '@mui/material';
import { withStyles } from '@mui/styles';
import { filter, find, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { convertAnswerToValue, convertEntityTypesToValues, convertYearToValue } from '../../utilities/convertValues';
import { hasPermission } from '../auth/authOperations';
import { EDIT_DATA_MAPPINGS, EDIT_QUESTIONS } from '../auth/permissions';
import { convertAttrToValue, convertAttrToValues, convertMapGroupsToValues, convertMapGroupToValue } from '../dataMappings/utils';
import BranchingQuestionsSelector from './branchingQuestionsSelector';
import { customValidationRules } from '@survey/common/dist/utilities/answerValidation';
import { validationRulesList } from '@survey/common/dist/utilities/answerValidation';
import CustomSelect from '@survey/common/dist/components/form-controls/CustomSelect';
import CustomTextField from '@survey/common/dist/components/form-controls/CustomTextField';
import ParentQuestionsSelector from '../technologyQuestions/parentQuestionsSelector';
import MatrixQuestionsSelector from './matrixQuestionsSelector';

const styles = (theme) => ({
  inputRoot: {
    boxShadow: 'none',
    backgroundColor: 'white !important',
  },
  form: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(5),
    marginRight: theme.spacing(5),
    display: 'flex',
  },
  formControl: {
    display: 'block',
    width: '100%',
    marginTop: theme.spacing(2)
  },
  textField: {
    width: 600,
    fontSize: 12,
  },
  select: {
    marginTop: theme.spacing(2),
    width: 600,
  },
  years: {
    width: 600,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  selectInline: {
    marginTop: theme.spacing(2),
    width: 250,
  },
  button: {
    margin: theme.spacing(1),
  },
  surveyTypesPaper: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
  },
  labelText: {
    marginBottom: '0.5rem',
    marginTop: '1rem',
  },
  additionalDescriptionLabel: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: 12,
    lineHeight: '2rem',
  }
});

const typeThatCanBranch = new RegExp(
  /(CheckBoxList|DropDownList|RadioButtonList|TechnologyInstallation|DigitalRadarTechnologyInstallation|DiamQuestion|CustomDiamServiceCheckbox|CustomCertifiedOrg|CustomCheckboxText|Matrix)/i
);
const typeThatSelectAll = new RegExp(/(CheckBoxList)/i);
const mappingToOptions = [
  { value: 'HA Database', label: 'HA Database' },
  { value: 'HA Wizard', label: 'HA Wizard' },
  { value: 'CSV', label: 'CSV' },
  { value: 'SugarCRM MaturityModel', label: 'SugarCRM MaturityModel' },
];
const controlTypes = [
  { label: 'Horizontal Check Box List', value: 'HorizontalCheckBoxList' },
  { label: 'Vertical Check Box List', value: 'VerticalCheckBoxList' },
  { label: 'Drop Down List - Single Value', value: 'SingleDropDownList' },
  { label: 'Drop Down List - Multi Value', value: 'MultiDropDownList' },
  { label: 'Vertical Radio Button List', value: 'VerticalRadioButtonList' },
  { label: 'Horizontal Radio Button List', value: 'HorizontalRadioButtonList' },
  { label: 'Matrix', value: 'Matrix' },
  { label: 'Address', value: 'Address' },
  { label: 'State', value: 'State' },
  { label: 'Date', value: 'Date' },
  { label: 'Text Box', value: 'TextBox' },
  { label: 'Text Area', value: 'TextArea' },
  { label: 'Description', value: 'Description' },
  { label: 'Technology Installation', value: 'TechnologyInstallation' },
  { label: 'Single Vendor/Product', value: 'VendorProductSelect' },
  { label: 'Multi Vendor/Product', value: 'MultiVendorProductSelect' },
  { label: 'Technology Plans', value: 'TechnologyPlans' },
  { label: 'Diam Question', value: 'DiamQuestion' },
  { label: 'Diam Imaging Service', value: 'CustomDiamServiceCheckbox' },
  { label: 'Diam Technology Installation', value: 'DiamTechnologyInstallation' },
  { label: 'Technology Installation (No Integration Level)', value: 'DiamTechnologyInstallation_NoIntegrationLevel' },
  { label: 'Technology Installation (DigitalRadar)', value: 'DigitalRadarTechnologyInstallation' },
  { label: 'Technology Installation (DigitalRadar No Integration Level)', value: 'DigitalRadarTechnologyInstallation_NoIntegrationLevel' },
  { label: 'Certified Organzation', value: 'CustomCertifiedOrg' },
  { label: 'Diam Respondent Information', value: 'DiamRespondentInfo' },
  { label: 'Diam Question (Free Text)', value: 'DiamFreeTextQuestion' },
  { label: 'Checkbox + Text', value: 'CustomCheckboxText' },
  { label: 'Label', value: 'CustomLabel' },
  { label: 'File Upload', value: 'FileUpload' },
];
const years = Array(100)
  .fill()
  .map((item, index) => {
    let year = 2000 + index;
    return { label: year + '', value: year };
  });

class QuestionForm extends Component {
  constructor(props) {
    super(props);

    const { question } = props;

    this.state = {
      // Be default a question should be active. Use the 'in' operator so a question that isn't active will have the correct value.
      active: question && 'active' in question ? question.active : true,
      tabID: question && question.tabID ? question.tabID : null,
      questionDescription: question && question.questionDescription ? question.questionDescription : '',
      additionalDescription: question && question.additionalDescription ? question.additionalDescription : '',
      hint: question && question.hint ? question.hint : '',
      sortOrder: question && question.sortOrder ? question.sortOrder : '',
      entityTypes: question && question.entityTypes ? question.entityTypes : [],
      controlType: question && question.controlType ? question.controlType : '',
      questionKey: question && question.questionKey ? question.questionKey : '',
      endYear: question && question.endYear ? question.endYear : '',
      startYear: question && question.startYear ? question.startYear : '',
      answerID: question && question.answerID ? question.answerID : '',
      questionID: question && question.questionID ? question.questionID : '',
      branchingQuestions: question && question.branchingQuestions ? question.branchingQuestions : {},
      childQuestions: question && question.childQuestions ? question.childQuestions : [],
      countries: question && question.countries ? question.countries : [],
      validationRule: question && question.validationRule && question.validationRule.validatorFunctionName !== null ? question.validationRule : { validatorFunctionName: null, value: null },
      mappingTo: question && question.mappingTo ? question.mappingTo : '',
      mappingGroupName: question && question.mappingGroupName ? question.mappingGroupName : '',
      mappingGroupAttribute: question && question.mappingGroupAttribute ? question.mappingGroupAttribute : '',
      technologyID: question && question.technologyID ? question.technologyID : '',
      answer: '',
      isBranching: false,
      allowSelectAll: question && question.allowSelectAll ? question.allowSelectAll : false,
      parentQuestions: question && question.parentQuestions ? question.parentQuestions : {},
      translations: question && question.translations ? question.translations : {},
      hintTranslations: question && question.hintTranslations ? question.hintTranslations : {},
      enableComments: question && question.enableComments ? question.enableComments : false,
    };

    ['onSubmit', 'handleInputChange', 'updateChildQuestions', 'updateBranchingQuestions', 'updateParentQuestions', 'updateSelectedValueField', 'handleSelectChange'].map(
      (item) => (this[item] = this[item].bind(this))
    );
  }

  static getDerivedStateFromProps(props, state) {
    const { question } = props;
    let newState = { ...state };

    if (!isEmpty(question) && question.questionID !== state.questionID && props.questionId !== 'new') {
      Object.keys(question).forEach((k) => {
        newState[k] = question[k];
      });
    }

    if(!state.mappingGroupName){
      newState.mappingGroupName = question && question.mappingGroupName ? question.mappingGroupName : '';
    }
    /* Add all of the custom validation rules */
    Object.keys(customValidationRules).forEach((ruleName) => {
      ValidatorForm.addValidationRule(ruleName, customValidationRules[ruleName]);
    });

    // HS-161: Start year should be the current year if this is a new question.
    if (newState.startYear === '' && newState.questionID === '') {
      newState.startYear = new Date().getFullYear();
    }

    return { ...newState };
  }

  updateSelectedValueField(field) {
    return (event, newValue) => {
      this.setState({ [field]: [...newValue.map((val) => val.value)]});
    };
  }

  onSubmit() {
    if (this.state.isBranching) {
      this.props.handleToastMessage('You must finish your changes in branching questions before saving', true);
      return;
    }

    let state = { ...this.state };

    /* Set some values to null if they are blank */
    ['answerID', 'endYear', 'startYear'].forEach((item) => {
      if (state[item] === '') {
        state[item] = null;
        return;
      }
    });

    /* Set some values to empty objects if they are blank */
    ['validationRule'].forEach((item) => {
      if (state[item] === '') state[item] = {};
    });
    // In the case where the control type switched from a type that allows branching questions to one that doesn't, remove all of the branching questions.
    if (state.controlType === 'TextBox' && this.props.question && this.props.question.controlType !== 'TextBox') {
      state.branchingQuestions = {};
    }

    /* Delete some values from the state before submitting */
    ['answer', 'bsonId', 'countriesList', 'createdDate', 'initialQuestionState', 'isBranching', 'questionID'].forEach((val) => delete state[val]);

    this.props.onSubmit(state);
  }

  handleInputChange(event) {
    const { name, value } = event.target;

    this.setState({ [name]: value });
  }

  updateChildQuestions(questions) {
    this.setState({ childQuestions: questions });
  }
  updateBranchingQuestions(questions) {
    this.setState({ branchingQuestions: questions });
  }
  updateParentQuestions(questions) {
    this.setState({ parentQuestions: questions });
  }
  handleSelectChange(name) {
    return (event, value) => {
      if (value === null) {
        this.setState({
          [name]: '',
        });
      } else {
        switch (name) {
          case 'controlType':
            if (value.value.toString().match(typeThatCanBranch)) {
              this.setState({ controlType: value.value });
            } else {
              this.setState({
                controlType: value.value,
                answerID: '',
              });
            }
            break;

          case 'validationRule':
            this.setState({
              validationRule: { ...this.state.validationRule, validatorFunctionName: value.value },
            });
            break;

          case 'mappingTo':
            this.setState({
              [name]: value.value,
              mappingGroupAttribute: '',
              mappingGroupName: '',
            });
            break;
          case 'technologyID':
            this.setState({
              technologyID: value.value,
            });
            break;
          default:
            this.setState({
              [name]: value.value,
            });
        }
      }
    };
  }

  render() {
    const {
      classes,
      permissions,
      question,
      pages,
      tabs,
      questions,
      answers,
      technologies,
      questionReferences,
      branchingQuestionReferences,
      countriesList,
      onError,
      mappings,
      handleToastMessage,
      history,
    } = this.props;
    const {
      countries,
      questionDescription,
      additionalDescription,
      tabID,
      controlType,
      hint,
      answerID,
      validationRule,
      startYear,
      endYear,
      sortOrder,
      entityTypes,
      mappingTo,
      mappingGroupAttribute,
      mappingGroupName,
      branchingQuestions,
      allowSelectAll,
      technologyID,
      enableComments,
    } = this.state;
    const disabled = !hasPermission(EDIT_QUESTIONS, permissions);
    //Sort the names for the dropdown
    technologies.sort((a, b) => {
      var x = a.technologyName;
      var y = b.technologyName;
      if (x < y) {
        return -1;
      }
      if (x > y) {
        return 1;
      }
      return 0;
    });
    const technologyList = technologies.map((item) => ({
      value: String(item.technologyID),
      label: item.technologyName,
    }));

    const answerOptions = [];
    const pagetabNames = [];

    let selectedTab = '';

    pages.forEach((p) => {
      const pageTabs = filter(tabs, { pageId: p.pageId });

      pageTabs.forEach((t) => {
        if (t.tabId === tabID) {
          selectedTab = {
            label: `${p.pageName} | ${t.tabName}`,
            value: t.tabId,
          };
        }

        pagetabNames.push({
          label: `${p.pageName} | ${t.tabName}`,
          value: t.tabId,
        });
      });
    });

    //Sort the names for the dropdown
    pagetabNames.sort((a, b) => {
      var x = a.label;
      var y = b.label;
      if (x < y) {
        return -1;
      }

      if (x > y) {
        return 1;
      }

      return 0;
    });

    answers.forEach((a) => {
      answerOptions.push({ label: a.answerName, value: a.answerID });
    });

    return (
      <ValidatorForm ref="form" onSubmit={this.onSubmit} onError={onError} className={classes.form}>
        <Grid container spacing={10}>
          <Grid item xs={12} md={6}>
            <CustomSelect
              label="Page Name | Tab Name"
              value={selectedTab}
              onChange={this.handleSelectChange}
              options={pagetabNames}
              disabled={disabled}
              placeholder="Select a Page and Tab"
              name="tabID"
              isClearable={true}
              required={true}
            />
            <FormControl className={classes.formControl}>
              <CustomTextField
                required={true}
                disabled={disabled}
                key="questionDescription"
                name="questionDescription"
                fullWidth
                margin="dense"
                multiline={true}
                maxRows="4"
                label="Question Description"
                value={questionDescription}
                onChange={(event) => this.setState({ questionDescription: event.target.value })}
              />
            </FormControl>
            {controlType === 'Description' && (
              <Paper className={classes.surveyTypesPaper}>
                <Typography variant="subtitle1" component="div">
                  <div dangerouslySetInnerHTML={{ __html: questionDescription }} />
                </Typography>
              </Paper>
            )}
            <FormControl className={classes.formControl}>
              <TextField
                fullWidth
                multiline
                label="Additional Question Description"
                key="additionalDescription"
                name="additionalDescription"
                margin="dense"
                value={additionalDescription}
                onChange={(event) => this.setState({ additionalDescription: event.target.value })}
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <TextField
                fullWidth
                multiline
                label="Question Hint"
                key="hint"
                name="hint"
                margin="dense"
                value={hint}
                onChange={(event) => this.setState({ hint: event.target.value })}
              />
            </FormControl>
            <CustomSelect
              label="Control Type"
              value={controlType ? find(controlTypes, { value: controlType }) : ''}
              onChange={this.handleSelectChange}
              options={controlTypes}
              disabled={disabled}
              name="controlType"
              required={true}
            />
            {controlType.match(typeThatSelectAll) && (
              <FormControlLabel
                control={<Checkbox checked={allowSelectAll} onChange={(event) => this.setState({ allowSelectAll: event.target.checked })} name="allowSelectAll" color="primary" />}
                label="Enable Select All / Deselect All"
              />
            )}
            {controlType.match(typeThatCanBranch) && (
              <CustomSelect
                label="Answer Key"
                value={convertAnswerToValue(answerID, answers)}
                onChange={this.handleSelectChange}
                options={answerOptions}
                disabled={disabled}
                name="answerID"
                required={controlType !== 'TextBox' && controlType !== 'DiamQuestion' ? true : false}
              />
            )}
            {(controlType === 'TextBox' || controlType === 'DiamFreeTextQuestion' || controlType === 'CustomCheckboxText') && (
              <CustomSelect
                label="Validation Rule"
                value={
                  !isEmpty(validationRule) && validationRule.validatorFunctionName !== null
                    ? {
                        label: find(validationRulesList, { validatorFunctionName: validationRule.validatorFunctionName }).name,
                        value: validationRule.validatorFunctionName,
                      }
                    : ''
                }
                onChange={this.handleSelectChange}
                options={
                  controlType === 'DiamFreeTextQuestion'
                    ? [validationRulesList.find((v) => v.name === 'Number')].map((v) => ({ value: v.validatorFunctionName, label: v.name }))
                    : controlType === 'CustomCheckboxText'
                    ? validationRulesList.filter((v) => v.name === 'Percent' || v.name === 'Number').map((v) => ({ value: v.validatorFunctionName, label: v.name }))
                    : validationRulesList.map((v) => ({ value: v.validatorFunctionName, label: v.name }))
                }
                disabled={disabled}
                isClearable={true}
                name="validationRule"
              />
            )}
            {controlType === 'TextBox' && validationRule.validatorFunctionName === 'maxStringLength' && (
              <TextField
                label="Validation Rule Value"
                name="validationRuleValue"
                value={validationRule.value}
                onChange={(event) => this.setState({ validationRule: { ...validationRule, value: event.target.value } })}
              />
            )}
            {controlType === 'TextBox' && validationRule.validatorFunctionName === 'isFloat' && (
              <Grid container spacing={8}>
                <Grid item>
                  <TextField
                    label="Fixed Digits"
                    name="validationRuleNumber"
                    value={validationRule.maxDigits}
                    onChange={(event) => this.setState({ validationRule: { ...validationRule, maxDigits: event.target.value } })}
                  />
                </Grid>
              </Grid>
            )}
            <Grid container spacing={8}>
              <Grid item xs={12} md={6}>
                <CustomSelect label="Start Year" value={convertYearToValue(startYear)} onChange={this.handleSelectChange} options={years} disabled={disabled} name="startYear" required={true} />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomSelect label="End Year" value={convertYearToValue(endYear)} onChange={this.handleSelectChange} options={years} disabled={disabled} name="endYear" />
              </Grid>
            </Grid>
            <Grid container spacing={8}>
              <Grid item xs={12} md={6}>
                <FormControlLabel
                  control={<Checkbox checked={enableComments} onChange={(event) => this.setState({ enableComments: event.target.checked })} name="enableComments" color="primary" />}
                  label="Enable Comments"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl className={classes.formControl}>
                  <TextValidator
                    fullWidth
                    label="Sort Order"
                    key="order"
                    name="sortOrder"
                    type="number"
                    margin="dense"
                    value={sortOrder}
                    onChange={(event) => this.setState({ sortOrder: event.target.value })}
                    validators={['required', 'isNumber']}
                    errorMessages={['This field is required', 'Sort Order must be an integer']}
                    InputProps={{ classes: { input: classes.root } }}
                    disabled={disabled}
                  />
                </FormControl>
              </Grid>
            </Grid>

            <CustomSelect
              options={this.props.entityTypes.map((item) => ({
                value: item.entityTypeId,
                label: item.name,
              }))}
              label="Entity Type(s)"
              disabled={disabled}
              // className={classes.select}
              placeholder="Select Entity Type(s)..."
              name="entityTypes"
              onChange={this.updateSelectedValueField}
              value={convertEntityTypesToValues(entityTypes, this.props.entityTypes)}
              isMulti={true}
              required={true}
            />
            <CustomSelect
              options={countriesList}
              label="Countries / Countries"
              disabled={disabled}
              name="countries"
              placeholder="Select Countries or Countries..."
              onChange={this.updateSelectedValueField}
              value={countries.map((value) => find(countriesList, { value }))}
              isMulti={true}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid container direction="column" style={{ height: '100%' }}>
              <FormControl>
                {controlType !== 'Description' && (
                  <Paper className={classes.surveyTypesPaper}>
                    <Grid container spacing={8}>
                      <Grid item xs={12} sm={3} style={{ alignSelf: 'center' }}>
                        <Typography variant="subtitle1">Data Mapping</Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <CustomSelect
                          label=""
                          value={mappingTo ? { value: mappingTo, label: mappingTo } : ''}
                          onChange={this.handleSelectChange}
                          options={mappingToOptions}
                          disabled={disabled || (!isEmpty(question) && !hasPermission(EDIT_DATA_MAPPINGS, permissions))}
                          placeholder="Select Map To..."
                          name="mappingTo"
                          required={true}
                        />
                      </Grid>
                    </Grid>
                    {mappingTo === 'HA Database' && (
                      <div>
                        <CustomSelect
                          label="Name"
                          value={convertMapGroupToValue(mappings, mappingGroupName)}
                          onChange={this.handleSelectChange}
                          options={convertMapGroupsToValues(mappings)}
                          disabled={!(disabled || (!isEmpty(question) && !hasPermission(EDIT_DATA_MAPPINGS, permissions))) && mappingTo ? false : true}
                          placeholder="Select Name..."
                          name="mappingGroupName"
                          required={true}
                        />
                        <CustomSelect
                          label="Attribute"
                          value={convertAttrToValue(mappings, mappingGroupName, mappingGroupAttribute)}
                          onChange={this.handleSelectChange}
                          options={convertAttrToValues(mappings, mappingGroupName)}
                          disabled={!(disabled || (!isEmpty(question) && !hasPermission(EDIT_DATA_MAPPINGS, permissions))) && mappingTo && mappingGroupName !== '' ? false : true}
                          placeholder="Select Attribute..."
                          name="mappingGroupAttribute"
                          //required={true}
                          required={controlType != 'Matrix'}
                        />
                        <CustomSelect
                          label="Technology"
                          value={technologyID ? find(technologyList, { value: String(technologyID) }) : ''}
                          onChange={this.handleSelectChange}
                          options={technologyList}
                          disabled={!(disabled || (!isEmpty(question) && !hasPermission(EDIT_DATA_MAPPINGS, permissions))) && mappingTo && mappingGroupName !== '' ? false : true}
                          placeholder="Select Technology..."
                          name="technologyID"
                        />
                      </div>
                    )}
                    {(mappingTo === 'CSV' || mappingTo === 'SugarCRM MaturityModel' || mappingTo === 'HA Wizard') && (
                      <FormControl className={classes.formControl}>
                        <CustomTextField
                          required={true}
                          disabled={disabled || (!isEmpty(question) && !hasPermission(EDIT_DATA_MAPPINGS, permissions))}
                          key="attribute"
                          name="mappingGroupAttribute"
                          fullWidth
                          margin="dense"
                          multiline={true}
                          maxRows="4"
                          label="Attribute"
                          value={mappingGroupAttribute}
                          onChange={(event) => this.setState({ mappingGroupAttribute: event.target.value })}
                        />
                      </FormControl>
                    )}
                  </Paper>
                )}
                {branchingQuestionReferences.length > 0 && (
                  <Grid item style={{ flexGrow: 1 }}>
                    <Paper className={classes.surveyTypesPaper}>
                      <Typography variant="subtitle1">Question References</Typography>
                      <List dense={true}>
                        {branchingQuestionReferences.map((i, index) => (
                          <ListItem button key={index}>
                            <ListItemText primary={`${i.questionDescription} (${i.questionID})`} onClick={() => history.push(`/questions/${i.questionID}`)} />
                          </ListItem>
                        ))}
                      </List>
                    </Paper>
                  </Grid>
                )}
                {controlType === 'Matrix' && questions && question && (
                  <Fragment>
                    <Typography variant="subtitle1" className={classes.labelText}>
                      Matrix Questions
                    </Typography>
                    <MatrixQuestionsSelector
                      updateChildQuestions={this.updateChildQuestions}
                      childQuestions={question.childQuestions}
                      mappings={mappings}
                      mappingTo={mappingTo}
                      mappingGroupName={mappingGroupName}
                      mappingGroupAttribute={mappingGroupAttribute}
                      permissions={permissions}
                    >
                    </MatrixQuestionsSelector>
                  </Fragment>
                )}
                {controlType !== 'Description' && controlType !== 'CustomLabel' && questions && question && (
                  <Fragment>
                    <Typography variant="subtitle1" className={classes.labelText}>
                      Display if Question is Answered
                    </Typography>
                    <BranchingQuestionsSelector
                      disabled={answerID === '' || controlType === ''}
                      questions={questions.filter((q) => q.questionID !== question.questionID)}
                      selectedAnswer={answers && answers.length ? answers.filter((a) => a.answerID === answerID)[0] : {}}
                      branchingQuestions={branchingQuestions}
                      updateBranchingQuestions={this.updateBranchingQuestions}
                      handleToastMessage={handleToastMessage}
                      toggleBranchingEditState={(isBranching) => this.setState({ isBranching: isBranching })}
                    />
                  </Fragment>
                )}
                <div>
                  <Typography variant="subtitle1" className={classes.labelText}>
                    Display if Parent Question is Answered
                  </Typography>
                  <ParentQuestionsSelector
                    disabled={false}
                    questions={questions}
                    parentQuestions={this.state.parentQuestions}
                    updateParentQuestions={this.updateParentQuestions}
                    handleToastMessage={this.props.handleToastMessage}
                    toggleBranchingEditState={(isBranching) => this.setState({ isBranching: isBranching })}
                  />
                </div>
                {questionReferences.length > 0 && (
                  <Grid item style={{ flexGrow: 1 }}>
                    <Paper className={classes.surveyTypesPaper}>
                      <Typography variant="subtitle1">Survey Types</Typography>
                      <List dense={true}>
                        {questionReferences.map((i, index) => (
                          <ListItem button key={index}>
                            <ListItemText primary={i.name} onClick={() => history.push(`/surveyTypes/${i.surveyTypeID}`)} />
                          </ListItem>
                        ))}
                      </List>
                    </Paper>
                  </Grid>
                )}
              </FormControl>
            </Grid>
          </Grid>
          <Grid item style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
            <Button variant="contained" color="primary" className={classes.button} onClick={() => this.props.history.goBack()}>
              Back
            </Button>
            <Button type="submit" disabled={disabled} variant="contained" color="primary" className={classes.button}>
              Save
            </Button>
          </Grid>
        </Grid>
      </ValidatorForm>
    );
  }
}

QuestionForm.propTypes = {
  question: PropTypes.object,
  questions: PropTypes.array,
  questionReferences: PropTypes.array,
  branchingQuestionReferences: PropTypes.array,
  pages: PropTypes.array,
  tabs: PropTypes.array,
  answers: PropTypes.array,
  history: PropTypes.object,
  countriesList: PropTypes.array,
  entityTypes: PropTypes.array,
  onSubmit: PropTypes.func,
  onError: PropTypes.func,
  permissions: PropTypes.array,
  handleToastMessage: PropTypes.func,
  mappings: PropTypes.array,
};

export default withStyles(styles)(QuestionForm);
