import Immutable from 'immutable';
import {
  CREATE_SURVEY_TYPE_FAILURE,
  CREATE_SURVEY_TYPE_REQUEST,
  CREATE_SURVEY_TYPE_SUCCESS,
  DELETE_SURVEY_TYPE_FAILURE,
  DELETE_SURVEY_TYPE_REQUEST,
  DELETE_SURVEY_TYPE_SUCCESS,
  GET_SURVEY_TYPES_FAILURE,
  GET_SURVEY_TYPES_REQUEST,
  GET_SURVEY_TYPES_SUCCESS,
  SAVE_SURVEY_TYPE_FAILURE,
  SAVE_SURVEY_TYPE_REQUEST,
  SAVE_SURVEY_TYPE_SUCCESS,
  SELECT_SURVEY_TYPE_FAILURE,
  SELECT_SURVEY_TYPE_REQUEST,
  SELECT_SURVEY_TYPE_SUCCESS
} from './surveyTypes.actions';

const initialState = Immutable.Map({
  surveyTypes: [],
  surveyType: {},
  isTypesLoading: false,
  isTypeLoading: false,
  isLoading: false,
  errors: []
});

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_SURVEY_TYPES_REQUEST:
      return state
        .set('surveyTypes', [])
        .set('errors', [])
        .set('isTypesLoading', true);
    case GET_SURVEY_TYPES_SUCCESS:
      return state.set('surveyTypes', action.response).set('isTypesLoading', false);
    case GET_SURVEY_TYPES_FAILURE:
      return state.set('isTypesLoading', false).set('error', action.messages);

    case SELECT_SURVEY_TYPE_REQUEST:
      return state
        .set('surveyType', {})
        .set('errors', [])
        .set('isTypeLoading', true);
    case SELECT_SURVEY_TYPE_SUCCESS:
      return state.set('surveyType', action.response).set('isTypeLoading', false);
    case SELECT_SURVEY_TYPE_FAILURE:
      return state.set('isTypeLoading', false).set('error', action.messages);

    case CREATE_SURVEY_TYPE_REQUEST:
      return state.set('isLoading', true);
    case CREATE_SURVEY_TYPE_SUCCESS:
      let newSurveyType = action.response;
      let surveyTypes = [...state.get('surveyTypes')];
      surveyTypes.push(newSurveyType);
      return state.set('surveyTypes', surveyTypes).set('isLoading', false);
    case CREATE_SURVEY_TYPE_FAILURE:
      return state.set('isLoading', false).set('error', action.messages);

    case SAVE_SURVEY_TYPE_REQUEST:
      return state.set('isLoading', true);
    case SAVE_SURVEY_TYPE_SUCCESS:
      return state.set('isLoading', false).set('surveyType', {});
    case SAVE_SURVEY_TYPE_FAILURE:
      return state.set('isLoading', false).set('error', action.messages);

    case DELETE_SURVEY_TYPE_SUCCESS: {
      /* 
      XXX:  No data other than a success is being returned from the API
      let surveyTypes = [...state.get('surveyTypes')];
      let indexToDelete = findIndex(surveyTypes, { id: action.response.surveyTypeId });
      if (indexToDelete > -1) {
        surveyTypes.splice(indexToDelete, 1);
      }
      */
      return state.set('surveyTypes', surveyTypes).set('isLoading', false);
    }
    case DELETE_SURVEY_TYPE_FAILURE:
      return state.set('errors', action.messages).set('isLoading', false);
    case DELETE_SURVEY_TYPE_REQUEST:
      return state.set('errors', []).set('isLoading', true);

    default:
      return state;
  }
};
