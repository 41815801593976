import { find, isEmpty } from 'lodash';

export const convertCountriesToValues = (values, countries) => {
  console.log('the Values', values);
  let testValues = values.map(c => {
    return { value: c.countryID, label: c.country };
  });

  console.log('testValues', testValues);
  return testValues;
};

export const convertCountriesToCountryData = (values, countries) => {
  if (values.length > 0) {
    return values.map(item => {
      return countries.reduce((arr, c) => {
        if (item.value === c.countryID) {
          arr = c;
        }

        return arr;
      }, '');
    });
  }
};

export const convertYearsToValues = (year, years) => {
  return years.find(y => y.value === year);
};

export const convertEntityTypesToValues = (values, options) => {
  return findValues('entityTypeId', 'name', values, options);
};

export const convertOrganizationTypesToValues = (values, options) => {
  return findValues('organizationTypeID', 'organizationTypeName', values, options);
};

export const convertTechnologiesToValues = (values, options) => {
  return findValues('technologyID', 'technologyName', values, options, 'technologies');
};

export const convertTechStatusToValues = (values, options) => {
  return findValues('statusID', 'name', values, options, 'techStatus');
};

export const convertAnswerToValue = (answerID, answers) => {
  if (answerID !== '') {
    const answer = find(answers, a => a.answerID === answerID);

    if (!isEmpty(answer)) {
      return {
        value: answerID,
        label: answer.answerName
      };
    }
  }

  return '';
};

export const convertYearToValue = year => {
  return year ? { label: year, value: year } : '';
};

export const convertTechIDsToTechData = (values, technologies, vendorID = '') => {
  return values.map(value => {
    return technologies.reduce((list, tech) => {
      if (tech.technologyID === value) {
        if (vendorID !== '') {
          list = {
            technologyID: tech.technologyID,
            technologyName: tech.technologyName,
            vendorID: typeof vendorID === 'number' ? vendorID : 0
          };
        } else {
          list = {
            technologyID: tech.technologyID,
            technologyName: tech.technologyName
          };
        }
      }

      return list;
    });
  });
};

export const convertCountryIDsToCountryData = (values, countries) => {
  console.log('Running convert data', values);
  let convertTest = values.map(value => {
    return countries.reduce((list, country) => {
      if (country.countryID === value.value) {
        list = {
          countryID: country.countryID
        };
      }
      return list;
    });
  });

    console.log('Finished convert data', convertTest);
  return convertTest;

};

const findValues = (valueKey, labelKey, values, options, type = '') => {
  const results = values.map(item => {
    return options.reduce((list, option) => {
      if (typeof item === 'object' && option[valueKey] === item[valueKey]) {
        list = {
          value: option[valueKey],
          label: option[labelKey]
        };
      } else if (option[valueKey] === item) {
        list = {
          value: option[valueKey],
          label: option[labelKey]
        };
      }
      return list;
    }, '');
  });

  // if (type === 'technologies' || type === 'techStatus') {
  //   return results.length > 0 ? results : [];
  // } else {
  return results.length > 0 ? results : '';
  // }
};
