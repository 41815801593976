import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AppBar, Avatar, Button, Paper, Toolbar, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import { find, isEmpty } from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';
import { getSurveyTypes } from '../surveyTypes/surveyTypes.actions';
import Entities from './entities/entities.component';
import { handleToastMessage } from '../layout/layout.actions';
import Confirm from '@survey/common/dist/components/dialogs/Confirm';
import { getTabs } from '@survey/common/dist/actions/tabs.actions';
import { getQuestions } from '@survey/common/dist/actions/questions.actions';
import { getPages } from '@survey/common/dist/actions/pages.actions';
import { getSurvey, submitSurvey } from '@survey/common/dist/actions/surveys.actions';
import { getTechnologies } from '@survey/common/dist/actions/technologies.actions';
import { getOrganizationTypes } from '@survey/common/dist/actions/organizations.actions';
import LoadingOverlay from '../layout/loadingOverlay.component';
import { getRegions } from '@survey/common/dist/actions/countries.actions';

const styles = (theme) => ({
  toolBar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  survey: {
    display: 'flex',
    flexDirection: 'row',
  },
  avatar: {
    margin: theme.spacing(1),
  },
  heading: {
    margin: theme.spacing(1),
  },
  socialIcons: {
    display: 'flex',
    justifyContent: 'space-around',
  },
  paper: {
    padding: theme.spacing(2),
  },
  grid: {
    marginBottom: theme.spacing(2),
  },
  button: {},
});

class SurveyContainer extends Component {
  constructor(props) {
    super(props);
    const { surveyTypes, pages, tabs, questions, technologies, organizationTypes, regions } = this.props;

    // Need to fetch the survey data fresh each time a user views a survey
    this.props.getSurvey(this.props.match.params.surveyId);
    isEmpty(surveyTypes) && this.props.getSurveyTypes();
    isEmpty(pages) && this.props.getPages();
    isEmpty(tabs) && this.props.getTabs();
    isEmpty(questions) && this.props.getQuestions();
    isEmpty(technologies) && this.props.getTechnologies();
    isEmpty(organizationTypes) && this.props.getOrganizationTypes();
    isEmpty(regions) && this.props.getRegions();

    this.state = {
      showSubmitDialog: false,
    };

    this.submitSurvey = this.submitSurvey.bind(this);
  }

  async submitSurvey() {
    /* Hide the dialog */
    this.setState({ showSubmitDialog: false });

    const survey = { ...this.props.survey, status: 'Submitted for QA Review' };

    /* Remove keys that will cause the submission to fail. */
    delete survey.bsonId;
    survey.questions.forEach((q) => {
      delete q.bsonId;
    });
    survey.technologyQuestions.forEach((q) => {
      delete q.bsonId;
    });

    /* Save the survey */
    const response = await this.props.submitSurvey(this.props.survey.surveyID);
    if (response.type === 'SUBMIT_SURVEY_SUCCESS') {
      this.props.handleToastMessage('Survey successfully submitted.', false);
      this.props.history.push('/');
    } else {
      this.props.handleToastMessage('Failed to submit survey!', true);
    }
  }
  render() {
    const { classes, survey } = this.props;
    let surveyTypeID = survey ? Number(survey.surveyTypeID) : '';
    let surveyType = survey.surveyType ? survey.surveyType : find(this.props.surveyTypes, { surveyTypeID });
    let allowSubmit = ['Not Started', 'In Progress', 'In Review'];
    return isEmpty(survey) || this.props.isLoading ? (
      <LoadingOverlay />
    ) : (
      <div>
        <AppBar position="static" color="default">
          <Toolbar className={classes.toolBar}>
            <div className={classes.survey}>
              <Avatar className={classes.avatar}>
                <FontAwesomeIcon icon="hospital" />
              </Avatar>
              <div className={classes.heading}>
                <Typography variant="h6" color="inherit" onClick={() => this.props.history.push(`/surveys/${survey.surveyID}`)}>
                  {survey.surveyName}
                </Typography>
                <Typography variant="subtitle1" color="inherit">
                  {surveyType ? surveyType.name : ''}
                </Typography>
              </div>
            </div>
            <Button variant="contained" disabled={!allowSubmit.includes(survey.status)} color="primary" className={classes.button} onClick={() => this.setState({ showSubmitDialog: true })}>
              Submit Survey
            </Button>
          </Toolbar>
        </AppBar>
        <Paper className={classes.paper} square>
          <Route
            exact
            path="/surveys/:id"
            render={(props) => (
              <Entities
                {...props}
                survey={survey}
                surveyType={surveyType}
                pages={this.props.pages}
                tabs={this.props.tabs}
                questions={this.props.questions}
                answers={survey.answers}
                technologies={this.props.technologies}
                organizationTypes={this.props.organizationTypes}
                stateList={this.props.regions}
              />
            )}
          />
        </Paper>
        <Confirm
          title="Submit Survey?"
          onClose={() => this.setState({ showSubmitDialog: false })}
          onConfirm={() => this.submitSurvey()}
          contentText={`Please confirm you are ready to submit this survey.`}
          open={this.state.showSubmitDialog}
        />
      </div>
    );
  }
}

function mapStateToProps(state, props) {
  return {
    survey: state.surveys.get('survey'),
    surveyTypes: state.surveyTypes.get('surveyTypes'),
    pages: state.pages.get('pages'),
    tabs: state.tabs.get('tabs'),
    questions: state.questions.get('questions'),
    technologies: state.technologies.get('technologies'),
    organizationTypes: state.organizations.get('organizationTypes'),
    regions: state.countries.get('regions'),
    isLoading:
      state.surveys.get('isLoading') ||
      state.surveyTypes.get('isLoading') ||
      state.pages.get('isLoading') ||
      state.tabs.get('isLoading') ||
      state.questions.get('isLoading') ||
      state.technologies.get('isLoading') ||
      state.organizations.get('isLoading'),
  };
}

export default withStyles(styles)(
  connect(mapStateToProps, {
    getSurvey,
    submitSurvey,
    getSurveyTypes,
    getPages,
    getTabs,
    getQuestions,
    getTechnologies,
    getOrganizationTypes,
    handleToastMessage,
    getRegions,
  })(SurveyContainer)
);
