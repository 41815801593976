import React, { Component } from 'react';
import { withStyles } from '@mui/styles';
const styles = (theme) => ({
  footer: {
    backgroundColor: '#F8F8F8',
    borderTop: '1px solid #E7E7E7',
    textAlign: 'center',
    padding: '0px',
    position: 'fixed',
    left: '0',
    bottom: '0',
    height: '20px',
    width: '100%',
  },
  phantom: {
    display: 'block',
    padding: '0px',
    height: '20px',
    width: '100%',
  },
});

class AppFooter extends Component {
  render() {
    const { classes, footerText } = this.props;

    return (
      <div>
        <div className={classes.phantom} />
        <div className={classes.footer}>{footerText ? footerText : 'footer_placeholder'}</div>
      </div>
    );
  }
}
export default withStyles(styles)(AppFooter);
