import React, { Component } from 'react';
import { find, uniq } from 'lodash';
import PreviewExternalSurveyType from './previewExternalSurveyType.component';
import PreviewInternalSurveyType from './previewInternalSurveyType.component';
import { getCurrentPageTabs } from '@survey/common/dist/actions/pages.actions';
import { createSurveyState } from '@survey/common/dist/utilities/createSurveyState';

const getAllSurveyQuestions = (surveyQuestions, questions) => {
  let allQuestions = [];
  surveyQuestions.forEach(q => {
    let question = find(questions, { questionID: q.questionID });
    if (question) {
      allQuestions.push(question);
      allQuestions = allQuestions.concat(getAllSurveyQuestions(q.branchingQuestions, questions));
    }
  });
  return uniq(allQuestions);
};

class PreviewSurveyType extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pages: [],
      tabs: [],
      data: {}
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (props.pages && props.tabs && props.questions && props.technologyQuestions && props.surveyType) {
      //Have to manually grab the all survey questions since we aren't relying on the API for the preview.
      let questions = getAllSurveyQuestions(props.surveyType.questions, props.questions);
      let technologyQuestions = getAllSurveyQuestions(props.surveyType.technologyQuestions, props.technologyQuestions);

      const survey = { questions: questions, technologyQuestions: technologyQuestions };
      /* Get the questions and technology questions arrays for this specific surveyType. */
      const newState = createSurveyState(survey, props.surveyType, props.surveyType.entityType, props.pages, props.tabs, props.technologies);
      state = { ...state, ...newState };

      if (Object.keys(state.data).length === 0) {
        state.pages.forEach(page => {
          state.data[page.pageId] = {};
          const tabs = getCurrentPageTabs(page.pageId, state.tabs, state.questions, state.surveyType.questions, state.technologyQuestions, state.surveyType.technologyQuestions, props.technologies);
          tabs.map(t => (state.data[page.pageId][t.tabId] = {}));
        });
      }
    }

    return state;
  }

  render() {
    const { answers, internalSurvey, surveyType, technologies, technologiesList, productsList, vendorsList, countriesList, regionsList, showPreview, regions } = this.props;
    const { pages, tabs, questions, technologyQuestions } = this.state;

    if (!showPreview) {
      return <div />;
    }

    if (internalSurvey) {
      return (
        <PreviewInternalSurveyType
          answers={answers}
          questions={questions}
          technologyQuestions={technologyQuestions}
          pages={pages}
          allTabs={tabs}
          technologies={technologies}
          surveyType={surveyType}
          countriesList={countriesList}
          regionsList={regionsList}
          productsList={productsList}
          technologiesList={technologiesList}
          vendorsList={vendorsList}
          regions={regions}
        />
      );
    } else {
      return (
        <PreviewExternalSurveyType
          answers={answers}
          questions={questions}
          technologyQuestions={technologyQuestions}
          pages={pages}
          allTabs={tabs}
          technologies={technologies}
          surveyType={surveyType}
          countriesList={countriesList}
          regionsList={regionsList}
          productsList={productsList}
          technologiesList={technologiesList}
          vendorsList={vendorsList}
          regions={regions}
        />
      );
    }
  }
}

export default PreviewSurveyType;
