import { Button,
        Paper,
        Checkbox,
        Table,
        TableBody,
        TableHead,
        TableRow,
        TableCell,
        TextField, } from '@mui/material';
        import { withStyles } from "@mui/styles";
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { handleToastMessage } from '../layout/layout.actions';
import LoadingOverlay from '../layout/loadingOverlay.component';
import { getMaintenance, updateMaintenance } from '@survey/common/dist/actions/maintenance.actions';
import { getResources } from '@survey/common/dist/actions/resources.actions';

class MaintenanceContainer extends Component {
  constructor(props) {
        super(props);

        this.state = {
          surveyType: {},
          maintenance: {},
          englishTextValue: "",
          frenchTextValue: "",
          spanishTextValue: "",
          turkishTextValue: "",
          portugueseTextValue: "",
          ItalianTextValue: "",
          britishTextValue: "",
          germanTextValue: "",
          polishTextValue: "",
          japaneseTextValue: "",
          koreanTextValue: "",
          indonesianTextValue: "",
          chineseTextValue: "",
          enabledChecked: false,
          isLoading: false,
          saveDisabled: true
        };

        this.handlecheckBoxUpdate = this.handlecheckBoxUpdate.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.getDbValues = this.getDbValues.bind(this);
        this.loadDbValues = this.loadDbValues.bind(this);

      }

   componentDidMount() {
        const { maintenance, resources } = this.props;
        this.getDbValues();
    }

    async getDbValues() {
        const response = await this.props.getMaintenance();
        this.loadDbValues(response);
    }

    loadDbValues(response) {
        this.setState({ englishTextValue: response.response[0]['maintenanceTextEnglish'] });
        this.setState({ germanTextValue: response.response[0]['maintenanceTextGerman'] });
        this.setState({ frenchTextValue: response.response[0]['maintenanceTextFrench'] });
        this.setState({ italianTextValue: response.response[0]['maintenanceTextItalian'] });
        this.setState({ polishTextValue: response.response[0]['maintenanceTextPolish'] });
        this.setState({ spanishTextValue: response.response[0]['maintenanceTextSpanish'] });
        this.setState({ portugueseTextValue: response.response[0]['maintenanceTextPortuguese'] });
        this.setState({ britishTextValue: response.response[0]['maintenanceTextBritish'] });
        this.setState({ turkishTextValue: response.response[0]['maintenanceTextTurkish'] });
        this.setState({ japaneseTextValue: response.response[0]['maintenanceTextJapanese'] });
        this.setState({ koreanTextValue: response.response[0]['maintenanceTextKorean'] });
        this.setState({ indonesianTextValue: response.response[0]['maintenanceTextIndonesian'] });
        this.setState({ chineseTextValue: response.response[0]['maintenanceTextChinese'] });
        this.setState({ enabledChecked: response.response[0]['maintenanceActiveFlag'] === 0 ? false : true });
    }

    handlecheckBoxUpdate(name) {

        return event => {
            this.setState({ [name]: event.target.checked });
            this.setState({ saveDisabled: false });
        };


    }

    async handleSave() {

        this.setState({ isLoading: true });

        var data = {
            maintenanceID:1,
            maintenanceActiveFlag: this.state.enabledChecked === true ? 1: 0,
            maintenanceTextEnglish: this.state.englishTextValue,
            maintenanceTextGerman: this.state.germanTextValue,
            maintenanceTextFrench: this.state.frenchTextValue,
            maintenanceTextItalian: this.state.italianTextValue,
            maintenanceTextPolish: this.state.polishTextValue,
            maintenanceTextSpanish: this.state.spanishTextValue,
            maintenanceTextPortuguese: this.state.portugueseTextValue,
            maintenanceTextBritish: this.state.britishTextValue,
            maintenanceTextTurkish: this.state.turkishTextValue,
            maintenanceTextJapanese: this.state.japaneseTextValue,
            maintenanceTextKorean: this.state.koreanTextValue,
            maintenanceTextIndonesian: this.state.indonesianTextValue,
            maintenanceTextChinese: this.state.chineseTextValue
        };

      //call  updateMaintenance
      const updateResponse = await this.props.updateMaintenance(1, data);

        if (updateResponse.type === 'UPDATE_MAINTENANCE_SUCCESS') {
            console.log('UPDATE MAINTENANCE SUCCESS: ', updateResponse);
            this.props.handleToastMessage('Update Complete!', false);
        } else {
            console.log('UPDATE MAINTENANCE ERROR: ', updateResponse);
            this.props.handleToastMessage('Update Failed!', false);
        }

        this.setState({ saveDisabled: true });
        this.setState({ isLoading: false });

    }

    handleInputChange(event) {
        const { name, value } = event.target;

        this.setState({
            [name]: value
        });

        this.setState({ saveDisabled: false });

    }


  render() {
    const classes = this.props.classes;
    return (
        <Paper className={classes.paper} square>
            {this.state.isLoading ? (
                <LoadingOverlay />
            ) : (
        <Table className={classes.table}>
            <TableHead>
            </TableHead>
                <TableBody>
                    <TableRow>
                      <TableCell className={classes.langText}>Maintenance banner enabled on all surveys - Y/N</TableCell>
                    <TableCell padding="checkbox">
                        <Checkbox
                            indeterminate={false}
                            checked={this.state.enabledChecked}
                            onChange={this.handlecheckBoxUpdate('enabledChecked')} name="enabledChecked" color="primary"

                        />
                  </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell className={classes.langText}>English maintenance message:</TableCell>
                        <TableCell>
                            <TextField
                                inputProps={{ maxLength: 600 }}
                                placeholder="English Maintenance Message"
                                key={1}
                                multiline="true"
                                name="englishTextValue"
                                fullWidth
                                value={this.state.englishTextValue}
                                onChange={event => this.handleInputChange(event)}
                            />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell className={classes.langText}>German maintenance message:</TableCell>
                        <TableCell>
                            <TextField
                                inputProps={{ maxLength: 600 }}
                                placeholder="German Maintenance Message"
                                key={1}
                                multiline="true"
                                name="germanTextValue"
                                fullWidth
                                value={this.state.germanTextValue}
                                onChange={event => this.handleInputChange(event)}
                            />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell className={classes.langText}>French maintenance message:</TableCell>
                        <TableCell>
                            <TextField
                                inputProps={{ maxLength: 600 }}
                                placeholder="French Maintenance Message"
                                key={1}
                                multiline="true"
                                name="frenchTextValue"
                                fullWidth
                                value={this.state.frenchTextValue}
                                onChange={event => this.handleInputChange(event)}
                            />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell className={classes.langText}>Italian maintenance message:</TableCell>
                        <TableCell>
                            <TextField
                                inputProps={{ maxLength: 600 }}
                                placeholder="Italian Maintenance Message"
                                key={1}
                                multiline="true"
                                name="italianTextValue"
                                fullWidth
                                value={this.state.italianTextValue}
                                onChange={event => this.handleInputChange(event)}
                            />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell className={classes.langText}>Polish maintenance message:</TableCell>
                        <TableCell>
                            <TextField
                                inputProps={{ maxLength: 600 }}
                                placeholder="Polish Maintenance Message"
                                key={1}
                                multiline="true"
                                name="polishTextValue"
                                fullWidth
                                value={this.state.polishTextValue}
                                onChange={event => this.handleInputChange(event)}
                            />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell className={classes.langText}>Spanish maintenance message:</TableCell>
                        <TableCell>
                            <TextField
                                inputProps={{ maxLength: 600 }}
                                placeholder="Spanish Maintenance Message"
                                key={1}
                                multiline="true"
                                name="spanishTextValue"
                                fullWidth
                                value={this.state.spanishTextValue}
                                onChange={event => this.handleInputChange(event)}
                            />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell className={classes.langText}>Portuguese maintenance message:</TableCell>
                        <TableCell>
                            <TextField
                                inputProps={{ maxLength: 600 }}
                                placeholder="Portuguese Maintenance Message"
                                key={1}
                                multiline="true"
                                name="portugueseTextValue"
                                fullWidth
                                value={this.state.portugueseTextValue}
                                onChange={event => this.handleInputChange(event)}
                            />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell className={classes.langText}>Turkish maintenance message:</TableCell>
                        <TableCell>
                            <TextField
                                inputProps={{ maxLength: 600 }}
                                placeholder="Turkish Maintenance Message"
                                key={1}
                                multiline="true"
                                name="turkishTextValue"
                                fullWidth
                                value={this.state.turkishTextValue}
                                onChange={event => this.handleInputChange(event)}
                            />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell className={classes.langText}>British English maintenance message:</TableCell>
                        <TableCell>
                            <TextField
                                inputProps={{ maxLength: 600 }}
                                placeholder="British English Maintenance Message"
                                key={1}
                                multiline="true"
                                name="britishTextValue"
                                fullWidth
                                value={this.state.britishTextValue}
                                onChange={event => this.handleInputChange(event)}
                            />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell className={classes.langText}>Japanese maintenance message:</TableCell>
                        <TableCell>
                            <TextField
                                inputProps={{ maxLength: 600 }}
                                placeholder="Japanese Maintenance Message"
                                key={1}
                                multiline="true"
                                name="japaneseTextValue"
                                fullWidth
                                value={this.state.japaneseTextValue}
                                onChange={event => this.handleInputChange(event)}
                            />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell className={classes.langText}>Korean maintenance message:</TableCell>
                        <TableCell>
                            <TextField
                                inputProps={{ maxLength: 600 }}
                                placeholder="Korean Maintenance Message"
                                key={1}
                                multiline="true"
                                name="koreanTextValue"
                                fullWidth
                                value={this.state.koreanTextValue}
                                onChange={event => this.handleInputChange(event)}
                            />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell className={classes.langText}>Indonesian maintenance message:</TableCell>
                        <TableCell>
                            <TextField
                                inputProps={{ maxLength: 600 }}
                                placeholder="Indonesian Maintenance Message"
                                key={1}
                                multiline="true"
                                name="indonesianTextValue"
                                fullWidth
                                value={this.state.indonesianTextValue}
                                onChange={event => this.handleInputChange(event)}
                            />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell className={classes.langText}>Chinese maintenance message:</TableCell>
                        <TableCell>
                            <TextField
                                inputProps={{ maxLength: 600 }}
                                placeholder="Chinese Maintenance Message"
                                key={1}
                                multiline="true"
                                name="chineseTextValue"
                                fullWidth
                                value={this.state.chineseTextValue}
                                onChange={event => this.handleInputChange(event)}
                            />
                        </TableCell>
                    </TableRow>
                </TableBody>
        </Table>
         )}
            <Button variant="contained" color="primary" className={classes.button} onClick={this.handleSave} disabled={this.state.saveDisabled}>
                Save Changes
            </Button>
        </Paper>
    );

  }
}


const styles = () => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'hidden',
    marginTop: '1rem'
  },
  paper: {
    maxWidth: '60%',
    margin: '0 auto',
    width: '60%',
    marginTop: '10px',
    padding: '20px'
  },
    button: {
      float: 'right',
      marginTop: '20px'
    },
    langText: {
      fontSize: '16px',
      width: '50%',
    }
});

const mapStateToProps = (state, props) => {
  return {
    isLoading: state.entities.get('isLoading'),
    surveyType: state.surveys.get('surveyType')
  };
};

export default withStyles(styles)(
    connect(
        mapStateToProps,
        {
          getMaintenance,
          getResources,
          updateMaintenance,
            handleToastMessage
        }
    )(MaintenanceContainer)
);
