import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AppBar, Avatar, Button, Paper, Toolbar, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import { filter } from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { handleToastMessage } from '../layout/layout.actions';
import SurveyTypeForm from './surveyTypeForm.component';
import { createSurveyType, deleteSurveyType, saveSurveyType, selectSurveyType } from './surveyTypes.actions';
import Confirm from '@survey/common/dist/components/dialogs/Confirm';
//import { getVendorsList } from '@survey/common/dist/actions/vendors.actions';
//import { getProductsList } from '@survey/common/dist/actions/products.actions';
import { getAnswers } from '@survey/common/dist/actions/answers.actions';
import { getTabs } from '@survey/common/dist/actions/tabs.actions';
import { getPages } from '@survey/common/dist/actions/pages.actions';
import { getQuestions } from '@survey/common/dist/actions/questions.actions';
import { getTechnologies, getTechnologiesList } from '@survey/common/dist/actions/technologies.actions';
import { getTechnologyQuestions } from '@survey/common/dist/actions/technologyQuestions.actions';
import { getEntityTypes } from '@survey/common/dist/actions/entities.actions';
import { getOrganizationTypes, getAccountsByType } from '@survey/common/dist/actions/organizations.actions';
import { getCountries, getCountriesList, getRegionsList } from '@survey/common/dist/actions/countries.actions';
import { getSurveys } from '@survey/common/dist/actions/surveys.actions';
import LoadingOverlay from '../layout/loadingOverlay.component';

const styles = (theme) => ({
  toolBar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  surveyType: {
    display: 'flex',
    flexDirection: 'row',
  },
  avatar: {
    margin: theme.spacing(1),
  },
  heading: {
    margin: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(1),
  },
});

class SurveyTypeContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showDeleteDialog: false,
      showSaveDialog: false,
      surveyType: {},
    };

    ['onSubmit', 'deleteSurveyType', 'handleOpenConfirmDialog', 'handleOpenDeleteDialog'].forEach((k) => (this[k] = this[k].bind(this)));
  }

  componentDidMount() {
    this.props.getPages();
    this.props.getTabs();
    this.props.getQuestions();
    this.props.getAnswers();
    this.props.getEntityTypes();
    this.props.getOrganizationTypes();
    this.props.getCountries();
    this.props.getTechnologyQuestions();
    this.props.getTechnologies();
    this.props.getCountriesList();
    this.props.getRegionsList();
    //this.props.getProductsList();
    this.props.getTechnologiesList();
    //this.props.getVendorsList();
    this.props.getSurveys();
    if (this.props.match.params.surveyTypeId !== 'new') {
      this.props.selectSurveyType(this.props.match.params.surveyTypeId);
    }
  }

  handleOpenDeleteDialog() {
    this.setState({ showDeleteDialog: true });
  }

  handleOpenConfirmDialog(data) {
    this.setState({ showSaveDialog: true, surveyType: data });
  }

  async onSubmit() {
    const { surveyTypeId } = this.props.match.params;
    this.setState({ showSaveDialog: false });

    if (surveyTypeId === 'new') {
      let data = { ...this.state.surveyType };
      delete data.surveyTypeId;
      let createResponse = await this.props.createSurveyType(data);
      if (createResponse.type === 'CREATE_SURVEY_TYPE_SUCCESS') {
        this.props.handleToastMessage('SUCCESSFUL', false);
        this.props.history.goBack();
      } else {
        //Display error
        this.props.handleToastMessage('ERROR!', true);
      }
    } else {
      // if (this.state.surveyType) {
      let saveResponse = await this.props.saveSurveyType(surveyTypeId, this.state.surveyType);

      if (saveResponse.type === 'SAVE_SURVEY_TYPE_SUCCESS') {
        this.props.handleToastMessage('SUCCESSFUL', false);
        this.props.history.goBack();
      } else {
        //Display error
        this.props.handleToastMessage('ERROR!', true);
      }
      // } else {
      //   let activationData = this.props.surveyType;
      //   activationData.active = !activationData.active;

      //   delete activationData.bsonId;

      //   let saveResponse = await this.props.saveSurveyType(surveyTypeId, activationData);

      //   if (saveResponse.type === 'SAVE_SURVEY_TYPE_SUCCESS') {
      //     this.props.handleToastMessage('SUCCESSFUL', false);
      //   } else {
      //     this.props.handleToastMessage('ERROR!', true);
      //   }
      // }
    }
  }

  async deleteSurveyType() {
    let deleteResponse = await this.props.deleteSurveyType(this.props.match.params.surveyTypeId);
    if (deleteResponse.type === 'DELETE_SURVEY_TYPE_SUCCESS') {
      this.props.handleToastMessage('SUCCESSFUL', false);
      this.props.history.goBack();
    } else {
      //Display error
      this.props.handleToastMessage('ERROR!', true);
    }
  }

  async toggleActiveStatus() {
    let surveyTypeId = this.props.match.params.surveyTypeId;
    let surveyType = { ...this.props.surveyType };
    surveyType.active = !surveyType.active;
    let saveResponse = await this.props.saveSurveyType(surveyTypeId, surveyType);

    if (saveResponse.type === 'SAVE_SURVEY_TYPE_SUCCESS') {
      this.props.handleToastMessage('Changed active status!', false);
      this.props.history.goBack();
    } else {
      //Display error
      this.props.handleToastMessage('ERROR!', true);
    }
  }

  render() {
    const {
      classes,
      surveyType,
      organizationTypes,
      accountList,
      isLoading,
      permissions,
      tabs,
      questions,
      entityTypes,
      pages,
      countries,
      technologyQuestions,
      technologies,
      history,
      answers,
      surveys,
    } = this.props;
    const { showSaveDialog, showDeleteDialog } = this.state;
    const { surveyTypeId } = this.props.match.params;

    let surveyTypeReferences = [];
    if (surveyType) {
      surveyTypeReferences = filter(surveys, (survey) => {
        return surveyType.surveyTypeID === survey.surveyTypeID;
      });
    }

    return isLoading ? (
      <LoadingOverlay />
    ) : (
      <Paper className={classes.paper} square>
        <AppBar position="static" color="default">
          <Toolbar className={classes.toolBar}>
            <div className={classes.surveyType}>
              <Avatar className={classes.avatar}>
                <FontAwesomeIcon icon="edit" />
              </Avatar>
              <div>
                <Typography variant="h6" color="inherit" style={{ alignSelf: 'center' }} onClick={() => this.props.history.push('/surveyTypes')}>
                  Survey Types
                </Typography>
                <Typography variant="subtitle1" color="inherit">
                  SurveyType ID: {surveyType && surveyTypeId !== 'new' ? surveyType.surveyTypeID : 'New'}
                </Typography>
              </div>
            </div>
            {surveyTypeId !== 'new' && (
              <div>
                {surveyType.active ? (
                  <Button variant="contained" color="primary" className={classes.button} onClick={() => this.toggleActiveStatus()}>
                    De-Activate
                  </Button>
                ) : (
                  <Button variant="contained" color="primary" className={classes.button} onClick={() => this.toggleActiveStatus()}>
                    Activate
                  </Button>
                )}
                <Button variant="contained" color="primary" className={classes.button} onClick={this.handleOpenDeleteDialog} disabled={surveyTypeReferences.length > 0}>
                  Delete
                </Button>
              </div>
            )}
          </Toolbar>
        </AppBar>
        <SurveyTypeForm
          history={history}
          permissions={permissions}
          surveyType={surveyTypeId !== 'new' ? surveyType : {}}
          organizationTypes={organizationTypes}
          accountList={accountList}
          pages={pages}
          tabs={tabs}
          answers={answers}
          questions={questions}
          technologyQuestions={technologyQuestions}
          entityTypes={entityTypes}
          surveyTypeReferences={surveyTypeReferences}
          onSubmit={this.handleOpenConfirmDialog}
          countries={countries}
          technologies={technologies}
          onError={(errors) => console.log(errors)}
          countriesList={this.props.countriesList}
          regionsList={this.props.regionsList}
          productsList={this.props.productsList}
          technologiesList={this.props.technologiesList}
          vendorsList={this.props.vendorsList}
        />
        <Confirm
          title="Confirm Save"
          onClose={() => this.setState({ showSaveDialog: false })}
          onConfirm={this.onSubmit}
          contentText={`Please confirm that you would like to save ${this.state.surveyType.name}`}
          open={showSaveDialog}
        />
        <Confirm
          title="Confirm Delete"
          onClose={() => this.setState({ showDeleteDialog: false })}
          onConfirm={this.deleteSurveyType}
          contentText={`Please confirm that you would like to delete this survey type.`}
          open={showDeleteDialog}
        />
      </Paper>
    );
  }
}

function mapStateToProps(state, props) {
  return {
    surveyType: state.surveyTypes.get('surveyType'),
    organizationTypes: state.organizations.get('organizationTypes'),
    pages: state.pages.get('pages'),
    tabs: state.tabs.get('tabs'),
    answers: state.answers.get('answers'),
    entityTypes: state.entities.get('entityTypes'),
    questions: state.questions.get('questions'),
    technologyQuestions: state.technologyQuestions.get('technologyQuestions'),
    technologies: state.technologies.get('technologies'),
    isLoading:
      state.surveyTypes.get('isTypeLoading') ||
      state.countries.get('isLoading') ||
      state.pages.get('isLoading') ||
      state.tabs.get('isLoading') ||
      state.answers.get('isLoading') ||
      state.questions.get('isLoading') ||
      state.entities.get('isLoading') ||
      state.technologies.get('isLoading') ||
      state.technologyQuestions.get('isLoading') ||
      //state.products.get('isProductsLoading') ||
      //state.vendors.get('isLoading') ||
      state.surveys.get('isLoading'),
    permissions: state.auth.get('permissions'),
    countries: state.countries.get('countries'),
    countriesList: state.countries.get('countriesList'),
    regionsList: state.countries.get('regionsList'),
    vendorsList: [],
    productsList: [],
    technologiesList: state.technologies.get('technologiesList'),
    surveys: state.surveys.get('surveys'),
  };
}

export default withStyles(styles)(
  connect(mapStateToProps, {
    selectSurveyType,
    createSurveyType,
    saveSurveyType,
    deleteSurveyType,
    getPages,
    getTabs,
    getQuestions,
    getAnswers,
    getOrganizationTypes,
    getEntityTypes,
    getCountries,
    getTechnologyQuestions,
    getTechnologies,
    handleToastMessage,
    getTechnologiesList,
    //getProductsList,
    //getVendorsList,
    getCountriesList,
    getRegionsList,
    getSurveys,
  })(SurveyTypeContainer)
);
