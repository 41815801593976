import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AppBar, Avatar, Button, Paper, Toolbar, Typography } from '@mui/material';
import { withStyles } from "@mui/styles";
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { hasPermission } from '../auth/authOperations';
import { EDIT_PRODUCTS } from '../auth/permissions';
import { handleToastMessage } from '../layout/layout.actions';
import ProductConsolidateDialog from './productConsolidateDialog';
import ProductForm from './productForm.component';
import { consolidateProducts, createProduct, deleteProduct, saveProduct, selectProduct } from '@survey/common/dist/actions/products.actions';
import Confirm from '@survey/common/dist/components/dialogs/Confirm';
import ConfirmWithInput from '@survey/common/dist/components/dialogs/ConfirmWithInput';
import LoadingOverlay from '../layout/loadingOverlay.component';

const styles = theme => ({
  toolBar: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  product: {
    display: 'flex',
    flexDirection: 'row'
  },
  avatar: {
    margin: theme.spacing(1)
  },
  heading: {
    margin: theme.spacing(1)
  },
  button: {
    margin: theme.spacing(1)
  }
});

class ProductContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: 0,
      showConsolidateDialog: false,
      showActivationDialog: false,
      showSaveDialog: false,
      confirmActivationContent: '',
      confirmActivationTitle: '',
      product: {}
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.handleConsolidateClose = this.handleConsolidateClose.bind(this);
    this.handleConsolidateProducts = this.handleConsolidateProducts.bind(this);
    this.handleOpenActivation = this.handleOpenActivation.bind(this);
    this.handleCloseActivation = this.handleCloseActivation.bind(this);
    this.handleActivation = this.handleActivation.bind(this);
    this.handleOpenConfirmDialog = this.handleOpenConfirmDialog.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    if (props.productID === 'new') {
        return {
            product: state.product
        };
    }

    if (!isEmpty(props.product) && props.product.productID !== state.product.productID) {
      return {
        product: props.product
      };
    }

    return null;
  }

  componentDidMount() {
    if (this.props.productID !== 'new') {
      this.props.selectProduct(this.props.productID);
    }
  }



  async onSubmit() {
    this.setState({ showSaveDialog: false });
    const product = { ...this.state.product };

    if (this.props.productID === 'new') {
      product.status = 'ACTIVE';
      delete product.productID;

      let createResponse = await this.props.createProduct(product);
      if (createResponse.type === 'CREATE_PRODUCT_SUCCESS') {
        this.props.handleToastMessage('SUCCESS', false);
        this.props.handleBack();
      } else {
        //Display error
        this.props.handleToastMessage('ERROR', true);
      }
    } else {
      let saveResponse = await this.props.saveProduct(product.productID, product);
      if (saveResponse.type === 'SELECT_PRODUCT_SUCCESS') {
        this.props.handleToastMessage('SUCCESS', false);
          //this.setState({ product: {} });
        // this.props.handleTabChange({}, 0);
      } else {
        //Display error
        this.props.handleToastMessage('ERROR', true);
      }
    }
  }

  handleConsolidateClose() {
    this.setState({ showConsolidateDialog: false });
  }

  async handleConsolidateProducts(data) {
    this.setState({ showConsolidateDialog: false });

    const consolidateResponse = await this.props.consolidateProducts(this.props.productID, data);

    if (consolidateResponse.type === 'CONSOLIDATE_PRODUCTS_SUCCESS') {
      this.props.handleToastMessage('SUCCESS', false);
      this.props.handleBack();
    } else {
      this.props.handleToastMessage('ERROR', true);
    }
  }

  handleCloseActivation() {
    this.setState({ showActivationDialog: false });
  }

  handleOpenActivation(type) {
    if (type === 'de-activate') {
      this.setState({
        showActivationDialog: true,
        confirmActivationTitle: 'Delete / De-Activate Product',
        confirmActivationContent:
          'Are you sure you would like to delete / de-activate this product?'
      });
    } else {
      this.setState({
        showActivationDialog: true,
        confirmActivationTitle: 'Activate Product',
        confirmActivationContent:
            'Are you sure you would like to activate this product?'
      });
    }
  }

  async handleActivation() {
    this.setState({ showActivationDialog: false });
    const { product } = this.state;

    if (product.status === 'ACTIVE') {
      const deactivateResponse = await this.props.deleteProduct(product.productID);

      if (deactivateResponse.type === 'SELECT_PRODUCT_SUCCESS') {
        this.props.handleToastMessage('SUCCESS', false);
      } else {
        this.props.handleToastMessage('ERROR', true);
      }
    } else {
      product.status = 'ACTIVE';

      const saveResponse = await this.props.saveProduct(product.productID, product);

      if (saveResponse.type === 'SELECT_PRODUCT_SUCCESS') {
        this.props.handleToastMessage('SUCCESS', false);
      } else {
        //Display error
        this.props.handleToastMessage('ERROR', true);
      }
    }
  }

  handleOpenConfirmDialog(product) {
    this.setState({ product, showSaveDialog: true });
  }

  render() {
    const { classes, permissions, productID, vendor, handleBack, isLoading, products } = this.props;
    const { showConsolidateDialog, product, showActivationDialog, confirmActivationContent, confirmActivationTitle, showSaveDialog } = this.state;

    console.log('WHAT IS THE PRODUCT IN STATE', this.state.product);

    return isLoading ? (
      <LoadingOverlay />
    ) : (
      <Paper className={classes.paper} square>
        <AppBar position="static" color="default">
          <Toolbar className={classes.toolBar}>
            <div className={classes.product}>
              <Avatar className={classes.avatar}>
                <FontAwesomeIcon icon="edit" />
              </Avatar>
              <div>
                <Typography variant="subtitle1" color="inherit">
                  Product Name: {!isEmpty(product) ? product.productName : 'New'}
                </Typography>
              </div>
            </div>
            {productID !== 'new' ? (
              <div>
                <Button
                  disabled={!hasPermission(EDIT_PRODUCTS, permissions)}
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  onClick={() => this.setState({ showConsolidateDialog: true })}
                >
                  Consolidate Products
                </Button>
                {product.status === 'ACTIVE' ? (
                  <Button disabled={!hasPermission(EDIT_PRODUCTS, permissions)} variant="contained" color="primary" className={classes.button} onClick={() => this.handleOpenActivation('de-activate')}>
                    De-Activate
                  </Button>
                ) : (
                  <Button disabled={!hasPermission(EDIT_PRODUCTS, permissions)} variant="contained" color="primary" className={classes.button} onClick={() => this.handleOpenActivation('activate')}>
                    Activate
                  </Button>
                )}
              </div>
            ) : (
              ''
            )}
          </Toolbar>
        </AppBar>
        <ProductForm handleBack={handleBack} vendorID={vendor.vendorID} product={product} permissions={permissions} onSubmit={this.handleOpenConfirmDialog} />
        <ProductConsolidateDialog product={product} products={products} open={showConsolidateDialog} onConsolidate={this.handleConsolidateProducts} onClose={this.handleConsolidateClose} />
        <ConfirmWithInput
          contentText={confirmActivationContent}
          title={confirmActivationTitle}
          onClose={this.handleCloseActivation}
          onConfirm={this.handleActivation}
          open={showActivationDialog}
          label="Comments"
          isMulti
        />
        <Confirm
          title="Confirm Save"
          onClose={() => this.setState({ showSaveDialog: false })}
          onConfirm={this.onSubmit}
          contentText={`Please confirm that you would like to save the product: ${product.productName}`}
          open={showSaveDialog}
        />
      </Paper>
    );
  }
}

ProductContainer.propTypes = {
  productID: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  vendor: PropTypes.object
};

function mapStateToProps(state, props) {
  return {
    product: state.products.get('product'),
    permissions: state.auth.get('permissions'),
    isLoading: state.products.get('isLoading'),
    products: state.vendors.get('vendorProducts')
  };
}

export default withStyles(styles)(
  connect(
    mapStateToProps,
    {
      selectProduct,
      saveProduct,
      createProduct,
      handleToastMessage,
      consolidateProducts,
      deleteProduct
    }
  )(withRouter(ProductContainer))
);
