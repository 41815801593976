import { CALL_API } from '@survey/common/dist/middleware/api';

export const GET_EMRAM_SCORINGS_REQUEST = 'GET_EMRAM_SCORINGS_REQUEST';
export const GET_EMRAM_SCORINGS_SUCCESS = 'GET_EMRAM_SCORINGS_SUCCESS';
export const GET_EMRAM_SCORINGS_FAILURE = 'GET_EMRAM_SCORINGS_FAILURE';

export const GET_EMRAM_SCORING_REQUEST = 'GET_EMRAM_SCORING_REQUEST';
export const GET_EMRAM_SCORING_SUCCESS = 'GET_EMRAM_SCORING_SUCCESS';
export const GET_EMRAM_SCORING_FAILURE = 'GET_EMRAM_SCORING_FAILURE';

export const CREATE_EMRAM_SCORING_REQUEST = 'CREATE_EMRAM_SCORING_REQUEST';
export const CREATE_EMRAM_SCORING_SUCCESS = 'CREATE_EMRAM_SCORING_SUCCESS';
export const CREATE_EMRAM_SCORING_FAILURE = 'CREATE_EMRAM_SCORING_FAILURE';

export const UPDATE_EMRAM_SCORING_REQUEST = 'UPDATE_EMRAM_SCORING_REQUEST';
export const UPDATE_EMRAM_SCORING_SUCCESS = 'UPDATE_EMRAM_SCORING_SUCCESS';
export const UPDATE_EMRAM_SCORING_FAILURE = 'UPDATE_EMRAM_SCORING_FAILURE';

export const DELETE_EMRAM_SCORING_REQUEST = 'DELETE_EMRAM_SCORING_REQUEST';
export const DELETE_EMRAM_SCORING_SUCCESS = 'DELETE_EMRAM_SCORING_SUCCESS';
export const DELETE_EMRAM_SCORING_FAILURE = 'DELETE_EMRAM_SCORING_FAILURE';

export const GET_OEMRAM_SCORINGS_REQUEST = 'GET_OEMRAM_SCORINGS_REQUEST';
export const GET_OEMRAM_SCORINGS_SUCCESS = 'GET_OEMRAM_SCORINGS_SUCCESS';
export const GET_OEMRAM_SCORINGS_FAILURE = 'GET_OEMRAM_SCORINGS_FAILURE';

export const GET_OEMRAM_SCORING_REQUEST = 'GET_OEMRAM_SCORING_REQUEST';
export const GET_OEMRAM_SCORING_SUCCESS = 'GET_OEMRAM_SCORING_SUCCESS';
export const GET_OEMRAM_SCORING_FAILURE = 'GET_OEMRAM_SCORING_FAILURE';

export const CREATE_OEMRAM_SCORING_REQUEST = 'CREATE_OEMRAM_SCORING_REQUEST';
export const CREATE_OEMRAM_SCORING_SUCCESS = 'CREATE_OEMRAM_SCORING_SUCCESS';
export const CREATE_OEMRAM_SCORING_FAILURE = 'CREATE_OEMRAM_SCORING_FAILURE';

export const UPDATE_OEMRAM_SCORING_REQUEST = 'UPDATE_OEMRAM_SCORING_REQUEST';
export const UPDATE_OEMRAM_SCORING_SUCCESS = 'UPDATE_OEMRAM_SCORING_SUCCESS';
export const UPDATE_OEMRAM_SCORING_FAILURE = 'UPDATE_OEMRAM_SCORING_FAILURE';

export const DELETE_OEMRAM_SCORING_REQUEST = 'DELETE_OEMRAM_SCORING_REQUEST';
export const DELETE_OEMRAM_SCORING_SUCCESS = 'DELETE_OEMRAM_SCORING_SUCCESS';
export const DELETE_OEMRAM_SCORING_FAILURE = 'DELETE_OEMRAM_SCORING_FAILURE';

export const GET_AMAM_SCORINGS_REQUEST = 'GET_AMAM_SCORINGS_REQUEST';
export const GET_AMAM_SCORINGS_SUCCESS = 'GET_AMAM_SCORINGS_SUCCESS';
export const GET_AMAM_SCORINGS_FAILURE = 'GET_AMAM_SCORINGS_FAILURE';

export const GET_AMAM_SCORING_REQUEST = 'GET_AMAM_SCORING_REQUEST';
export const GET_AMAM_SCORING_SUCCESS = 'GET_AMAM_SCORING_SUCCESS';
export const GET_AMAM_SCORING_FAILURE = 'GET_AMAM_SCORING_FAILURE';

export const CREATE_AMAM_SCORING_REQUEST = 'CREATE_AMAM_SCORING_REQUEST';
export const CREATE_AMAM_SCORING_SUCCESS = 'CREATE_AMAM_SCORING_SUCCESS';
export const CREATE_AMAM_SCORING_FAILURE = 'CREATE_AMAM_SCORING_FAILURE';

export const UPDATE_AMAM_SCORING_REQUEST = 'UPDATE_AMAM_SCORING_REQUEST';
export const UPDATE_AMAM_SCORING_SUCCESS = 'UPDATE_AMAM_SCORING_SUCCESS';
export const UPDATE_AMAM_SCORING_FAILURE = 'UPDATE_AMAM_SCORING_FAILURE';

export const DELETE_AMAM_SCORING_REQUEST = 'DELETE_AMAM_SCORING_REQUEST';
export const DELETE_AMAM_SCORING_SUCCESS = 'DELETE_AMAM_SCORING_SUCCESS';
export const DELETE_AMAM_SCORING_FAILURE = 'DELETE_AMAM_SCORING_FAILURE';

export const GET_INFRAM_SCORING_REQUEST = 'GET_INFRAM_SCORING_REQUEST';
export const GET_INFRAM_SCORING_SUCCESS = 'GET_INFRAM_SCORING_SUCCESS';
export const GET_INFRAM_SCORING_FAILURE = 'GET_INFRAM_SCORING_FAILURE';

export const CREATE_INFRAM_SCORING_REQUEST = 'CREATE_INFRAM_SCORING_REQUEST';
export const CREATE_INFRAM_SCORING_SUCCESS = 'CREATE_INFRAM_SCORING_SUCCESS';
export const CREATE_INFRAM_SCORING_FAILURE = 'CREATE_INFRAM_SCORING_FAILURE';

export const UPDATE_INFRAM_SCORING_REQUEST = 'UPDATE_INFRAM_SCORING_REQUEST';
export const UPDATE_INFRAM_SCORING_SUCCESS = 'UPDATE_INFRAM_SCORING_SUCCESS';
export const UPDATE_INFRAM_SCORING_FAILURE = 'UPDATE_INFRAM_SCORING_FAILURE';

export const DELETE_INFRAM_SCORING_REQUEST = 'DELETE_INFRAM_SCORING_REQUEST';
export const DELETE_INFRAM_SCORING_SUCCESS = 'DELETE_INFRAM_SCORING_SUCCESS';
export const DELETE_INFRAM_SCORING_FAILURE = 'DELETE_INFRAM_SCORING_FAILURE';

export const GET_CISOM_SCORING_REQUEST = 'GET_CISOM_SCORING_REQUEST';
export const GET_CISOM_SCORING_SUCCESS = 'GET_CISOM_SCORING_SUCCESS';
export const GET_CISOM_SCORING_FAILURE = 'GET_CISOM_SCORING_FAILURE';

export const CREATE_CISOM_SCORING_REQUEST = 'CREATE_CISOM_SCORING_REQUEST';
export const CREATE_CISOM_SCORING_SUCCESS = 'CREATE_CISOM_SCORING_SUCCESS';
export const CREATE_CISOM_SCORING_FAILURE = 'CREATE_CISOM_SCORING_FAILURE';

export const UPDATE_CISOM_SCORING_REQUEST = 'UPDATE_CISOM_SCORING_REQUEST';
export const UPDATE_CISOM_SCORING_SUCCESS = 'UPDATE_CISOM_SCORING_SUCCESS';
export const UPDATE_CISOM_SCORING_FAILURE = 'UPDATE_CISOM_SCORING_FAILURE';

export const DELETE_CISOM_SCORING_REQUEST = 'DELETE_CISOM_SCORING_REQUEST';
export const DELETE_CISOM_SCORING_SUCCESS = 'DELETE_CISOM_SCORING_SUCCESS';
export const DELETE_CISOM_SCORING_FAILURE = 'DELETE_CISOM_SCORING_FAILURE';

export const getEmramScorings = () => {
  return {
    [CALL_API]: {
      types: [GET_EMRAM_SCORINGS_REQUEST, GET_EMRAM_SCORINGS_SUCCESS, GET_EMRAM_SCORINGS_FAILURE],
      authenticated: true,
      endpoint: `EmramScores`,
      method: 'GET'
    }
  };
};

export const getEmramScoring = id => {
  return {
    [CALL_API]: {
      types: [GET_EMRAM_SCORINGS_REQUEST, GET_EMRAM_SCORINGS_SUCCESS, GET_EMRAM_SCORINGS_FAILURE],
      authenticated: true,
      endpoint: `EmramScores/${id}`,
      method: 'GET'
    }
  };
};

export const createEmramScoring = data => {
  return {
    [CALL_API]: {
      types: [CREATE_EMRAM_SCORING_REQUEST, CREATE_EMRAM_SCORING_SUCCESS, CREATE_EMRAM_SCORING_FAILURE],
      authenticated: true,
      endpoint: `EmramScores`,
      method: 'POST',
      payload: data
    }
  };
};

export const updateEmramScoring = (id, data) => {
  return {
    [CALL_API]: {
      types: [UPDATE_EMRAM_SCORING_REQUEST, UPDATE_EMRAM_SCORING_SUCCESS, UPDATE_EMRAM_SCORING_FAILURE],
      authenticated: true,
      endpoint: `EmramScores/${id}`,
      method: 'PUT',
      payload: data
    }
  };
};

export const deleteEmramScoring = id => {
  return {
    [CALL_API]: {
      types: [DELETE_EMRAM_SCORING_REQUEST, DELETE_EMRAM_SCORING_SUCCESS, DELETE_EMRAM_SCORING_FAILURE],
      authenticated: true,
      endpoint: `EmramScores/${id}`,
      method: 'DELETE',
      payload: id
    }
  };
};

export const getOEmramScorings = () => {
  return {
    [CALL_API]: {
      types: [GET_OEMRAM_SCORINGS_REQUEST, GET_OEMRAM_SCORINGS_SUCCESS, GET_OEMRAM_SCORINGS_FAILURE],
      authenticated: true,
      endpoint: `OEmramScores`,
      method: 'GET'
    }
  };
};

export const getOEmramScoring = id => {
  return {
    [CALL_API]: {
      types: [GET_OEMRAM_SCORINGS_REQUEST, GET_OEMRAM_SCORINGS_SUCCESS, GET_OEMRAM_SCORINGS_FAILURE],
      authenticated: true,
      endpoint: `OEmramScores/${id}`,
      method: 'GET'
    }
  };
};

export const createOEmramScoring = data => {
  return {
    [CALL_API]: {
      types: [CREATE_OEMRAM_SCORING_REQUEST, CREATE_OEMRAM_SCORING_SUCCESS, CREATE_OEMRAM_SCORING_FAILURE],
      authenticated: true,
      endpoint: `OEmramScores`,
      method: 'POST',
      payload: data
    }
  };
};

export const updateOEmramScoring = (id, data) => {
  return {
    [CALL_API]: {
      types: [UPDATE_OEMRAM_SCORING_REQUEST, UPDATE_OEMRAM_SCORING_SUCCESS, UPDATE_OEMRAM_SCORING_FAILURE],
      authenticated: true,
      endpoint: `OEmramScores/${id}`,
      method: 'PUT',
      payload: data
    }
  };
};

export const deleteOEmramScoring = id => {
  return {
    [CALL_API]: {
      types: [DELETE_OEMRAM_SCORING_REQUEST, DELETE_OEMRAM_SCORING_SUCCESS, DELETE_OEMRAM_SCORING_FAILURE],
      authenticated: true,
      endpoint: `OEmramScores/${id}`,
      method: 'DELETE',
      payload: id
    }
  };
};

export const getAmamScorings = () => {
  return {
    [CALL_API]: {
      types: [GET_AMAM_SCORINGS_REQUEST, GET_AMAM_SCORINGS_SUCCESS, GET_AMAM_SCORINGS_FAILURE],
      authenticated: true,
      endpoint: `AmamScoring`,
      method: 'GET'
    }
  };
};

export const getAmamScoring = id => {
  return {
    [CALL_API]: {
      types: [GET_AMAM_SCORINGS_REQUEST, GET_AMAM_SCORINGS_SUCCESS, GET_AMAM_SCORINGS_FAILURE],
      authenticated: true,
      endpoint: `AmamScoring/${id}`,
      method: 'GET'
    }
  };
};

export const createAmamScoring = data => {
  return {
    [CALL_API]: {
      types: [CREATE_AMAM_SCORING_REQUEST, CREATE_AMAM_SCORING_SUCCESS, CREATE_AMAM_SCORING_FAILURE],
      authenticated: true,
      endpoint: `AmamScoring`,
      method: 'POST',
      payload: data
    }
  };
};

export const updateAmamScoring = (id, data) => {
  return {
    [CALL_API]: {
      types: [UPDATE_AMAM_SCORING_REQUEST, UPDATE_AMAM_SCORING_SUCCESS, UPDATE_AMAM_SCORING_FAILURE],
      authenticated: true,
      endpoint: `AmamScoring/${id}`,
      method: 'PUT',
      payload: data
    }
  };
};

export const deleteAmamScoring = id => {
  return {
    [CALL_API]: {
      types: [DELETE_AMAM_SCORING_REQUEST, DELETE_AMAM_SCORING_SUCCESS, DELETE_AMAM_SCORING_FAILURE],
      authenticated: true,
      endpoint: `AmamScoring/${id}`,
      method: 'DELETE',
      payload: id
    }
  };
};

export const getInframScorings = () => {
  return {
    [CALL_API]: {
      types: [GET_INFRAM_SCORING_REQUEST, GET_INFRAM_SCORING_SUCCESS, GET_INFRAM_SCORING_FAILURE],
      authenticated: true,
      endpoint: `InframScoring`,
      method: 'GET'
    }
  };
};

export const getInframScoring = id => {
  return {
    [CALL_API]: {
      types: [GET_INFRAM_SCORING_REQUEST, GET_INFRAM_SCORING_SUCCESS, GET_INFRAM_SCORING_FAILURE],
      authenticated: true,
      endpoint: `InframScoring/${id}`,
      method: 'GET'
    }
  };
};

export const createInframScoring = data => {
  return {
    [CALL_API]: {
      types: [CREATE_INFRAM_SCORING_REQUEST, CREATE_INFRAM_SCORING_SUCCESS, CREATE_INFRAM_SCORING_FAILURE],
      authenticated: true,
      endpoint: `InframScoring`,
      method: 'POST',
      payload: data
    }
  };
};

export const updateInframScoring = (id, data) => {
  return {
    [CALL_API]: {
      types: [UPDATE_INFRAM_SCORING_REQUEST, UPDATE_INFRAM_SCORING_SUCCESS, UPDATE_INFRAM_SCORING_FAILURE],
      authenticated: true,
      endpoint: `InframScoring/${id}`,
      method: 'PUT',
      payload: data
    }
  };
};

export const deleteInframScoring = id => {
  return {
    [CALL_API]: {
      types: [DELETE_INFRAM_SCORING_REQUEST, DELETE_INFRAM_SCORING_SUCCESS, DELETE_INFRAM_SCORING_FAILURE],
      authenticated: true,
      endpoint: `InframScoring/${id}`,
      method: 'DELETE',
      payload: id
    }
  };
};

export const getCisomScorings = () => {
  return {
    [CALL_API]: {
      types: [GET_CISOM_SCORING_REQUEST, GET_CISOM_SCORING_SUCCESS, GET_CISOM_SCORING_FAILURE],
      authenticated: true,
      endpoint: `CisomScoring`,
      method: 'GET'
    }
  };
};

export const getCisomScoring = id => {
  return {
    [CALL_API]: {
      types: [GET_CISOM_SCORING_REQUEST, GET_CISOM_SCORING_SUCCESS, GET_CISOM_SCORING_FAILURE],
      authenticated: true,
      endpoint: `CisomScoring/${id}`,
      method: 'GET'
    }
  };
};

export const createCisomScoring = data => {
  return {
    [CALL_API]: {
      types: [CREATE_CISOM_SCORING_REQUEST, CREATE_CISOM_SCORING_SUCCESS, CREATE_CISOM_SCORING_FAILURE],
      authenticated: true,
      endpoint: `CisomScoring`,
      method: 'POST',
      payload: data
    }
  };
};

export const updateCisomScoring = (id, data) => {
  return {
    [CALL_API]: {
      types: [UPDATE_CISOM_SCORING_REQUEST, UPDATE_CISOM_SCORING_SUCCESS, UPDATE_CISOM_SCORING_FAILURE],
      authenticated: true,
      endpoint: `CisomScoring/${id}`,
      method: 'PUT',
      payload: data
    }
  };
};

export const deleteCisomScoring = id => {
  return {
    [CALL_API]: {
      types: [DELETE_CISOM_SCORING_REQUEST, DELETE_CISOM_SCORING_SUCCESS, DELETE_CISOM_SCORING_FAILURE],
      authenticated: true,
      endpoint: `CisomScoring/${id}`,
      method: 'DELETE',
      payload: id
    }
  };
};
