import { isEmpty } from 'lodash';
// const isTokenExpired = decodedJwt => {
//   try {
//     return !decodedJwt.exp || new Date().getTime() >= decodedJwt.exp * 1000;
//   } catch (e) {
//     return true;
//   }
// };

export const hasPermission = (permission, userPermissions) => {
  //M.Droy hack to remove till rework
  return true;
  //return userPermissions && userPermissions.indexOf(permission) > -1 ? true : false;
};

export const isAuthenticated = decodedJwt => {
  if (!isEmpty(decodedJwt)) {
    let expiresAt = JSON.parse(localStorage.getItem('expires_at'));
    return new Date().getTime() < expiresAt;
  }

  return false;
};
