import { Getter } from '@devexpress/dx-react-core';
import { EditingState } from '@devexpress/dx-react-grid';
import { Grid, Table, TableEditColumn, TableEditRow, TableHeaderRow } from '@devexpress/dx-react-grid-material-ui';
import { FormControl, Paper, TableCell } from '@mui/material';
import { withStyles } from "@mui/styles";
import { find, findIndex, isEmpty, sortBy } from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import CustomSaveTableRow from '../formControls/CustomSaveTableRow';
import { getVendorsList } from '@survey/common/dist/actions/vendors.actions';
import { getProductsList } from '@survey/common/dist/actions/products.actions';
import CustomSelect from '@survey/common/dist/components/form-controls/CustomSelect';
import { getCountriesList, getRegionsList } from '@survey/common/dist/actions/countries.actions';
import { getTechnologiesList } from '@survey/common/dist/actions/technologies.actions';

const styles = theme => ({
  gridPaper: {
    width: '100%',
    position: 'relative'
  },
  disabledOverlay: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    left: 0,
    top: 0,
    backgroundColor: 'rgb(211,212,214,0.8)',
    zIndex: 1
  },
  root: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  formControl: {
    margin: theme.spacing(.5)
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  chip: {
    margin: theme.spacing(.25)
  },
  smallBtn: {
    padding: '0px',
    margin: '0px'
  }
});

const AnswerEditor = ({ answers, value, onValueChange }) => {
  let answerValue = value;

  if (typeof value === 'string' && value.length > 0) {
    answerValue = answers.find(a => a.label === value);
  }

  return (
    <FormControl fullWidth={true}>
      <CustomSelect disabled={false} name="selectedAnswer" fullWidth={true} value={answerValue ? answerValue : ''} onChange={(e) => onValueChange(e)} options={answers} label="" />
    </FormControl>
  );
};

const QuestionEditor = ({ questions, selectedQuestions, onValueChange }) => {
  return (
      <CustomSelect disabled={false} name="selectedQuestions" fullWidth={true} isMulti={true} value={selectedQuestions} onChange={(e) => onValueChange(e)} options={questions} label="" />
  );
};

class BranchingQuestionsSelector extends Component {
  constructor(props) {
    super(props);

    const { selectedAnswer } = props;

    this.state = {
      columns: [
        {
          name: 'answer',
          title: 'If answer is'
        },
        {
          name: 'questions',
          title: 'Display Questions Id(s)',
          getCellValue: row => {
            if (row && row.questions) {
              let cellValue = '';

              row.questions.forEach((i, index) => {
                if (index < row.questions.length - 1) {
                  cellValue += `${i}, `;
                } else {
                  cellValue += i;
                }
              });

              return cellValue;
            } else {
              return [];
            }
          }
        }
      ],
      editingRowIds: [],
      addedRows: [],
      rowChanges: {},
      questionsList: [],
      selectedQuestions: [],
      formattedBranchingQuestions: [],
      answers: selectedAnswer ? selectedAnswer.answers : []
    };

    if (this.props.questions) {
      this.state.questionsList = this.mapQuestionsToValues(this.props.questions);
    }

    if (this.props.branchingQuestions) {
      this.state.formattedBranchingQuestions = this.transformQuestions(this.props.branchingQuestions);
    }

    [
      'mapQuestionsToValues',
      'changeAddedRows',
      'changeEditingRowIds',
      'changeRowChanges',
      'selectCellEditor',
      'commitChanges',
      'updateValue',
      'transformQuestions',
      'verifyQuestions',
      'saveRow',
      'disableLoopingQuestions'
    ].map(item => (this[item] = this[item].bind(this)));
  }

  componentDidMount() {
    const { vendorsList, countriesList, productsList, regionsList, technologiesList } = this.props;
    isEmpty(countriesList) && this.props.getCountriesList();
    isEmpty(productsList) && this.props.getProductsList();
    isEmpty(regionsList) && this.props.getRegionsList();
    isEmpty(technologiesList) && this.props.getTechnologiesList();
    isEmpty(vendorsList) && this.props.getVendorsList();
  }

  mapQuestionsToValues(questions) {
    questions = sortBy(questions, [question => question.questionDescription.toLowerCase()]);

    const questionsList = questions.map(q => ({
      value: q.questionID,
      label: `${q.questionDescription} (${q.questionID})`
    }));

    return this.disableLoopingQuestions(questionsList);
  }

  updateValue(field, onValueChange) {
    return (event, val) => {
      onValueChange(val);

      this.setState({
        [field]: val
      });
    };
  }

  saveRow(value) {
    if (!isEmpty(value.added) && this.state.addedRows.length) {
      if (this.verifyQuestions(this.state.addedRows)) {
        this.commitChanges({ added: [...this.state.addedRows], changed: null, deleted: null });
        this.setState({ addedRows: [], rowChanges: {}, selectedQuestions: [] });
      }
    } else if (!isEmpty(value.changed)) {
      const changed = this.state.editingRowIds.reduce((obj, val) => {
        obj[val] = { ...this.state.formattedBranchingQuestions[val], ...this.state.rowChanges[val] };
        return obj;
      }, {});

      if (this.verifyQuestions(Object.values(changed))) {
        this.commitChanges({ added: null, changed, deleted: null });
        this.setState({ editingRowIds: [], selectedQuestions: [] });
      }
    } else {
      this.commitChanges({ added: null, changed: null, deleted: value.deleted });
    }
  }

  disableLoopingQuestions(questionsList) {
    const ownID = this.props.match.params.questionId;
    const testedQuestion = [];

    let isValid = true;

    /* This makes a unique list of branching questions for a provided question. */
    const getUniqueListOfChildQuestions = questionID => {
      let qList = [];

      /* Get the question data for the questionID */
      const question = this.props.questions.find(q => {
        return q.questionID === questionID;
      });

      /* If the question wasn't found or doesn't have branching questions, 
         return an empty array. */
      if (!question || !question.branchingQuestions) {
        return qList;
      }

      /* Each value in the branchingQuestions object is an array of questionIDs. 
         Add them to the list of child nodes. */
      Object.values(question.branchingQuestions).forEach(val => {
        qList = [...qList, ...val];
      });

      /* Return a unique list */
      return qList.filter((value, index, self) => {
        return self.indexOf(value) === index;
      });
    };

    /* Do a depth first search to check a question for possible loops. */
    const depthFirstCheckQuestions = questionID => {
      // If this node has already been discovered, skip it.
      if (testedQuestion.findIndex(qId => qId === questionID) > -1) {
        return;
      }

      /* If this node is the same as the parent question, this question must 
         not be an option to branch to. */
      if (Number(questionID) === Number(ownID)) {
        isValid = false;
        return;
      }

      /* Add this question to the list of questions that have been evaluated */
      testedQuestion.push(questionID);

      /* Get a list of child questions and test all that haven't been tested */
      getUniqueListOfChildQuestions(questionID).forEach(childQuestionId => {
        if (-1 === testedQuestion.findIndex(qId => qId === childQuestionId)) {
          depthFirstCheckQuestions(childQuestionId);
        }
      });
    };

    /* Return the provided array with an extra key added describing whether the 
       question should be disabled or not. */
    return questionsList.map(q => {
      isValid = true;
      depthFirstCheckQuestions(q.value);

      return { ...q, disabled: !isValid };
    });
  }

  selectCellEditor({ column, value, onValueChange }) {
    const { questionsList, selectedQuestions } = this.state;

    if (column.name === 'questions') {
      return (
        <TableCell style={{ padding: '0px 10px' }}>
          <QuestionEditor key={value} questions={questionsList} selectedQuestions={selectedQuestions} value={value} onValueChange={field => this.updateValue(field, onValueChange)} />
        </TableCell>
      );
    } else {
      return (
        <TableCell style={{ padding: '0px 10px' }}>
          <AnswerEditor answers={this.state.answers ? this.state.answers : []} value={value} onValueChange={field => this.updateValue(field, onValueChange)} />
        </TableCell>
      );
    }
  }

  /* Verify that any questions being added or updated have all of the requisite
     fields */
  verifyQuestions(questions) {
    let hasFailed = false;

    questions.forEach(question => {
      if (!question.questions || question.questions.length < 1) {
        this.props.handleToastMessage('A question must be selected before saving changes!', true);
        hasFailed = true;
      } else if (!question.answer || question.answer.length <= 1) {
        this.props.handleToastMessage('An answer must be selected before saving changes!', true);
        hasFailed = true;
      }
    });

    return !hasFailed;
  }

  changeAddedRows(addedRows) {
    if (addedRows.length === 1) {
      if (addedRows[0].answer && findIndex(this.state.formattedBranchingQuestions, { answer: addedRows[0].answer.label }) > -1) {
        this.props.handleToastMessage('You cannot save another branching question with the same answer.', true);

        return;
      }

      const initialized = addedRows.map(row => (Object.keys(row).length ? row : {}));

      if (this.state.editingRowIds.length > 0) {
        this.setState({ addedRows: initialized, editingRowIds: [], selectedQuestions: [] });
      }

      this.setState({ addedRows: initialized, editingRowIds: [] });

      /* Set the editing state as true */
      this.props.toggleBranchingEditState(true);

      return;
    }

    /* Set the editing state as false */
    this.props.toggleBranchingEditState(false);

    this.setState({ addedRows: [], editingRowIds: [], selectedQuestions: [] });
  }

  changeEditingRowIds(editingRowIds) {
    const { selectedQuestions } = this.state;

    if (editingRowIds.length > 0) {
      this.props.toggleBranchingEditState(true);

      const key = Object.keys(this.props.branchingQuestions)[editingRowIds[0]];
      const questions = this.props.branchingQuestions[key].map(q => {
        const quest = this.state.questionsList.find(ql => ql.value === q);

        if (quest) {
          return quest;
        } else {
          return null;
        }
      });

      questions.forEach(q => {
        if (!selectedQuestions.includes(q)) selectedQuestions.push(q);
      });

      this.setState({ editingRowIds: [editingRowIds[0]], selectedQuestions, addedRows: [] });
    } else {
      this.props.toggleBranchingEditState(false);
      this.setState({ editingRowIds: [], selectedQuestions: [] });
    }
  }

  changeRowChanges(rowChanges) {
    if (!isEmpty(rowChanges)) {
      const rowIDs = Object.keys(rowChanges);

      if (rowChanges[rowIDs[0]].answer && findIndex(this.state.formattedBranchingQuestions, { answer: rowChanges[rowIDs[0]].answer.label }) > -1) {
        this.props.handleToastMessage('You cannot save another branching question with the same answer.', true);

        return;
      }
    }

    this.setState({ rowChanges });
  }
  
  componentDidUpdate(prevProps, prevState){
    if (prevProps.questions !== this.props.questions) {
      const questionsList = this.mapQuestionsToValues(this.props.questions);

      this.setState({ questionsList });
    }

    if (prevProps.branchingQuestions !== this.props.branchingQuestions) {
      const formattedBranchingQuestions = this.transformQuestions(this.props.branchingQuestions);

      this.setState({ formattedBranchingQuestions });
    }

    if (this.props.selectedAnswer && this.props.selectedAnswer.answers) {
      // If no answers are set or the selected answer changed, get answers from the selected answer and clear any changes.
      if (this.state.answers.length === 0 || this.props.selectedAnswer.answerID !== prevProps.selectedAnswer.answerID) {
        let dropdownAnswers;
        if (this.props.selectedAnswer.type === 'API' && !this.props.selectedAnswer.apiName.includes('entity')) {
          dropdownAnswers = this.props[this.props.selectedAnswer.apiName];
        } else {
          dropdownAnswers = this.props.selectedAnswer.answers;
        }
        this.setState({ answers: dropdownAnswers, editingRowIds: [], addedRows: [], rowChanges: {} });
        // this.setState({ answers: dropdownAnswers });
      }
    }
  }

  commitChanges({ added, changed, deleted }) {
    let { branchingQuestions } = this.props;

    if (added) {
      added = added.reduce((list, question) => {
        // Only add the question if all the data is valid
        if (question.answer && question.questions && question.questions.length > 0) {
          const answer = this.state.answers.find(a => a.label === question.answer.label);
          const questions = question.questions.map(q => {
            return q.value;
          });

          if (answer) {
            list[answer.value] = questions;
          }
        }

        return list;
      }, {});

      branchingQuestions = { ...branchingQuestions, ...added };
    }

    if (changed) {
      Object.keys(changed).forEach(c => {
        const questionsChanged = typeof changed[c].questions[0] === 'object';
        const answerChanged = typeof changed[c].answer === 'object';

        const key = Object.keys(branchingQuestions)[c];
        const answer = this.state.answers.find(a => (answerChanged ? a.label === changed[c].answer.label : a.label === changed[c].answer));
        const questions = questionsChanged
          ? changed[c].questions.map(q => q.value)
          : changed[c].questions.map(ql => {
              const question = find(this.state.questionsList, { label: ql });
              return question ? question.value : '';
            });

        if (answer && questions) {
          branchingQuestions[answer.value] = [...questions];
        }

        // Remove the old value if we edited a different answerId.
        if (answer && answer.value !== key) {
          delete branchingQuestions[key];
        }
      });
    }

    if (deleted) {
      deleted.forEach(idx => {
        let key = Object.keys(branchingQuestions)[idx];

        delete branchingQuestions[key];
      });
    }

    this.props.toggleBranchingEditState(false);
    this.props.updateBranchingQuestions(branchingQuestions);
    this.setState({ formattedBranchingQuestions: this.transformQuestions(branchingQuestions) });
  }

  transformQuestions(questions) {
    const { answers } = this.state;

    if (!answers) {
      return [];
    }

    return Object.keys(questions).reduce((all, answerId) => {
      // Use == instead of === so in case one of these is a string, there will be an automatic type conversion.
      // eslint-disable-next-line
      const answer = answers.find(a => a.value == answerId);

      if (answer) {
        const questionNames = questions[answerId].map(qId => {
          const question = this.state.questionsList.find(q => q.value === qId);

          if (question) {
            return question.label;
          } else {
            return '';
          }
        });

        all.push({ answer: answer.label, questions: [...questionNames] });
      }
      return all;
    }, []);
  }

  render() {
    const { classes } = this.props;
    const { columns, editingRowIds, formattedBranchingQuestions, rowChanges, addedRows, answers } = this.state;

    return (
      <Paper className={classes.gridPaper}>
        {this.props.disabled && <div className={classes.disabledOverlay} />}
        <Grid rows={formattedBranchingQuestions} columns={columns} getRowId={this.getRowId}>
          <Table />
          <TableHeaderRow />
          <EditingState
            editingRowIds={editingRowIds}
            onEditingRowIdsChange={this.changeEditingRowIds}
            rowChanges={rowChanges}
            onRowChangesChange={this.changeRowChanges}
            addedRows={addedRows}
            onAddedRowsChange={this.changeAddedRows}
            onCommitChanges={this.saveRow}
          />
          <TableEditRow rowComponent={({ ...rowProps }) => <CustomSaveTableRow {...rowProps} />} cellComponent={this.selectCellEditor} />
          <TableEditColumn showAddCommand={answers && answers.length > 0 ? true : false} showEditCommand showDeleteCommand />
          <Getter name="tableColumns" computed={({ tableColumns }) => [...tableColumns.filter(c => c.type !== 'editCommand'), { key: 'editCommand', type: 'editCommand', width: 0 }]} />
        </Grid>
      </Paper>
    );
  }
}

const mapStateToProps = state => ({
  regionsList: state.countries.get('regionsList'),
  countriesList: state.countries.get('countriesList'),
  vendorsList: state.vendors.get('vendorsList'),
  productsList: state.products.get('productsList'),
  technologiesList: state.technologies.get('technologiesList'),
  hospitals: state.entities.get('hospitals')
});

export default withRouter(
  withStyles(styles, { withTheme: true })(
    connect(
      mapStateToProps,
      {
        getCountriesList,
        getRegionsList,
        getProductsList,
        getTechnologiesList,
        getVendorsList
      }
    )(BranchingQuestionsSelector)
  )
);
