import { find, isEmpty } from 'lodash';

export const convertMapGroupsToValues = mappings => {
  return mappings.map(mapping => {
    return { label: mapping.dataGroupDescription, value: String(mapping.dataGroupID) };
  });
};

export const convertMapGroupToValue = (mappings, groupName) => {
  const grouping = find(mappings, { dataGroupID: parseInt(groupName) });

  if (!isEmpty(grouping)) {
    return { label: grouping.dataGroupDescription, value: grouping.dataGroupID };
  }

  return '';
};

export const convertAttrToValues = (mappings, groupName) => {
  if (groupName !== '') {
    const grouping = find(mappings, { dataGroupID: parseInt(groupName) });

    if (!isEmpty(grouping)) {
      return grouping.dataMappings.map(group => {
        return { value: String(group.dataMappingID), label: group.dataPointName };
      });
    }
  }
};

export const convertAttrToValue = (mappings, groupName, groupAttr) => {
  const grouping = find(mappings, { dataGroupID: parseInt(groupName) });

  if (!isEmpty(grouping)) {
    const attr = find(grouping.dataMappings, { dataMappingID: parseInt(groupAttr) });

    if (!isEmpty(attr)) {
      return { value: attr.dataMappingID, label: attr.dataPointName };
    }
  }

  return '';
};
