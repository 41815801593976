import { CALL_API } from '@survey/common/dist/middleware/api';

export const GET_SURVEY_TYPES_REQUEST = 'GET_SURVEY_TYPES_REQUEST';
export const GET_SURVEY_TYPES_SUCCESS = 'GET_SURVEY_TYPES_SUCCESS';
export const GET_SURVEY_TYPES_FAILURE = 'GET_SURVEY_TYPES_FAILURE';

export const SELECT_SURVEY_TYPE_REQUEST = 'SELECT_SURVEY_TYPE_REQUEST';
export const SELECT_SURVEY_TYPE_SUCCESS = 'SELECT_SURVEY_TYPE_SUCCESS';
export const SELECT_SURVEY_TYPE_FAILURE = 'SELECT_SURVEY_TYPE_FAILURE';

export const CREATE_SURVEY_TYPE_REQUEST = 'CREATE_SURVEY_TYPE_REQUEST';
export const CREATE_SURVEY_TYPE_SUCCESS = 'CREATE_SURVEY_TYPE_SUCCESS';
export const CREATE_SURVEY_TYPE_FAILURE = 'CREATE_SURVEY_TYPE_FAILURE';

export const SAVE_SURVEY_TYPE_REQUEST = 'SAVE_SURVEY_TYPE_REQUEST';
export const SAVE_SURVEY_TYPE_SUCCESS = 'SAVE_SURVEY_TYPE_SUCCESS';
export const SAVE_SURVEY_TYPE_FAILURE = 'SAVE_SURVEY_TYPE_FAILURE';

export const DELETE_SURVEY_TYPE_REQUEST = 'DELETE_SURVEY_TYPE_REQUEST';
export const DELETE_SURVEY_TYPE_SUCCESS = 'DELETE_SURVEY_TYPE_SUCCESS';
export const DELETE_SURVEY_TYPE_FAILURE = 'DELETE_SURVEY_TYPE_FAILURE';

export const getSurveyTypes = () => {
  return {
    [CALL_API]: {
      types: [GET_SURVEY_TYPES_REQUEST, GET_SURVEY_TYPES_SUCCESS, GET_SURVEY_TYPES_FAILURE],
      authenticated: true,
      endpoint: `surveyTypes`,
      method: 'GET'
    }
  };
};

export const selectSurveyType = surveyTypeId => {
  return {
    [CALL_API]: {
      types: [SELECT_SURVEY_TYPE_REQUEST, SELECT_SURVEY_TYPE_SUCCESS, SELECT_SURVEY_TYPE_FAILURE],
      authenticated: true,
      endpoint: `surveyTypes/${surveyTypeId}`,
      method: 'GET'
    }
  };
};

export const createSurveyType = data => {
  return {
    [CALL_API]: {
      types: [CREATE_SURVEY_TYPE_REQUEST, CREATE_SURVEY_TYPE_SUCCESS, CREATE_SURVEY_TYPE_FAILURE],
      authenticated: true,
      endpoint: `surveyTypes`,
      method: 'POST',
      payload: data
    }
  };
};

export const saveSurveyType = (type_id, data) => {
  return {
    [CALL_API]: {
      types: [SAVE_SURVEY_TYPE_REQUEST, SAVE_SURVEY_TYPE_SUCCESS, SAVE_SURVEY_TYPE_FAILURE],
      authenticated: true,
      endpoint: `surveyTypes/${type_id}`,
      method: 'PUT',
      payload: data
    }
  };
};

export const deleteSurveyType = type_id => {
  return {
    [CALL_API]: {
      types: [DELETE_SURVEY_TYPE_REQUEST, DELETE_SURVEY_TYPE_SUCCESS, DELETE_SURVEY_TYPE_FAILURE],
      authenticated: true,
      endpoint: `surveyTypes/${type_id}`,
      method: 'DELETE'
    }
  };
};
