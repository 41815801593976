import React, { PureComponent, Fragment } from 'react';
import { Button, Menu, MenuItem, TextField } from '@mui/material';
import { withStyles } from '@mui/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { cloneDeep, find, findIndex, sortBy } from 'lodash';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { SilverSand } from '../layout/styles/colors';
import Confirm from '@survey/common/dist/components/dialogs/Confirm';
import CustomSelect from '@survey/common/dist/components/form-controls/CustomSelect';

class MenuButton extends React.Component {
  state = {
    anchorEl: null
  };

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const { anchorEl } = this.state;

    return (
      <Fragment>
        <Button color="primary" size="small" onClick={this.handleClick}>
          Add Question
        </Button>
        <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={this.handleClose}>
          <MenuItem
            onClick={() => {
              this.setState({ anchorEl: null });
              this.props.handleMenu(this.props.row, false);
            }}
          >
            Add Question
          </MenuItem>
          <MenuItem
            onClick={() => {
              this.setState({ anchorEl: null });
              this.props.handleMenu(this.props.row, true);
            }}
          >
            Add Tech Question
          </MenuItem>
        </Menu>
      </Fragment>
    );
  }
}

const findByValue = (options, value) => {
  const result = find(options, { value: value });
  if (result) {
    return result;
  } else {
    return {};
  }
};

class ScoringTable extends PureComponent {
  constructor(props) {
    super(props);
    const rows = props.scores;
    this.state = {
      anchorEl: [],
      rows,
      showScoreDeleteDialog: false,
      showQuestionDeleteDialog: false,
      scoreToDelete: {},
      questionToDelete: {}
    };

    ['addScore', 'saveScore', 'onDeleteScore', 'addQuestion', 'updateRow', 'update', 'updateQuestion', 'updateQuestionOperator', 'updateQuestionValue', 'updateQuestionPoints'].forEach(k => {
      this[k] = this[k].bind(this);
    });
  }

  addScore() {
    let rows = [...this.state.rows];
    rows.push({
      isNew: true,
      scoreID: `temp_${Date.now()}`, //Need a temporary scoreID as it used to find rows when editing before saving
      logicalOperator: '',
      points: '',
      stage: '',
      reference: '',
      active: false,
      questions: []
    });
    this.setState({ rows });
  }

  saveScore(data) {
    let row = cloneDeep(data);
    delete row.bsonId;
    delete row.isDirty;

    if (typeof row.scoreID === 'string') {
      row.scoreID = 0;
      this.props.createScore(row);
    } else {
      this.props.updateScore(row.scoreID, row);
    }
  }

  onDeleteScore() {
    const row = this.state.scoreToDelete;
    let rows = [...this.state.rows];
    let rowIndex = findIndex(rows, { scoreID: row.scoreID });
    rows.splice(rowIndex, 1);
    this.setState({ rows, showScoreDeleteDialog: false, scoreToDelete: {} });

    //Do nothing if it was an unsaved row
    if (typeof row.scoreID !== 'string') {
      this.props.deleteScore(row.scoreID);
    }
  }

  addQuestion(row, isTech) {
    let rows = [...this.state.rows];
    let r = find(rows, { scoreID: row.scoreID });
    r.questions.push({
      technologyID: isTech ? '' : null,
      tabID: isTech ? null : '',
      questionID: '',
      answerID: '',
      values: [],
      logicalOperator: 'OR'
    });
    r.isDirty = true;
    this.setState({ rows });
  }

  onDeleteQuestion() {
    const { row, question } = this.state.questionToDelete;
    let rows = [...this.state.rows];
    let r = find(rows, { scoreID: row.scoreID });
    let questionIndex = findIndex(r.questions, { questionID: question.questionID, technologyID: question.technologyID, tabID: question.tabID });
    r.questions.splice(questionIndex, 1);
    r.isDirty = true;
    this.setState({ rows, showQuestionDeleteDialog: false, questionToDelete: {} });
  }

  updateRow(row, key, newValue) {
    let rows = [...this.state.rows];
    let r = find(rows, { scoreID: row.scoreID });
    r[key] = newValue;
    r.isDirty = true;
    this.setState({ rows });
  }

  update(row, index, question, newValue) {
    let { technologyID } = question;
    let rows = [...this.state.rows];
    let r = find(rows, { scoreID: row.scoreID });

    let q = r.questions[index];
    if (technologyID === null) {
      q.tabID = newValue;
    } else {
      q.technologyID = newValue;
    }

    q.questionID = '';
    q.answerID = '';
    q.values = [];

    r.isDirty = true;
    this.setState({ rows });
  }

  updateQuestion(row, index, question, newValue) {
    let { technologyID } = question;
    let rows = [...this.state.rows];
    let r = find(rows, { scoreID: row.scoreID });

    let q = r.questions[index];
    let surveyQuestion = technologyID === null ? find(this.props.questions, { questionID: newValue }) : find(this.props.technologyQuestions, { questionID: newValue });
    if (surveyQuestion) {
      q.questionID = newValue;
      q.answerID = surveyQuestion.answerID;
      q.values = [];
    }
    r.isDirty = true;
    this.setState({ rows });
  }

  updateQuestionOperator(row, index, question, newValue) {
    let rows = [...this.state.rows];
    let r = find(rows, { scoreID: row.scoreID });
    let q = r.questions[index];
    q.logicalOperator = newValue;

    r.isDirty = true;
    this.setState({ rows });
  }

  updateQuestionValue(row, index, question, newValue) {
    let rows = [...this.state.rows];
    let r = find(rows, { scoreID: row.scoreID });
    let q = r.questions[index];
    q.values = newValue.map(v => Number(v.value));

    r.isDirty = true;
    this.setState({ rows });
  }

  updateQuestionPoints(row, index, question, newValue) {
    let rows = [...this.state.rows];
    let r = find(rows, { scoreID: row.scoreID });
    let q = r.questions[index];
    q.points = newValue;

    r.isDirty = true;
    this.setState({ rows });
  }

  render() {
    const { rows } = this.state;
    const { classes } = this.props;

    return (
      <Fragment>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell style={{ width: '15%' }}>Stage</TableCell>
              <TableCell style={{ width: '10%' }}>Points</TableCell>
              <TableCell style={{ width: '10%' }}>Operator</TableCell>
              <TableCell style={{ width: '20%' }}>Study Tool Reference</TableCell>
              <TableCell style={{ width: '15%' }}>Status</TableCell>
              <TableCell style={{ width: '20%' }}></TableCell>
              <TableCell style={{ width: '10%' }}>
                <Button color="primary" size="small" onClick={this.addScore}>
                  Add Score
                </Button>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map(row => {
              let logicalOperatorOptions = [{ label: 'AND', value: 'AND' }, { label: 'OR', value: 'OR' }];
              let stageOptions = Array.from(new Array(7), (x, i) => ({ label: `Stage ${i + 1}`, value: i + 1 }));
              stageOptions.push({ label: `Bonus`, value: 999 });
              let activeOptions = [{ label: 'Active', value: true }, { label: 'In-active', value: false }];

              return (
                <Fragment key={row.scoreID}>
                  <TableRow>
                    <TableCell style={{ backgroundColor: SilverSand['100'] }}>
                      <ValidatorForm onSubmit={() => {}}>
                        <CustomSelect
                          name="stage"
                          isMulti={false}
                          disabled={false}
                          label=""
                          value={findByValue(stageOptions, row.stage)}
                          options={stageOptions}
                          onChange={field => newElement => this.updateRow(row, field, newElement.value)}
                        />
                      </ValidatorForm>
                    </TableCell>
                    <TableCell style={{ backgroundColor: SilverSand['100'] }}>
                      <TextField value={row.points ? row.points : ''} onChange={e => this.updateRow(row, 'points', e.target.value)} style={{ marginTop: '1rem' }} />
                    </TableCell>
                    <TableCell style={{ backgroundColor: SilverSand['100'] }}>
                      <ValidatorForm onSubmit={() => {}}>
                        <CustomSelect
                          name="logicalOperator"
                          isMulti={false}
                          disabled={false}
                          label=""
                          value={findByValue(logicalOperatorOptions, row.logicalOperator)}
                          options={logicalOperatorOptions}
                          onChange={field => newElement => this.updateRow(row, field, newElement.value)}
                        />
                      </ValidatorForm>
                    </TableCell>
                    <TableCell style={{ backgroundColor: SilverSand['100'] }}>
                      <TextField value={row.reference ? row.reference : ''} onChange={e => this.updateRow(row, 'reference', e.target.value)} style={{ marginTop: '1rem' }} />
                    </TableCell>
                    <TableCell style={{ backgroundColor: SilverSand['100'] }}>
                      <ValidatorForm onSubmit={() => {}}>
                        <CustomSelect
                          name="active"
                          isMulti={false}
                          disabled={false}
                          label=""
                          value={findByValue(activeOptions, row.active)}
                          options={activeOptions}
                          onChange={field => newElement => this.updateRow(row, field, newElement.value)}
                        />
                      </ValidatorForm>
                    </TableCell>
                    <TableCell style={{ backgroundColor: SilverSand['100'] }}>
                      {(row.isDirty || row.isNew) && (
                        <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          onClick={() => {
                            this.saveScore(row);
                          }}
                        >
                          Save
                        </Button>
                      )}
                      <Button color="primary" size="small" onClick={() => this.setState({ showScoreDeleteDialog: true, scoreToDelete: row })}>
                        Delete Score
                      </Button>
                    </TableCell>
                    <TableCell style={{ backgroundColor: SilverSand['100'] }}>
                      <MenuButton row={row} handleMenu={this.addQuestion} />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={7}>
                      <Table>
                        <TableBody>
                          {row.questions.map((question, index) => {
                            let options =
                              question.technologyID === null
                                ? this.props.tabs.map(t => {
                                    let page = this.props.pages.find(p => p.pageId === t.pageId);
                                    return { label: `${page && page.pageName} | ${t.tabName}`, value: t.tabId };
                                  })
                                : this.props.technologiesList;
                            options = sortBy(options, [option => option.label.toLowerCase()]);

                            let questionOptions =
                              question.technologyID === null
                                ? this.props.questions.filter(q => q.tabID === question.tabID).map(q => ({ label: `${q.questionDescription} (${q.questionID})`, value: q.questionID }))
                                : this.props.technologyQuestions
                                    .filter(q => q.technologies.length === 0 || q.technologies.includes(question.technologyID))
                                    .map(q => ({ label: `${q.questionDescription} (${q.questionID})`, value: q.questionID }));
                            questionOptions = sortBy(questionOptions, [option => option.label.toLowerCase()]);

                            let answerOptions = [];
                            let q = find(question.technologyID === null ? this.props.questions : this.props.technologyQuestions, { questionID: question.questionID });
                            if (q) {
                              let answer = find(this.props.answers, { answerID: q.answerID });
                              if (answer) {
                                answerOptions = answer.answers;
                              }
                            }

                            let answerValues = question.values.map(v => find(answerOptions, { value: v.toString() }));

                            return (
                              <TableRow key={index}>
                                <TableCell style={{ width: '25%', padding: '10px' }}>
                                  <ValidatorForm onSubmit={() => {}}>
                                    <CustomSelect
                                      name="technologyID"
                                      isMulti={false}
                                      disabled={false}
                                      label=""
                                      value={findByValue(options, Number(question.technologyID === null ? question.tabID : question.technologyID))}
                                      options={options}
                                      onChange={field => newElement => this.update(row, index, question, newElement.value)}
                                    />
                                  </ValidatorForm>
                                </TableCell>
                                <TableCell style={{ width: '25%', padding: '10px' }}>
                                  <ValidatorForm onSubmit={() => {}}>
                                    <CustomSelect
                                      name="questionID"
                                      isMulti={false}
                                      disabled={false}
                                      label=""
                                      value={findByValue(questionOptions, question.questionID)}
                                      options={questionOptions}
                                      onChange={field => newElement => this.updateQuestion(row, index, question, newElement.value)}
                                    />
                                  </ValidatorForm>
                                </TableCell>
                                <TableCell style={{ width: '10%', padding: '10px' }}>
                                  <ValidatorForm onSubmit={() => {}}>
                                    <CustomSelect
                                      name="logicalOperator"
                                      isMulti={false}
                                      disabled={false}
                                      label=""
                                      value={findByValue(logicalOperatorOptions, question.logicalOperator)}
                                      options={logicalOperatorOptions}
                                      onChange={field => newElement => this.updateQuestionOperator(row, index, question, newElement.value)}
                                    />
                                  </ValidatorForm>
                                </TableCell>
                                <TableCell style={{ width: '20%', padding: '10px' }}>
                                  <ValidatorForm onSubmit={() => {}}>
                                    <CustomSelect
                                      name="answer"
                                      isMulti={true}
                                      disabled={false}
                                      label=""
                                      value={answerValues}
                                      options={answerOptions}
                                      onChange={field => newElement => this.updateQuestionValue(row, index, question, newElement)}
                                    />
                                  </ValidatorForm>
                                </TableCell>
                                <TableCell style={{ width: '10%', padding: '10px' }}>
                                  <TextField
                                    value={question.points ? question.points : ''}
                                    onChange={e => this.updateQuestionPoints(row, index, question, e.target.value)}
                                    style={{ marginTop: '1rem' }}
                                    placeholder={row.points ? row.points.toString() : row.points}
                                  />
                                </TableCell>
                                <TableCell style={{ width: '10%', padding: '10px' }}>
                                  <Button color="primary" size="small" onClick={() => this.setState({ showQuestionDeleteDialog: true, questionToDelete: { row, question } })}>
                                    Delete Question
                                  </Button>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableCell>
                  </TableRow>
                </Fragment>
              );
            })}
          </TableBody>
        </Table>
        <Confirm
          title="Delete Scoring Row and all Questions?"
          onClose={() => this.setState({ showScoreDeleteDialog: false, scoreToDelete: {} })}
          onConfirm={() => this.onDeleteScore()}
          contentText={`Please confirm that you would like to delete this row.`}
          open={this.state.showScoreDeleteDialog}
        />
        <Confirm
          title="Delete this Question?"
          onClose={() => this.setState({ showQuestionDeleteDialog: false, questionToDelete: {} })}
          onConfirm={() => this.onDeleteQuestion()}
          contentText={`Please confirm you want to delete this question.`}
          open={this.state.showQuestionDeleteDialog}
        />
      </Fragment>
    );
  }
}

const styles = theme => ({});

export default withStyles(styles)(ScoringTable);
