import { FilteringState, IntegratedFiltering, IntegratedSorting, SelectionState, SortingState } from '@devexpress/dx-react-grid';
import { Grid, TableFilterRow, TableHeaderRow, TableSelection, VirtualTable } from '@devexpress/dx-react-grid-material-ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AppBar, Avatar, Fab, Toolbar, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import AddIcon from '@mui/icons-material/Add';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { getPages } from '@survey/common/dist/actions/pages.actions';
import { getTabs } from '@survey/common/dist/actions/tabs.actions';
import { getQuestions, uploadQuestions } from '@survey/common/dist/actions/questions.actions';
import LoadingOverlay from '../layout/loadingOverlay.component';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { handleToastMessage } from '../layout/layout.actions';

const styles = theme => ({
  toolBar: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  toolbarButtons: {
    marginLeft: 'auto',
    marginRight: -12
  },
  question: {
    display: 'flex',
    flexDirection: 'row'
  },
  avatar: {
    margin: theme.spacing(1)
  },
  heading: {
    margin: theme.spacing(1)
  },
  button: {
    margin: theme.spacing(1)
  },
  paper: {
    padding: theme.spacing(2)
  }
});

class QuestionsContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: 'pageName', title: 'Page Name' },
        { name: 'tabName', title: 'Tab Name' },
        { name: 'questionDescription', title: 'Question Description' },
        { name: 'additionalDescription', title: 'Additional Question Description' },
        { name: 'controlType', title: 'Control Type' },
        { name: 'startYear', title: 'Start Year' },
        { name: 'endYear', title: 'End Year' },
        { name: 'active', title: 'Active', getCellValue: row => (row.active ? 'Active' : 'Inactive') }
      ],
      tableColumnExtensions: [
        { columnName: 'controlType', width: 150 },
        { columnName: 'startYear', width: 100 },
        { columnName: 'endYear', width: 100 },
        { columnName: 'active', width: 100 }
      ],
      openImportDialog: false,
      selectedFile: null
    };

    this.addQuestion = this.addQuestion.bind(this);
    this.changeGridSelection = this.changeGridSelection.bind(this);
    this.handleClickOpen = this.handleClickOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleImport = this.handleImport.bind(this);
  }
  handleClickOpen() {
    this.setState({ openImportDialog: true });
  }
  handleClose() {
    this.setState({ openImportDialog: false });
  }
  handleImport() {
    const formData = new FormData();
    formData.append('file', this.state.selectedFile);
    this.props.uploadQuestions(formData);
    this.setState({ openImportDialog: false });
  }
  onFileChangeHandler = event => {
    this.setState({
      selectedFile: event.target.files[0]
    });
  };
  componentDidMount() {
    this.props.getPages();
    this.props.getTabs();
    this.props.getQuestions();
  }

  addQuestion() {
    this.props.history.push(`/questions/new`);
  }

  changeGridSelection(selection) {
    let question = this.props.questions[selection];
    this.props.history.push(`/questions/${question.questionID}`);
  }

  render() {
    const classes = this.props.classes;
    const { columns, tableColumnExtensions } = this.state;
    const { pages, tabs } = this.props;

    const questions = this.props.questions.map(q => {
      let tab = tabs.find(t => t.tabId === q.tabID);
      if (tab) {
        q.tabName = tab.tabName;
        let page = pages.find(p => p.pageId === tab.pageId);
        if (page) {
          q.pageName = page.pageName;
          q.pageId = page.pageId;
        }
      } else {
        q.tabName = 'No Tab';
        q.pageName = 'No Page';
      }
      return q;
    });
    if (this.props.questionUploadComplete) {
      this.props.handleToastMessage('Import complete', false);
    }
    return (
      <Fragment>
        <Dialog open={this.state.openImportDialog} onClose={this.handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Import Questions</DialogTitle>
          <DialogContent>
            <Button variant="contained" component="label">
              <input type="file" onChange={this.onFileChangeHandler} accept=".xlsx" />
            </Button>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={this.handleImport} color="primary">
              Import
            </Button>
          </DialogActions>
        </Dialog>
        <AppBar position="static" color="default">
          <Toolbar className={classes.toolBar}>
            <div className={classes.question}>
              <Avatar className={classes.avatar}>
                <FontAwesomeIcon icon="list-ul" />
              </Avatar>
              <Typography variant="h6" color="inherit" style={{ alignSelf: 'center' }}>
                Questions
              </Typography>
            </div>
            <span className={classes.toolbarButtons}>
              <Fab size="small" color="primary" onClick={this.handleClickOpen}>
                <CloudUploadIcon />
              </Fab>
              <Fab size="small" color="primary" className={classes.button} onClick={this.addQuestion}>
                <AddIcon />
              </Fab>
            </span>
          </Toolbar>
        </AppBar>
        {this.props.isLoading ? (
          <LoadingOverlay />
        ) : (
          <Grid rows={questions} columns={columns} selection={[1]}>
            <SelectionState onSelectionChange={selection => this.changeGridSelection(selection)} />
            <FilteringState defaultFilters={[]} />
            <IntegratedFiltering />
            <SortingState defaultSorting={[]} />
            <IntegratedSorting />
            <VirtualTable height={1000} columnExtensions={tableColumnExtensions} />
            <TableHeaderRow showSortingControls />
            <TableFilterRow />
            <TableSelection selectByRowClick highlightSelected showSelectionColumn={false} />
          </Grid>
        )}
      </Fragment>
    );
  }
}

function mapStateToProps(state, props) {
  return {
    pages: state.pages.get('pages'),
    tabs: state.tabs.get('tabs'),
    questions: state.questions.get('questions'),
    isLoading: state.pages.get('isLoading') || state.tabs.get('isLoading') || state.questions.get('isLoading'),
    questionUploadComplete: state.questions.get('questionUploadComplete')
  };
}

export default withStyles(styles)(
  connect(mapStateToProps, {
    getPages,
    getTabs,
    getQuestions,
    uploadQuestions,
    handleToastMessage
  })(QuestionsContainer)
);
