import { FilteringState, IntegratedFiltering, IntegratedSorting, SelectionState, SortingState } from '@devexpress/dx-react-grid';
import { Grid, TableFilterRow, TableHeaderRow, TableSelection, VirtualTable } from '@devexpress/dx-react-grid-material-ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AppBar, Avatar, Fab, Toolbar, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import { Add as AddIcon } from '@mui/icons-material';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { getPages } from '@survey/common/dist/actions/pages.actions';
import { getTabs } from '@survey/common/dist/actions/tabs.actions';
import { getTechnologies } from '@survey/common/dist/actions/technologies.actions';
import { getTechnologyQuestions, uploadTechnologyQuestions } from '@survey/common/dist/actions/technologyQuestions.actions';
import LoadingOverlay from '../layout/loadingOverlay.component';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { handleToastMessage } from '../layout/layout.actions';
const styles = theme => ({
  toolBar: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  toolbarButtons: {
    marginLeft: 'auto',
    marginRight: -12
  },
  technologyQuestion: {
    display: 'flex',
    flexDirection: 'row'
  },
  avatar: {
    margin: theme.spacing(1)
  },
  heading: {
    margin: theme.spacing(1)
  },
  button: {
    margin: theme.spacing(1)
  },
  paper: {
    padding: theme.spacing(2)
  }
});

const technologiesPredicate = (value, filter) => {
  if (value === 'All Technologies') {
    return true;
  }

  let values = value.join().toLowerCase();
  let filters = filter.value.toLowerCase().split(',');

  let predicate = true;
  filters.forEach(element => {
    if (values.indexOf(element) === -1) {
      predicate = false;
    }
  });
  return predicate;
};

const getTechnologyName = (technologies, technologyID) => {
  let technology = technologies.find(t => {
    return t.technologyID === technologyID;
  });
  return technology ? technology.technologyName : '';
};

class TechnologyQuestionsContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tableColumnExtensions: [
        { columnName: 'controlType', width: 150 },
        { columnName: 'startYear', width: 100 },
        { columnName: 'endYear', width: 100 },
        { columnName: 'active', width: 100 }
      ],
      openImportDialog: false,
      selectedFile: null,
      integratedFilteringColumnExtensions: [{ columnName: 'technologies', predicate: technologiesPredicate }]
    };

    this.addTechnologyQuestion = this.addTechnologyQuestion.bind(this);
    this.changeGridSelection = this.changeGridSelection.bind(this);
    this.handleClickOpen = this.handleClickOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleImport = this.handleImport.bind(this);
  }
  handleClickOpen() {
    this.setState({ openImportDialog: true });
  }
  handleClose() {
    this.setState({ openImportDialog: false });
  }
  handleImport() {
    const formData = new FormData();
    formData.append('file', this.state.selectedFile);
    this.props.uploadTechnologyQuestions(formData);
    this.setState({ openImportDialog: false });
  }
  onFileChangeHandler = event => {
    this.setState({
      selectedFile: event.target.files[0]
    });
  };
  componentDidMount() {
    this.props.getPages();
    this.props.getTabs();
    this.props.getTechnologies();
    this.props.getTechnologyQuestions();
  }

  addTechnologyQuestion() {
    this.props.history.push(`/technologyQuestions/new`);
  }

  changeGridSelection(selection) {
    let technologyQuestion = this.props.technologyQuestions[selection];
    this.props.history.push(`/technologyQuestions/${technologyQuestion.questionID}`);
  }

  render() {
    const { classes, technologyQuestions } = this.props;
    const { tableColumnExtensions, integratedFilteringColumnExtensions } = this.state;

    //Defining this here instead of in the constructor since we need to build the technologies column using names returned by a service
    const columns = [
      { name: 'questionDescription', title: 'Technology Question Description' },
      {
        name: 'technologies',
        title: 'Technologies',
        getCellValue: row => ((row.technologies && row.technologies.length) > 0 ? row.technologies.map(i => getTechnologyName(this.props.technologies, i)) : 'All Technologies')
      },
      { name: 'controlType', title: 'Control Type' },
      { name: 'startYear', title: 'Start Year' },
      { name: 'endYear', title: 'End Year' },
      { name: 'active', title: 'Active', getCellValue: row => (row.active ? 'Active' : 'Inactive') }
    ];
    if (this.props.technologyQuestionUploadComplete) {
      this.props.handleToastMessage('Import complete', false);
    }
    return (
      <Fragment>
        <Dialog open={this.state.openImportDialog} onClose={this.handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Import Technology Questions</DialogTitle>
          <DialogContent>
            <Button variant="contained" component="label">
              <input type="file" onChange={this.onFileChangeHandler} accept=".xlsx" />
            </Button>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={this.handleImport} color="primary">
              Import
            </Button>
          </DialogActions>
        </Dialog>
        <AppBar position="static" color="default">
          <Toolbar className={classes.toolBar}>
            <div className={classes.technologyQuestion}>
              <Avatar className={classes.avatar}>
                <FontAwesomeIcon icon="list-ul" />
              </Avatar>
              <Typography variant="h6" color="inherit" style={{ alignSelf: 'center' }}>
                Technology Questions
              </Typography>
            </div>
            <span className={classes.toolbarButtons}>
              <Fab size="small" color="primary" onClick={this.handleClickOpen}>
                <CloudUploadIcon />
              </Fab>
              <Fab size="small" color="primary" className={classes.button} onClick={this.addTechnologyQuestion}>
                <AddIcon />
              </Fab>
            </span>
          </Toolbar>
        </AppBar>
        {this.props.isLoading ? (
          <LoadingOverlay />
        ) : (
          <Grid rows={technologyQuestions} columns={columns} selection={[1]}>
            <SelectionState onSelectionChange={selection => this.changeGridSelection(selection)} />
            <FilteringState defaultFilters={[]} />
            <IntegratedFiltering columnExtensions={integratedFilteringColumnExtensions} />
            <SortingState defaultSorting={[]} />
            <IntegratedSorting />
            <VirtualTable height={1000} columnExtensions={tableColumnExtensions} />
            <TableHeaderRow showSortingControls />
            <TableFilterRow />
            <TableSelection selectByRowClick highlightSelected showSelectionColumn={false} />
          </Grid>
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  pages: state.pages.get('pages'),
  tabs: state.tabs.get('tabs'),
  technologies: state.technologies.get('technologies'),
  technologyQuestions: state.technologyQuestions.get('technologyQuestions'),
  isLoading: state.pages.get('isLoading') || state.tabs.get('isLoading') || state.technologies.get('isLoading') || state.technologyQuestions.get('isLoading'),
  technologyQuestionUploadComplete: state.technologyQuestions.get('technologyQuestionUploadComplete')
});

export default withStyles(styles)(
  connect(mapStateToProps, {
    getPages,
    getTabs,
    getTechnologies,
    getTechnologyQuestions,
    uploadTechnologyQuestions,
    handleToastMessage
  })(TechnologyQuestionsContainer)
);
