import { FilteringState, IntegratedPaging, IntegratedSorting, PagingState, SelectionState, SortingState } from '@devexpress/dx-react-grid';
import { Grid, PagingPanel, Table, TableFilterRow, TableHeaderRow, TableSelection } from '@devexpress/dx-react-grid-material-ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AppBar, Avatar, Fab, Paper, Toolbar, Typography, FormControl, Table as MyTable, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { withStyles } from "@mui/styles";
import AddIcon from '@mui/icons-material/Add';
import SyncIcon from '@mui/icons-material/Sync';
import { debounce, isEmpty, uniqBy, orderBy } from 'lodash';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { hasPermission } from '../auth/authOperations';
import { CREATE_VENDORS, EDIT_VENDORS } from '../auth/permissions';
import { getFilteredVendors, getVendors, getVendorProductSyncLog, vendorProductSync } from '@survey/common/dist/actions/vendors.actions';
import LoadingOverlay from '../layout/loadingOverlay.component';
import CustomSelect from "@survey/common/dist/components/form-controls/CustomSelect";
/*import {ValidatorForm} from "./vendorForm.component";*/
import { ValidatorForm } from 'react-material-ui-form-validator';
import { getProductsList } from '@survey/common/dist/actions/products.actions';
import { getTechnologiesList } from '@survey/common/dist/actions/technologies.actions';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { handleToastMessage } from '../layout/layout.actions';

const styles = theme => ({
    toolBar: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    vendor: {
        display: 'flex',
        flexDirection: 'row'
    },
    filterSelects: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        marginTop: "20px",
        "& > div": {
            width: "100%",
            margin: "0px 20px",
        }
    },
    avatar: {
        margin: theme.spacing(1)
    },
    heading: {
        margin: theme.spacing(1)
    },
    button: {
        margin: theme.spacing(1)
    },
    paper: {
        padding: theme.spacing(2)
    }
});

class VendorsContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            columns: [{ name: 'vendorID', title: 'Vendor ID' }, { name: 'name', title: 'Vendor Name' }, { name: 'website', title: 'Website' }, { name: 'status', title: 'Status' }],
            tableColumnExtensions: [{ columnName: 'vendorID', width: 150 }, { columnName: 'status', width: 100 }],
            currentPage: 0,
            pageSize: 10,
            allowedPageSizes: [10, 25, 50, 100],
            lastQuery: '',
            filters: [],
            productsList: [],
            technologiesList: [],
            selectedTechnology: '',
            selectedProduct: '',
            syncDialog: false,
            syncLogs: [],
        };

        this.changePageSize = pageSize => this.setState({ pageSize });
        this.changeCurrentPage = this.changeCurrentPage.bind(this);
        this.changeFilters = this.changeFilters.bind(this);
        this.addVendor = this.addVendor.bind(this);
        this.changeGridSelection = this.changeGridSelection.bind(this);
        this.handleTechnologySelectChange = this.handleTechnologySelectChange.bind(this);
        this.handleProductSelectChange = this.handleProductSelectChange.bind(this);
        this.handleClickSync = this.handleClickSync.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleSync = this.handleSync.bind(this);
        // this.loadData = this.loadData.bind(this);
    }

    componentDidMount() {
        this.loadData();
        this.loadProductsAndTechnologies();
    }

    async loadProductsAndTechnologies(type) {
        this.props.getProductsList().then((response) => {
            this.setState(
                { 'productsList': orderBy(uniqBy(response.response, 'value'), ['label'], ['asc']) }
            );
        });

        this.props.getTechnologiesList().then((response) => {
            this.setState(
                { 'technologiesList': orderBy(response.response, ['label'], ['asc']) }
            );
        });

    }

    componentDidUpdate(prevProps, prevState) {
        const { isLoading, vendors, totalVendorsLoaded } = this.props;
        const { currentPage, pageSize, filters } = this.state;


        if (prevState.pageSize !== pageSize) {
            console.log('1');
            return;
        }

        if ((!isLoading && !isEmpty(vendors)) || (filters.length !== prevState.filters.length)) {
            if (currentPage === 0 && totalVendorsLoaded === 0) {
                console.log('2');
                this.loadData();
            }

            if (filters.length !== prevState.filters.length) {
                console.log('3', this.state);
                this.debouncedLoadData('filtering');
            } else {
                for (let i = 0; i < filters.length; i++) {
                    const curColumnName = filters[i].columnName;
                    const curColumnValue = filters[i].value;
                    let filtersDirty = true;

                    for (let j = 0; j < prevState.filters.length; j++) {
                        if (prevState.filters[j].columnName === curColumnName && prevState.filters[j].value === curColumnValue) {
                            filtersDirty = false;
                            break;
                        }
                    }

                    if (filtersDirty === true) {
                        console.log('4');
                        this.debouncedLoadData('filtering');
                    }
                }
            }
        }
    }

    debouncedLoadData = debounce(type => {
        this.loadData(type);
    }, 1500);

    addVendor() {
        this.props.history.push(`/vendors/new`);
    }

    changeCurrentPage(currentPage) {
        this.setState({
            currentPage
        });
    }

    changeFilters(filters) {
        this.setState({
            currentPage: 0,
            filters
        });
    };

    changeGridSelection(selection) {
        let vendor = this.props.vendors[selection];
        this.props.history.push(`/vendors/${vendor.vendorID}`);
    }

    queryString() {
        const { pageSize, filters, currentPage, selectedProduct, selectedTechnology } = this.state;
        console.log('THIS STATE', this.state);

        let prodComboString = "";
        let techComboString = "";

        if (selectedProduct) {
            prodComboString = "&products=" + selectedProduct.value;
        }

        if (selectedTechnology) {
            techComboString = "&technologies=" + selectedTechnology.value;
        }

        //Adding in TechnologyList and ProductList here
        if (filters.length !== 0) {
            //let queryString = `activeOnlyFlag=false&pageSize=200&skip=${this.props.totalVendorsLoaded === pageSize * (currentPage + 1) ? this.props.totalVendorsLoaded : 0}&filters=[`;
            let queryString = `${prodComboString}${techComboString}&sortColumn=V.Name&activeOnlyFlag=false&pageSize=200&skip=${this.props.totalVendorsLoaded === pageSize * (currentPage + 1) ? this.props.totalVendorsLoaded : 0}&filters=[`;
            //let queryString = `${prodComboString}${techComboString}&activeOnlyFlag=false&pageSize=200&skip=${this.props.totalVendorsLoaded === pageSize * (currentPage + 1) ? this.props.totalVendorsLoaded : 0}&filters=[`;

            console.log("QUERY STRING", queryString);

            filters.forEach((filter, index) => {
                const { columnName, value } = filter;

                if (index + 1 === filters.length) {
                    queryString = `${queryString}${JSON.stringify({ columnName, value })}]`;
                } else {
                    queryString = `${queryString}${JSON.stringify({ columnName, value })},`;
                }
            });

            return queryString;
        }


        return `activeOnlyFlag=false&sortColumn=V.Name&pageSize=200${prodComboString}${techComboString}`;
        //return `activeOnlyFlag=false&sortColumn=Name&pageSize=200${prodComboString}${techComboString}`;
        //return `activeOnlyFlag=false&pageSize=200&skip=${this.props.totalVendorsLoaded}${prodComboString}${techComboString}`;
        //return `activeOnlyFlag=false&pageSize=200&skip=${this.props.totalVendorsLoaded}`;
    }

    async loadData(type) {
        const queryString = this.queryString();

        console.log('Our current query string', queryString);

        if (queryString === this.state.lastQuery) {
            return;
        }

        if (type === 'filtering') {
            await this.props.getFilteredVendors(queryString);
        } else {
            await this.props.getVendors(queryString);
        }

        this.setState({
            lastQuery: queryString
        });
    }

    handleProductSelectChange() {
        return (event, selection) => {
            this.setState({
                selectedProduct: selection
            },
                () => this.loadData('filtering')
            );

        };
    }

    handleTechnologySelectChange() {
        return (event, selection) => {
            this.setState({
                selectedTechnology: selection
            },
                () => this.loadData('filtering')
            );
        };
    }
    handleClose() {
        this.setState({ syncDialog: false });
    }
    handleSync() {
        this.props.vendorProductSync().then(resp => {
            this.setState({ syncDialog: false });
            console.log('handleClickSync', resp);
            if (resp.type == "VENDORPRODUCTS_SYNC_SUCCESS") {
                this.props.handleToastMessage("Sync job has been started");
            }
            else if (resp.type == "VENDORPRODUCTS_SYNC_FAILURE") {
                this.props.handleToastMessage("Sync job failed to start", true);
            }
        })
        
    }
    handleClickSync() {
        this.setState({ syncDialog: true });
        this.props.getVendorProductSyncLog().then(resp => {
            this.setState({ syncLogs: resp.response.slice(0, 5) });
           
        })

    }
    render() {
        const { classes, isLoading, permissions, vendors } = this.props;
        const { columns, tableColumnExtensions, currentPage, pageSize, allowedPageSizes, filters, selectedTechnology, selectedProduct, syncLogs } = this.state;

        return (
            <div>
                <Dialog open={this.state.syncDialog} onClose={this.handleClose} aria-labelledby="form-dialog-title" fullWidth maxWidth="sm">
                    <DialogTitle id="form-dialog-title">Sync Logs</DialogTitle>
                    <DialogContent>
                        <MyTable key='SyncLogTable'>

                            <TableHead>
                                <TableRow style={{ backgroundColor: '#fafafa' }}>
                                    <TableCell className={classes.centerText}>Start time</TableCell>
                                    <TableCell className={classes.centerText}>End Time</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {syncLogs.map((r) => {
                                    return (<TableRow>
                                        <TableCell className={classes.tblCell}>
                                            {r.startExecutionTime}
                                        </TableCell>

                                        <TableCell className={classes.tblCell}>
                                            {r.endExecutionTime}
                                        </TableCell>
                                    </TableRow>);
                                })}
                            </TableBody>
                        </MyTable>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleClose} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={this.handleSync} color="primary">
                            Synchronize
                        </Button>
                    </DialogActions>
                </Dialog>
                <AppBar position="static" color="default">
                    <Toolbar className={classes.toolBar}>
                        <div className={classes.vendor}>
                            <Avatar className={classes.avatar}>
                                <FontAwesomeIcon icon="edit" />
                            </Avatar>
                            <Typography variant="h6" color="inherit" style={{ alignSelf: 'center' }}>
                                Vendors
                            </Typography>
                        </div>
                        {hasPermission(CREATE_VENDORS, permissions) && (
                            <span className={classes.toolbarButtons}>
                                <Fab size="small" color="primary" className={classes.button} onClick={this.handleClickSync}>
                                    <SyncIcon />
                                </Fab>
                                <Fab size="small" color="primary" className={classes.button} onClick={this.addVendor}>
                                    <AddIcon />
                                </Fab>
                            </span>
                        )}
                    </Toolbar>
                </AppBar>
                {isLoading ? (
                    <LoadingOverlay />
                ) : (
                    <Paper className={classes.paper} square>
                        <ValidatorForm className={classes.filterSelects}>
                            <CustomSelect label="Product" onChange={this.handleProductSelectChange} disabled={false} value={selectedProduct} options={this.state.productsList} name="product" />
                            <CustomSelect label="Technology" onChange={this.handleTechnologySelectChange} disabled={false} value={selectedTechnology} options={this.state.technologiesList} name="technology" />
                        </ValidatorForm>
                        <Grid rows={vendors} columns={columns} selection={[1]}>
                            <SortingState defaultSorting={[{ columnName: 'name', direction: 'asc' }]} />
                            <PagingState currentPage={currentPage} onCurrentPageChange={this.changeCurrentPage} pageSize={pageSize} onPageSizeChange={this.changePageSize} />
                            <IntegratedSorting />
                            <SelectionState onSelectionChange={selection => this.changeGridSelection(selection)} />
                            <FilteringState filters={filters} onFiltersChange={this.changeFilters} />
                            <IntegratedPaging />
                            <Table columnExtensions={tableColumnExtensions} />
                            <TableHeaderRow showSortingControls />
                            <TableFilterRow />
                            <TableSelection selectByRowClick highlightSelected showSelectionColumn={false} />
                            <PagingPanel pageSizes={allowedPageSizes} />
                        </Grid>
                    </Paper>
                )}
            </div>
        );
    }
}

function mapStateToProps(state, props) {
    return {
        vendors: state.vendors.get('vendors'),
        syncLogs: state.vendors.get('syncLogs'),
        totalVendorsLoaded: state.vendors.get('totalVendorsLoaded'),
        isLoading: state.vendors.get('isLoading'),
        permissions: state.auth.get('permissions')
    };
}

export default withStyles(styles)(
    connect(
        mapStateToProps,
        {
            getVendors,
            getFilteredVendors,
            getProductsList,
            getTechnologiesList,
            getVendorProductSyncLog,
            vendorProductSync,
            handleToastMessage
        }
    )(VendorsContainer)
);
