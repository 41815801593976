"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createSurveyDataObject = void 0;
var _lodash = require("lodash");
var createSurveyDataObject = exports.createSurveyDataObject = function createSurveyDataObject(surveyType) {
  var getTechnologies = function getTechnologies(surveyType) {
    var technologyQuestions = surveyType.technologyQuestions;
    var list = technologyQuestions.reduce(function (technologies, question) {
      if (!technologies.includes(question.technologyID)) technologies.push(question.technologyID);
      return technologies;
    }, []);
    return list.reduce(function (obj, technology) {
      obj[technology] = surveyType.technologyQuestions.filter(function (q) {
        return q.technologyID === technology;
      }).reduce(_createDataObject, {});
      return obj;
    }, {});
  };
  var _createDataObject = function createDataObject(data, question) {
    // If the question wasn't found, continue
    if (!question) {
      return data;
    }
    var processedQuestions = {};
    // Find any branching questions for this question.
    if (!(0, _lodash.isEmpty)(question.branchingQuestions)) {
      // Recursively call self to create the full tree of data for branching questions.
      processedQuestions = Object.values(question.branchingQuestions).reduce(_createDataObject, {});
    }
    data[question.questionID] = {
      answer: [],
      questions: processedQuestions
    };
    return data;
  };
  return {
    questions: surveyType.questions.reduce(_createDataObject, {}),
    technologyQuestions: getTechnologies(surveyType)
  };
};