import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AppBar from '@mui/material/AppBar';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import { withStyles } from '@mui/styles';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { filter, find, uniq } from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getDataMappings } from '../dataMappings/dataMappings.actions';
import { handleToastMessage } from '../layout/layout.actions';
import { getSurveyTypes } from '../surveyTypes/surveyTypes.actions';
import TechnologyQuestionForm from './technologyQuestionForm.component';
import LoadingOverlay from '../layout/loadingOverlay.component';
import { getQuestions } from '@survey/common/dist/actions/questions.actions';
import { getAnswers } from '@survey/common/dist/actions/answers.actions';
import { getEntityTypes } from '@survey/common/dist/actions/entities.actions';
import { getOrganizationTypes } from '@survey/common/dist/actions/organizations.actions';
import { getTechnologies } from '@survey/common/dist/actions/technologies.actions';
import { createTechnologyQuestion, deleteTechnologyQuestion, getTechnologyQuestions, getTechnologyStatus, saveTechnologyQuestion } from '@survey/common/dist/actions/technologyQuestions.actions';
import { getCountriesList } from '@survey/common/dist/actions/countries.actions';
import Confirm from '@survey/common/dist/components/dialogs/Confirm';
import QuestionTranslation from '@survey/common/dist/components/dialogs/QuestionTranslation';

const styles = (theme) => ({
  toolBar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  technologyquestion: {
    display: 'flex',
    flexDirection: 'row',
  },
  avatar: {
    margin: theme.spacing(1),
  },
  heading: {
    margin: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(2),
  },
});

class TechnologyQuestionContainer extends Component {
  constructor(props) {
    super(props);
    this.state = { showDeleteDialog: false, showTranslationDialog: false };
    this.onSubmit = this.onSubmit.bind(this);
    this.deleteTechnologyQuestion = this.deleteTechnologyQuestion.bind(this);
    this.updateTranslation = this.updateTranslation.bind(this);
  }
  updateTranslation(data) {
    let technologyQuestion = find(this.props.technologyQuestions, {
      questionID: parseInt(this.props.match.params.questionId, 10),
    });
    technologyQuestion = data;
    this.setState({ showTranslationDialog: false });
  }
  componentDidMount() {
    this.props.getSurveyTypes();
    this.props.getTechnologyQuestions();
    this.props.getAnswers();
    this.props.getEntityTypes();
    this.props.getOrganizationTypes();
    this.props.getTechnologies();
    this.props.getTechnologyStatus();
    this.props.getCountriesList();
    this.props.getDataMappings();
    this.props.getQuestions();
  }

  async onSubmit(data) {
    if (this.props.match.params.questionId === 'new') {
      /* questionID has to be an integer */

      data.questionID = 0;
      let createResponse = await this.props.createTechnologyQuestion(data);
      if (createResponse.type === 'CREATE_TECHNOLOGY_QUESTION_SUCCESS') {
        this.props.history.goBack();
      } else {
        this.props.handleToastMessage('ERROR!', true);
        //Display error
      }
    } else {
      /* Set the questionID based on the route */
      data['questionID'] = parseInt(this.props.match.params.questionId, 10);
      let createResponse = await this.props.saveTechnologyQuestion(this.props.match.params.questionId, data);
      if (createResponse.type === 'SAVE_TECHNOLOGY_QUESTION_SUCCESS') {
        this.props.history.goBack();
      } else {
        //Display error
        this.props.handleToastMessage('ERROR!', true);
      }
    }
  }

  async deleteTechnologyQuestion() {
    this.setState({ showDeleteDialog: false });
    let deleteResponse = await this.props.deleteTechnologyQuestion(this.props.match.params.questionId);
    if (deleteResponse.type === 'DELETE_TECHNOLOGY_QUESTION_SUCCESS') {
      this.props.history.goBack();
    } else {
      //Display error
      this.props.handleToastMessage('ERROR!', true);
    }
  }

  render() {
    const { classes } = this.props;
    let technologyQuestion = find(this.props.technologyQuestions, {
      questionID: parseInt(this.props.match.params.questionId, 10),
    });

    let technologyQuestionReferences = [];
    let branchingQuestionReferences = [];

    if (technologyQuestion) {
      technologyQuestionReferences = filter(this.props.surveyTypes, (surveyType) => {
        let index = surveyType.technologyQuestions.findIndex((i) => {
          return i.questionID === technologyQuestion.questionID;
        });
        return index !== -1;
      });

      this.props.technologyQuestions.forEach((q) => {
        Object.values(q.branchingQuestions).forEach((val) => {
          if (val.indexOf(technologyQuestion.questionID) !== -1) {
            branchingQuestionReferences.push(q);
          }
        });
      });
      branchingQuestionReferences = uniq(branchingQuestionReferences);
    }

    return this.props.isLoading ? (
      <LoadingOverlay />
    ) : (
      <Paper className={classes.paper} square>
        <AppBar position="static" color="default">
          <Toolbar className={classes.toolBar}>
            <div className={classes.technologyquestion}>
              <Avatar className={classes.avatar}>
                <FontAwesomeIcon icon="list-ul" />
              </Avatar>
              <div>
                <Typography variant="h6" color="inherit" style={{ alignSelf: 'center' }} onClick={() => this.props.history.push('/technologyquestions')}>
                  Technology Questions
                </Typography>
                <Typography variant="subtitle1" color="inherit">
                  Technology Question ID: {technologyQuestion ? technologyQuestion.questionID : 'New'}
                </Typography>
              </div>
            </div>
            {technologyQuestion && (
              <div>
                <Button variant="contained" color="primary" className={classes.button} onClick={() => this.setState({ showTranslationDialog: true })}>
                  Translation
                </Button>
                {technologyQuestion.active ? (
                  <Button variant="contained" color="primary" className={classes.button} onClick={() => this.onSubmit({ ...technologyQuestion, active: false })}>
                    De-Activate
                  </Button>
                ) : (
                  <Button variant="contained" color="primary" className={classes.button} onClick={() => this.onSubmit({ ...technologyQuestion, active: true })}>
                    Activate
                  </Button>
                )}
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  onClick={() => this.setState({ showDeleteDialog: true })}
                  disabled={technologyQuestionReferences.length > 0 || branchingQuestionReferences.length > 0}
                >
                  Delete
                </Button>
              </div>
            )}
          </Toolbar>
        </AppBar>
        <TechnologyQuestionForm
          technologyQuestion={technologyQuestion}
          technologyQuestions={this.props.technologyQuestions}
          technologyStatus={this.props.technologyStatus}
          technologyQuestionReferences={technologyQuestionReferences}
          branchingQuestionReferences={branchingQuestionReferences}
          technologies={this.props.technologies}
          answers={this.props.answers}
          history={this.props.history}
          entityTypes={this.props.entityTypes}
          organizationTypes={this.props.organizationTypes}
          onSubmit={this.onSubmit}
          onError={(errors) => console.log(errors)}
          handleToastMessage={this.props.handleToastMessage}
          permissions={this.props.permissions}
          countriesList={this.props.countriesList}
          regularQuestions={this.props.questions}
        />
        <Confirm
          title="Confirm Delete"
          onClose={() => this.setState({ showDeleteDialog: false })}
          onConfirm={this.deleteTechnologyQuestion}
          contentText={technologyQuestion ? `Please confirm that you would like to delete Question ID: ${technologyQuestion.questionID}` : ''}
          open={this.state.showDeleteDialog}
        />
        <QuestionTranslation onClose={() => this.setState({ showTranslationDialog: false })} question={technologyQuestion} onConfirm={this.updateTranslation} open={this.state.showTranslationDialog} />
      </Paper>
    );
  }
}

function mapStateToProps(state) {
  return {
    surveyTypes: state.surveyTypes.get('surveyTypes'),
    entityTypes: state.entities.get('entityTypes'),
    organizationTypes: state.organizations.get('organizationTypes'),
    countriesList: state.countries.get('countriesList'),
    technologies: state.technologies.get('technologies'),
    technologyQuestions: state.technologyQuestions.get('technologyQuestions'),
    technologyStatus: state.technologyQuestions.get('technologyStatus'),
    answers: state.answers.get('answers'),
    isLoading: state.technologyQuestions.get('isLoading') || state.answers.get('isLoading') || state.questions.get('isLoading'),
    permissions: state.auth.get('permisisons'),
    questions: state.questions.get('questions'),
  };
}

export default withStyles(styles)(
  connect(mapStateToProps, {
    getQuestions,
    getSurveyTypes,
    getTechnologyQuestions,
    createTechnologyQuestion,
    saveTechnologyQuestion,
    deleteTechnologyQuestion,
    getAnswers,
    getEntityTypes,
    getOrganizationTypes,
    getTechnologies,
    getTechnologyStatus,
    getCountriesList,
    handleToastMessage,
    getDataMappings,
  })(TechnologyQuestionContainer)
);
