import { AppBar, Button, IconButton, Menu, MenuItem, Toolbar, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import HomeIcon from '@mui/icons-material/Home';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { hasPermission } from '../auth/authOperations';
import { VIEW_ANSWERS, VIEW_QUESTIONS, VIEW_SCORING, VIEW_SURVEY_TYPES, VIEW_VENDORS, VIEW_TECHNOLOGY_QUESTIONS } from '../auth/permissions';

const styles = theme => ({
  toolBar: {
    flex: '1 1 auto',
    justifyContent: 'space-between'
  },
  iconButton: {
    marginLeft: -12,
    marginRight: theme.spacing(2)
  },
  menu: {
    marginTop: '20px'
  }
});

class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      anchorEl: null
    };
  }

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = page => {
    this.setState({ anchorEl: null });
    this.props.history.push(`/${page}Scoring`);
  };

  render() {
    const { anchorEl } = this.state;
    let { classes, auth } = this.props;
    let pathname = this.props.location.pathname;
    return (
      <AppBar position="static">
        <Toolbar className={classes.toolBar}>
          <IconButton
            onClick={() => this.props.history.push('/surveys')}
            className={classes.iconButton}
            color={pathname.startsWith('/surveys') || pathname.startsWith('/home') ? 'secondary' : 'inherit'}
          >
            <HomeIcon />
          </IconButton>
          <Typography variant="h6" color="inherit">
            HA Survey Builder
          </Typography>

          <div>
            {hasPermission(VIEW_SURVEY_TYPES, this.props.permissions) && (
                <Button color={pathname.startsWith('/maintenance') ? 'secondary' : 'inherit'} onClick={() => this.props.history.push('/maintenance')}>
                  Maintenance
                </Button>
            )}
            {hasPermission(VIEW_SURVEY_TYPES, this.props.permissions) && (
              <Button color={pathname.startsWith('/surveyTypes') ? 'secondary' : 'inherit'} onClick={() => this.props.history.push('/surveyTypes')}>
                Survey Types
              </Button>
            )}
            {hasPermission(VIEW_SCORING, this.props.permissions) && (
              <Fragment>
                <Button color={pathname.endsWith('Scoring') ? 'secondary' : 'inherit'} onClick={this.handleClick}>
                  Scoring
                </Button>
                <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={this.handleClose} className={classes.menu}>
                  <MenuItem onClick={() => this.handleClose('EMRAM')} selected={pathname.startsWith('/EMRAMScoring')}>
                    EMRAM Scoring
                  </MenuItem>
                  <MenuItem onClick={() => this.handleClose('OEMRAM')} selected={pathname.startsWith('/OEMRAMScoring')}>
                    O-EMRAM Scoring
                  </MenuItem>
                  <MenuItem onClick={() => this.handleClose('AMAM')} selected={pathname.startsWith('/AMAMScoring')}>
                    AMAM Scoring
                  </MenuItem>
                  <MenuItem onClick={() => this.handleClose('INFRAM')} selected={pathname.startsWith('/INFRAMScoring')}>
                    INFRAM Scoring
                  </MenuItem>
                  <MenuItem onClick={() => this.handleClose('CISOM')} selected={pathname.startsWith('/CISOMScoring')}>
                    CISOM Scoring
                  </MenuItem>
                </Menu>
              </Fragment>
            )}
            {hasPermission(VIEW_QUESTIONS, this.props.permissions) && (
              <Button color={pathname.startsWith('/questions') ? 'secondary' : 'inherit'} onClick={() => this.props.history.push('/questions')}>
                Questions
              </Button>
            )}

            {hasPermission(VIEW_TECHNOLOGY_QUESTIONS, this.props.permissions) && (
              <Button color={pathname.startsWith('/technologyQuestions') ? 'secondary' : 'inherit'} onClick={() => this.props.history.push('/technologyQuestions')}>
                Technology Questions
              </Button>
            )}

            {hasPermission(VIEW_ANSWERS, this.props.permissions) && (
              <Button color={pathname.startsWith('/answers') ? 'secondary' : 'inherit'} onClick={() => this.props.history.push('/answers')}>
                Answers
              </Button>
            )}
            {hasPermission(VIEW_VENDORS, this.props.permissions) && (
              <Button color={pathname.startsWith('/vendors') ? 'secondary' : 'inherit'} onClick={() => this.props.history.push('/vendors')}>
                Vendors
              </Button>
            )}
            <Button color="inherit" onClick={() => auth.logout()}>
              Log Out
            </Button>
          </div>
        </Toolbar>
      </AppBar>
    );
  }
}

Header.propTypes = {
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    permissions: state.auth.get('permissions')
  };
};

export default withStyles(styles)(
  withRouter(
    connect(
      mapStateToProps,
      {}
    )(Header)
  )
);
