import Immutable from 'immutable';
import { findIndex } from 'lodash';
import {
  CREATE_EMRAM_SCORING_FAILURE,
  CREATE_EMRAM_SCORING_REQUEST,
  CREATE_EMRAM_SCORING_SUCCESS,
  DELETE_EMRAM_SCORING_FAILURE,
  DELETE_EMRAM_SCORING_REQUEST,
  DELETE_EMRAM_SCORING_SUCCESS,
  GET_EMRAM_SCORINGS_FAILURE,
  GET_EMRAM_SCORINGS_REQUEST,
  GET_EMRAM_SCORINGS_SUCCESS,
  UPDATE_EMRAM_SCORING_FAILURE,
  UPDATE_EMRAM_SCORING_REQUEST,
  UPDATE_EMRAM_SCORING_SUCCESS,
  CREATE_OEMRAM_SCORING_FAILURE,
  CREATE_OEMRAM_SCORING_REQUEST,
  CREATE_OEMRAM_SCORING_SUCCESS,
  DELETE_OEMRAM_SCORING_FAILURE,
  DELETE_OEMRAM_SCORING_REQUEST,
  DELETE_OEMRAM_SCORING_SUCCESS,
  GET_OEMRAM_SCORINGS_FAILURE,
  GET_OEMRAM_SCORINGS_REQUEST,
  GET_OEMRAM_SCORINGS_SUCCESS,
  UPDATE_OEMRAM_SCORING_FAILURE,
  UPDATE_OEMRAM_SCORING_REQUEST,
  UPDATE_OEMRAM_SCORING_SUCCESS,
  CREATE_AMAM_SCORING_FAILURE,
  CREATE_AMAM_SCORING_REQUEST,
  CREATE_AMAM_SCORING_SUCCESS,
  DELETE_AMAM_SCORING_FAILURE,
  DELETE_AMAM_SCORING_REQUEST,
  DELETE_AMAM_SCORING_SUCCESS,
  GET_AMAM_SCORINGS_FAILURE,
  GET_AMAM_SCORINGS_REQUEST,
  GET_AMAM_SCORINGS_SUCCESS,
  UPDATE_AMAM_SCORING_FAILURE,
  UPDATE_AMAM_SCORING_REQUEST,
  UPDATE_AMAM_SCORING_SUCCESS,
  GET_INFRAM_SCORING_REQUEST,
  GET_INFRAM_SCORING_SUCCESS,
  GET_INFRAM_SCORING_FAILURE,
  CREATE_INFRAM_SCORING_REQUEST,
  CREATE_INFRAM_SCORING_SUCCESS,
  CREATE_INFRAM_SCORING_FAILURE,
  UPDATE_INFRAM_SCORING_REQUEST,
  UPDATE_INFRAM_SCORING_SUCCESS,
  UPDATE_INFRAM_SCORING_FAILURE,
  DELETE_INFRAM_SCORING_REQUEST,
  DELETE_INFRAM_SCORING_SUCCESS,
  DELETE_INFRAM_SCORING_FAILURE,
  GET_CISOM_SCORING_REQUEST,
  GET_CISOM_SCORING_SUCCESS,
  GET_CISOM_SCORING_FAILURE,
  CREATE_CISOM_SCORING_REQUEST,
  CREATE_CISOM_SCORING_SUCCESS,
  CREATE_CISOM_SCORING_FAILURE,
  UPDATE_CISOM_SCORING_REQUEST,
  UPDATE_CISOM_SCORING_SUCCESS,
  UPDATE_CISOM_SCORING_FAILURE,
  DELETE_CISOM_SCORING_REQUEST,
  DELETE_CISOM_SCORING_SUCCESS,
  DELETE_CISOM_SCORING_FAILURE
} from './scoring.actions';

const initialState = Immutable.Map({
  amamScoring: [],
  emramScoring: [],
  inframScoring: [],
  cisomScoring: [],
  isEmramLoading: false,
  oEmramScoring: [],
  isOEmramLoading: false,
  isCisomLoading: false,
  isInframLoading: false,
  errors: []
});

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_EMRAM_SCORINGS_REQUEST:
      return state
        .set('emramScoring', [])
        .set('errors', [])
        .set('isEmramLoading', true);
    case GET_EMRAM_SCORINGS_SUCCESS:
      return state.set('emramScoring', action.response).set('isEmramLoading', false);
    case GET_EMRAM_SCORINGS_FAILURE:
      return state.set('isEmramLoading', false).set('error', action.messages);

    case CREATE_EMRAM_SCORING_REQUEST:
      return state.set('isEmramLoading', true);
    case CREATE_EMRAM_SCORING_SUCCESS:
      let newScoringRule = action.response;
      let emramScoring = [...state.get('emramScoring')];
      emramScoring.push(newScoringRule);
      return state.set('emramScoring', emramScoring).set('isEmramLoading', false);
    case CREATE_EMRAM_SCORING_FAILURE:
      return state.set('isEmramLoading', false).set('error', action.messages);

    case UPDATE_EMRAM_SCORING_REQUEST:
      return state.set('isEmramLoading', true);
    case UPDATE_EMRAM_SCORING_SUCCESS:
      const scoring = [...state.get('emramScoring')];
      const idx = findIndex(scoring, { scoreID: action.payload.scoreID });
      if (idx > -1) scoring[idx] = { ...action.payload };
      return state.set('isEmramLoading', false).set('emramScoring', scoring);
    case UPDATE_EMRAM_SCORING_FAILURE:
      return state.set('isEmramLoading', false).set('error', action.messages);

    case DELETE_EMRAM_SCORING_SUCCESS: {
      let scoring = [...state.get('emramScoring')];
      let indexToDelete = findIndex(scoring, { scoreID: action.payload });
      if (indexToDelete > -1) {
        scoring.splice(indexToDelete, 1);
      }
      return state.set('emramScoring', scoring).set('isEmramLoading', false);
    }
    case DELETE_EMRAM_SCORING_FAILURE:
      return state.set('errors', action.messages).set('isOEmramLoading', false);
    case DELETE_EMRAM_SCORING_REQUEST:
      return state.set('errors', []).set('isOEmramLoading', true);
    case GET_OEMRAM_SCORINGS_REQUEST:
      return state
        .set('oEmramScoring', [])
        .set('errors', [])
        .set('isOEmramLoading', true);
    case GET_OEMRAM_SCORINGS_SUCCESS:
      return state.set('oEmramScoring', action.response).set('isOEmramLoading', false);
    case GET_OEMRAM_SCORINGS_FAILURE:
      return state.set('isOEmramLoading', false).set('error', action.messages);

    case CREATE_OEMRAM_SCORING_REQUEST:
      return state.set('isOEmramLoading', true);
    case CREATE_OEMRAM_SCORING_SUCCESS:
      let newOEmramRule = action.response;
      let oEmramScoring = [...state.get('oEmramScoring')];
      oEmramScoring.push(newOEmramRule);
      return state.set('oEmramScoring', oEmramScoring).set('isOEmramLoading', false);
    case CREATE_OEMRAM_SCORING_FAILURE:
      return state.set('isOEmramLoading', false).set('error', action.messages);

    case UPDATE_OEMRAM_SCORING_REQUEST:
      return state.set('isOEmramLoading', true);
    case UPDATE_OEMRAM_SCORING_SUCCESS:
      const oEScoring = [...state.get('oEmramScoring')];
      const index = findIndex(oEScoring, { scoreID: action.payload.scoreID });
      if (index > -1) oEScoring[index] = { ...action.payload };
      return state.set('isOEmramLoading', false).set('oEmramScoring', oEScoring);
    case UPDATE_OEMRAM_SCORING_FAILURE:
      return state.set('isOEmramLoading', false).set('error', action.messages);

    case DELETE_OEMRAM_SCORING_SUCCESS: {
      let scoring = [...state.get('oEmramScoring')];
      let indexToDelete = findIndex(scoring, { scoreID: action.payload });
      if (indexToDelete > -1) {
        scoring.splice(indexToDelete, 1);
      }
      return state.set('oEmramScoring', scoring).set('isOEmramLoading', false);
    }
    case DELETE_OEMRAM_SCORING_FAILURE:
      return state.set('errors', action.messages).set('isOEmramLoading', false);
    case DELETE_OEMRAM_SCORING_REQUEST:
      return state.set('errors', []).set('isOEmramLoading', true);
    case GET_AMAM_SCORINGS_REQUEST:
      return state
        .set('amamScoring', [])
        .set('errors', [])
        .set('isAmamLoading', true);
    case GET_AMAM_SCORINGS_SUCCESS:
      return state.set('amamScoring', action.response).set('isAmamLoading', false);
    case GET_AMAM_SCORINGS_FAILURE:
      return state.set('isAmamLoading', false).set('error', action.messages);

    case CREATE_AMAM_SCORING_REQUEST:
      return state.set('isAmamLoading', true);
    case CREATE_AMAM_SCORING_SUCCESS:
      let newAmamRule = action.response;
      let amamScoring = [...state.get('amamScoring')];
      amamScoring.push(newAmamRule);
      return state.set('amamScoring', amamScoring).set('isAmamLoading', false);
    case CREATE_AMAM_SCORING_FAILURE:
      return state.set('isAmamLoading', false).set('error', action.messages);

    case UPDATE_AMAM_SCORING_REQUEST:
      return state.set('isAmamLoading', true);
    case UPDATE_AMAM_SCORING_SUCCESS:
      const newAmamScoring = [...state.get('amamScoring')];
      const nIdx = findIndex(newAmamScoring, { scoreID: action.payload.scoreID });
      if (nIdx > -1) newAmamScoring[nIdx] = { ...action.payload };
      return state.set('isAmamLoading', false).set('amamScoring', newAmamScoring);
    case UPDATE_AMAM_SCORING_FAILURE:
      return state.set('isAmamLoading', false).set('error', action.messages);

    case DELETE_AMAM_SCORING_SUCCESS: {
      let scoring = [...state.get('amamScoring')];
      let indexToDelete = findIndex(scoring, { scoreID: action.payload });
      if (indexToDelete > -1) {
        scoring.splice(indexToDelete, 1);
      }
      return state.set('amamScoring', scoring).set('isAmamLoading', false);
    }
    case DELETE_AMAM_SCORING_FAILURE:
      return state.set('errors', action.messages).set('isAmamLoading', false);
    case DELETE_AMAM_SCORING_REQUEST:
      return state.set('errors', []).set('isAmamLoading', true);
    case GET_INFRAM_SCORING_REQUEST:
      return state
        .set('inframScoring', [])
        .set('errors', [])
        .set('isInframLoading', true);
    case GET_INFRAM_SCORING_SUCCESS:
      return state.set('inframScoring', action.response).set('isInframLoading', false);
    case GET_INFRAM_SCORING_FAILURE:
      return state.set('isInframLoading', false).set('error', action.messages);

    case CREATE_INFRAM_SCORING_REQUEST:
      return state.set('isInframLoading', true);
    case CREATE_INFRAM_SCORING_SUCCESS:
      let newRule = action.response;
      let inframScoring = [...state.get('inframScoring')];
      inframScoring.push(newRule);
      return state.set('inframScoring', inframScoring).set('isInframLoading', false);
    case CREATE_INFRAM_SCORING_FAILURE:
      return state.set('isInframLoading', false).set('error', action.messages);

    case UPDATE_INFRAM_SCORING_REQUEST:
      return state.set('isInframLoading', true);
    case UPDATE_INFRAM_SCORING_SUCCESS:
      const newScoring = [...state.get('inframScoring')];
      const inframIdx = findIndex(newScoring, { scoreID: action.payload.scoreID });
      if (inframIdx > -1) newScoring[inframIdx] = { ...action.response };
      return state.set('isInframLoading', false).set('inframScoring', newScoring);
    case UPDATE_INFRAM_SCORING_FAILURE:
      return state.set('isInframLoading', false).set('error', action.messages);

    case DELETE_INFRAM_SCORING_SUCCESS: {
      let scoring = [...state.get('inframScoring')];
      let indexToDelete = findIndex(scoring, { scoreID: action.payload });
      if (indexToDelete > -1) {
        scoring.splice(indexToDelete, 1);
      }
      return state.set('inframScoring', scoring).set('isInframLoading', false);
    }
    case DELETE_INFRAM_SCORING_FAILURE:
      return state.set('errors', action.messages).set('isInframLoading', false);
    case DELETE_INFRAM_SCORING_REQUEST:
      return state.set('errors', []).set('isInframLoading', true);

    case GET_CISOM_SCORING_REQUEST:
      return state
        .set('cisomScoring', [])
        .set('errors', [])
        .set('isCisomLoading', true);
    case GET_CISOM_SCORING_SUCCESS:
      return state.set('cisomScoring', action.response).set('isCisomLoading', false);
    case GET_CISOM_SCORING_FAILURE:
      return state.set('isCisomLoading', false).set('error', action.messages);
    case CREATE_CISOM_SCORING_REQUEST:
      return state.set('isCisomLoading', true);
    case CREATE_CISOM_SCORING_SUCCESS:
      let newCisomRule = action.response;
      let cisomScoring = [...state.get('cisomScoring')];
      cisomScoring.push(newCisomRule);
      return state.set('cisomScoring', cisomScoring).set('isCisomLoading', false);
    case CREATE_CISOM_SCORING_FAILURE:
      return state.set('isCisomLoading', false).set('error', action.messages);

    case UPDATE_CISOM_SCORING_REQUEST:
      return state.set('isCisomLoading', true);
    case UPDATE_CISOM_SCORING_SUCCESS:
      const newCisomScoring = [...state.get('cisomScoring')];
      const cisomIdx = findIndex(newCisomScoring, { scoreID: action.payload.scoreID });
      if (cisomIdx > -1) newCisomScoring[cisomIdx] = { ...action.response };
      return state.set('isCisomLoading', false).set('cisomScoring', newCisomScoring);
    case UPDATE_CISOM_SCORING_FAILURE:
      return state.set('isCisomLoading', false).set('error', action.messages);

    case DELETE_CISOM_SCORING_SUCCESS: {
      let scoring = [...state.get('cisomScoring')];
      let indexToDelete = findIndex(scoring, { scoreID: action.payload });
      if (indexToDelete > -1) {
        scoring.splice(indexToDelete, 1);
      }
      return state.set('cisomScoring', scoring).set('isCisomLoading', false);
    }
    case DELETE_CISOM_SCORING_FAILURE:
      return state.set('errors', action.messages).set('isCisomLoading', false);
    case DELETE_CISOM_SCORING_REQUEST:
      return state.set('errors', []).set('isCisomLoading', true);
    default:
      return state;
  }
};
