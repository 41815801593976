import { FilteringState, IntegratedFiltering, IntegratedPaging, IntegratedSorting, PagingState, SelectionState, SortingState } from '@devexpress/dx-react-grid';
import { Grid, PagingPanel, Table, TableFilterRow, TableHeaderRow, TableSelection } from '@devexpress/dx-react-grid-material-ui';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { withStyles } from "@mui/styles";
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { getAcquireVendors } from '@survey/common/dist/actions/vendors.actions';
import LoadingOverlay from '../layout/loadingOverlay.component';
import { isEmpty } from 'lodash';
const styles = (theme) => ({
  button: {
    margin: theme.spacing(1),
    marginTop: 'auto',
  },
  dialogPaper: {
    minWidth: '50rem',
    minHeight: '30rem',
  },
  dialogActions: {
    flexGrow: 1,
  },
  title: {
    marginBottom: '2rem',
  },
});

const technologyPredicate = (value, filter) => {
  let values = value.join().toLowerCase();
  let filters = filter.value.toLowerCase().split(',');

  let predicate = true;
  filters.forEach((element) => {
    if (values.indexOf(element) === -1) {
      predicate = false;
    }
  });

  return predicate;
};

const getTechCellValue = (tech) => {
  let cellValue = '';

  tech.map((i, index) => {
    if (index < tech.length - 1) {
      cellValue += `${i.technologyName}, `;
    } else {
      cellValue += i.technologyName;
    }

    return cellValue;
  });

  return cellValue;
};

class VendorAcquireDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: 'vendorID', title: 'Id' },
        { name: 'name', title: 'Vendor Name' },
        { name: 'technology', title: 'Technology', getCellValue: (row) => (row.technologies ? getTechCellValue(row.technologies) : []) },
        { name: 'status', title: 'Status' },
      ],
      defaultSorting: [{ columnName: 'name', direction: 'asc' }],
      currentPage: 0,
      pageSize: 5,
      allowedPageSizes: [5, 10, 25, 50],
      next: false,
      vendorsLoaded: false,
      selection: [],
      selectedVendor: {},
      integratedFilteringColumnExtensions: [{ columnName: 'technology', predicate: technologyPredicate }],
    };

    this.changeCurrentPage = (currentPage) => this.setState({ currentPage });
    this.changePageSize = (pageSize) => this.setState({ pageSize });
    this.changeSelection = this.changeSelection.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleAcquire = this.handleAcquire.bind(this);
    this.setSelectedVendors = this.setSelectedVendors.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.vendorID && isEmpty(this.props.vendors) && this.props.open && this.props.isVendorsDialogLoading === false && this.props.vendors.length === 0) {
      this.props.getAcquireVendors(this.props.vendorID);
    }
  }

  changeSelection(selection) {
    if (selection.length > 1) selection.shift();
    this.setState({ selection });
  }

  handleClose() {
    this.props.onClose();
  }

  handleAcquire() {
    this.props.onAcquireVendor({
      vendorID: this.state.selectedVendor.vendorID,
      // XXX: Should we ask the user for this?
      note: 'NOTE',
      dateOfChange: new Date().toISOString(),
    });
  }

  getRowData() {
    const { vendors, acquireVendors } = this.props;

    return vendors.length > 0 ? vendors : acquireVendors;
  }

  setSelectedVendors() {
    const { selection } = this.state;
    const { vendors } = this.props;

    this.setState({ next: true, selectedVendor: vendors[selection[0]] });
  }

  render() {
    const { columns, pageSize, allowedPageSizes, next, selection, selectedVendor } = this.state;
    const { isVendorsDialogLoading, classes, onClose, vendors, selectedValue, ...other } = this.props;

    delete other.dispatch;
    delete other.getAcquireVendors;
    delete other.onAcquireVendor;

    return (
      <Dialog classes={{ paper: classes.dialogPaper }} onClose={this.handleClose} aria-labelledby="simple-dialog-title" {...other}>
        <DialogTitle id="simple-dialog-title" className={classes.title}>
          Acquire Vendor - {!next ? 'Select A Vendor' : 'Confirm Selection'}
        </DialogTitle>
        {isVendorsDialogLoading ? (
          <LoadingOverlay />
        ) : !next ? (
          <DialogContent>
            <Grid rows={vendors} columns={columns}>
              <SelectionState selection={selection} onSelectionChange={this.changeSelection} />
              <FilteringState />
              <IntegratedFiltering />
              <SortingState defaultSorting={[{ columnName: 'name', direction: 'asc' }]} />
              <IntegratedSorting />
              <PagingState currentPage={this.state.currentPage} onCurrentPageChange={this.changeCurrentPage} pageSize={pageSize} onPageSizeChange={this.changePageSize} />
              <IntegratedPaging />
              <Table />
              <TableHeaderRow showSortingControls />
              <TableFilterRow />
              <TableSelection selectByRowClick />
              <PagingPanel pageSizes={allowedPageSizes} />
            </Grid>
          </DialogContent>
        ) : (
          <DialogContent>
            <div>You have selected {selectedVendor.name}. Please confirm that you'd like to acquire this vendor.</div>
          </DialogContent>
        )}
        <DialogActions className={classes.dialogActions}>
          {!next && [
            <Button
              key="back-button"
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={() => {
                this.props.onClose();
              }}
            >
              Cancel
            </Button>,
            <Button
              key="next-button"
              variant="contained"
              color="primary"
              className={classes.button}
              disabled={isVendorsDialogLoading || selection.length === 0}
              onClick={() => {
                this.setSelectedVendors();
              }}
            >
              Next
            </Button>,
          ]}
          {next && [
            <Button
              key="back-button"
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={() => {
                this.setState({ next: false, selectedVendors: [] });
              }}
            >
              Back
            </Button>,
            <Button key="acquire-button" variant="contained" color="primary" className={classes.button} onClick={this.handleAcquire}>
              Acquire
            </Button>,
          ]}
        </DialogActions>
      </Dialog>
    );
  }
}

VendorAcquireDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  onAcquireVendor: PropTypes.func.isRequired,
};

function mapStateToProps(state, props) {
  return {
    vendors: state.vendors.get('acquireVendors'),
    isVendorsDialogLoading: state.vendors.get('isVendorsDialogLoading'),
  };
}

export default withStyles(styles)(connect(mapStateToProps, { getAcquireVendors })(VendorAcquireDialog));
