import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@mui/material';
import { withStyles } from '@mui/styles';
import React from 'react';

const styles = theme => ({
  pageCard: {
    backgroundColor: 'white',
    padding: '15px 25px',
    width: '90%'
  },
  button: {
    '&:hover': {
      backgroundColor: '#005f8a'
    },
    fontWeight: 'bold',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    textTransform: 'none',
    color: 'white',
    backgroundColor: '#005f8a'
  },
  buttonDisabled: {
    backgroundColor: '#E0E0E0'
  },
  iconHolder: {
    verticalAlign: 'middle',
    marginRight: '15px',
    display: 'inline-block',
    backgroundColor: '#005f8a',
    width: '40px',
    height: '40px',
    borderRadius: '35px',
    textAlign: 'center'
  },
  icon: {
    color: 'white',
    paddingTop: '11px'
  },
  numberOfQuestions: {
    color: '#78777a',
    paddingRight: '15px',
    fontSize: '85%',
    fontStyle: 'italic'
  }
});

const SurveyPageCard = ({ classes, item, numberOfQuestions, onClick }) => {
  return (
    <div className={classes.pageCard}>
      <div className={classes.iconHolder}>
        <FontAwesomeIcon icon={item && item.iconName ? item.iconName.replace('fa-', '') : ''} className={classes.icon} />
      </div>
      <span style={{ fontWeight: 'bold' }}>{item.pageName}</span>
      <div style={{ display: 'inline-block', float: 'right' }}>
        <span className={classes.numberOfQuestions}>
          {numberOfQuestions} Question
          {numberOfQuestions > 1 ? 's' : ''}
        </span>
        <Button variant="contained" classes={{ root: classes.button, disabled: classes.buttonDisabled }} onClick={onClick}>
          Start
        </Button>
      </div>
    </div>
  );
};

export default withStyles(styles)(SurveyPageCard);
