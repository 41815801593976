import { FilteringState, IntegratedFiltering, IntegratedPaging, IntegratedSorting, PagingState, SelectionState, SortingState } from '@devexpress/dx-react-grid';
import { Grid, PagingPanel, Table, TableFilterRow, TableHeaderRow, TableSelection } from '@devexpress/dx-react-grid-material-ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AppBar, Avatar, Fab, Paper, Toolbar, Typography } from '@mui/material';
import { withStyles } from "@mui/styles";
import AddIcon from '@mui/icons-material/Add';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { hasPermission } from '../auth/authOperations';
import { CREATE_PRODUCTS } from '../auth/permissions';
import Product from './product.container';

const styles = theme => ({
  toolBar: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  product: {
    display: 'flex',
    flexDirection: 'row'
  },
  avatar: {
    margin: theme.spacing(1)
  },
  heading: {
    margin: theme.spacing(1)
  },
  button: {
    margin: theme.spacing(1)
  },
  paper: {
    padding: theme.spacing(2)
  }
});

const technologyPredicate = (value, filter) => {
  let values = value.join().toLowerCase();
  let filters = filter.value.toLowerCase().split(',');

  let predicate = true;
  filters.forEach(element => {
    if (values.indexOf(element) === -1) {
      predicate = false;
    }
  });

  return predicate;
};

const getTechCellValue = tech => {
  let cellValue = '';

  tech.map((i, index) => {
    if (index < tech.length - 1) {
      cellValue += `${i.technologyName}, `;
    } else {
      cellValue += i.technologyName;
    }

    return cellValue;
  });

  return cellValue;
};

class ProductsContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: 'productID', title: 'Product ID' },
        { name: 'productName', title: 'Product Name' },
        { name: 'technologies', title: 'Technology', getCellValue: row => (row.technologies ? getTechCellValue(row.technologies) : []) },
        { name: 'status', title: 'Status' }
      ],
      tableColumnExtensions: [{ columnName: 'productID', width: 150 }, { columnName: 'status', width: 100 }],
      currentPage: 0,
      pageSize: 10,
      allowedPageSizes: [10, 25, 50, 100],
      integratedFilteringColumnExtensions: [{ columnName: 'technology', predicate: technologyPredicate }],
      addEdit: false
    };

    this.changeCurrentPage = currentPage => this.setState({ currentPage });
    this.changePageSize = pageSize => this.setState({ pageSize });
    this.changeGridSelection = this.changeGridSelection.bind(this);
    this.toggleAdd = this.toggleAdd.bind(this);
  }

  async toggleAdd() {
    await this.props.updateVendorProducts();

    this.setState({
        addEdit: !this.state.addEdit,
        productID: null
    });
  }

  changeGridSelection(selection) {
    this.setState({ addEdit: true, productID: this.props.products[selection].productID });
  }

  render() {
    const { classes, vendor } = this.props;
    const { columns, tableColumnExtensions, pageSize, allowedPageSizes, addEdit, productID } = this.state;

    return (
      <div>
        {!addEdit ? (
          <Fragment>
            <AppBar key="products-appbar" position="static" color="default">
              <Toolbar className={classes.toolBar}>
                <div className={classes.product}>
                  <Avatar className={classes.avatar}>
                    <FontAwesomeIcon icon="edit" />
                  </Avatar>
                  <Typography variant="h6" color="inherit" style={{ alignSelf: 'center' }}>
                    Products
                  </Typography>
                </div>
                {hasPermission(CREATE_PRODUCTS, this.props.permissions) && (
                  <Fab disabled={isEmpty(vendor)} size="small" color="primary" className={classes.button} onClick={this.toggleAdd}>
                    <AddIcon />
                  </Fab>
                )}
              </Toolbar>
            </AppBar>
            <Paper key="products-paper" className={classes.paper} square>
              <Grid rows={this.props.products} columns={columns} selection={[1]}>
                <SortingState defaultSorting={[{ columnName: 'productName', direction: 'asc' }]} />
                <PagingState currentPage={this.state.currentPage} onCurrentPageChange={this.changeCurrentPage} pageSize={pageSize} onPageSizeChange={this.changePageSize} />
                <IntegratedSorting />
                <SelectionState onSelectionChange={selection => this.changeGridSelection(selection)} />
                <FilteringState defaultFilters={[]} />
                <IntegratedFiltering />
                <IntegratedPaging />
                <Table columnExtensions={tableColumnExtensions} />
                <TableHeaderRow showSortingControls />
                <TableFilterRow />
                <TableSelection selectByRowClick highlightSelected showSelectionColumn={false} />
                <PagingPanel pageSizes={allowedPageSizes} />
              </Grid>
            </Paper>
          </Fragment>
        ) : (
          <Product handleBack={this.toggleAdd} handleTabChange={this.props.handleTabChange} productID={productID ? productID : 'new'} vendor={vendor} />
        )}
      </div>
    );
  }
}

ProductsContainer.propTypes = {
  products: PropTypes.array,
  vendor: PropTypes.object
};

export default withStyles(styles)(ProductsContainer);
