import { FilteringState, IntegratedFiltering, IntegratedPaging, PagingState, SelectionState } from '@devexpress/dx-react-grid';
import { Grid, PagingPanel, Table, TableFilterRow, TableHeaderRow, TableSelection } from '@devexpress/dx-react-grid-material-ui';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import { withStyles } from "@mui/styles";
import { isEmpty, orderBy } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import LoadingOverlay from '../layout/loadingOverlay.component';

const styles = theme => ({
  appBar: {
    marginBottom: theme.spacing(4)
  },
  toolBar: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  button: {
    margin: theme.spacing(1),
    marginTop: 'auto'
  },
  paper: {
    padding: theme.spacing(2)
  },
  dialogPaper: {
    minWidth: '50rem',
    minHeight: '30rem'
  },
  dialogActions: {
    flexGrow: 1
  },
  textField: {
    marginTop: '2rem',
    width: '50%'
  }
});

class VendorAcquireProductsDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      vendorColumns: [{ name: 'vendorID', title: 'Vendor ID' }, { name: 'name', title: 'Vendor Name' }, { name: 'status', title: 'Status' }],
      productColumns: [{ name: 'value', title: 'Product ID' }, { name: 'label', title: 'Product Name' }],
      vendorRows: [],
      currentPage: 0,
      pageSize: 5,
      allowedPageSizes: [5, 10, 25, 50],
      activeView: 1,
      vendorSelection: [],
      productSelection: [],
      selectedVendor: this.props.vendor,
      selectedProduct: {},
      acquireDate: moment().format('YYYY-MM-DD'),
      note: ''
    };

    this.changeCurrentPage = currentPage => this.setState({ currentPage });
    this.changePageSize = pageSize => this.setState({ pageSize });
    this.changeSelection = this.changeSelection.bind(this);
    this.handleAcquire = this.handleAcquire.bind(this);
    this.isNextDisabled = this.isNextDisabled.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    if (!isEmpty(props.vendors) && !isEmpty(props.vendor)) {
      const vendorRows = props.vendors.filter(vendor => {
        if (!isEmpty(vendor.products)) {
          return props.vendor.vendorID !== vendor.vendorID;
        }

        return false;
      });

      return {
        vendorRows: vendorRows
      };
    }

    return null;
  }

  changeSelection(selection) {

    let sortedProducts = orderBy(this.props.products, ['label'], ['asc']);

    if (this.state.activeView === 0) {
      switch (selection.length) {
        case 0:
          this.setState({ vendorSelection: [], selectedVendor: {} });
          break;
        case 1:
          this.setState({ vendorSelection: selection, selectedVendor: this.state.vendorRows[selection[0]] });
          break;
        default:
          selection.shift();
          this.setState({ vendorSelection: selection, selectedVendor: this.state.vendorRows[selection[0]] });
      }
    } else {
      switch (selection.length) {
        case 0:
          this.setState({ productSelection: [], selectedProduct: {} });
          break;
        case 1:
          this.setState({ productSelection: selection, selectedProduct: sortedProducts[selection[0]] });
          break;
        default:
          selection.shift();
          this.setState({ productSelection: selection, selectedProduct: sortedProducts[selection[0]] });
      }
    }
  }

  handleAcquire() {
    const { selectedVendor, selectedProduct, acquireDate, note } = this.state;


    this.props.onAcquireProducts({ vendorID: selectedVendor.vendorID, oldVendorID: selectedProduct.vendorID, productID: selectedProduct.value, dateOfChange: acquireDate, note });
  }

  isNextDisabled() {
    const { activeView, vendorSelection, productSelection } = this.state;

    if (activeView === 0) {
      return vendorSelection.length === 0;
    }

    return productSelection.length === 0;
  }

  render() {

    const { vendorColumns, productColumns, pageSize, allowedPageSizes, activeView, selectedVendor, selectedProduct, vendorSelection, productSelection, acquireDate, vendorRows } = this.state;
    const { isVendorsDialogLoading, classes, vendors, vendorList, onAcquisition, ...other } = this.props;

    delete other.dispatch;
    delete other.getAcquireVendors;
    delete other.onAcquireProducts;

    return (
      <Dialog classes={{ paper: classes.dialogPaper }} aria-labelledby="simple-dialog-title" {...other}>
        <DialogTitle id="simple-dialog-title">
          Acquire Products - {activeView === 0 && 'Select A Vendor'} {activeView === 1 && 'Select A Product'}
        </DialogTitle>
        {isVendorsDialogLoading && <LoadingOverlay />}
        <DialogContent>
          {activeView === 1 && (
              //Vendor Aquires Product Modal
            <Grid rows={orderBy(this.props.products, ['label'], ['asc'])} columns={productColumns}>
              <SelectionState selection={productSelection} onSelectionChange={this.changeSelection} />
              <FilteringState defaultFilters={[]} />
              <IntegratedFiltering />
              <PagingState currentPage={this.state.currentPage} onCurrentPageChange={this.changeCurrentPage} pageSize={pageSize} onPageSizeChange={this.changePageSize} />
              <IntegratedPaging />
              <Table />
              <TableHeaderRow />
              <TableFilterRow />
              <TableSelection selectByRowClick />
              <PagingPanel pageSizes={allowedPageSizes} />
            </Grid>
          )}
          {activeView === 2 && [

            <div key="confirm-text">
              {`You have selected to acquire ${selectedProduct.label} into ${selectedVendor.name}. Please confirm that you would like to acquire ${selectedProduct.label} into ${selectedVendor.name}.`}
            </div>,
            <div key="date-selector">
              <TextField
                label="Consolidated Date"
                type="date"
                value={acquireDate}
                className={classes.textField}
                margin="dense"
                onChange={event => this.setState({ acquireDate: event.target.value })}
              />
            </div>,
            <div key="note">
              <TextField className={classes.textField} label="Note" multiline margin="dense" value={this.state.note} onChange={event => this.setState({ note: event.target.value })} />
            </div>
          ]}
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          {activeView === 2 && (
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={() => {
                this.setState({ activeView: activeView - 1 });
              }}
            >
              Back
            </Button>
          )}
          {activeView == 1 && (
              <Fragment>
              <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  onClick={() => {
                      this.props.onClose();
                  }}
              >
                  Cancel
              </Button>

            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              disabled={isVendorsDialogLoading || this.isNextDisabled()}
              onClick={() => {
                this.setState({ activeView: activeView + 1 });
              }}
            >
              Next
            </Button>
              </Fragment>
          )}
          {activeView === 2 && (
            <Button key="acquire-button" variant="contained" color="primary" className={classes.button} onClick={this.handleAcquire}>
              Acquire
            </Button>
          )}
        </DialogActions>
      </Dialog>
    );
  }
}

VendorAcquireProductsDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  onAcquireProducts: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  vendor: PropTypes.object.isRequired,
  vendors: PropTypes.array
};

function mapStateToProps(state, props) {
  return {
    isVendorsDialogLoading: state.vendors.get('isVendorsDialogLoading')
  };
}

export default withStyles(styles)(connect(mapStateToProps)(VendorAcquireProductsDialog));
