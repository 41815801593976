import { TableEditRow } from '@devexpress/dx-react-grid-material-ui';
import { withStyles } from '@mui/styles';
import React from 'react';

const styles = theme => ({
  root: {
    padding: '0px',
    margin: '0px'
  }
});

const CustomSaveTableRow = ({ onSave, onCancel, row, ...restProps }) => {
  const buttons = [...restProps.children];

  return <TableEditRow.Row {...restProps}>{buttons}</TableEditRow.Row>;
};

export default withStyles(styles, { withTheme: true })(CustomSaveTableRow);
