import { Button, Card, CardContent, Drawer, Grid, List, Paper, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import { findIndex } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import EntityOverview from './entityOverview.container';
import CustomTabs from '@survey/common/dist/components/form-controls/CustomTabs';
import { getCurrentPageTabs } from '@survey/common/dist/actions/pages.actions';
import LoadingOverlay from '../../layout/loadingOverlay.component';
import { ValidatorForm } from 'react-material-ui-form-validator';
const styles = (theme) => ({
  root: {
    width: '100%',
  },
  appFrame: {
    position: 'relative',
    display: 'flex',
    width: '100%',
    height: '100%',
  },
  drawerPaper: {
    position: 'relative !important',
    height: '100%',
    width: 240,
    backgroundColor: theme.palette.background.default,
  },
  content: {
    overflow: 'hidden',
    width: '100%',
    padding: theme.spacing(1),
    height: '100%',
  },
  card: {
    margin: theme.spacing(1),
  },
  button: {
    float: 'right',
    padding: '.375rem .75rem',
  },
});

class Entity extends Component {
  constructor(props) {
    super(props);

    ['handlePreviousPage', 'handleNextPage', 'isFirstPage', 'isLastPage'].map((name) => (this[name] = this[name].bind(this)));
  }

  handlePreviousPage(pages) {
    const { page, changeSelection } = this.props;
    const curPageIndex = findIndex(pages, { pageId: page.pageId });

    changeSelection(pages[curPageIndex - 1].pageId);
  }

  handleNextPage(pages) {
    const { page, changeSelection } = this.props;

    const curPageIndex = findIndex(pages, { pageId: page.pageId });

    changeSelection(pages[curPageIndex + 1].pageId);
  }

  isFirstPage(pages) {
    const { page } = this.props;
    return findIndex(pages, { pageId: page.pageId }) === 0;
  }

  isLastPage(pages) {
    const { page } = this.props;
    return findIndex(pages, { pageId: page.pageId }) === pages.length - 1;
  }

  render() {
    const {
      allTabs,
      answers,
      changeSelection,
      classes,
      clickSaveSurvey,
      completePage,
      countriesList,
      delegates,
      entity,
      isLoading,
      isSaving,
      page,
      pageTabs,
      pages,
      productsList,
      questions,
      regionsList,
      saveSurvey,
      saveSurveyChanges,
      surveyType,
      technologies,
      technologiesList,
      technologyQuestions,
      vendorsList,
      regions,
      survey,
      pageQuestionCount,
    } = this.props;

    return (
      <ValidatorForm onSubmit={() => {}} onError={() => {}} className={this.props.classes.form}>
        <Paper >
          {surveyType.surveyTemplate === 'One Page' ? (
            <div className={classes.appFrame}>
              <main className={classes.content}>
                {pages.map((page) => {
                  let currentTabs = getCurrentPageTabs(page.pageId, allTabs, questions, surveyType.questions, technologyQuestions, surveyType.technologyQuestions, technologies);

                  return (
                    <Card key={page.pageId} className={classes.card}>
                      <CardContent>
                        <Grid container>
                          <Grid item xs={6}>
                            <Typography variant="h5">{page.pageName}</Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Button className={classes.button} color="primary" variant="contained" onClick={() => clickSaveSurvey()}>
                              Save
                            </Button>
                          </Grid>
                        </Grid>
                        <CustomTabs
                          answers={answers}
                          countriesList={countriesList}
                          delegates={delegates}
                          entity={entity}
                          inline={true}
                          isInternal={true}
                          isFirstPage={() => this.isFirstPage(pages)}
                          isLastPage={() => this.isLastPage(pages)}
                          handlePreviousPage={() => this.handlePreviousPage(pages)}
                          handleNextPage={() => this.handleNextPage(pages)}
                          completePage={completePage}
                          pageId={page.pageId}
                          page={page}
                          pages={pages}
                          productsList={productsList}
                          questions={questions}
                          regionsList={regionsList}
                          saveSurvey={saveSurvey}
                          surveyType={surveyType}
                          tabs={currentTabs}
                          technologiesList={technologiesList}
                          technologyQuestions={technologyQuestions}
                          vendorsList={vendorsList}
                          regions={regions}
                          survey={survey}
                          pageQuestionCount={pageQuestionCount}
                        />
                      </CardContent>
                    </Card>
                  );
                })}
              </main>
            </div>
          ) : (
            <div className={classes.appFrame}>
              <Drawer
                variant="permanent"
                classes={{
                  paper: classes.drawerPaper,
                }}
              >
                <List>
                  {pages.map((item) => {
                    return (
                      <Button
                        key={item.pageId}
                        fullWidth
                        color="primary"
                        className={classes.button}
                        // Use == instead of === so in case one of these is a number there will be an automatic type conversion.
                        // eslint-disable-next-line
                        variant={page && item.pageId == page.pageId ? 'contained' : 'text'}
                        onClick={() => changeSelection(item.pageId)}
                      >
                        {item.pageName}
                      </Button>
                    );
                  })}
                </List>
              </Drawer>
              <main className={classes.content}>
                {isLoading ? (
                  <LoadingOverlay />
                ) : page ? (
                  <Fragment>
                    {isSaving && <LoadingOverlay />}
                    <CustomTabs
                      answers={answers}
                      countriesList={countriesList}
                      delegates={delegates}
                      entity={entity}
                      inline={false}
                      isInternal={true}
                      isFirstPage={() => this.isFirstPage(pages)}
                      isLastPage={() => this.isLastPage(pages)}
                      handlePreviousPage={() => this.handlePreviousPage(pages)}
                      handleNextPage={() => this.handleNextPage(pages)}
                      completePage={completePage}
                      pageId={String(page.pageId)}
                      page={page}
                      pages={pages}
                      productsList={productsList}
                      questions={questions}
                      regionsList={regionsList}
                      saveSurvey={saveSurvey}
                      saveSurveyChanges={saveSurveyChanges}
                      surveyType={surveyType}
                      tabs={pageTabs}
                      technologiesList={technologiesList}
                      technologyQuestions={technologyQuestions}
                      vendorsList={vendorsList}
                      regions={regions}
                      survey={survey}
                      pageQuestionCount={pageQuestionCount}
                    />
                  </Fragment>
                ) : (
                  <EntityOverview />
                )}
              </main>
            </div>
          )}
        </Paper>
      </ValidatorForm>
    );
  }
}

Entity.propTypes = {
  allTabs: PropTypes.array.isRequired,
  answers: PropTypes.array,
  changeSelection: PropTypes.func,
  clickSaveSurvey: PropTypes.func,
  completePage: PropTypes.func.isRequired,
  countriesList: PropTypes.array.isRequired,
  isLoading: PropTypes.bool,
  isSaving: PropTypes.bool,
  pageTabs: PropTypes.array.isRequired,
  pages: PropTypes.array.isRequired,
  productsList: PropTypes.array.isRequired,
  questions: PropTypes.array.isRequired,
  regionsList: PropTypes.array.isRequired,
  saveSurvey: PropTypes.func,
  surveyType: PropTypes.object.isRequired,
  technologies: PropTypes.array.isRequired,
  technologiesList: PropTypes.array.isRequired,
  technologyQuestions: PropTypes.array.isRequired,
  vendorsList: PropTypes.array.isRequired,
  regions: PropTypes.array.isRequired,
};

export default withStyles(styles)(withRouter(Entity));
