import { FilteringState, IntegratedFiltering, SortingState, IntegratedSorting, SelectionState } from '@devexpress/dx-react-grid';
import { Grid, VirtualTable, TableFilterRow, TableHeaderRow, TableSelection } from '@devexpress/dx-react-grid-material-ui';
import { AppBar, Avatar, Button, Fab, Toolbar, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import AddIcon from '@mui/icons-material/Add';
import React, { Component, Fragment } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connect } from 'react-redux';
import { find, uniq } from 'lodash';
import { getSurveyTypes } from './surveyTypes.actions';
import { handleToastMessage } from '../layout/layout.actions';
import ConfirmWithInput from '@survey/common/dist/components/dialogs/ConfirmWithInput';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { getQuestions } from '@survey/common/dist/actions/questions.actions';
import { getTechnologyQuestions } from '@survey/common/dist/actions/technologyQuestions.actions';
import { createSurvey, CREATE_SURVEY_SUCCESS, downLoadSurveyType } from '@survey/common/dist/actions/surveys.actions';
import LoadingOverlay from '../layout/loadingOverlay.component';
import GetAppIcon from '@mui/icons-material/GetApp';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
const styles = (theme) => ({
  toolBar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  surveyType: {
    display: 'flex',
    flexDirection: 'row',
  },
  avatar: {
    margin: theme.spacing(1),
  },
  heading: {
    margin: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(1),
  },
  paper: {
    padding: theme.spacing(2),
  },
});

class SurveyTypesContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showNewSurveyDialog: false,
      openDownloadDialog: false,
      downloading: false,
      surveyType: {},
      surveyTypeLanguages: [],
      selectedLanugage: '',
      surveyTypeDownload: {},
      columns: [
        {
          name: 'download',
          title: 'Download',
          getCellValue: (row) => {
            return (
              <Fab
                size="small"
                color="primary"
                onClick={(e) => {
                  e.stopPropagation();
                  let surveyTypeLanguages = row.languageOptions.map((l) => {
                    return { value: l === 'English' ? '' : l, label: l };
                  });
                  if (surveyTypeLanguages.length === 0) {
                    surveyTypeLanguages.push({ value: '', label: 'English' });
                  }
                  this.setState({ openDownloadDialog: true, surveyTypeLanguages, surveyTypeDownload: row });
                }}
              >
                <GetAppIcon />
              </Fab>
            );
          },
        },
        { name: 'name', title: 'Name' },
        {
          name: 'createdDate',
          title: 'Created Date',
          getCellValue: (row) => (row.createdDate ? new Date(row.createdDate).toLocaleString('en-US', { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' }) : undefined),
        },
        {
          name: 'updatedDate',
          title: 'Updated Date',
          getCellValue: (row) => (row.modifiedDate ? new Date(row.modifiedDate).toLocaleString('en-US', { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' }) : undefined),
        },
        { name: 'active', title: 'Active', getCellValue: (row) => (row.active ? 'Active' : 'Inactive') },
        {
          name: 'actions',
          title: 'Actions',
          getCellValue: (row) => {
            return (
              <Fragment>
                <Button
                  onClick={(e) => {
                    e.stopPropagation();

                    this.setState({ showNewSurveyDialog: true, surveyType: row });
                  }}
                >
                  Launch Blank Survey from Survey Type
                </Button>
              </Fragment>
            );
          },
        },
      ],
      tableColumnExtensions: [
        { columnName: 'startYear', width: 100 },
        { columnName: 'endYear', width: 100 },
        { columnName: 'active', width: 100 },
        { columnName: 'download', width: 100 },
      ],
    };

    ['addSurveyType', 'changeGridSelection', 'launchBlankSurvey', 'getAllSurveyQuestions', 'handleClose', 'handleDownload', 'setSelectedLanguage'].forEach((k) => {
      this[k] = this[k].bind(this);
    });
  }

  componentDidMount() {
    this.props.getSurveyTypes();
    this.props.getQuestions();
    this.props.getTechnologyQuestions();
  }

  addSurveyType() {
    this.props.history.push(`/surveyTypes/new`);
  }

  changeGridSelection(selection) {
    let surveyType = this.props.surveyTypes[selection];
    this.props.history.push(`/surveyTypes/${surveyType.surveyTypeID}`);
  }

  getAllSurveyQuestions = (surveyQuestions, questions) => {
    let allQuestions = [];
    surveyQuestions.forEach((q) => {
      let question = find(questions, { questionID: q.questionID });
      if (question) {
        allQuestions.push(question);
        allQuestions = allQuestions.concat(this.getAllSurveyQuestions(q.branchingQuestions, questions));
      }
    });
    return uniq(allQuestions);
  };

  launchBlankSurvey = async (surveyName, surveyType) => {
    const survey = {
      surveyTypeID: surveyType.surveyTypeID,
      surveyName,
      status: 'Not Started',
      assignedMRA: null,
      entities: [
        {
          parentEntityID: 0,
          entityDescription: null,
          entityID: 0,
          entityName: surveyName,
          haEntityTypeID: 1,
        },
      ],
    };

    const result = await this.props.createSurvey(survey);
    if (result.type === CREATE_SURVEY_SUCCESS) {
      this.props.handleToastMessage(`${surveyName} created successfully`, false);
    } else {
      this.props.handleToastMessage(`Failed to create survey`, true);
    }
  };

  handleClose() {
    this.setState({ openDownloadDialog: false });
  }
  handleDownload() {
    console.log(this.state.selectedLanugage);
    this.setState({ downloading: true });
    this.props.downLoadSurveyType(this.state.surveyTypeDownload.surveyTypeID, this.state.selectedLanugage).then(() => {
      this.setState({ openDownloadDialog: false, downloading: false });
    });
  }
  setSelectedLanguage(v) {
    this.setState({ selectedLanugage: v.target.value });
  }
  render() {
    const { classes, isTypesLoading, surveyTypes } = this.props;
    const { columns, tableColumnExtensions, downloading, surveyTypeDownload, surveyTypeLanguages } = this.state;

    return (
      <Fragment>
        <Dialog fullWidth={true} open={this.state.openDownloadDialog} onClose={this.handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Download {surveyTypeDownload.name}</DialogTitle>
          <DialogContent>
            <FormControl component="fieldset">
              <FormLabel component="legend">Please select a lanugage</FormLabel>
              <RadioGroup aria-label="gender" name="gender1" onChange={this.setSelectedLanguage}>
                {surveyTypeLanguages.map((l) => (
                  <FormControlLabel value={l.value} control={<Radio />} label={l.label} />
                ))}
              </RadioGroup>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={this.handleDownload} color="primary">
              Download
            </Button>
          </DialogActions>
        </Dialog>
        <AppBar position="static" color="default">
          <Toolbar className={classes.toolBar}>
            <div className={classes.surveyType}>
              <Avatar className={classes.avatar}>
                <FontAwesomeIcon icon="edit" />
              </Avatar>
              <Typography variant="h6" color="inherit" style={{ alignSelf: 'center' }}>
                Survey Types
              </Typography>
            </div>
            <Fab size="small" color="primary" className={classes.button} onClick={this.addSurveyType}>
              <AddIcon />
            </Fab>
          </Toolbar>
        </AppBar>
        {isTypesLoading || downloading ? (
          <LoadingOverlay />
        ) : (
          <Grid rows={surveyTypes ? surveyTypes : []} columns={columns} selection={[1]}>
            <SelectionState onSelectionChange={(selection) => this.changeGridSelection(selection)} />
            <FilteringState defaultFilters={[]} />
            <IntegratedFiltering />
            <SortingState defaultSorting={[]} />
            <IntegratedSorting />
            <VirtualTable height={1000} columnExtensions={tableColumnExtensions} />
            <TableHeaderRow showSortingControls />
            <TableFilterRow />
            <TableSelection selectByRowClick highlightSelected showSelectionColumn={false} />
          </Grid>
        )}
        <ConfirmWithInput
          open={this.state.showNewSurveyDialog}
          title={'Enter name for new survey'}
          contentText="New Survey Name"
          onConfirm={(name) => {
            this.setState({ showNewSurveyDialog: false });
            this.launchBlankSurvey(name, this.state.surveyType);
          }}
          onClose={() => this.setState({ showNewSurveyDialog: false })}
        />
      </Fragment>
    );
  }
}

function mapStateToProps(state, props) {
  return {
    surveyTypes: state.surveyTypes.get('surveyTypes'),
    questions: state.questions.get('questions'),
    technologyQuestions: state.technologyQuestions.get('technologyQuestions'),
    isTypesLoading: state.surveyTypes.get('isTypesLoading'),
  };
}

export default withStyles(styles)(
  connect(mapStateToProps, {
    getSurveyTypes,
    getQuestions,
    getTechnologyQuestions,
    createSurvey,
    handleToastMessage,
    downLoadSurveyType,
  })(SurveyTypesContainer)
);
