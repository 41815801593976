import React, { PureComponent } from 'react';
import { Getter } from '@devexpress/dx-react-core';
import { EditingState, GroupingState, IntegratedGrouping } from '@devexpress/dx-react-grid';
import { Grid as DevGrid, Table, TableHeaderRow, TableGroupRow, TableEditRow, TableEditColumn, Toolbar, GroupingPanel } from '@devexpress/dx-react-grid-material-ui';
import { Button, TextField } from '@mui/material';
import { withStyles } from '@mui/styles';
import { ChevronRight, ExpandMore } from '@mui/icons-material';
import { cloneDeep, find, findIndex, findLastIndex, isArray, isEmpty, isEqual, sortBy } from 'lodash';
import { ValidatorForm } from 'react-material-ui-form-validator';
import Confirm from '@survey/common/dist/components/dialogs/Confirm';
import CustomSelect from '@survey/common/dist/components/form-controls/CustomSelect';

const findByValue = (options, value) => {
  const result = find(options, { value: value });
  if (result) {
    return result;
  } else {
    return {};
  }
};

const makeEditingRowIds = rows => {
  const editingRowIds = [];
  for (let i = 0; i < rows.length; i++) {
    editingRowIds.push(i);
  }
  return editingRowIds;
};

const filterTechnologiesList = (item, rows, currentTechID) => {
  if (findIndex(rows, { technologyID: item.value }) === -1) {
    return true;
  } else if (currentTechID === item.value) {
    return true;
  } else {
    return false;
  }
};

const DropdownEditor = ({ name, options, value, onValueChange, isMulti = false, style = {}, disabled = false }) => {
  return (
    <ValidatorForm onSubmit={() => {}} style={style}>
      <CustomSelect disabled={disabled} name={name} isMulti={isMulti} value={value} onChange={onValueChange} options={options} label="" />
    </ValidatorForm>
  );
};

/* Define what an empty row should be. */
const emptyRow = {
  active: true,
  scoreID: null,
  groupID: null,
  technologyID: null,
  questionID: null,
  answerID: null,
  values: [],
  points: 0.05,
  stage: 0,
  min: 0,
  max: 0,
  weight: null
};

/* This flattens the scoring rows so it's easier to use for the scoring table. */
const flattenRows = rows => {
  if (!rows) return [];
  return rows.reduce((all, row) => {
    let newRow = cloneDeep(row);
    delete newRow.answers;
    row.answers.forEach(answer => {
      all.push({ ...newRow, ...answer });
    });

    return all;
  }, []);
};

/* Convert from the flattened rows we use to the inflated rows used by the API */
const inflateRow = (row, rows) => {
  const matchingRows = rows.filter(r => r.questionID === row.questionID && r.technologyID === row.technologyID);
  let newRow = cloneDeep(row);
  /* If this row has an empty scoreID there are other matching rows, use that row's scoreID. All rows with matching questions have to have the same scoreID. */
  if (row.scoreID === null && matchingRows.length > 1) {
    const updatedRow = find(matchingRows, r => r.scoreID !== null);
    if (updatedRow) {
      newRow = cloneDeep(updatedRow);
    }
  }

  /* Remove fields that will cause the API to reject the request. */
  delete newRow.bsonId;

  const answers = matchingRows.map(r => {
    let { answerID, values, points, stage, active } = r;
    // Convert some values to what the endpoint expects them to be.
    values = values.map(v => Number(v));
    if (isArray(stage)) {
      stage = stage[0];
    }
    stage = Number(stage);
    points = Number(points);
    return {
      answerID,
      values,
      points,
      stage,
      active,
      reference: ''
    };
  });
  /* Remove fields that are now in the answers array. */
  delete newRow.answerID;
  delete newRow.values;
  delete newRow.points;
  delete newRow.stage;
  // delete newRow.min;
  // delete newRow.max;
  delete newRow.active;
  newRow['answers'] = answers;

  return newRow;
};

class ScoringTable extends PureComponent {
  constructor(props) {
    super(props);
    const rows = flattenRows(props.scores);
    this.state = {
      editingRowIds: makeEditingRowIds(rows),
      expandedGroups: [],
      rowChanges: {},
      origRows: cloneDeep(rows),
      rows,
      columns: [
        {
          name: 'technologyID',
          title: 'Technology(s)'
        },
        {
          name: 'questionID',
          title: 'Question'
        },
        { name: 'values', title: 'Criteria' },
        { name: 'points', title: 'Points' },
        { name: 'stage', title: 'Stage' },
        { name: 'min', title: 'Min' },
        { name: 'max', title: 'Max' },
        { name: 'active', title: 'Status' }
      ],
      showRowDeleteDialog: false,
      showTechnologyDeleteDialog: false,
      rowToDelete: {},
      technologyRowToDelete: {}
    };

    [
      'Cell',
      'getOptions',
      'Grouping',
      'changeRowChanges',
      'updateRows',
      'CustomSaveTableRow',
      'changeAddedRows',
      'onCommitChanges',
      'onDeleteRow',
      'ExpandGroupBtn',
      'updateWeight',
      'getWeight'
    ].forEach(k => {
      this[k] = this[k].bind(this);
    });
  }

  ExpandGroupBtn({ expanded }) {
    const props = {
      onClick: () => this.setState({ clickedButton: true }),
      className: this.props.classes.icon
    };
    if (expanded) {
      return <ExpandMore {...props} />;
    } else {
      return <ChevronRight {...props} />;
    }
  }
  changeRowChanges(rowChanges) {
    let newRows = [...this.state.rows];
    Object.keys(rowChanges).forEach(id => {
      Object.keys(rowChanges[id]).forEach(field => {
        const value = rowChanges[id][field];
        if (typeof value === 'object') {
          if (isArray(value)) {
            if (newRows[id]) {
              newRows[id][field] = value.map(v => v.value);
            } else {
              console.error('Failed setting', newRows, 'id:', id, 'field:', field);
            }
          } else {
            newRows[id][field] = value.value;
          }
        } else {
          newRows[id][field] = value;
        }
      });
    });

    this.updateRows(newRows, { rowChanges });
  }

  /* Update the rows, the editingRowIds if necessary, and also update the state with any other changes the caller may want. */
  updateRows(rows, otherUpdates = {}) {
    if (this.state.rows.length !== rows.length) {
      const editingRowIds = makeEditingRowIds(rows);
      this.setState({ ...otherUpdates, editingRowIds, rows });
    } else {
      this.setState({ ...otherUpdates, rows });
    }
  }

  onDeleteRow() {
    const row = this.state.rowToDelete;
    let rows = [...this.state.rows];
    const idx = findIndex(rows, row);
    if (idx > -1) {
      rows = [...rows.slice(0, idx), ...rows.slice(idx + 1)];
    }
    this.updateRows(rows, { showRowDeleteDialog: false });

    const data = inflateRow(row, rows);
    if (!data.scoreID) {
      // Don't do anything if the scoreID isn't set.
    } else if (data.answers.length) {
      this.props.updateScore(data.scoreID, data);
    } else {
      this.props.deleteScore(data.scoreID);
    }
  }

  getOptions(columnName, questionID) {
    switch (columnName) {
      case 'stage':
        return Array.from(new Array(7), (x, i) => ({ label: `Stage ${i + 1}`, value: `${i + 1}` }));
      case 'values':
        const question = find(this.props.questions, { questionID });
        if (!question) {
          return [];
        }
        const answer = find(this.props.answers, { answerID: question.answerID });
        if (answer) {
          return answer.answers;
        } else {
          return [];
        }
      default:
        return [];
    }
  }

  CustomSaveTableRow({ row, ...restProps }) {
    const onSave = row => {
      let newRows = [...this.state.rows];
      const idx = findIndex(newRows, row);
      if (idx > -1) {
        newRows = [...newRows.slice(0, idx - 1), newRows.slice(idx + 1)];
      }

      const data = inflateRow(row, this.state.rows);
      if (data.scoreID) {
        this.props.updateScore(data.scoreID, data);
      } else {
        data.scoreID = 0;
        this.props.createScore(data);
      }
    };

    const buttons = [...restProps.children];
    const editButtons = buttons.pop();
    const save = (
      <Table.Cell key={editButtons.key} props={editButtons.props}>
        <Button color="primary" size="small" onClick={() => onSave(row)}>
          Save
        </Button>
        <Button color="primary" size="small" onClick={() => this.setState({ showRowDeleteDialog: true, rowToDelete: row })}>
          Delete
        </Button>
      </Table.Cell>
    );

    return (
      <TableEditRow.Row {...restProps}>
        {buttons}
        {save}
      </TableEditRow.Row>
    );
  }

  // Remove all rows of technologyID
  onDeleteTechnology() {
    const rowsToDelete = [];
    const rows = this.state.rows.filter(r => {
      if (r.technologyID === this.state.technologyToDelete) {
        rowsToDelete.push(r);
        return false;
      }
      return true;
    });

    this.updateRows(rows, { technologyToDelete: {}, showTechnologyDeleteDialog: false });

    /* Delete any rows in the database that have already been saved. */
    rowsToDelete.forEach(r => {
      if ('scoreID' in r && r.scoreID > 0) this.props.deleteScore(r.scoreID);
    });
  }

  getWeight(technologyID, questionID) {
    const row = find(this.state.rows, { technologyID, questionID });
    return row.weight;
  }

  updateWeight(technologyID, questionID, val) {
    const row = find(this.state.rows, { technologyID, questionID });
    row.weight = val;
    this.updateRows([row]);
  }

  Grouping(props) {
    const { column, row } = props;

    let options = [];
    let technologyID;
    if (column.name === 'technologyID') {
      options = this.props.technologiesList.filter(t => filterTechnologiesList(t, this.state.rows, row.value));
    } else if (column.name === 'questionID') {
      /* Get the tech ID from this row key */
      technologyID = row.compoundKey.match(/^(.*)\|/);
      if (technologyID) {
        technologyID = Number(technologyID[1]);
      }
      options = this.props.questions
        .filter(q => q.technologies.length === 0 || q.technologies.includes(technologyID))
        .map(q => ({ label: `${q.questionDescription} (${q.questionID})`, value: q.questionID }));
    }
    options = sortBy(options, [option => option.label.toLowerCase()]);

    const update = (key, oldValue, newValue) => {
      const rows = this.state.rows.map(r => {
        const technologyMatch = new RegExp(`^${r.technologyID}`);

        /* If this row is of the correct technologyID or if the technologyID is being changed and the current row has the old value, set it to the new value. */
        if ((technologyMatch.test(row.compoundKey) || key === 'technologyID') && r[key] === oldValue) {
          r[key] = newValue;
          /* If the questionID changes, also change the answerID */
          if (key === 'questionID') {
            const question = find(this.props.questions, { questionID: newValue });
            if (question) {
              r['answerID'] = question.answerID;
            }
          }
        }

        return r;
      });
      this.updateRows(rows);
    };

    /* Disable the dropdown if this is a tab and it has questions. This prevents people from changing the tab after setting questions. */
    const isDisabled = column.name === 'technologyID' && this.state.rows.filter(r => r.technologyID === row.value && r.questionID).length ? true : false;

    return (
      <div style={{ display: 'inline-block', width: 'calc(100% - 50px)' }}>
        <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
          <div style={{ marginRight: '0.75rem' }}>
            <strong>{column.title}</strong>:
          </div>
          <DropdownEditor
            name={column.name}
            disabled={isDisabled}
            options={options}
            value={findByValue(options, row.value)}
            onValueChange={field => newElement => update(field, row.value, newElement.value)}
            style={{ verticalAlign: 'top', marginBottom: '0.5rem', minWidth: '15rem', flex: 2 }}
          />
          {column.name === 'technologyID' && (
            <div style={{ flex: 4, display: 'flex', marginLeft: '2rem' }}>
              <div style={{ marginLeft: 'auto' }}>
                <Button color="primary" size="small" onClick={e => this.setState({ showTechnologyDeleteDialog: true, technologyToDelete: row.value })}>
                  Delete
                </Button>
                <Button color="primary" size="small" onClick={e => this.changeAddedRows([{}], row.value)}>
                  Add Rule
                </Button>
              </div>
            </div>
          )}
          {column.name === 'questionID' && (
            <div style={{ flex: 1, display: 'flex', marginLeft: '1rem' }}>
              <div style={{ display: 'flex', flexDirection: 'row', marginTop: '0.35rem' }}>
                <TextField value={this.getWeight(technologyID, row.value, row.value)} onChange={e => this.updateWeight(technologyID, row.value, e.target.value)} />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }

  Cell({ value, ...props }) {
    let myCell;

    /* Setup the props for each cell */
    const cellProps = cloneDeep(props);
    delete cellProps.onValueChange;
    delete cellProps.editingEnabled;

    const columnName = props.column.name;
    switch (columnName) {
      case 'min':
      case 'max':
        myCell = <Table.Cell />;
        break;
      case 'points':
        myCell = (
          <Table.Cell {...cellProps}>
            <TextField value={value ? value : ''} onChange={e => props.onValueChange(e.target.value)} style={{ marginTop: '1rem' }} />
          </Table.Cell>
        );
        break;
      case 'active':
        myCell = (
          <Table.Cell {...cellProps}>
            <Button color="primary" size="small" onClick={() => props.onValueChange(!props.row.active)}>
              {props.row.active ? 'DE-' : ''}ACTIVATE
            </Button>
          </Table.Cell>
        );
        break;
      default:
        let dropdownValue = props.row[columnName];
        let isMulti = false;
        if (dropdownValue && typeof dropdownValue !== 'object') {
          dropdownValue = [dropdownValue];
        }
        /* Only the values column is a multi dropdown */
        if (columnName === 'values') isMulti = true;
        const options = this.getOptions(columnName, props.row.questionID);
        let val = [];
        if (options && dropdownValue) {
          val = dropdownValue.map(v => find(options, { value: v.toString() }));
        }

        myCell = (
          <Table.Cell {...cellProps}>
            <DropdownEditor
              key={columnName}
              name={columnName}
              options={options}
              value={val}
              isMulti={isMulti}
              onValueChange={() => v => props.onValueChange(v)}
              /* Set the style so multi and single selects sit on the same line horizontally */
              style={{ marginBottom: isMulti ? '0.5rem' : '-0.33rem' }}
            />
          </Table.Cell>
        );
        break;
    }
    return myCell;
  }

  changeAddedRows(newRows, technologyID = null) {
    if (newRows.length === 1) {
      let rows = [...this.state.rows];
      let newRow = { ...emptyRow };

      /* If a technologyID was provided, add the row at the end of the other rows with that technologyID. Otherwise, added it at the very end of the table. */
      if (technologyID !== null) {
        newRow['technologyID'] = technologyID;

        const idx = findLastIndex(rows, { technologyID });
        rows = [...rows.slice(0, idx + 1), newRow, ...rows.slice(idx + 1)];
      } else {
        rows.push(newRow);
      }

      this.updateRows(rows);
    } else {
      console.error('changeAddedRows newRows is not 1!', newRows.length, newRows);
    }
  }

  onCommitChanges(value) {
    console.log('ON COMMIT CHANGES FUNC');
    if (!isEmpty(value.added)) {
      console.log('ADDED!');
      this.setState({ rowChanges: {} });
    } else if (!isEmpty(value.changed)) {
      console.log('change this manually', value);
    } else {
      console.log('delete this manually', value);
      let rows = [...this.state.rows];
      value.deleted.forEach(idx => {
        rows = [...rows.slice(0, idx), ...rows.slice(idx + 1)];
      });

      this.updateRows(rows);
    }
  }

  render() {
    const { editingRowIds, rowChanges, rows, columns } = this.state;

    return (
      <div>
        <DevGrid rows={rows} columns={columns}>
          <GroupingState grouping={[{ columnName: 'technologyID' }, { columnName: 'questionID' }]} />
          <Getter
            name="expandedGroups"
            computed={({ expandedGroups }) => {
              /* Only expand the group on a button click, don't open otherwise. This prevents automatic opening and closing when a select is opened or closed. */
              if (this.state.clickedButton) {
                const nullRegExp = new RegExp('null');
                /* Only open for groups that have a tab and or question defined. */
                const filteredGroups = expandedGroups.filter(g => {
                  if (nullRegExp.test(g)) {
                    return false;
                  }
                  return true;
                });
                /* If the values are different, update the groups. If not, don't do anything. */
                if (!isEqual(filteredGroups, this.state.expandedGroups)) {
                  this.setState({ expandedGroups: filteredGroups, clickedButton: false });
                  return filteredGroups;
                }
              }
              return this.state.expandedGroups;
            }}
          />
          <IntegratedGrouping />
          <Table />
          <TableHeaderRow />
          <TableGroupRow contentComponent={this.Grouping} iconComponent={this.ExpandGroupBtn} />
          <Toolbar />
          <GroupingPanel itemComponent={() => <span />} />
          <EditingState
            editingRowIds={editingRowIds}
            onEditingRowIdsChange={a => {
              // XXX: CHANGE ME
              console.log('on editing row ids change', a);
            }}
            rowChanges={rowChanges}
            onRowChangesChange={this.changeRowChanges}
            addedRows={[]}
            onAddedRowsChange={this.changeAddedRows}
            onCommitChanges={this.onCommitChanges}
          />
          <TableEditRow rowComponent={this.CustomSaveTableRow} cellComponent={this.Cell} />
          <TableEditColumn showAddCommand showEditCommand showDeleteCommand />
          <Getter
            name="tableColumns"
            computed={({ tableColumns }) => {
              const groups = [...tableColumns.filter(c => c.type.toString().match(/group/))];

              const columns = [...tableColumns.filter(c => !c.type.toString().match(/select/) && !c.type.toString().match(/group/))];
              // Reorder the columns to put the edit commands at the end.
              const updatedColumns = [...columns.slice(1), columns[0]];

              const select = [...tableColumns.filter(c => c.type.toString().match(/select/))];

              return [...groups, ...updatedColumns, ...select];
            }}
          />
        </DevGrid>
        <Confirm
          title="Delete All Rows for this Tab?"
          onClose={() => this.setState({ showTechnologyDeleteDialog: false, technologyToDelete: {} })}
          onConfirm={() => this.onDeleteTechnology()}
          contentText={`Please confirm you want to delete ALL rows for this tab.`}
          open={this.state.showTechnologyDeleteDialog}
        />
        <Confirm
          title="Delete Scoring Row?"
          onClose={() => this.setState({ showRowDeleteDialog: false, rowToDelete: {} })}
          onConfirm={() => this.onDeleteRow()}
          contentText={`Please confirm that you would like to delete this row.`}
          open={this.state.showRowDeleteDialog}
        />
      </div>
    );
  }
}

const styles = theme => ({
  icon: {
    fill: '#5a5b5c',
    margin: theme.spacing(1),
    marginBottom: 1.5 * theme.spacing(1),
    fontSize: theme.fontSize,
    verticalAlign: 'middle'
  },
  '&:hover': {
    cursor: 'pointer'
  }
});

export default withStyles(styles)(ScoringTable);
