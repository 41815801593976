import { withStyles } from '@mui/styles';
import React, { Component } from 'react';
import { connect } from 'react-redux';

const styles = theme => ({});

class EntityOverviewContainer extends Component {
  render() {
    // const classes = this.props.classes;

    return <div>Entity Overview</div>;
  }
}

function mapStateToProps(state, props) {
  return {
    survey: state.surveys.get('survey')
  };
}

export default withStyles(styles)(
  connect(
    mapStateToProps,
    {}
  )(EntityOverviewContainer)
);
