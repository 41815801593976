import { CALL_API } from '@survey/common/dist/middleware/api';

export const COPY_DATA_REQUEST = 'COPY_DATA_REQUEST';
export const COPY_DATA_SUCCESS = 'COPY_DATA_SUCCESS';
export const COPY_DATA_FAILURE = 'COPY_DATA_FAILURE';

export const copyData = data => {
  return {
    [CALL_API]: {
      types: [COPY_DATA_REQUEST, COPY_DATA_SUCCESS, COPY_DATA_FAILURE],
      authenticated: true,
      endpoint: `Surveys/CopyData`,
      method: 'POST',
      payload: data
    }
  };
};
