import Immutable from 'immutable';
import { GET_DATA_MAPPINGS_FAILURE, GET_DATA_MAPPINGS_REQUEST, GET_DATA_MAPPINGS_SUCCESS } from './dataMappings.actions';

const initialState = Immutable.Map({
  mappings: [],
  isLoading: false,
  errors: []
});

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_DATA_MAPPINGS_REQUEST:
      return state
        .set('mappings', [])
        .set('errors', [])
        .set('isLoading', true);
    case GET_DATA_MAPPINGS_SUCCESS:
      return state.set('mappings', action.response).set('isLoading', false);
    case GET_DATA_MAPPINGS_FAILURE:
      return state.set('isLoading', false).set('error', action.messages);

    default:
      return state;
  }
};
