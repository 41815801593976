import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Paper,
  Stepper,
  Step,
  StepContent,
  StepLabel,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  TextField,
  Select,
  MenuItem,
  InputLabel,
} from '@mui/material';
import { withStyles } from "@mui/styles";
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { copyData } from './copyData.actions';
import { add, find, groupBy, intersection, isEmpty } from 'lodash';
import { handleToastMessage } from '../../layout/layout.actions';
import { getSurveys } from '@survey/common/dist/actions/surveys.actions';
import LoadingOverlay from '../../layout/loadingOverlay.component';

const styles = (theme) => ({
  button: {
    margin: theme.spacing(1),
    marginTop: 'auto',
  },
  dialogPaper: {
    minWidth: '50rem',
    minHeight: '30rem',
  },
  dialogActions: {
    flexGrow: 1,
  },
  title: {},
  paper: {
    padding: theme.spacing(2),
  },
  formControl: {
    marginRight: '1rem',
  },
  group: {
    flexDirection: 'row',
  },
  actionsContainer: {
    marginTop: theme.spacing(2),
  },
  select: {
    marginTop: theme.spacing(2),
    width: 600,
  },
  selectControl: {
    minWidth: 120,
    marginTop: '1rem !important',
    width: '100%',
  },
});

class CopyDataDialog extends Component {
  constructor(props) {
    super(props);

    const { copyFromEntity, survey, pages, tabs } = this.props;

    const { entities } = survey;
    const { questions } = survey;

    //Filter out the currently selected entity and all entities that are not the same type
    let filteredEntities = entities.filter((e) => {
      return e.haEntityTypeID === copyFromEntity.haEntityTypeID && e.entityID !== copyFromEntity.entityID;
    });

    //For AMAM and INFRAM, we need to allow copying to other types:
      if (survey.surveyType.name === 'AMAM' || survey.surveyType.name === 'INFRAM' || survey.surveyType.name === 'CISOM' || survey.surveyType.name === 'DIAM' || survey.surveyType.name === 'CCMM' || survey.surveyType.name === 'INFRAM 2024' || survey.surveyType.name === 'AMAM 2024') {
      filteredEntities = entities.filter((e) => {
        return e.entityID !== copyFromEntity.entityID && e.haEntityTypeID !== 6 && e.haEntityTypeID !== 8 && e.haEntityTypeID !== 14;
      });
    }

    let questionsInSurvey = survey.questions.map((question) => {
      return find(questions, { questionID: question.questionID });
    });

    //get an array of all unique tabs for the survey
    const surveyTabIds = [];
    questionsInSurvey.map(function (currQuestion) {
      if (!surveyTabIds.includes(currQuestion.tabID)) {
        surveyTabIds.push(currQuestion.tabID);
      }
    });

    //get an array of all unique pages for the survey
    const surveyPageIds = [];
    tabs.map(function (currTab) {
      if (surveyTabIds.includes(currTab.tabId)) {
        if (!surveyPageIds.includes(currTab.pageId)) {
          surveyPageIds.push(currTab.pageId);
        }
      }
    });
    console.log('survey pages', surveyPageIds);
    //console.log('surveyTabIds: ', surveyTabIds);
    //console.log('surveyPageIds: ', surveyPageIds);
    //console.log('questionsInSurvey: ', questionsInSurvey);

    //build an array of objects for questionGroupsInSurvey that looks like technologyGroupsInSurvey:[{pageID:214, pageName: "Data Content...", questions: Array(8)},{}]
    var questionGroups = [];
    var pageData;

      if (survey.surveyType.name === 'AMAM' || survey.surveyType.name === 'INFRAM' || survey.surveyType.name === 'CISOM' || survey.surveyType.name === 'DIAM' || survey.surveyType.name === 'DigitalRadar' || survey.surveyType.name === 'EMRAM 2022' || survey.surveyType.name === 'CCMM' || survey.surveyType.name === 'C-COMM' || survey.surveyType.name === 'INFRAM 2024' || survey.surveyType.name === 'AMAM 2024') {
      surveyPageIds.map(function (currPageId) {
        if (Number(currPageId) !== 213 && Number(currPageId) !== 214 && Number(currPageId) !== 219) {
          //hacky removal of  pages we never want to copy-paste for AMAM or INFRAM or CISOM
          pageData = find(pages, { pageId: Number(currPageId) });

          //get all questions for that page: loop through tabs, for each tab that has this pageid, get all questions that have that tabid from questionsinsurvey
          tabs.map(function (currTab) {
            //this is gross but they wanted a very specific set of questions to show up.  Adding ticket to AHA to add a copy/paste flag on the tab/survey level
            if (currTab.pageId === pageData.pageId && (survey.surveyType.name != 'DigitalRadar' || (survey.surveyType.name === 'DigitalRadar' && pageData.pageId != 252) || (survey.surveyType.name === 'DigitalRadar' && pageData.pageId === 252 && currTab.tabId === 288))) {
              var pageQuestions = [];
              questionsInSurvey.map(function (currQuestion) {
                if (currQuestion.tabID === currTab.tabId) {
                  //build array of questions
                  pageQuestions.push(currQuestion.questionID);
                }
              });
              if (pageQuestions.length > 0) {
                //used tabId instead of pageID
                questionGroups.push({ pageID: currTab.tabId, pageName: pageData.pageName + ' - ' + currTab.tabName, questions: pageQuestions });
              }
              //var questionGroup = questionGroups.find((qg) => qg.pageID == currTab.pageId);
              //if (questionGroup) {
              //  questionGroup.questions.push(...pageQuestions);
              //} else {
              //  questionGroups.push({ pageID: pageData.pageId, pageName: pageData.pageName, questions: pageQuestions });
              //}
            }
          });
        }
      });
    }

    console.log('questionGroups: ', questionGroups);
    //need checkbox list that shows each page name, when they select that page, we need to send all questions from that page to the API

    this.state = {
      activeStep: 1,
      selectedEntities: [],
      selectByTechnologiesGroup:
        survey.surveyType.name === 'AMAM'
          ? 'AMAM Pages'
          : survey.surveyType.name === 'AMAM 2024'
          ? 'AMAM 2024 Pages'
          : survey.surveyType.name === 'INFRAM'
          ? 'INFRAM Pages'
          : survey.surveyType.name === 'CISOM'
          ? 'CISOM Pages'
          : survey.surveyType.name === 'DigitalRadar'
          ? 'DigitalRadar Pages'
          : survey.surveyType.name === 'DIAM'
          ? 'DIAM Pages'
          : survey.surveyType.name === 'CCMM'
          ? 'CCMM Pages'
          : survey.surveyType.name === 'EMRAM 2022'
          ? 'EMRAM 2022 Pages'
          : survey.surveyType.name === 'C-COMM'
          ? 'C-COMM Pages'
          : survey.surveyType.name === 'INFRAM 2024'
          ? 'INFRAM 2024 Pages'
          : 'Technology Group',
      selectByHardwareAndTech: null,
      selectedTechnologies: [],
      selectedTechnologyGroups: [],
      selectedAMAM_INFRAMGroups: [],
      surveyQuestionGroups: questionGroups,
      questionsInSurvey: questionsInSurvey,
      selectedHardwareAndTech: [],
      copyCurrentInstallations: false,
      copyCurrentInstallationDetails: false,
      copyPlansToPurchase: false,
      geoState: 'All',
      emrVendor: 'All',
      entitiesList: filteredEntities,
      isTechnologyGroupSelected: true,
      isTechnologySelected: false,
      isHardwareAndTechSelected: false,
      isLoading: false,
    };

    this.handleClose = this.handleClose.bind(this);
    this.isEntitySelected = this.isEntitySelected.bind(this);
    this.handleSelectEntity = this.handleSelectEntity.bind(this);
    this.handleSelectAllEntities = this.handleSelectAllEntities.bind(this);

    this.isTechnologyGroupSelected = this.isTechnologyGroupSelected.bind(this);
    this.handleSelectTechnologyGroup = this.handleSelectTechnologyGroup.bind(this);
    this.handleSelectAllTechnologyGroups = this.handleSelectAllTechnologyGroups.bind(this);

    this.isTechnologySelected = this.isTechnologySelected.bind(this);
    this.handleSelectTechnology = this.handleSelectTechnology.bind(this);
    this.handleSelectAllTechnologies = this.handleSelectAllTechnologies.bind(this);

    this.handleCopyData = this.handleCopyData.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.updateEntitiesList = this.updateEntitiesList.bind(this);

    this.getBranchingQuestions = this.getBranchingQuestions.bind(this);
    this.getEmrVendorList = this.getEmrVendorList.bind(this);
    this.getAnswers = this.getAnswers.bind(this);
  }

  handleClose() {
    this.props.onClose();
  }

  handleNext = () => {
    this.setState({ activeStep: this.state.activeStep + 1 });
  };

  handleBack = () => {
    this.setState({ activeStep: this.state.activeStep - 1 });
  };

  isEntitySelected = (id) => {
    return this.state.selectedEntities.indexOf(id) !== -1;
  };

  handleSelectEntity = (event, id) => {
    const { selectedEntities } = this.state;
    const selectedIndex = selectedEntities.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedEntities, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedEntities.slice(1));
    } else if (selectedIndex === selectedEntities.length - 1) {
      newSelected = newSelected.concat(selectedEntities.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selectedEntities.slice(0, selectedIndex), selectedEntities.slice(selectedIndex + 1));
    }

    this.setState({ selectedEntities: newSelected });
  };

  handleSelectAllEntities = (event, entities) => {
    if (event.target.checked) {
      this.setState({ selectedEntities: entities.map((entity) => entity.entityID) });
      return;
    }
    this.setState({ selectedEntities: [] });
  };

  isTechnologyGroupSelected = (id) => {
    return this.state.selectedTechnologyGroups.indexOf(id) !== -1;
  };

  isAMAM_INFRAMGroupSelected = (id) => {
    return this.state.selectedAMAM_INFRAMGroups.indexOf(id) !== -1;
  };

  isTechnologySelected = (id) => {
    return this.state.selectedTechnologies.indexOf(id) !== -1;
  };

  isHardwareAndTechSelected = (id) => {
    return this.state.selectedHardwareAndTech.indexOf(id) !== -1;
  };

  handleSelectTechnologyGroup = (event, id) => {
    const { selectedTechnologyGroups } = this.state;
    const selectedIndex = selectedTechnologyGroups.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedTechnologyGroups, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedTechnologyGroups.slice(1));
    } else if (selectedIndex === selectedTechnologyGroups.length - 1) {
      newSelected = newSelected.concat(selectedTechnologyGroups.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selectedTechnologyGroups.slice(0, selectedIndex), selectedTechnologyGroups.slice(selectedIndex + 1));
    }

    this.setState({ selectedTechnologyGroups: newSelected });
  };

  handleSelectAMAM_INFRAMGroup = (event, id) => {
    const { selectedAMAM_INFRAMGroups } = this.state;
    const selectedIndex = selectedAMAM_INFRAMGroups.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedAMAM_INFRAMGroups, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedAMAM_INFRAMGroups.slice(1));
    } else if (selectedIndex === selectedAMAM_INFRAMGroups.length - 1) {
      newSelected = newSelected.concat(selectedAMAM_INFRAMGroups.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selectedAMAM_INFRAMGroups.slice(0, selectedIndex), selectedAMAM_INFRAMGroups.slice(selectedIndex + 1));
    }

    this.setState({ selectedAMAM_INFRAMGroups: newSelected });
  };

  handleSelectAllAMAM_INFRAMGroups = (event, amamGroups) => {
    if (event.target.checked) {
      this.setState({ selectedAMAM_INFRAMGroups: amamGroups.map((amamGroup) => amamGroup.pageID) });
      return;
    }
    this.setState({ selectedAMAM_INFRAMGroups: [] });
  };

  handleSelectAllTechnologyGroups = (event, technologyGroups) => {
    if (event.target.checked) {
      this.setState({ selectedTechnologyGroups: technologyGroups.map((technologyGroup) => technologyGroup.technologyGroupID) });
      return;
    }
    this.setState({ selectedTechnologyGroups: [] });
  };

  handleSelectTechnology = (event, id) => {
    const { selectedTechnologies } = this.state;
    const selectedIndex = selectedTechnologies.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedTechnologies, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedTechnologies.slice(1));
    } else if (selectedIndex === selectedTechnologies.length - 1) {
      newSelected = newSelected.concat(selectedTechnologies.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selectedTechnologies.slice(0, selectedIndex), selectedTechnologies.slice(selectedIndex + 1));
    }

    this.setState({ selectedTechnologies: newSelected });
  };

  handleSelectAllTechnologies = (event, technologies) => {
    if (event.target.checked) {
      this.setState({ selectedTechnologies: technologies.map((technology) => technology.technologyID) });
      return;
    }
    this.setState({ selectedTechnologies: [] });
  };

  handleSelectHardwareAndTech = (event, id) => {
    const { selectedHardwareAndTech } = this.state;
    const selectedIndex = selectedHardwareAndTech.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedHardwareAndTech, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedHardwareAndTech.slice(1));
    } else if (selectedIndex === selectedHardwareAndTech.length - 1) {
      newSelected = newSelected.concat(selectedHardwareAndTech.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selectedHardwareAndTech.slice(0, selectedIndex), selectedHardwareAndTech.slice(selectedIndex + 1));
    }

    //selectedHardwareAndTech needs to be all selected HW and tech questions
    this.setState({ selectedHardwareAndTech: newSelected });
  };

  handleSelectAllHardwareAndTech = (event, questions) => {
    if (event.target.checked) {
      this.setState({ selectedHardwareAndTech: questions.map((question) => question.questionID) });
      return;
    }
    this.setState({ selectedHardwareAndTech: [] });
  };

  handleCopyData = async (technologiesInSurvey) => {
    let technologies = [];
    let questions = [];
    if (this.state.selectByTechnologiesGroup === 'Technology Group') {
      //Convert the selected technology group IDs to technology IDs to send to the API
      this.state.selectedTechnologyGroups.forEach((technologyGroupID) => {
        technologies = technologies.concat(technologiesInSurvey.filter((technology) => technology.technologyGroupID === technologyGroupID).map((technology) => technology.technologyID));
      });
    }

    if (this.state.selectByTechnologiesGroup === 'Technology') {
      technologies = [...this.state.selectedTechnologies];
    }

    if (this.state.selectByTechnologiesGroup === 'Hardware & Technology') {
      questions = this.state.selectedHardwareAndTech;
    }

    if (
      this.state.selectByTechnologiesGroup === 'AMAM Pages' ||
      this.state.selectByTechnologiesGroup === 'AMAM 2024 Pages' ||
      this.state.selectByTechnologiesGroup === 'INFRAM Pages' ||
      this.state.selectByTechnologiesGroup === 'CISOM Pages' ||
      this.state.selectByTechnologiesGroup === 'DIAM Pages' ||
      this.state.selectByTechnologiesGroup === 'DigitalRadar Pages' ||
      this.state.selectByTechnologiesGroup === 'CCMM Pages' ||
      this.state.selectByTechnologiesGroup === 'EMRAM 2022 Pages' ||
      this.state.selectByTechnologiesGroup === 'C-COMM Pages' ||
      this.state.selectByTechnologiesGroup === 'INFRAM 2024 Pages'
    ) {
      // this.state.selectedAMAMGroups is an array of page ids, so we need to get question ids for that page id (questions = will just be question ids from the pages)

      const selectedAMAM_INFRAMPages = this.state.selectedAMAM_INFRAMGroups;
      const allSurveyPages = this.state.surveyQuestionGroups;

      allSurveyPages.map(function (currPage) {
        if (selectedAMAM_INFRAMPages.includes(currPage.pageID)) {
          //get all questions from that currPage
          currPage.questions.map(function (currPage) {
            questions.push(currPage);
          });
        }
      });
    }

    let data = {};

    if (this.state.selectByTechnologiesGroup === 'Technology' || this.state.selectByTechnologiesGroup === 'Technology Group') {
      data = {
        surveyID: this.props.survey.surveyID,
        copyFrom: this.props.copyFromEntity.entityID,
        copyTo: this.state.selectedEntities,
        technologies,
        copyCurrentInstallations: this.state.copyCurrentInstallations,
        copyCurrentInstallationDetails: this.state.copyCurrentInstallationDetails,
        copyPlansToPurchase: this.state.copyPlansToPurchase,
      };
    } else {
      data = {
        surveyID: this.props.survey.surveyID,
        copyFrom: this.props.copyFromEntity.entityID,
        copyTo: this.state.selectedEntities,
        questions,
        copyCurrentInstallations: this.state.copyCurrentInstallations,
        copyCurrentInstallationDetails: this.state.copyCurrentInstallationDetails,
        copyPlansToPurchase: this.state.copyPlansToPurchase,
      };
    }

    let response = await this.props.copyData(data);

    if (response.type === 'COPY_DATA_SUCCESS') {
      this.props.handleToastMessage('Survey data copied successfully.', false);
      this.handleClose();
      //Re-fetch the survey to fetch all the copied data
      this.props.getSurveys();
    } else {
      this.props.handleToastMessage('Survey data copy failed to save!', true);
    }

    //reload the
    window.location.reload();
  };

  handleSelectChange(event) {
    const { name, value } = event.target;

    this.setState({ [name]: value }, () => {
      this.updateEntitiesList();
    });
  }

  updateEntitiesList() {
    const { copyFromEntity, survey } = this.props;
    const { emrVendor, geoState } = this.state;
    const { questions, entities } = this.props.survey;

    if (geoState === 'All' && emrVendor === 'All') {
      let allFilteredEntities = entities.filter((e) => {
        return e.haEntityTypeID === copyFromEntity.haEntityTypeID && e.entityID !== copyFromEntity.entityID;
      });

      this.setState({ entitiesList: allFilteredEntities });
    } else {
      if (geoState != 'All') {
        if (emrVendor != 'All') {
          //both have values
          //create an array with all entities filtered on state:

          let allFilteredEntities = entities.filter((e) => {
            return e.haEntityTypeID === copyFromEntity.haEntityTypeID && e.entityID !== copyFromEntity.entityID;
          });
          const addressQuestion = questions.find((q) => q.mappingGroupAttribute == '-5');
          const addressQuestionId = addressQuestion.questionID;
          //when state filter is changed, we need to update state.entitiesList to just have facilities that are in that state

          let newEntities = allFilteredEntities.filter((e) => {
            var addressDetails = this.getAnswers(e.data.questions, addressQuestionId); //example ["19"]
            if (addressDetails.length > 0) {
              const item = JSON.parse(addressDetails[0]);
              if (item) {
                return item.stateID === geoState;
              }
            }
            return false;
          });

          //now use newEntities and filter that on EMR Vendor
          let vendorQuestionId = 177;
          let emrTechnologyId = 31;

          if (survey.surveyType.surveyTypeID === 8) {
            vendorQuestionId = 169;
            emrTechnologyId = 1;
          }

          let filteredNewEntities = [];
          newEntities.map(function (entity) {
            let entityVendorID = '';

            const techArray = entity.data.technologyQuestions[emrTechnologyId][vendorQuestionId].answer[0];
            if (techArray) {
              const entityVendorArray = techArray.split(',');
              entityVendorID = JSON.parse(entityVendorArray[1]);

              if (entityVendorID == emrVendor) {
                filteredNewEntities.push(entity);
              }
            }
          });

          this.setState({ entitiesList: filteredNewEntities });
        } else {
          //geoState has value, emrVendor is All
          const addressQuestion = questions.find((q) => q.mappingGroupAttribute == '-5');
          const addressQuestionId = addressQuestion.questionID;

          let allFilteredEntities = entities.filter((e) => {
            return e.haEntityTypeID === copyFromEntity.haEntityTypeID && e.entityID !== copyFromEntity.entityID;
          });

          //when state filter is changed, we need to update state.entitiesList to just have facilities that are in that state
          let newEntities = allFilteredEntities.filter((e) => {
            var addressDetails = this.getAnswers(e.data.questions, addressQuestionId); //example ["19"]
            if (addressDetails.length > 0) {
              const item = JSON.parse(addressDetails[0]);
              if (item) {
                return item.stateID === geoState;
              }
            }
            return false;
          });
          this.setState({ entitiesList: newEntities });
        }
      } else {
        //geoState is All
        if (emrVendor != 'All') {
          //geoState is All and emrVendor has a value

          let vendorQuestionId = 177;
          let emrTechnologyId = 31;

          if (survey.surveyType.surveyTypeID === 8) {
            vendorQuestionId = 169;
            emrTechnologyId = 1;
          }

          let newEntities = [];

          let allFilteredEntities = entities.filter((e) => {
            return e.haEntityTypeID === copyFromEntity.haEntityTypeID && e.entityID !== copyFromEntity.entityID;
          });

          allFilteredEntities.map(function (entity) {
            let entityVendorID = '';

            const techArray = entity.data.technologyQuestions[emrTechnologyId][vendorQuestionId].answer[0];
            if (techArray) {
              const entityVendorArray = techArray.split(',');
              entityVendorID = JSON.parse(entityVendorArray[1]);

              if (entityVendorID == emrVendor) {
                newEntities.push(entity);
              }
            }
          });

          this.setState({ entitiesList: newEntities });
        }
      }
    }
  }

  //get all branching questions  and add them to allHardwareTechQuestionsInSurvey
  getBranchingQuestions(hardwareAndTechQuestions, survey) {
    //see if the hardware and tech question has branching questions, if so return the branching questions as an array for each hardwareAndTechQuestion
    let allHardwareAndTechQuestions = [];

    //for each hardwareAndTechQuestions see if there are branching questions,
    Object.values(hardwareAndTechQuestions).map((hardWareQuestion) => {
      allHardwareAndTechQuestions.push(hardWareQuestion);

      Object.values(hardWareQuestion.branchingQuestions).map((branchingQuestion) => {
        //console.log('inner branchingQuestion', branchingQuestion); //this is an array like [40, 41], each of these is a branching question id
        branchingQuestion.map(function (bQ) {
          if (bQ) {
            let branchingQuestion = find(survey.questions, { questionID: parseInt(bQ) });
            if (allHardwareAndTechQuestions.indexOf(branchingQuestion) === -1) {
              allHardwareAndTechQuestions.push(branchingQuestion);
            }
          }
        });
      });
    });

    return allHardwareAndTechQuestions;
  }

  //generate the EMR Vendor filter list
  getEmrVendorList(entities) {
    const { survey, vendorsList } = this.props;

    var uniqueVendorList = [];
    uniqueVendorList.push({ label: 'All', value: 'All' });

    if (survey.surveyType.name !== 'AMAM' && survey.surveyType.name !== 'INFRAM' && survey.surveyType.name !== 'CISOM' && survey.surveyType.name !== 'DIAM') {
      //hard coded for now
      let vendorQuestionId = 177;
      let emrTechnologyId = 31;

      if (survey.surveyType.surveyTypeID === 8) {
        vendorQuestionId = 169;
        emrTechnologyId = 31; //was 1 -- this was updated to 31 for HAD-1603
      }

      //build the List of Vendors for the Vendor Select Filter
      const vendorSelectList = [];
      vendorSelectList.push({ label: 'All', value: 'All' });
      entities.map(function (entity) {
        let entityVendorID = '';
        let vendorData = '';
        let vendorName = '';

        //HOW TO GET emr Tech Questions Programatically:
        //get all questions from survey.technologyQuestions which has mappingGroupAttribute== -1
        const negOneTechQuestions = [];

        survey.technologyQuestions.map(function (currSurvey) {
          if (currSurvey.mappingGroupAttribute == '-1') {
            negOneTechQuestions.push(currSurvey);
          }
        });
        //Go to survey.surveyType.technologyQuestions and filter the questions by technologyID 31 (emr Technology Id)
        const emrTechQuestions = [];

        survey.surveyType.technologyQuestions.map(function (techQuestion) {
          if (techQuestion.technologyID == 31) {
            emrTechQuestions.push(techQuestion);
          }
        });
       
        //now filter emrTechQuestions based on negOneTechQuestions
        //END HOW TO GET emr Tech Questions Programatically:

        //looking for entity.data.technologyQuestions[1][169]

        //console.log('emrTechQuestionss: ', emrTechQuestions);

        //console.log('entity.data.technologyQuestions: ', entity.data.technologyQuestions);

        const techArray = entity.data.technologyQuestions[emrTechnologyId] ? entity.data.technologyQuestions[emrTechnologyId][vendorQuestionId].answer[0] : '';
        //const techArray = entity.data.technologyQuestions[emrTechnologyId][vendorQuestionId].answer[0];

        if (techArray) {
          const entityVendorArray = techArray.split(',');
          entityVendorID = JSON.parse(entityVendorArray[1]);

          if (entityVendorID != '') {
            vendorData = find(vendorsList, { value: entityVendorID });
            if (vendorData) {
              vendorName = vendorData.label;
              vendorSelectList.push({ label: vendorName, value: entityVendorID });
            }
          }
        }
      });

      uniqueVendorList = Array.from(new Set(vendorSelectList.map((a) => a.value))).map((value) => {
        return vendorSelectList.find((a) => a.value === value);
      });
    }

    return uniqueVendorList;
  }
  getAnswers(questionData, questionID) {
    var answer = [];
    Object.keys(questionData).forEach((k) => {
      if (k == questionID) {
        answer = questionData[questionID].answer;
      }
      if (questionData[k].questions.length > 0) {
        answer = this.getAnswers(questionData[k].questions, questionID);
      }
    });
    return answer;
  }
  render() {
    const {
      activeStep,
      selectedEntities,
      selectByTechnologiesGroup,
      selectedTechnologies,
      selectedTechnologyGroups,
      selectedAMAM_INFRAMGroups,
      selectedHardwareAndTech,
      copyCurrentInstallations,
      copyCurrentInstallationDetails,
      copyPlansToPurchase,
      geoState,
      emrVendor,
      entitiesList,
    } = this.state;
    const { classes, isLoading, open, copyFromEntity, survey, technologies, organizationTypes, isSaving, vendorsList, tabs, pages, stateList } = this.props;

    const { entities } = survey;
    const { questions } = survey;

    //console.log('tabs: ', tabs);
    //console.log('pages: ', pages);

    //let stateQuestion = questions.filter(q=>q.questionDescription=='State');
    const addressQuestion = questions.find((q) => q.mappingGroupAttribute == '-5');
    const addressQuestionId = addressQuestion.questionID;

    //const surveyAnswerListStateValues =  survey.answers.filter(q=>q.answerID==stateAnswerId);
    const states = [];
    states.push({ label: 'All', value: 'All' });
    entities.map((entity) => {
      var addressDetails = this.getAnswers(entity.data.questions, addressQuestionId); //example ["19"]
      addressDetails.map((a) => {
        const item = JSON.parse(a);
        if (item.stateID) {
          const stateItem = stateList.find((s) => Number(s.geographicalRegionID) === Number(item.stateID));
          if (stateItem) {
            states.push({ label: stateItem.geographicalRegion, value: item.stateID });
          }
        }
      });
      // value: i.geographicalRegionID.toString(), label: i.geographicalRegion
      //for USA Health System entityAnswerIdForStateList is [], so adding the following check
      //var entityTextValueForStateList = '';
      //if (entityAnswerIdForStateList !== undefined && entityAnswerIdForStateList.length != 0) {
      //  entityTextValueForStateList = stateList.find(s => s.geographicalRegionID.toString() == entityAnswerIdForStateList); //surveyAnswerListStateValues[0].answers.filter(q=>q.value==entityAnswerIdForStateList);
      //  entityTextValueForStateList = entityTextValueForStateList.geographicalRegion;
      //}

      //states.push({ label: entityTextValueForStateList, value: entityAnswerIdForStateList[0] });
    });

    //eliminate dupes from states
    const uniqueStates = Array.from(new Set(states.map((a) => a.value))).map((value) => {
      return states.find((a) => a.value === value);
    });

    //DW bugfix for techgroups not copying, changed:  technologyGroupID: key  to: technologyGroupID: value[0].technologyGroupID,
    let technologyGroups = Object.values(groupBy(technologies, (x) => x.technologyGroupID)).map((value, key) => ({
      technologyGroupID: value[0].technologyGroupID,
      technologyGroup: value[0].technologyGroup,
      technologies: value,
    }));

    let technologiesInSurvey = Object.keys(copyFromEntity.data.technologyQuestions).map((technologyID) => {
      return find(technologies, { technologyID: parseInt(technologyID) });
    });

    //using hard coded mappingGroupName: "10" to get questions in the Hardware and Technology Tab for now
    let hardwareTechQuestionsInSurvey = this.state.questionsInSurvey.filter((tg) => {
      return tg.mappingGroupName === '10';
    });

    let allHardwareAndTechQuestions = this.getBranchingQuestions(hardwareTechQuestionsInSurvey, survey); //this returns an array if branching questionIds for teh HW and tech question passed in

    //Uncomment to test all technology groups
    //technologiesInSurvey = technologies;
    let technologyGroupsInSurvey = technologyGroups.filter((tg) => {
      //Check to make sure at least one technology in the group is in the survey before showing it
      return intersection(tg.technologies, technologiesInSurvey).length > 0;
    });

    const uniqueVendorSelectList = this.getEmrVendorList(entities);

    return (
      <Dialog classes={{ paper: classes.dialogPaper }} onClose={this.handleClose} aria-labelledby="simple-dialog-title" open={open}>
        <DialogTitle id="simple-dialog-title" className={classes.title}>
          Copy survey data to other Health System Entities
        </DialogTitle>
        {this.state.isLoading ? (
          <LoadingOverlay />
        ) : (
          <DialogContent>
            {isSaving && <LoadingOverlay />}
            <Stepper activeStep={activeStep} orientation="vertical">
              <Step key="step1">
                <StepLabel>
                  <FormControl className={classes.formControl}>
                    <TextField style={{ width: 400 }} margin="dense" value={copyFromEntity.entityName} disabled={true} variant="outlined" />
                  </FormControl>

                  <FormControl className={classes.formControl}>
                    <TextField margin="dense" value={find(organizationTypes, { organizationTypeID: copyFromEntity.haEntityTypeID }).organizationTypeName} disabled={true} variant="outlined" />
                  </FormControl>
                </StepLabel>
              </Step>
              <Step key="step2">
                <StepLabel>
                  Copy To &nbsp;
                  {activeStep !== 1 &&
                    selectedEntities
                      .map((entityID) => {
                        return find(entitiesList, { entityID }).entityName;
                      })
                      .join(', ')}
                </StepLabel>
                <StepContent>
                  <Paper className={classes.paper} square>
                    <FormControl className={classes.selectControl}>
                      <InputLabel>State</InputLabel>
                      <Select
                        value={geoState}
                        onChange={this.handleSelectChange}
                        inputProps={{
                          name: 'geoState',
                        }}
                        fullWidth
                      >
                        {uniqueStates.map((state) => (
                          <MenuItem key={`${state.value}`} value={state.value}>
                            {state.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <FormControl className={classes.selectControl}>
                      <InputLabel>EMR Vendor</InputLabel>
                      <Select
                        value={emrVendor}
                        onChange={this.handleSelectChange}
                        inputProps={{
                          name: 'emrVendor',
                        }}
                        fullWidth
                      >
                        {uniqueVendorSelectList.map((uniqueVendor) => (
                          <MenuItem key={`${uniqueVendor.value}`} value={uniqueVendor.value}>
                            {uniqueVendor.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <Table className={classes.table}>
                      <TableHead>
                        <TableRow>
                          <TableCell padding="checkbox">
                            <Checkbox
                              indeterminate={selectedEntities.length > 0 && selectedEntities.length < entitiesList.length}
                              checked={selectedEntities.length === entitiesList.length}
                              onChange={(event) => this.handleSelectAllEntities(event, entitiesList)}
                            />
                          </TableCell>
                          <TableCell>Entity Name</TableCell>
                          <TableCell>Entity ID</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {entitiesList.map((entity) => {
                          const isEntitySelected = this.isEntitySelected(entity.entityID);
                          return (
                            <TableRow hover onClick={(event) => this.handleSelectEntity(event, entity.entityID)} role="checkbox" key={entity.entityID} selected={isEntitySelected}>
                              <TableCell padding="checkbox">
                                <Checkbox checked={isEntitySelected} />
                              </TableCell>
                              <TableCell>{entity.entityName}</TableCell>
                              <TableCell>{entity.entityID}</TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                    <div className={classes.actionsContainer}>
                      <div>
                        <Button variant="contained" color="primary" onClick={this.handleNext} disabled={selectedEntities.length === 0} className={classes.button}>
                          Next
                        </Button>
                      </div>
                    </div>
                  </Paper>
                </StepContent>
              </Step>
              <Step key="step3">
                <StepLabel>
                  Copy
                  {selectByTechnologiesGroup === 'Hardware & Technology'
                    ? ' Hardware and Technologies: '
                    : selectByTechnologiesGroup === 'AMAM Pages'
                    ? ' AMAM Pages: '
                    : selectByTechnologiesGroup === 'INFRAM Pages'
                    ? ' INFRAM Pages: '
                    : selectByTechnologiesGroup === 'CISOM Pages'
                    ? ' CISOM Pages: '
                    : selectByTechnologiesGroup === 'DIAM Pages'
                    ? ' DIAM Pages: '
                    : selectByTechnologiesGroup === 'DigitalRadar'
                    ? ' DigitalRadar Pages: '
                    : selectByTechnologiesGroup === 'CCMM'
                    ? ' CCMM Pages: '
                    : selectByTechnologiesGroup === 'EMRAM 2022'
                    ? ' EMRAM 2022 Pages: '
                    : selectByTechnologiesGroup === 'C-COMM'
                    ? ' C-COMM Pages: '
                    : selectByTechnologiesGroup === 'INFRAM 2024'
                    ? ' INFRAM 2024 Pages: '
                    : selectByTechnologiesGroup === 'AMAM 2024'
                    ? ' AMAM 2024 Pages: '
                    : ' Technologies: '}
                  &nbsp;
                  {activeStep !== 2 &&
                    (selectByTechnologiesGroup === 'Technology Group'
                      ? selectedTechnologyGroups
                          .map((technologyGroupID) => {
                            return find(technologyGroupsInSurvey, { technologyGroupID }).technologyGroup;
                          })
                          .join(', ')
                      : selectByTechnologiesGroup === 'Hardware & Technology'
                      ? selectedHardwareAndTech
                          .map((questionID) => {
                            return find(allHardwareAndTechQuestions, { questionID }).questionDescription;
                          })
                          .join(', ')
                      : selectByTechnologiesGroup === 'AMAM Pages' ||
                        selectByTechnologiesGroup === 'AMAM 2024 Pages' || 
                        selectByTechnologiesGroup === 'INFRAM Pages' ||
                        selectByTechnologiesGroup === 'CISOM Pages' ||
                        selectByTechnologiesGroup === 'DIAM Pages' ||
                        selectByTechnologiesGroup === 'DigitalRadar Pages' ||
                        selectByTechnologiesGroup === 'CCMM Pages' ||
                        selectByTechnologiesGroup === 'C-COMM Pages' ||
                        selectByTechnologiesGroup === 'EMRAM 2022 Pages' ||
                        selectByTechnologiesGroup === 'INFRAM 2024 Pages'
                      ? selectedAMAM_INFRAMGroups
                          .map((pageID) => {
                            return find(this.state.surveyQuestionGroups, { pageID }).pageName;
                          })
                          .join(', ')
                      : selectedTechnologies
                          .map((technologyID) => {
                            return find(technologiesInSurvey, { technologyID }).technologyName;
                          })
                          .join(', '))}
                </StepLabel>
                <StepContent>
                  <Paper className={classes.paper} square>
                    <FormControl component="fieldset" className={classes.formControl}>
                      <FormLabel component="legend">Select By </FormLabel>
                      <RadioGroup
                        aria-label="Gender"
                        name="gender1"
                        className={classes.group}
                        value={this.state.selectByTechnologiesGroup} //this is now "Technology Group", "Technology" or "Hardware & Technology"
                        onChange={(event) => this.setState({ selectByTechnologiesGroup: event.target.value })}
                      >
                        <FormControlLabel value="Technology Group" control={<Radio />} label="Technology Group" disabled={technologyGroupsInSurvey.length === 0} />
                        <FormControlLabel value="Technology" control={<Radio />} label="Technology" disabled={technologiesInSurvey.length === 0} />
                        <FormControlLabel value="Hardware & Technology" control={<Radio />} label="Hardware & Technology" disabled={allHardwareAndTechQuestions.length === 0} />
                        <FormControlLabel
                          value={
                            this.state.selectByTechnologiesGroup === 'AMAM Pages'
                              ? 'AMAM Pages'
                              : this.state.selectByTechnologiesGroup === 'AMAM 2024 Pages'
                              ? 'AMAM 2024 Pages'
                              : this.state.selectByTechnologiesGroup === 'CISOM Pages'
                              ? 'CISOM Pages'
                              : this.state.selectByTechnologiesGroup === 'DIAM Pages'
                              ? 'DIAM Pages'
                              : this.state.selectByTechnologiesGroup === 'DigitalRadar Pages'
                              ? 'DigitalRadar Pages'
                              : this.state.selectByTechnologiesGroup === 'EMRAM 2022 Pages'
                              ? 'EMRAM 2022 Pages'
                              : this.state.selectByTechnologiesGroup === 'CCMM Pages'
                              ? 'CCMM Pages'
                              : this.state.selectByTechnologiesGroup === 'C-COMM Pages'
                              ? 'C-COMM Pages'
                              : this.state.selectByTechnologiesGroup === 'INFRAM 2024 Pages'
                              ? 'INFRAM 2024 Pages'
                              : 'INFRAM Pages'
                          }
                          control={<Radio />}
                          label="AMAM/INFRAM/CISOM/DIAM/Digital Radar/EMRAM 2022/CCMM/C-COMM/INFRAM 2024 Pages/AMAM 2024 Pages"
                          disabled={this.state.surveyQuestionGroups.length === 0}
                        />
                      </RadioGroup>
                    </FormControl>

                    {selectByTechnologiesGroup === 'Technology Group' && (
                      <Table className={classes.table}>
                        <TableHead>
                          <TableRow>
                            <TableCell padding="checkbox">
                              <Checkbox
                                indeterminate={selectedTechnologyGroups.length > 0 && selectedTechnologyGroups.length < technologyGroupsInSurvey.length}
                                checked={selectedTechnologyGroups.length === technologyGroupsInSurvey.length}
                                onChange={(event) => this.handleSelectAllTechnologyGroups(event, technologyGroupsInSurvey)}
                              />
                            </TableCell>
                            <TableCell>Technology Group</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {technologyGroupsInSurvey.map((technologyGroup) => {
                            const isTechnologyGroupSelected = this.isTechnologyGroupSelected(technologyGroup.technologyGroupID);
                            return (
                              <TableRow
                                hover
                                onClick={(event) => this.handleSelectTechnologyGroup(event, technologyGroup.technologyGroupID)}
                                role="checkbox"
                                key={technologyGroup.technologyGroupID}
                                selected={isTechnologyGroupSelected}
                              >
                                <TableCell padding="checkbox">
                                  <Checkbox checked={isTechnologyGroupSelected} />
                                </TableCell>
                                <TableCell>{technologyGroup.technologyGroup}</TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    )}

                    {selectByTechnologiesGroup === 'Hardware & Technology' && (
                      <Table className={classes.table}>
                        <TableHead>
                          <TableRow>
                            <TableCell padding="checkbox">
                              <Checkbox
                                indeterminate={selectedHardwareAndTech.length > 0 && selectedHardwareAndTech.length < allHardwareAndTechQuestions.length}
                                checked={selectedHardwareAndTech.length === allHardwareAndTechQuestions.length}
                                onChange={(event) => this.handleSelectAllHardwareAndTech(event, allHardwareAndTechQuestions)}
                              />
                            </TableCell>
                            <TableCell>Hardware and Technology Question</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {allHardwareAndTechQuestions.map((hardwareQuestion) => {
                            const isHardwareAndTechSelected = this.isHardwareAndTechSelected(hardwareQuestion.questionID);
                            return (
                              <TableRow
                                hover
                                onClick={(event) => this.handleSelectHardwareAndTech(event, hardwareQuestion.questionID)}
                                role="checkbox"
                                key={hardwareQuestion.questionID}
                                selected={isHardwareAndTechSelected}
                              >
                                <TableCell padding="checkbox">
                                  <Checkbox checked={isHardwareAndTechSelected} />
                                </TableCell>
                                <TableCell>{hardwareQuestion.questionDescription}</TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    )}

                    {selectByTechnologiesGroup === 'Technology' && (
                      <Table className={classes.table}>
                        <TableHead>
                          <TableRow>
                            <TableCell padding="checkbox">
                              <Checkbox
                                indeterminate={selectedTechnologies.length > 0 && selectedTechnologies.length < technologiesInSurvey.length}
                                checked={selectedTechnologies.length === technologiesInSurvey.length}
                                onChange={(event) => this.handleSelectAllTechnologies(event, technologiesInSurvey)}
                              />
                            </TableCell>
                            <TableCell>Technology Name</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {technologiesInSurvey.map((technology) => {
                            const isTechnologySelected = this.isTechnologySelected(technology.technologyID);
                            return (
                              <TableRow
                                hover
                                onClick={(event) => this.handleSelectTechnology(event, technology.technologyID)}
                                role="checkbox"
                                key={technology.technologyID}
                                selected={isTechnologySelected}
                              >
                                <TableCell padding="checkbox">
                                  <Checkbox checked={isTechnologySelected} />
                                </TableCell>
                                <TableCell>{technology.technologyName}</TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    )}

                    {(selectByTechnologiesGroup === 'AMAM Pages' ||
                      selectByTechnologiesGroup === 'AMAM 2024 Pages' ||
                      selectByTechnologiesGroup === 'INFRAM Pages' ||
                      selectByTechnologiesGroup === 'CISOM Pages' ||
                      selectByTechnologiesGroup === 'DigitalRadar Pages' ||
                      selectByTechnologiesGroup === 'EMRAM 2022 Pages' ||
                      selectByTechnologiesGroup === 'CCMM Pages' ||
                      selectByTechnologiesGroup === 'C-COMM Pages' ||
                      selectByTechnologiesGroup === 'INFRAM 2024 Pages' ||
                      selectByTechnologiesGroup === 'DIAM Pages') && (
                      <Table className={classes.table}>
                        <TableHead>
                          <TableRow>
                            <TableCell padding="checkbox">
                              <Checkbox
                                indeterminate={selectedAMAM_INFRAMGroups.length > 0 && selectedAMAM_INFRAMGroups.length < this.state.surveyQuestionGroups.length}
                                checked={selectedAMAM_INFRAMGroups.length === this.state.surveyQuestionGroups.length}
                                onChange={(event) => this.handleSelectAllAMAM_INFRAMGroups(event, this.state.surveyQuestionGroups)}
                              />
                            </TableCell>
                            {selectByTechnologiesGroup === 'AMAM Pages' && <TableCell>AMAM Page</TableCell>}
                            {selectByTechnologiesGroup === 'AMAM 2024 Pages' && <TableCell>AMAM 2024 Page</TableCell>}
                            {selectByTechnologiesGroup === 'INFRAM Pages' && <TableCell>INFRAM Page</TableCell>}
                            {selectByTechnologiesGroup === 'CISOM Pages' && <TableCell>CISOM Page</TableCell>}
                            {selectByTechnologiesGroup === 'DIAM Pages' && <TableCell>DIAM Page</TableCell>}
                            {selectByTechnologiesGroup === 'DigitalRadar Pages' && <TableCell>DigitalRadar Page</TableCell>}
                            {selectByTechnologiesGroup === 'EMRAM 2022 Pages' && <TableCell>EMRAM 2022 Page</TableCell>}
                            {selectByTechnologiesGroup === 'CCMM Pages' && <TableCell>CCMM Page</TableCell>}
                            {selectByTechnologiesGroup === 'C-COMM Pages' && <TableCell>C-COMM Page</TableCell>}
                            {selectByTechnologiesGroup === 'INFRAM 2024 Pages' && <TableCell>INFRAM 2024 Page</TableCell>}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {this.state.surveyQuestionGroups.map((questionGroup) => {
                            const isAMAM_INFRAMGroupSelected = this.isAMAM_INFRAMGroupSelected(questionGroup.pageID);
                            return (
                              <TableRow
                                hover
                                onClick={(event) => this.handleSelectAMAM_INFRAMGroup(event, questionGroup.pageID)}
                                role="checkbox"
                                key={questionGroup.pageID}
                                selected={isAMAM_INFRAMGroupSelected}
                              >
                                <TableCell padding="checkbox">
                                  <Checkbox checked={isAMAM_INFRAMGroupSelected} />
                                </TableCell>
                                <TableCell>{questionGroup.pageName}</TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    )}

                    <div className={classes.actionsContainer}>
                      <div>
                        <Button variant="contained" onClick={this.handleBack} className={classes.button}>
                          Back
                        </Button>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={this.handleNext}
                          disabled={
                            selectByTechnologiesGroup === 'Technology Group'
                              ? selectedTechnologyGroups.length === 0
                              : selectByTechnologiesGroup === 'Technology'
                              ? selectedTechnologies.length === 0
                              : selectByTechnologiesGroup === 'Hardware & Technology'
                              ? selectedHardwareAndTech.length === 0
                              : selectByTechnologiesGroup === 'AMAM Pages' ||
                                selectByTechnologiesGroup === 'AMAM 2024 Pages' ||
                                selectByTechnologiesGroup === 'INFRAM Pages' ||
                                selectByTechnologiesGroup === 'CISOM Pages' ||
                                selectByTechnologiesGroup === 'DigitalRadar Pages' ||
                                selectByTechnologiesGroup === 'EMRAM 2022 Pages' ||
                                selectByTechnologiesGroup === 'CCMM Pages' ||
                                selectByTechnologiesGroup === 'C-COMM Pages' ||
                                selectByTechnologiesGroup === 'INFRAM 2024 Pages' ||
                                selectByTechnologiesGroup === 'DIAM Pages'
                              ? selectedAMAM_INFRAMGroups.length === 0
                              : true
                          }
                          className={classes.button}
                        >
                          Next
                        </Button>
                      </div>
                    </div>
                  </Paper>
                </StepContent>
              </Step>
              {(selectByTechnologiesGroup === 'Technology Group' || selectByTechnologiesGroup === 'Technology') && (
                <Step key="step4">
                  <StepLabel>Select What You Want Copied</StepLabel>
                  <StepContent>
                    <Paper className={classes.paper} square>
                      <div>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={copyCurrentInstallations}
                              onChange={() => this.setState({ copyCurrentInstallations: !copyCurrentInstallations, copyCurrentInstallationDetails: false })}
                            />
                          }
                          label="Copy Current Installations"
                        />
                      </div>
                      <div style={{ marginLeft: '2rem' }}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={copyCurrentInstallationDetails}
                              onChange={() => this.setState({ copyCurrentInstallationDetails: !copyCurrentInstallationDetails })}
                              disabled={!copyCurrentInstallations}
                            />
                          }
                          label="Copy Current Installation Details"
                        />
                      </div>
                      <div>
                        <FormControlLabel
                          control={<Checkbox checked={copyPlansToPurchase} onChange={() => this.setState({ copyPlansToPurchase: !copyPlansToPurchase })} />}
                          label="Copy Plans to Purchase"
                        />
                      </div>

                      <div className={classes.actionsContainer}>
                        <div>
                          <Button variant="contained" onClick={this.handleBack} className={classes.button}>
                            Back
                          </Button>
                        </div>
                      </div>
                    </Paper>
                  </StepContent>
                </Step>
              )}
              {selectByTechnologiesGroup === 'Hardware & Technology' && (
                <Step key="step4">
                  <StepLabel>Confirm</StepLabel>
                  <StepContent>
                    <Paper className={classes.paper} square>
                      <StepLabel>You are copying Hardware and Technology data, to make changes click the back button, or click Copy Data below to proceed.</StepLabel>
                      <div className={classes.actionsContainer}>
                        <div>
                          <Button variant="contained" onClick={this.handleBack} className={classes.button}>
                            Back
                          </Button>
                        </div>
                      </div>
                    </Paper>
                  </StepContent>
                </Step>
              )}
              {(selectByTechnologiesGroup === 'AMAM Pages' ||
                selectByTechnologiesGroup === 'AMAM 2024 Pages' ||
                selectByTechnologiesGroup === 'INFRAM Pages' ||
                selectByTechnologiesGroup === 'CISOM Pages' ||
                selectByTechnologiesGroup === 'DigitalRadar Pages' ||
                selectByTechnologiesGroup === 'EMRAM 2022 Pages' ||
                selectByTechnologiesGroup === 'CCMM Pages' ||
                selectByTechnologiesGroup === 'C-COMM Pages' ||
                selectByTechnologiesGroup === 'INFRAM 2024 Pages' ||
                selectByTechnologiesGroup === 'DIAM Pages') && (
                <Step key="step4">
                  <StepLabel>Confirm</StepLabel>
                  <StepContent>
                    <Paper className={classes.paper} square>
                    <StepLabel>You are copying AMAM, CCMM, CISOM, INFRAM, DIAM, Digital Radar, EMRAM 2022, C-COMM, INFRAM 2024 Page, AMAM 2024 Page data, to make changes click the back button, or click Copy Data below to proceed.</StepLabel>
                      <div className={classes.actionsContainer}>
                        <div>
                          <Button variant="contained" onClick={this.handleBack} className={classes.button}>
                            Back
                          </Button>
                        </div>
                      </div>
                    </Paper>
                  </StepContent>
                </Step>
              )}
            </Stepper>
          </DialogContent>
        )}
        <DialogActions className={classes.dialogActions}>
          <Button
            key="acquire-button"
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={
              selectByTechnologiesGroup === 'Technology Group' || selectByTechnologiesGroup === 'Technology'
                ? () => this.handleCopyData(technologiesInSurvey)
                : selectByTechnologiesGroup === 'Hardware & Technology'
                ? () => this.handleCopyData(allHardwareAndTechQuestions)
                : () => this.handleCopyData(this.state.surveyQuestionGroups)
            }
            disabled={
              copyFromEntity === null ||
              selectedEntities.length === 0 ||
              activeStep != 3 ||
              (selectByTechnologiesGroup === 'Technology Group'
                ? selectedTechnologyGroups.length === 0
                : selectByTechnologiesGroup === 'Technology'
                ? selectedTechnologies.length === 0
                : selectByTechnologiesGroup === 'Hardware & Technology'
                ? selectedHardwareAndTech.length === 0
                : selectByTechnologiesGroup === 'AMAM Pages' ||
                  selectByTechnologiesGroup === 'AMAM 2024 Pages' ||
                  selectByTechnologiesGroup === 'INFRAM Pages' ||
                  selectByTechnologiesGroup === 'CISOM Pages' ||
                  selectByTechnologiesGroup === 'DigitalRadar Pages' ||
                  selectByTechnologiesGroup === 'EMRAM 2022 Pages' ||
                  selectByTechnologiesGroup === 'CCMM Pages' ||
                  selectByTechnologiesGroup === 'DIAM Pages' ||
                  selectByTechnologiesGroup === 'C-COMM Pages' ||
                  selectByTechnologiesGroup === 'INFRAM 2024 Pages'
                ? selectedAMAM_INFRAMGroups.length === 0
                : false) ||
              ((selectByTechnologiesGroup === 'Technology Group' || selectByTechnologiesGroup === 'Technology') && !copyCurrentInstallations && !copyPlansToPurchase)

            }
          >
            COPY DATA
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

CopyDataDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  copyFromEntity: PropTypes.object,
  survey: PropTypes.object.isRequired,
  technologies: PropTypes.array.isRequired,
  organizationTypes: PropTypes.array.isRequired,
  vendorsList: PropTypes.array.isRequired,
  tabs: PropTypes.array.isRequired,
  pages: PropTypes.array.isRequired,
  stateList: PropTypes.array.isRequired,
};

function mapStateToProps(state, props) {
  return {
    isSaving: state.copyData.get('isSaving'),
  };
}

export default withStyles(styles)(
  connect(mapStateToProps, {
    copyData,
    handleToastMessage,
    getSurveys,
  })(CopyDataDialog)
);
