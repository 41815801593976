import React from 'react';
import { Avatar } from '@mui/material';
import { withStyles } from "@mui/styles";

const styles = theme => ({
  tab: {
    margin: '1rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  avatar: {
    width: '20px',
    height: '20px',
    fontSize: '12px',
    marginRight: '10px'
  }
});
const CustomSurveyTab = ({ classes, index, label, selected }) => {
  const backgroundColor = selected ? '#004565' : '#bdbdbd';

  return (
    <div className={classes.tab}>
      <Avatar className={classes.avatar} style={{ backgroundColor }}>
        {index}
      </Avatar>
      {label}
    </div>
  );
};
export default withStyles(styles)(CustomSurveyTab);
