import { FilteringState, IntegratedFiltering, IntegratedPaging, PagingState, SelectionState } from '@devexpress/dx-react-grid';
import { Grid, PagingPanel, Table, TableFilterRow, TableHeaderRow, TableSelection } from '@devexpress/dx-react-grid-material-ui';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import { withStyles } from "@mui/styles";
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

const styles = theme => ({
  appBar: {
    marginBottom: theme.spacing(4)
  },
  toolBar: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  button: {
    margin: theme.spacing(1),
    marginTop: 'auto'
  },
  paper: {
    padding: theme.spacing(2)
  },
  dialogPaper: {
    minWidth: '50rem',
    minHeight: '30rem'
  },
  dialogActions: {
    flexGrow: 1
  },
  textField: {
    marginTop: '2rem',
    width: '50%'
  }
});

class ProductConsolidateDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      columns: [{ name: 'productID', title: 'Id' }, { name: 'productName', title: 'Name' }, { name: 'status', title: 'Status' }],
      rows: this.getProductRows(),
      currentPage: 0,
      pageSize: 5,
      allowedPageSizes: [5, 10, 25, 50],
      next: false,
      selection: [],
      selectedProduct: {},
      consolidatedDate: moment().format('YYYY-MM-DD'),
      note: ''
    };

    this.changeCurrentPage = currentPage => this.setState({ currentPage });
    this.changePageSize = pageSize => this.setState({ pageSize });
    this.changeSelection = this.changeSelection.bind(this);
    this.handleConsolidate = this.handleConsolidate.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.getProductRows = this.getProductRows.bind(this);
  }
  
  componentDidMount(){
    if (this.props.open && this.props.products.length === 0) {
      this.props.getProducts();
    }
  }

  changeSelection(selection) {
    if (selection.length > 1) selection.shift();
    this.setState({ selection, selectedProduct: this.state.rows[selection[0]] });
  }

  handleConsolidate() {
    const { selectedProduct, note, consolidatedDate } = this.state;

    this.setState({ next: false, selection: [], selectedProduct: {} });
    this.props.onConsolidate({ productID: selectedProduct.productID, note, dateOfChange: consolidatedDate });
  }

  handleClose() {
    this.setState({ next: false, selection: [], selectedProduct: {} });
    this.props.onClose();
  }

  getProductRows() {
    return this.props.products.filter(product => this.props.product.productID !== product.productID);
  }

  render() {
    const { columns, pageSize, allowedPageSizes, next, selection, selectedProduct, consolidatedDate } = this.state;
    const { classes, product, products, ...other } = this.props;

    delete other.getProducts;
    delete other.onConsolidate;
    delete other.onClose;

    return (
      <Dialog onClose={this.handleClose} classes={{ paper: classes.dialogPaper }} aria-labelledby="simple-dialog-title" {...other}>
        <DialogTitle id="simple-dialog-title">Consolidate Products</DialogTitle>
        {!next ? (
          <DialogContent>
            <Grid rows={this.getProductRows()} columns={columns} selection={[1]}>
              <SelectionState selection={selection} onSelectionChange={this.changeSelection} />
              <FilteringState defaultFilters={[]} />
              <IntegratedFiltering />
              <PagingState currentPage={this.state.currentPage} onCurrentPageChange={this.changeCurrentPage} pageSize={pageSize} onPageSizeChange={this.changePageSize} />
              <IntegratedPaging />
              <Table />
              <TableHeaderRow />
              <TableFilterRow />
              <TableSelection selectByRowClick />
              <PagingPanel pageSizes={allowedPageSizes} />
            </Grid>
          </DialogContent>
        ) : (
          <DialogContent>
            <div>
              {selectedProduct.productName ? `You have selected ${selectedProduct.productName}. ` : ''}
              {`Please confirm that you would like to consolidate ${selectedProduct.productName ? selectedProduct.productName : ''} with ${product.productName}.`}
            </div>
            <div>
              <TextField
                label="Consolidated Date"
                type="date"
                defaultValue={consolidatedDate}
                value={consolidatedDate}
                className={classes.textField}
                margin="dense"
                onChange={event => this.setState({ consolidatedDate: event.target.value })}
              />
            </div>
            <div>
              <TextField className={classes.textField} label="Note" multiline margin="dense" value={this.state.note} onChange={event => this.setState({ note: event.target.value })} />
            </div>
          </DialogContent>
        )}
        <DialogActions className={classes.dialogActions}>
          {!next && (
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={() => {
                this.setState({ next: true });
              }}
            >
              Next
            </Button>
          )}
          {next && (
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={() => {
                this.setState({ next: false });
              }}
            >
              Back
            </Button>
          )}
          {next && (
            <Button variant="contained" color="primary" className={classes.button} onClick={this.handleConsolidate} disabled={!selection.length}>
              Consolidate
            </Button>
          )}
        </DialogActions>
      </Dialog>
    );
  }
}

ProductConsolidateDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  onConsolidate: PropTypes.func.isRequired,
  product: PropTypes.object.isRequired,
  products: PropTypes.array.isRequired
};

export default withStyles(styles)(ProductConsolidateDialog);
