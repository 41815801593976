import { SelectionState, DataTypeProvider } from '@devexpress/dx-react-grid';
import { Grid, Table, TableHeaderRow, TableSelection } from '@devexpress/dx-react-grid-material-ui';
import { Button, Paper } from '@mui/material';
import { withStyles } from "@mui/styles";
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { isEmpty, find } from 'lodash';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import CopyDataDialog from '../copyData/copyDataDialog';
import { ProgressBar } from '@survey/common/dist/components/tables/progressBar';
import { createSurveyState } from '@survey/common/dist/utilities/createSurveyState';
import { getSurveyScore, saveSurvey, setAssessmentSectionsComplete } from '@survey/common/dist/actions/surveys.actions';
import { calculatePageStatus, SurveyPageStatuses } from '@survey/common/dist/utilities/surveyUtils';
import { getVendorsList } from '@survey/common/dist/actions/vendors.actions';

const styles = (theme) => ({
  paper: {
    padding: theme.spacing(2),
  },
  button: {
    margin: "5px !important",
  },
  toolBar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  grid: {
    marginBottom: theme.spacing(2),
  },
});

class EntitiesContainer extends Component {
  constructor(props) {
    super(props);
    const { vendorsList } = this.props;

    isEmpty(vendorsList) && this.props.getVendorsList();

    this.state = {
      showCopyDataDialog: false,
      copyFromEntity: null,
      entityStages: [],
      filteredEntities: [],
    };

    this.getCell = this.getCell.bind(this);
    this.changeSelection = this.changeSelection.bind(this);
    this.getPrompt = this.getPrompt.bind(this);
    this.completeAllSections = this.completeAllSections.bind(this);
    this.saveSurvey = this.saveSurvey.bind(this);
  }

  componentDidMount() {
    this.setEntities();
  }

  setEntities() {
    let filteredEntities = this.props.survey.entities.reduce((all, entity) => {
      const newEntity = { ...entity };
      newEntity.stage = this.state.entityStages[newEntity.entityID];
      var entityWithProgress = this.addEntityProgress(newEntity);

      if (entityWithProgress.emptyEntity === false) {
        all.push(entityWithProgress);
      }
      return all;
    }, []);

    this.setState({ filteredEntities });
  }

  changeSelection(selection) {
    let surveyId = this.props.match.params.id;

    //Because we sort the entities before render we need to sort here before selected by index
    let entities = this.state.filteredEntities;
    entities.sort((a, b) => (a.entityName > b.entityName ? 1 : -1));
    if (this.props.survey.surveyType.surveyTypeID === 8 || this.props.survey.surveyType.surveyTypeID === 41) {
      entities = entities.sort(this.sortOutpatientEntities);
    } else {
      entities = entities.sort(this.sortEntities);
    }

    let entityId = entities[selection].entityID;
    this.props.history.push(`/surveys/${surveyId}/entities/${entityId}`);
  }

  async completeAllSections(entityID) {
    const response = await this.props.setAssessmentSectionsComplete(this.props.survey.surveyID, entityID);

    if (response.type === 'SET_ASSESSMENT_SECTIONS_COMPLETE_SUCCESS') {
      this.setEntities();
    }
  }

  async saveSurvey(survey) {
    delete survey.bsonId;
    // TODO:  We should just remove the questions and technologyQuestions entirely but right now that will delete them from the survey
    survey.questions &&
      survey.questions.forEach((q) => {
        delete q.bsonId;
      });
    survey.technologyQuestions &&
      survey.technologyQuestions.forEach((q) => {
        delete q.bsonId;
      });

    const response = await this.props.saveSurvey(survey.surveyID, survey);
  }

  addEntityProgress(entity) {
    const { survey, surveyType, pages, tabs, technologies } = this.props;

    let totalQuestionsAnswered = 0;
    let totalQuestions = 0;
    if (!isEmpty(survey) && !isEmpty(surveyType) && !isEmpty(pages)) {
      const surveyState = createSurveyState(survey, surveyType, entity.haEntityTypeID, pages, tabs, technologies);

      const pagesWithProgress = surveyState.pages.map((p) => {
        if (survey.pageProgress && survey.pageProgress[`${entity.entityID}-${p.pageId}`]) {
          p.pageProgress = survey.pageProgress[`${entity.entityID}-${p.pageId}`];
        } else {
          p.pageProgress = {};
        }

        return p;
      });

      pagesWithProgress.forEach((p) => {
        const { surveyType, tabs } = surveyState;
        const pageStatus = calculatePageStatus(`${p.pageId}`, entity, tabs, surveyType, survey, technologies);

        totalQuestions += pageStatus.totalQuestions;

        if (p.pageProgress && p.pageProgress.completed === true) {
          totalQuestionsAnswered += pageStatus.totalQuestions;
        } else {
          totalQuestionsAnswered += pageStatus.totalQuestionsAnswered;
        }
      });
    }

    const progress = totalQuestions > 0 ? Math.floor((totalQuestionsAnswered / totalQuestions) * 100) / 100 : 0;

    return { ...entity, progress, emptyEntity: totalQuestions == 0 };
  }

  getPrompt = (status) => {
    switch (status) {
      case SurveyPageStatuses.NOT_STARTED:
        return 'START';

      case SurveyPageStatuses.INCOMPLETE:
        return 'CONTINUE';

      case SurveyPageStatuses.COMPLETE:
        return 'COMPLETE';

      default:
        return 'START';
    }
  };

  getCell(props) {
    if (props.column.name === 'progress') {
      return (
        <Table.Cell>
          <ProgressBar value={props.row.progress ? props.row.progress : 0} style={{ width: '300px', borderBottom: 0 }} />
        </Table.Cell>
      );
    }
    if (props.column.name === 'actions') {
      return (
        <Table.Cell>
          <Button
            className={this.props.classes.button}
            color="primary"
            variant={'contained'}
            onClick={(e) => {
              e.stopPropagation();
              this.setState({
                showCopyDataDialog: true,
                copyFromEntity: props.row,
              });
            }}
            disabled={props.row.progress !== 1 || (props.row.parentEntityID == null && this.props.survey.surveyTypeID === 75)}
          >
            COPY DATA
          </Button>
          <Button
            className={this.props.classes.button}
            color="primary"
            variant={'contained'}
            onClick={(e) => {
              e.stopPropagation();
              this.completeAllSections(props.row.entityID);
            }}
          >
            Complete All Sections
          </Button>
          <Button
            className={this.props.classes.button}
            color="primary"
            variant={ props.row.status === SurveyPageStatuses.COMPLETE ? 'outlined' : 'contained'}
            onClick={() => {
              this.changeSelection(props.tableRow.rowId);
            }}
          >
            {this.getPrompt(props.row.status)}
          </Button>
        </Table.Cell>
      );
    }
    return <Table.Cell {...props} >{ props.row[props.column.name] }</Table.Cell>;
  }

  sortEntities(a, b) {
    var x = a.haEntityTypeID;
    var y = b.haEntityTypeID;
    if (y === 6 || x === 8) {
      return -1;
    }
    if (x === 6 || x === 8) {
      return 1;
    }
    return 0;
  }

  sortOutpatientEntities(a, b) {
    var x = a.haEntityTypeID;
    var y = b.haEntityTypeID;
    if (y === 6 || y === 8) {
      return 1;
    }
    if (x === 6 || x === 8) {
      return -1;
    }
    return 0;
  }

  async getCurrentStageAndAddToState(surveyID, entityID) {
    let res = await this.props.getSurveyScore(surveyID);

    let currentEntityScore = find(res.response, function (entity) {
      return entity.haEntityID == entityID;
    });

    const entityStages = { ...this.state.entityStages };

    if (currentEntityScore) {
      entityStages[entityID] = currentEntityScore.stage;
    } else {
      entityStages[entityID] = 'N/A';
    }

    this.setState({ entityStages: entityStages });
  }

  render() {
    const { classes, vendorsList } = this.props;
    const { filteredEntities } = this.state;

    //sort entities by name first:
    filteredEntities.sort((a, b) => (a.entityName > b.entityName ? 1 : -1));

    //Sort the entities so that 'Single Hospital Health System' and 'IDS/RHA' are at the top of the list
    filteredEntities.sort(this.sortEntities);

    //hacky fix for Outpatient sort (sortEntities doesnt work on outpatient for some reason):
    if (this.props.survey.surveyType.surveyTypeID === 8 || this.props.survey.surveyType.surveyTypeID === 41) {
      filteredEntities.sort(this.sortOutpatientEntities);
    }

    function determineColor(value) {
      if (value >= 6) {
        return <b style={{ color: 'red' }}>{value}</b>;
      }

      return <span>{value}</span>;
    }

    const StageColorFormatter = ({ value }) => <span>{determineColor(value)}</span>;

    const StageTypeProvider = (props) => <DataTypeProvider formatterComponent={StageColorFormatter} {...props} />;

    const scoreText =
      this.props.survey.surveyType.name === 'Digital Health Indicator' ||
      this.props.survey.surveyType.name === 'DHI' ||
      this.props.survey.surveyType.name === 'DHIShort' ||
      this.props.survey.surveyType.name === 'Digital Health Indicator Rapid Assessment'
        ? 'DHI Score'
        : 'Current Stage';

    return (
      <div>
        <Paper className={classes.grid} square>
          <Grid
            rows={filteredEntities}
            selection={[1]}
            columns={[
              { name: 'entityName', title: 'Name' },
              { name: 'progress', title: 'Progress' },
              { name: 'status', title: 'Status' },
              { name: 'lastModified', title: 'Last Modified' },
              { name: 'stage', title: scoreText },
              { name: 'actions', title: 'Actions' },
            ]}
          >
            <StageTypeProvider for={['stage']} />

            <SelectionState onSelectionChange={(selection) => this.changeSelection(selection)} />
            <Table
              cellComponent={this.getCell}
              columnExtensions={[
                { columnName: 'progress', width: 400 },
                { columnName: 'status', width: 100 },
                { columnName: 'lastModified', width: 100 },
                { columnName: 'stage', width: 100, color: 'red' },
                { columnName: 'actions', width: 450 },
              ]}
            />
            <TableHeaderRow />
            <TableSelection selectByRowClick highlightSelected showSelectionColumn={false} />
          </Grid>
        </Paper>
        {this.state.copyFromEntity && (
          <CopyDataDialog
            open={this.state.showCopyDataDialog}
            onClose={() => this.setState({ showCopyDataDialog: false, copyFromEntity: null })}
            copyFromEntity={this.state.copyFromEntity}
            survey={this.props.survey}
            technologies={this.props.technologies}
            organizationTypes={this.props.organizationTypes}
            vendorsList={vendorsList}
            pages={this.props.pages}
            tabs={this.props.tabs}
            stateList={this.props.stateList}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  vendorsList: state.vendors.get('vendorsList'),
});

EntitiesContainer.propTypes = {
  survey: PropTypes.object.isRequired,
  technologies: PropTypes.array.isRequired,
  vendorsList: PropTypes.array.isRequired,
  completePage: PropTypes.func,
  page: PropTypes.object,
  stateList: PropTypes.array.isRequired,
};

export default withRouter(
  withStyles(styles, { withTheme: true })(
    connect(mapStateToProps, {
      getVendorsList,
      saveSurvey,
      getSurveyScore,
      setAssessmentSectionsComplete,
    })(EntitiesContainer)
  )
);
