/* eslint array-callback-return: 0 */
import { Button, FormControl, Grid, Paper, TextField, Typography, Checkbox, FormControlLabel } from '@mui/material';
import { withStyles } from "@mui/styles";
import { find, findIndex, isEmpty, orderBy } from 'lodash';
import { Facebook as FacebookBox, Linkedin as LinkedinBox, Twitter as TwitterBox } from 'mdi-material-ui';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { convertCountriesToCountryData, convertCountriesToValues, convertTechnologiesToValues, convertYearToValue } from '../../utilities/convertValues';
import { hasPermission } from '../auth/authOperations';
import { EDIT_VENDORS } from '../auth/permissions';
import CustomAddList from '../formControls/CustomAddList';
import { handleToastMessage } from '../layout/layout.actions';
import { getVendorContacts } from '@survey/common/dist/actions/vendors.actions';
import { getCountryRegions } from '@survey/common/dist/actions/countries.actions';
import CustomSelect from '@survey/common/dist/components/form-controls/CustomSelect';
import CustomTextField from '@survey/common/dist/components/form-controls/CustomTextField';
import { customValidationRules } from '@survey/common/dist/utilities/answerValidation';
const styles = (theme) => ({
  form: {
    margin: `${theme.spacing(2)}px ${theme.spacing(5)}px`,
  },
  formControl: {
    display: 'block',
    width: '100%',
    marginBottom: '.5rem',
  },
  textField: {
    fontSize: 12,
  },
  gridPaper: {
    margin: `${theme.spacing(3)}px 0`,
    padding: `1rem`,
  },
  productPaper: {
    marginTop: theme.spacing(3),
    padding: theme.spacing(2),
    color: 'rgba(0, 0, 0, 0.54)',
  },
  button: {
    margin: theme.spacing(1),
  },
  linkedIn: {
    color: '#0077B5',
    fontSize: '3rem',
  },
  facebook: {
    color: '#3b5998',
    fontSize: '3rem',
  },
  twitter: {
    color: '#1da1f2',
    fontSize: '3rem',
  },
  socialWrapper: {
    marginBottom: '.25rem',
  },
  noSocial: {
    color: '#a8a8a8',
    fontSize: '3rem',
  },
  checkbox: {
    fontSize: '1rem',
    font: 'inherit',
  },
});

const years = Array(100)
  .fill()
  .map((item, index) => {
    let year = 2000 + index;
    return { label: year + '', value: year };
  });

class VendorForm extends Component {
  constructor(props) {
    super(props);

    const { vendor } = props;

    this.state = {
      addresses: vendor && vendor.addresses ? vendor.addresses : [],
      address: {
        address1: '',
        address2: '',
        country: {},
        city: '',
        state: {},
        zip: '',
      },
      regionList: [],
      name: vendor && vendor.name ? vendor.name : '',
      akaVendorNames: vendor && vendor.akaVendorNames ? vendor.akaVendorNames : [],
      alternateNames: vendor && vendor.alternateNames ? vendor.alternateNames : [],
      comments: vendor && vendor.comments ? vendor.comments : '',
      /*startYear: vendor && vendor.startYear ? convertYearToValue(vendor.startYear) : '',*/
      /*endYear: vendor && vendor.endYear ? convertYearToValue(vendor.endYear) : '',*/
      phones: vendor && vendor.phones ? vendor.phones : [],
      phone: vendor && vendor.phones ? vendor.phones[0] : '',
      products: vendor && vendor.products ? vendor.products : [],
      countries: vendor && vendor.countries ? vendor.countries : [],
      status: vendor && vendor.status ? vendor.status : 'ACTIVE',
      technologies: vendor && vendor.technologies ? vendor.technologies : [],
      vendorID: vendor && vendor.vendorID ? vendor.vendorID : '',
      website: vendor && vendor.website ? vendor.website : '',
      technologySelectedValues: vendor && vendor.technologies ? vendor.technologies.map((t) => ({ value: t.technologyID, label: t.technologyName })) : [],
      countrySelectedValues: vendor && vendor.countries ? convertCountriesToValues(vendor.countries) : [],
      countriesList: [],
      isDirty: false,
      isAddressDirty: false,
      selectAllText: 'Select All Regions',
    };

    ['onSubmit', 'handleSelectChange', 'handleInputChange', 'handleAddName', 'handleDeleteName', 'updateSelectedValueField', 'handleSelectAllRegions'].map(
      (name) => (this[name] = this[name].bind(this))
    );
  }

  async componentDidMount() {
    if (this.state.addresses.length) {
      const { addressID, addressType, address1, address2, city, country, countryID, zip, sourceID, stateID, status } = this.state.addresses[0];

      let regionList = [];
      let stateLabel = '';

      if (countryID) {
        const regions = await this.props.getCountryRegions(countryID);

        if (regions.response && regions.response.length) {
          regionList = regions.response.map((r) => {
            return { value: r.geographicalRegionID, label: r.geographicalRegion };
          });

          const region = find(regionList, { value: stateID });
          if (region) {
            stateLabel = region.label;
          }
        }
      }

      this.setState({
        regionList,
        address: {
          addressID,
          addressType,
          address1,
          address2,
          country: { label: country, value: countryID },
          city,
          state: { label: stateLabel, value: stateID },
          countryID,
          zip,
          sourceID,
          status,
        },
      });
    }
  }

  static getDerivedStateFromProps(props, state) {
    let newState = { ...state };

    /* Add all of the custom validation rules */
    Object.keys(customValidationRules).forEach((ruleName) => {
      ValidatorForm.addValidationRule(ruleName, customValidationRules[ruleName]);
    });

    /*if (newState.startYear === '' && newState.vendorID === '') {
      newState.startYear = new Date().getFullYear();
    }*/

    return { ...newState };
  }

  onSubmit() {
    const { address, technologySelectedValues, countrySelectedValues, isAddressDirty, vendorID, ...state } = this.state;
    const { technologies, countries } = this.props;

    if (isAddressDirty) {
      if (state.addresses.length) {
        state.addresses[0] = { ...state.addresses[0], ...address, country: address.country.label, countryID: address.country.value, state: address.state.label, stateID: address.state.value };
      } else {
        state.addresses[0] = {
          ...state.addresses[0],
          ...address,
          addressType: 'Primary',
          country: address.country.label,
          countryID: address.country.value,
          state: address.state.label,
          stateID: address.state.value,
        };
      }
    }
    //state.technologies = convertTechIDsToTechData(technologySelectedValues, technologies, this.state.vendorID);
    state.technologies = technologySelectedValues.map((t) => ({ technologyID: t.value, technologyName: t.label }));
    state.countries = convertCountriesToCountryData(countrySelectedValues, countries);
    /* Set some values to null if they are blank */
    /* REMOVED FROM VENDORS ALL TOGETHER */
    /*['endYear', 'startYear'].forEach(item => {
      if (state[item] === '') {
        state[item] = null;
      } else if (state[item] && state[item].value) {
        state[item] = state[item].value;
      }
    });*/

    delete state.countriesList;
    delete state.isDirty;
    delete state.regionList;

    this.props.onSubmit(state);
  }

  handleAddName(name, value) {
    if (value.toLowerCase() == this.state.name.toLowerCase()) {
      return false;
    }

    this.setState({ [name]: [...this.state[name], { name: value }], isDirty: true });
  }

  handleDeleteName(name, value) {
    const namesCopy = [...this.state[name]];
    const nameIndex = findIndex(namesCopy, { name: value.name });

    namesCopy.splice(nameIndex, 1);

    this.setState({ [name]: namesCopy, isDirty: true });
  }

  handleInputChange(event) {
    const { name, value } = event.target;

    switch (name) {
      case 'city':
      case 'state':
      case 'zip':
      case 'address1':
      case 'address2':
        return this.setState({ address: { ...this.state.address, [name]: value }, isDirty: true, isAddressDirty: true });

      default:
        return this.setState({ [name]: value, isDirty: true });
    }
  }

  updateSelectedValueField(field) {
    return (event, val) => {
      if (val instanceof Array) {
        this.setState({ [field]: [...val.map((v) => ({ value: v.value, label: v.label }))] });
      } else {
        this.setState({
          [field]: val,
        });
      }
    };
  }

  handleSelectAllRegions = (event, regions) => {
    //regions example: {value: 87, label: "Georgia"}

    //console.log('in handleSelectAllRegions!');
    //console.log('in handleSelectAllRegions, regions: ', regions);

    if (event.target.checked) {
      if (this.state.countrySelectedValues.length > 0) {
        //remove all
        this.setState({ countrySelectedValues: [] });
        this.setState({ selectAllText: 'Select All Regions' });
      } else {
        let testValues = regions.map((c) => {
          return { value: c.value, label: c.label };
        });

        this.setState({ countrySelectedValues: testValues });
        this.setState({ selectAllText: 'Deselect All Regions' });
        return;
      }
    }

    this.setState({ countrySelectedValues: [] });
    this.setState({ selectAllText: 'Select All Regions' });
  };

  handleSelectChange(name) {
    return async (event, value) => {
      if(!value){
        this.setState({ address: { ...this.state.address, [name]: value }});
      } else {
        switch (name) {
          case 'country':
            
            let regionList = await this.props.getCountryRegions(value.value);

            if (regionList.response && regionList.response.length && value) {
              regionList = regionList.response.map((r) => {
                return { value: r.geographicalRegionID, label: r.geographicalRegion };
              });

              this.setState({ regionList, address: { ...this.state.address, [name]: value }, isAddressDirty: true });
            }
            break;

          case 'state':
            this.setState({ address: { ...this.state.address, [name]: value }, isAddressDirty: true });
            break;

          case 'countrySelectedValues':
            this.setState({
              [name]: value,
              isDirty: true,
            });

            if (value.length > 0) {
              this.setState({ selectAllText: 'Deselect All Regions' });
            } else {
              this.setState({ selectAllText: 'Select All Regions' });
            }

            break;

          default:
            this.setState({
              [name]: value,
              isDirty: true,
            });
        }
      }
    };
  }

  handleOpen(event) {
    const { name } = event.target;

    this.setState({ [`${name}Open`]: true });
  }

  handleClose(event) {
    const { name } = event.target;

    this.setState({ [`${name}Open`]: false });
  }

  render() {
    const {
      technologySelectedValues,
      countrySelectedValues,
      name,
      akaVendorNames,
      alternateNames,
      address,
      regionList,
      startYear,
      endYear,
      phone,
      website,
      comments,
      vendorID,
      selectAllText,
    } = this.state;
    let { classes, permissions, contacts, technologies, countriesList } = this.props;
    const { LinkedInUrl, TwitterUrl, FacebookUrl, PhotoUrl } = contacts;

    //console.log('this.props', this.props);

    countriesList = orderBy(countriesList, ['label'], ['asc']);
    technologies = orderBy(technologies, ['technologyName'], ['asc']);
    return (
      <ValidatorForm onSubmit={this.onSubmit} onError={this.props.onError} className={classes.form}>
        <Grid container spacing={10}>
          <Grid item xs={12} md={6}>
            <FormControl className={classes.formControl}>
              <CustomTextField
                className={classes.textField}
                disabled={!hasPermission(EDIT_VENDORS, permissions)}
                key="Vendor Name"
                fullWidth
                inputProps={{ style: { height: '1.625rem' } }}
                name="name"
                label="Vendor Name"
                margin="dense"
                value={name}
                onChange={(event) => this.handleInputChange(event)}
                required={true}
              />
            </FormControl>
            <Grid container justifyContent="space-around">
              <Grid item xs={12} sm={6}>
                <CustomAddList
                  id="aka-vendor-name"
                  addText="Add Name"
                  deleteText="Remove Name"
                  hasPermission={hasPermission(EDIT_VENDORS, permissions)}
                  keyName="akaVendorNames"
                  addItem={this.handleAddName}
                  deleteItem={this.handleDeleteName}
                  label="AKAVendorNames"
                  addDialogText="Input the vendor name that you would like to add."
                  deleteDialogText="Please confirm that you would like to remove this vendor name."
                  items={akaVendorNames}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <CustomAddList
                  id="alternate-names"
                  addText="Add Name"
                  deleteText="Remove Name"
                  hasPermission={hasPermission(EDIT_VENDORS, permissions)}
                  keyName="alternateNames"
                  addItem={this.handleAddName}
                  deleteItem={this.handleDeleteName}
                  label="AlternateNames"
                  addDialogText="Input the vendor name that you would like to add."
                  deleteDialogText="Please confirm that you would like to remove this vendor name."
                  items={alternateNames}
                />
              </Grid>
            </Grid>
            <Paper className={classes.gridPaper}>
              <Typography variant="h5" color="inherit" gutterBottom style={{ marginBottom: '1rem' }}>
                Vendor Address
              </Typography>
              <CustomSelect label="Country" value={address.country} onChange={this.handleSelectChange} options={countriesList} disabled={!hasPermission(EDIT_VENDORS, permissions)} name="country" />
              <FormControl className={classes.formControl}>
                <TextField
                  disabled={!hasPermission(EDIT_VENDORS, permissions)}
                  key="Address Line 1"
                  name="address1"
                  className={classes.textField}
                  fullWidth
                  margin="dense"
                  inputProps={{ style: { height: '1.625rem' } }}
                  label="Address Line 1"
                  value={address.address1 ? address.address1 : ''}
                  onChange={(event) => this.handleInputChange(event)}
                />
              </FormControl>
              <FormControl className={classes.formControl}>
                <TextField
                  disabled={!hasPermission(EDIT_VENDORS, permissions)}
                  key="Address Line 2"
                  name="address2"
                  className={classes.textField}
                  fullWidth
                  margin="dense"
                  inputProps={{ style: { height: '1.625rem' } }}
                  label="Address Line 2"
                  value={address.address2 ? address.address2 : ''}
                  onChange={(event) => this.handleInputChange(event)}
                />
              </FormControl>
              <FormControl className={classes.formControl}>
                <TextField
                  disabled={!hasPermission(EDIT_VENDORS, permissions)}
                  key="City"
                  name="city"
                  className={classes.textField}
                  fullWidth
                  margin="dense"
                  inputProps={{ style: { height: '1.625rem' } }}
                  label="City"
                  value={address.city ? address.city : ''}
                  onChange={(event) => this.handleInputChange(event)}
                />
              </FormControl>
              <Grid container spacing={8}>
                <Grid item xs={12} md={6}>
                  <CustomSelect
                    label="State / Providence"
                    value={address.state}
                    onChange={this.handleSelectChange}
                    options={regionList}
                    disabled={!hasPermission(EDIT_VENDORS, permissions) || !regionList.length}
                    name="state"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    disabled={!hasPermission(EDIT_VENDORS, permissions)}
                    key="Zip / Postal Code"
                    name="zip"
                    className={classes.textField}
                    fullWidth
                    margin="dense"
                    inputProps={{ style: { height: '1.625rem' } }}
                    label="Zip / Postal Code"
                    value={address.zip ? address.zip : ''}
                    onChange={(event) => this.handleInputChange(event)}
                  />
                </Grid>
              </Grid>
            </Paper>
            <FormControl className={classes.formControl}>
              <TextField
                disabled={!hasPermission(EDIT_VENDORS, permissions)}
                key="Phone"
                name="phone"
                className={classes.textField}
                fullWidth
                margin="dense"
                inputProps={{ style: { height: '1.625rem' } }}
                label="Phone"
                value={phone ? phone : ''}
                onChange={(event) => this.handleInputChange(event)}
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <TextField
                disabled={!hasPermission(EDIT_VENDORS, permissions)}
                key="Website"
                name="website"
                className={classes.textField}
                fullWidth
                margin="dense"
                inputProps={{ style: { height: '1.625rem' } }}
                label="Website"
                value={website ? website : ''}
                onChange={(event) => this.handleInputChange(event)}
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <TextField
                disabled={!hasPermission(EDIT_VENDORS, permissions)}
                key="Comments"
                name="comments"
                className={classes.textFieldMulti}
                fullWidth
                margin="dense"
                inputProps={{ style: { height: '1.625rem' } }}
                multiline
                maxRows="4"
                label="Comments"
                value={comments ? comments : ''}
                onChange={(event) => this.handleInputChange(event)}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid container style={{ height: '100%' }} direction="column">
              <Grid item>
                {!isEmpty(contacts) && vendorID ? (
                  <Grid container className={classes.socialWrapper} spacing={8} justifyContent="flex-end">
                    {PhotoUrl && (
                      <Grid item>
                        {/* {PhotoUrl && this.state.website ? (
                      <a href={this.state.website} target="_blank">
                        <img src={PhotoUrl} alt={`${this.state.name} company logo`} />
                      </a>
                    ) : ( */}
                        <img src={PhotoUrl} alt={`${name} company logo`} />
                        {/* )} */}
                      </Grid>
                    )}
                    <Grid item>
                      {LinkedInUrl ? (
                        <a href={LinkedInUrl} target="_blank" rel="noopener noreferrer">
                          <LinkedinBox className={classes.linkedIn} />
                        </a>
                      ) : (
                        <LinkedinBox className={classes.noSocial} />
                      )}
                    </Grid>
                    <Grid item>
                      {TwitterUrl ? (
                        <a href={TwitterUrl} target="_blank" rel="noopener noreferrer">
                          <TwitterBox className={classes.twitter} />
                        </a>
                      ) : (
                        <TwitterBox className={classes.noSocial} />
                      )}
                    </Grid>
                    <Grid item>
                      {FacebookUrl ? (
                        <a href={FacebookUrl} target="_blank" rel="noopener noreferrer">
                          <FacebookBox className={classes.facebook} />
                        </a>
                      ) : (
                        <FacebookBox className={classes.noSocial} />
                      )}
                    </Grid>
                  </Grid>
                ) : (
                  <span />
                )}
              </Grid>
              <Grid item style={{ marginTop: '1rem', width: '100%' }}>
                <div style={{ marginBottom: '.5rem' }}>
                  <CustomSelect
                    name="technologySelectedValues"
                    label="Technologies"
                    className={classes.select}
                    value={technologySelectedValues}
                    options={technologies.map((item) => ({
                      value: item.technologyID,
                      label: item.technologyName,
                    }))}
                    disabled={!hasPermission(EDIT_VENDORS, permissions)}
                    onChange={this.updateSelectedValueField}
                    isMulti={true}
                    required={true}
                  />
                </div>
              </Grid>
              <Grid item style={{ width: '100%' }}>
                <div style={{ marginBottom: '.5rem' }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        indeterminate={countrySelectedValues.length > 0 && countrySelectedValues.length < countriesList.length}
                        checked={countrySelectedValues.length === countriesList.length}
                        onChange={(event) => this.handleSelectAllRegions(event, countriesList)}
                      />
                    }
                    label={selectAllText}
                    className={classes.checkbox}
                  />
                  <CustomSelect
                    options={countriesList}
                    label="Regions / Countries"
                    name="countrySelectedValues"
                    className={classes.select}
                    isMulti={true}
                    permission={true}
                    onChange={this.handleSelectChange}
                    value={countrySelectedValues}
                    disabled={!hasPermission(EDIT_VENDORS, permissions)}
                  />
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid item style={{ width: '100%', paddingTop: 0, display: 'flex', justifyContent: 'flex-end' }}>
            <Button variant="text" color="primary" className={classes.button} onClick={() => this.props.history.goBack()}>
              Back
            </Button>
            <Button type="submit" disabled={!hasPermission(EDIT_VENDORS, permissions)} variant="contained" color="primary" className={classes.button}>
              Save
            </Button>
          </Grid>
        </Grid>
      </ValidatorForm>
    );
  }
}

VendorForm.propTypes = {
  vendor: PropTypes.object,
  onSubmit: PropTypes.func,
};

function mapStateToProps(state, props) {
  return {
    contacts: state.vendors.get('contacts'),
    isContactsLoading: state.vendors.get('isLoading'),
  };
}

export default withRouter(
  withStyles(styles)(
    connect(mapStateToProps, {
      getVendorContacts,
      getCountryRegions,
      handleToastMessage,
    })(VendorForm)
  )
);
