import { withStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import DocumentTitleFormatter from '../../utilities/documentTitleFormatter';
import MapRouteTitle from '../../utilities/mapRouteTitle';
import { withRouteOnEnter } from '../../utilities/withRouteOnEnter.component';
import Auth from '../auth/auth';
import { clearAuth, setAuth } from '../auth/auth.actions';
import { hasPermission, isAuthenticated } from '../auth/authOperations';
import { VIEW_ANSWERS, VIEW_QUESTIONS, VIEW_SCORING, VIEW_SURVEYS, VIEW_SURVEY_TYPES, VIEW_TECHNOLOGY_QUESTIONS, VIEW_VENDORS } from '../auth/permissions';
import Callback from '../callback/callback';
import Header from '../layout/header.container';
import Footer from '../layout/footer.container';
import Answer from '../questions/answer.container';
import Answers from '../questions/answers.container';
import Question from '../questions/question.container';
import Questions from '../questions/questions.container';
import EMRAMScoring from '../scoring/emramScoring.container';
import OEMRAMScoring from '../scoring/oemramScoring.container';
import AMAMScoring from '../scoring/amamScoring.container';
import INFRAMScoring from '../scoring/inframScoring.container';
import CISOMScoring from '../scoring/cisomScoring.container';
import Entity from '../surveys/entities/entity.container';
import Survey from '../surveys/survey.container';
import Surveys from '../surveys/surveys.container';
import SurveyType from '../surveyTypes/surveyType.container';
import SurveyTypes from '../surveyTypes/surveyTypes.container';
import TechnologyQuestion from '../technologyQuestions/technologyQuestion.container';
import TechnologyQuestions from '../technologyQuestions/technologyQuestions.container';
import Vendor from '../vendors/vendor.container';
import Vendors from '../vendors/vendors.container';
import Maintenance from '../layout/maintenance.container';
import Toast from './toast.container';
import LoadingOverlay from '../layout/loadingOverlay.component';

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    marginTop: 0,
    width: '100%',
  },
});

class MainContainer extends Component {
  constructor(props) {
    super(props);
    let rawIdToken = localStorage.getItem('id_token');
    this.props.setAuth(rawIdToken);
    this.auth = new Auth(this.props.history, props.setAuth, props.clearAuth);
    this.checkAuth = this.checkAuth.bind(this);
  }

  componentDidMount() {
    if (this.props.location.pathname !== '/login' && this.props.location.pathname !== '/callback') {
      localStorage.setItem('redirect_uri', this.props.location.pathname);
    }
  }

  checkAuth() {
    if (isAuthenticated(this.props.tokenInfo)) {
      // NOTE: Right here you can read from the user's profile to determine if they have what they need to proceed
      if (this.props.location.pathname === '/login') {
        this.props.history.replace('/home');
      }
    } else {
        this.auth.login();
    }
  }

  render() {
    const { authSet, classes, location, permissions, tokenInfo } = this.props;
    document.title = DocumentTitleFormatter(MapRouteTitle(location));
    return (
      <Fragment>
        {!authSet ? (
          <LoadingOverlay />
        ) : (
          <div className={classes.root}>
            {isAuthenticated(tokenInfo) && <Header auth={this.auth} />}
            <div className="main-content-wrapper">
              {isAuthenticated(tokenInfo) ? (
                <Switch>
                  <Route exact path="/home" component={withRouteOnEnter(this.checkAuth)(Surveys, this.props)} />
                  {hasPermission(VIEW_SURVEYS, permissions) && <Route exact path="/surveys" component={withRouteOnEnter(this.checkAuth)(Surveys, this.props)} />}
                  {hasPermission(VIEW_SURVEYS, permissions) && <Route path="/surveys/:surveyId/entities/:entityId/:pageId?" component={withRouteOnEnter(this.checkAuth)(Entity, this.props)} />}
                  {hasPermission(VIEW_SURVEYS, permissions) && <Route path="/surveys/:surveyId" component={withRouteOnEnter(this.checkAuth)(Survey, this.props)} />}
                  {hasPermission(VIEW_SCORING, permissions) && <Route exact path="/emramScoring/" component={withRouteOnEnter(this.checkAuth)(EMRAMScoring, this.props)} />}
                  {hasPermission(VIEW_SCORING, permissions) && <Route exact path="/oemramScoring/" component={withRouteOnEnter(this.checkAuth)(OEMRAMScoring, this.props)} />}
                  {hasPermission(VIEW_SCORING, permissions) && <Route exact path="/amamScoring/" component={withRouteOnEnter(this.checkAuth)(AMAMScoring, this.props)} />}
                  {hasPermission(VIEW_SCORING, permissions) && <Route exact path="/inframScoring/" component={withRouteOnEnter(this.checkAuth)(INFRAMScoring, this.props)} />}
                  {hasPermission(VIEW_SCORING, permissions) && <Route exact path="/cisomScoring/" component={withRouteOnEnter(this.checkAuth)(CISOMScoring, this.props)} />}
                  {hasPermission(VIEW_SURVEY_TYPES, permissions) && <Route exact path="/surveyTypes" component={withRouteOnEnter(this.checkAuth)(SurveyTypes, this.props)} />}
                  {hasPermission(VIEW_SURVEY_TYPES, permissions) && <Route exact path="/surveyTypes/:surveyTypeId" component={withRouteOnEnter(this.checkAuth)(SurveyType, this.props)} />}
                  {hasPermission(VIEW_QUESTIONS, permissions) && <Route exact path="/questions" component={withRouteOnEnter(this.checkAuth)(Questions, this.props)} />}
                  {hasPermission(VIEW_SURVEY_TYPES, permissions) && <Route exact path="/maintenance" component={withRouteOnEnter(this.checkAuth)(Maintenance, this.props)} />}
                  {hasPermission(VIEW_TECHNOLOGY_QUESTIONS, permissions) && <Route exact path="/technologyQuestions" component={withRouteOnEnter(this.checkAuth)(TechnologyQuestions, this.props)} />}
                  {hasPermission(VIEW_QUESTIONS, permissions) && <Route exact path="/questions/:questionId" component={withRouteOnEnter(this.checkAuth)(Question, this.props)} />}
                  {hasPermission(VIEW_TECHNOLOGY_QUESTIONS, permissions) && (
                    <Route exact path="/technologyQuestions/:questionId" component={withRouteOnEnter(this.checkAuth)(TechnologyQuestion, this.props)} />
                  )}
                  {hasPermission(VIEW_ANSWERS, permissions) && <Route exact path="/answers" component={withRouteOnEnter(this.checkAuth)(Answers, this.props)} />}
                  {hasPermission(VIEW_ANSWERS, permissions) && <Route exact path="/answers/:answerId" component={withRouteOnEnter(this.checkAuth)(Answer, this.props)} />}
                  {hasPermission(VIEW_VENDORS, permissions) && <Route exact path="/vendors" component={withRouteOnEnter(this.checkAuth)(Vendors, this.props)} />}
                  {hasPermission(VIEW_VENDORS, permissions) && <Route exact path="/vendors/:vendorId" component={withRouteOnEnter(this.checkAuth)(Vendor, this.props)} />}
                  <Redirect from="*" to="/home" />
                </Switch>
              ) : (
                <Switch>
                  <Route path="/login" render= {(props)=>this.checkAuth() } />
                  <Route
                    exact
                    path="/callback"
                    render={(props) => {
                      this.auth.handleAuthentication(props);
                      return <Callback {...props} />;
                    }}
                  />
                  <Redirect from="*" to="/login" />
                </Switch>
              )}
            </div>
            <Toast /> <Footer footerText={process.env.REACT_APP_VERSION} />
          </div>
        )}
      </Fragment>
    );
  }
}

MainContainer.contextTypes = {
  router: PropTypes.object,
};

MainContainer.propTypes = {
  // Injected by React Router
  children: PropTypes.node,
};

const mapStateToProps = (state) => {
  return {
    authSet: state.auth.get('authSet'),
    tokenInfo: state.auth.get('tokenInfo'),
    roles: state.auth.get('roles'),
    permissions: state.auth.get('permissions'),
  };
};

export default withStyles(styles)(
  withRouter(
    connect(mapStateToProps, {
      setAuth,
      clearAuth,
    })(MainContainer)
  )
);
