import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AppBar, Avatar, Paper, Tab, Tabs, Toolbar, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import { find, isEmpty } from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import AMAMScoringTable from './amamScoringTable.component';
import AMAMTechScoringTable from './amamTechScoringTable.component';
import { getAmamScorings, createAmamScoring, updateAmamScoring, deleteAmamScoring } from './scoring.actions';
import { handleToastMessage } from '../layout/layout.actions';
import { getAnswers } from '@survey/common/dist/actions/answers.actions';
import { getQuestions } from '@survey/common/dist/actions/questions.actions';
import { getTechnologyQuestions } from '@survey/common/dist/actions/technologyQuestions.actions';
import { getTechnologiesList } from '@survey/common/dist/actions/technologies.actions';
import { getPages } from '@survey/common/dist/actions/pages.actions';
import { getTabs } from '@survey/common/dist/actions/tabs.actions';
import LoadingOverlay from '../layout/loadingOverlay.component';

const styles = theme => {
  return {
    toolBar: {
      display: 'flex',
      justifyContent: 'space-between'
    },
    survey: {
      display: 'flex',
      flexDirection: 'row'
    },
    avatar: {
      margin: theme.spacing(1)
    },
    heading: {
      margin: theme.spacing(1)
    },
    socialIcons: {
      display: 'flex',
      justifyContent: 'space-around'
    },
    paper: {
      padding: theme.spacing(2)
    },
    grid: {
      marginBottom: theme.spacing(2)
    },
    typography: {
      padding: theme.spacing(3)
    },
    pageHeader: {
      color: theme.palette.text.secondary,
      /* Need a negative margin because there are empty headers in the table. */
      marginBottom: '-2rem',
      marginTop: '1rem'
    }
  };
};

class AMAMScoringContainer extends Component {
  constructor(props) {
    super(props);
    isEmpty(this.props.answers) && this.props.getAnswers();
    isEmpty(this.props.questions) && this.props.getQuestions();
    isEmpty(this.props.technologyQuestions) && this.props.getTechnologyQuestions();
    isEmpty(this.props.technologiesList) && this.props.getTechnologiesList();
    isEmpty(this.props.pages) && this.props.getPages();
    isEmpty(this.props.tabs) && this.props.getTabs();
    isEmpty(this.props.amamScoring) && this.props.getAmamScorings();
    this.state = {
      currentTab: 0
    };
    this.changeTab = this.changeTab.bind(this);
  }

  changeTab(event, value) {
    this.setState({ currentTab: value });
  }

  render() {
    const { classes, answers, questions, technologyQuestions, technologiesList, pages, tabs, handleToastMessage, amamScoring, createAmamScoring, updateAmamScoring, deleteAmamScoring } = this.props;
    const { currentTab } = this.state;
    const noAPIQuestions = questions.filter(q => {
      if (q.answerID === null) {
        return false;
      }
      const answer = find(answers, { answerID: q.answerID });
      if (answer) {
        return answer.type !== 'API';
      }
      // If no answer was found and the answerID isn't null, some data linking is broken so don't show the question.
      return false;
    });

    const noAPITechnologyQuestions = technologyQuestions.filter(q => {
      if (q.answerID === null) {
        return false;
      }
      const answer = find(answers, { answerID: q.answerID });
      if (answer) {
        return answer.type !== 'API';
      }
      // If no answer was found and the answerID isn't null, some data linking is broken so don't show the question.
      return false;
    });

    const questionsAmamScoring = amamScoring.filter(score => score.groupID !== null);
    const techQuestionsAmamScoring = amamScoring.filter(score => {
      return score.technologyID !== null;
    });

    return this.props.isLoading ? (
      <LoadingOverlay />
    ) : (
      <div>
        <AppBar position="static" color="default">
          <Toolbar className={classes.toolBar}>
            <div className={classes.survey}>
              <Avatar className={classes.avatar}>
                <FontAwesomeIcon icon="hospital" />
              </Avatar>
              <div className={classes.heading}>
                <Typography variant="h6" color="inherit" onClick={() => this.props.history.push(`/scoring`)}>
                  AMAM Survey Scoring Metrics
                </Typography>
              </div>
            </div>
          </Toolbar>
        </AppBar>
        <Tabs value={currentTab} onChange={this.changeTab} classes={{ flexContainer: classes.tabsBB }}>
          <Tab label="Questions" value={0} />
        </Tabs>
        <Paper className={classes.paper} square>
          {this.state.currentTab === 0 && (
            <div>
              <AMAMScoringTable
                answers={answers}
                questions={noAPIQuestions}
                pages={pages}
                tabs={tabs}
                handleToastMessage={handleToastMessage}
                scores={questionsAmamScoring}
                createScore={createAmamScoring}
                updateScore={updateAmamScoring}
                deleteScore={deleteAmamScoring}
              />
            </div>
          )}
          {this.state.currentTab === 1 && (
            <div>
              <AMAMTechScoringTable
                answers={answers}
                questions={noAPITechnologyQuestions}
                technologiesList={technologiesList}
                handleToastMessage={handleToastMessage}
                scores={techQuestionsAmamScoring}
                createScore={createAmamScoring}
                updateScore={updateAmamScoring}
                deleteScore={deleteAmamScoring}
              />
            </div>
          )}
        </Paper>
      </div>
    );
  }
}

function mapStateToProps(state, props) {
  return {
    questions: state.questions.get('questions'),
    technologyQuestions: state.technologyQuestions.get('technologyQuestions'),
    answers: state.answers.get('answers'),
    technologiesList: state.technologies.get('technologiesList'),
    pages: state.pages.get('pages'),
    tabs: state.tabs.get('tabs'),
    emramScoring: state.scoring.get('emramScoring'),
    oEmramScoring: state.scoring.get('oEmramScoring'),
    amamScoring: state.scoring.get('amamScoring'),
    isLoading:
      state.questions.get('isLoading') ||
      state.technologyQuestions.get('isLoading') ||
      state.answers.get('isLoading') ||
      state.pages.get('isLoading') ||
      state.tabs.get('isLoading') ||
      state.technologies.get('isLoading') ||
      state.scoring.get('isAmamLoading')
  };
}

export default withStyles(styles)(
  connect(
    mapStateToProps,
    {
      getAnswers,
      getQuestions,
      getTechnologyQuestions,
      getTechnologiesList,
      getPages,
      getTabs,
      handleToastMessage,
      getAmamScorings,
      deleteAmamScoring,
      updateAmamScoring,
      createAmamScoring
    }
  )(AMAMScoringContainer)
);
