import { FilteringState, IntegratedFiltering, IntegratedSorting, SelectionState, SortingState } from '@devexpress/dx-react-grid';
import { Grid, TableFilterRow, TableHeaderRow, TableSelection, VirtualTable } from '@devexpress/dx-react-grid-material-ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Getter } from '@devexpress/dx-react-core';
import {
  AppBar,
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Toolbar,
  Typography
} from '@mui/material';
import { withStyles } from "@mui/styles";
import AddIcon from '@mui/icons-material/Add';
import { find, isEmpty } from 'lodash';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { hasPermission } from '../auth/authOperations';
import { CREATE_SURVEYS } from '../auth/permissions';
import { handleToastMessage } from '../layout/layout.actions';
import { getSurveyTypes } from '../surveyTypes/surveyTypes.actions';
import LoadingOverlay from '../layout/loadingOverlay.component';

import { externalURL } from '@survey/common/dist/middleware/api';
import Confirm from '@survey/common/dist/components/dialogs/Confirm';
import { deleteSurvey, getSurveys, createSurvey, CREATE_SURVEY_SUCCESS } from '@survey/common/dist/actions/surveys.actions';

const styles = ({ spacing }) => ({
  toolBar: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  question: {
    display: 'flex',
    flexDirection: 'row'
  },
  avatar: {
    margin: spacing(1)
  },
  heading: {
    margin: spacing(1)
  },
  button: {
    margin: spacing(1)
  },
  paper: {
    flexGrow: 1,
    padding: spacing(1)
  },
  callToAction: {
    height: '100%',
    margin: '12.5rem',
    textAlign: 'center'
  },
  formControl: {
    marginTop: '1rem',
    width: '100%'
  }
});

class SurveysContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tableColumnExtensions: [
        { columnName: 'status', width: 100 },
        { columnName: 'actions', width: 150 }
      ],
      showDeleteDialog: false,
      surveyID: '',
      selection: [],
      showNewSurveyDialog: false,
      surveyName: '',
      surveyTypeID: ''
    };

    ['changeSelection', 'addSurvey', 'launchBlankSurvey', 'handleSelectChange'].map(k => (this[k] = this[k].bind(this)));
  }

  componentDidMount() {
    this.props.getSurveys();
    this.props.getSurveyTypes();
  }

  changeSelection(selection) {
    let surveyId = this.props.surveys[selection].surveyID;

    if (this.props.surveys[selection].status === 'Preparing') {
      this.props.handleToastMessage('This survey has not finished preparing.', true);
      return;
    }

    this.props.history.push(`/surveys/${surveyId}`);
  }

  removeSurvey(surveyId) {
    this.props.deleteSurvey(surveyId);
    this.props.getSurveys();

    this.setState({ surveyID: '', showDeleteDialog: false });
  }

  addSurvey() {
    this.props.history.push('/survey/new');
  }

  launchBlankSurvey = async (surveyName, surveyTypeID) => {
    const survey = {
      surveyTypeID: surveyTypeID,
      surveyName,
      status: 'Not Started',
      assignedMRA: null,
      entities: [
        {
          parentEntityID: 0,
          entityDescription: null,
          entityID: 0,
          entityName: surveyName,
          haEntityTypeID: 1
        }
      ]
    };

    const result = await this.props.createSurvey(survey);

    if (result.type === CREATE_SURVEY_SUCCESS) {
      this.props.handleToastMessage(`${surveyName} created successfully`, false);
    } else {
      this.props.handleToastMessage(`Failed to create survey`, true);
    }
  };

  handleSelectChange(event) {
    const { name, value } = event.target;

    this.setState({ [name]: value });
  }

  render() {
    const { classes, permissions, surveyTypes } = this.props;
    const { selection, tableColumnExtensions, showDeleteDialog, surveyID, showNewSurveyDialog, surveyName, surveyTypeID } = this.state;

    return !isEmpty(permissions) ? (
      <Fragment>
        <AppBar position="static" color="default">
          <Toolbar className={classes.toolBar}>
            <div className={classes.question}>
              <Avatar className={classes.avatar}>
                <FontAwesomeIcon icon="list-ul" />
              </Avatar>
              <Typography variant="h6" color="inherit" style={{ alignSelf: 'center' }}>
                Surveys
              </Typography>
            </div>
            {hasPermission(CREATE_SURVEYS, permissions) && (
              <Fab size="small" color="primary" className={classes.button} onClick={() => this.setState({ showNewSurveyDialog: true })}>
                <AddIcon />
              </Fab>
            )}
          </Toolbar>
        </AppBar>
        {this.props.isLoading ? (
          <LoadingOverlay />
        ) : (
          <Grid
            rows={this.props.surveys}
            columns={[
              { name: 'surveyName', title: 'Name' },
              { name: 'surveyID', title: 'Survey ID' },
              {
                name: 'entityID',
                title: 'HIMSS ID',
                getCellValue: row => {
                  let entities = row.entities.map((e) => {
                    return e.entityID;
                  }).join(", ");

                  return entities;
                }
              },
              {
                name: 'surveyType',
                title: 'Survey Type',
                getCellValue: row => {
                  let surveyType = find(this.props.surveyTypes, { surveyTypeID: row.surveyTypeID });
                  return surveyType ? surveyType.name : undefined;
                }
              },
              { name: 'assignedMRA', title: 'Assigned MRA' },
              { name: 'status', title: 'Status' },
              {
                name: 'actions',
                title: 'Actions',
                getCellValue: row => {
                  return (
                    <Fragment>
                      {row.status !== 'Preparing' && (
                        <Button
                          onClick={e => {
                            e.stopPropagation();
                            window.open(`${externalURL}surveyLanding/${row.surveyGuid}/home?pin=${row.accessPin}`, '_blank');
                          }}
                        >
                          Launch External
                        </Button>
                      )}
                      <Button
                        onClick={e => {
                          e.stopPropagation();
                          this.setState({
                            surveyID: row.surveyID,
                            showDeleteDialog: true
                          });
                        }}
                      >
                        Delete
                      </Button>
                    </Fragment>
                  );
                }
              }
            ]}
          >
            <SelectionState selection={selection} onSelectionChange={selection => this.changeSelection(selection)} />
            <FilteringState defaultFilters={[]} />
            <IntegratedFiltering />
            <SortingState defaultSorting={[]} />
            <IntegratedSorting />
            <VirtualTable height={"100%"} columnExtensions={tableColumnExtensions} />
            <TableHeaderRow showSortingControls />
            <TableFilterRow />
            <TableSelection selectByRowClick highlightSelected showSelectionColumn={false} />
            <Getter
              name="tableColumns"
              computed={({ tableColumns }) => {
                const columns = [...tableColumns];
                columns[0].width = 500; //Name
                columns[1].width = 200; //Survey ID
                // columns[2].width = 200; //HIMSS ID
                // columns[3].width = 200; //Survey Type
                // columns[4].width = 200; //Assigned MRA
                // columns[5].width = 300; //Status
                columns[6].width = 300; //Actions

                return [...columns];
              }}
            />
          </Grid>
        )}
        <Confirm
          title="Confirm Delete"
          onClose={() => this.setState({ showDeleteDialog: false })}
          onConfirm={() => this.removeSurvey(surveyID)}
          contentText={`Please confirm that you would like to delete this survey.`}
          open={showDeleteDialog}
        />
        <Dialog onClose={() => this.setState({ showNewSurveyDialog: false })} open={showNewSurveyDialog} maxWidth="sm" fullWidth={true}>
          <DialogTitle>Enter New Survey Details</DialogTitle>
          <DialogContent>
            <TextField fullWidth name="value" label="Survey Name" multiline={true} margin="dense" value={surveyName} onChange={event => this.setState({ surveyName: event.currentTarget.value })} />
            <FormControl className={classes.formControl}>
              <InputLabel>Survey Type</InputLabel>
              <Select
                value={surveyTypeID}
                onChange={this.handleSelectChange}
                inputProps={{
                  name: 'surveyTypeID'
                }}
                fullWidth
              >
                {surveyTypes.map(type => (
                  <MenuItem key={`${type.surveyTypeID}`} value={type.surveyTypeID}>
                    {type.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" color="primary" className={classes.button} onClick={() => this.setState({ showNewSurveyDialog: false })}>
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={() => {
                this.setState({ showNewSurveyDialog: false });
                this.launchBlankSurvey(surveyName, surveyTypeID);
              }}
              disabled={!surveyName}
            >
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    ) : (
      <Paper className={classes.paper}>
        <div className={classes.callToAction}>
          <Typography variant="h5">You do not have access to this application.</Typography>
        </div>
      </Paper>
    );
  }
}

function mapStateToProps(state, props) {
  return {
    surveys: state.surveys.get('surveys'),
    surveyTypes: state.surveyTypes.get('surveyTypes'),
    permissions: state.auth.get('permissions'),
    isLoading: state.surveys.get('isLoading') || state.surveyTypes.get('isLoading')
  };
}

export default withStyles(styles)(
  connect(mapStateToProps, {
    createSurvey,
    deleteSurvey,
    getSurveyTypes,
    getSurveys,
    handleToastMessage
  })(SurveysContainer)
);
