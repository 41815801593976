const MapRouteTitle = location => {
  let pathname = location.pathname;

  function locate(route) {
    return pathname.indexOf(route) !== -1;
  }

  if (locate('login')) {
    return 'Login';
  } else if (locate('surveys')) {
    return 'Surveys';
  } else if (locate('surveyTypes')) {
    return 'Survey Types';
  } else if (locate('scoring')) {
    return 'Scoring';
  } else if (locate('questions')) {
    return 'Questions';
  } else if (locate('technologyQuestions')) {
    return 'Technology Questions';
  } else if (locate('answers')) {
    return 'Answers';
  } else if (locate('vendors')) {
    return 'Vendors';
  } else if (locate('maintenance')) {
    return 'Maintenance';
  }

  return 'Home';
};

export default MapRouteTitle;
