"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.uploadTechnologyQuestions = exports.saveTechnologyQuestion = exports.getTechnologyStatus = exports.getTechnologyQuestions = exports.deleteTechnologyQuestion = exports.createTechnologyQuestion = exports.UPLOAD_TECHNOLOGY_QUESTION_SUCCESS = exports.UPLOAD_TECHNOLOGY_QUESTION_REQUEST = exports.UPLOAD_TECHNOLOGY_QUESTION_FAILURE = exports.SAVE_TECHNOLOGY_QUESTION_SUCCESS = exports.SAVE_TECHNOLOGY_QUESTION_REQUEST = exports.SAVE_TECHNOLOGY_QUESTION_FAILURE = exports.GET_TECHNOLOGY_STATUS_SUCCESS = exports.GET_TECHNOLOGY_STATUS_REQUEST = exports.GET_TECHNOLOGY_STATUS_FAILURE = exports.GET_TECHNOLOGY_QUESTIONS_SUCCESS = exports.GET_TECHNOLOGY_QUESTIONS_REQUEST = exports.GET_TECHNOLOGY_QUESTIONS_FAILURE = exports.DELETE_TECHNOLOGY_QUESTION_SUCCESS = exports.DELETE_TECHNOLOGY_QUESTION_REQUEST = exports.DELETE_TECHNOLOGY_QUESTION_FAILURE = exports.CREATE_TECHNOLOGY_QUESTION_SUCCESS = exports.CREATE_TECHNOLOGY_QUESTION_REQUEST = exports.CREATE_TECHNOLOGY_QUESTION_FAILURE = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _api = require("../middleware/api");
var GET_TECHNOLOGY_QUESTIONS_REQUEST = exports.GET_TECHNOLOGY_QUESTIONS_REQUEST = 'GET_TECHNOLOGY_QUESTIONS_REQUEST';
var GET_TECHNOLOGY_QUESTIONS_SUCCESS = exports.GET_TECHNOLOGY_QUESTIONS_SUCCESS = 'GET_TECHNOLOGY_QUESTIONS_SUCCESS';
var GET_TECHNOLOGY_QUESTIONS_FAILURE = exports.GET_TECHNOLOGY_QUESTIONS_FAILURE = 'GET_TECHNOLOGY_QUESTIONS_FAILURE';
var CREATE_TECHNOLOGY_QUESTION_REQUEST = exports.CREATE_TECHNOLOGY_QUESTION_REQUEST = 'CREATE_TECHNOLOGY_QUESTION_REQUEST';
var CREATE_TECHNOLOGY_QUESTION_SUCCESS = exports.CREATE_TECHNOLOGY_QUESTION_SUCCESS = 'CREATE_TECHNOLOGY_QUESTION_SUCCESS';
var CREATE_TECHNOLOGY_QUESTION_FAILURE = exports.CREATE_TECHNOLOGY_QUESTION_FAILURE = 'CREATE_TECHNOLOGY_QUESTION_FAILURE';
var SAVE_TECHNOLOGY_QUESTION_REQUEST = exports.SAVE_TECHNOLOGY_QUESTION_REQUEST = 'SAVE_TECHNOLOGY_QUESTION_REQUEST';
var SAVE_TECHNOLOGY_QUESTION_SUCCESS = exports.SAVE_TECHNOLOGY_QUESTION_SUCCESS = 'SAVE_TECHNOLOGY_QUESTION_SUCCESS';
var SAVE_TECHNOLOGY_QUESTION_FAILURE = exports.SAVE_TECHNOLOGY_QUESTION_FAILURE = 'SAVE_TECHNOLOGY_QUESTION_FAILURE';
var DELETE_TECHNOLOGY_QUESTION_REQUEST = exports.DELETE_TECHNOLOGY_QUESTION_REQUEST = 'DELETE_TECHNOLOGY_QUESTION_REQUEST';
var DELETE_TECHNOLOGY_QUESTION_SUCCESS = exports.DELETE_TECHNOLOGY_QUESTION_SUCCESS = 'DELETE_TECHNOLOGY_QUESTION_SUCCESS';
var DELETE_TECHNOLOGY_QUESTION_FAILURE = exports.DELETE_TECHNOLOGY_QUESTION_FAILURE = 'DELETE_TECHNOLOGY_QUESTION_FAILURE';
var GET_TECHNOLOGY_STATUS_REQUEST = exports.GET_TECHNOLOGY_STATUS_REQUEST = 'GET_TECHNOLOGY_STATUS_REQUEST';
var GET_TECHNOLOGY_STATUS_SUCCESS = exports.GET_TECHNOLOGY_STATUS_SUCCESS = 'GET_TECHNOLOGY_STATUS_SUCCESS';
var GET_TECHNOLOGY_STATUS_FAILURE = exports.GET_TECHNOLOGY_STATUS_FAILURE = 'GET_TECHNOLOGY_STATUS_FAILURE';
var UPLOAD_TECHNOLOGY_QUESTION_REQUEST = exports.UPLOAD_TECHNOLOGY_QUESTION_REQUEST = 'UPLOAD_TECHNOLOGY_QUESTION_REQUEST';
var UPLOAD_TECHNOLOGY_QUESTION_SUCCESS = exports.UPLOAD_TECHNOLOGY_QUESTION_SUCCESS = 'UPLOAD_TECHNOLOGY_QUESTION_SUCCESS';
var UPLOAD_TECHNOLOGY_QUESTION_FAILURE = exports.UPLOAD_TECHNOLOGY_QUESTION_FAILURE = 'UPLOAD_TECHNOLOGY_QUESTION_FAILURE';
var getTechnologyQuestions = exports.getTechnologyQuestions = function getTechnologyQuestions() {
  return (0, _defineProperty2["default"])({}, _api.CALL_API, {
    types: [GET_TECHNOLOGY_QUESTIONS_REQUEST, GET_TECHNOLOGY_QUESTIONS_SUCCESS, GET_TECHNOLOGY_QUESTIONS_FAILURE],
    authenticated: true,
    endpoint: "technologyQuestions",
    method: 'GET'
  });
};
var uploadTechnologyQuestions = exports.uploadTechnologyQuestions = function uploadTechnologyQuestions(data) {
  return (0, _defineProperty2["default"])({}, _api.CALL_API, {
    types: [UPLOAD_TECHNOLOGY_QUESTION_REQUEST, UPLOAD_TECHNOLOGY_QUESTION_SUCCESS, UPLOAD_TECHNOLOGY_QUESTION_FAILURE],
    authenticated: true,
    endpoint: "technologyQuestions/upload",
    method: 'POST',
    payload: data
  });
};
var createTechnologyQuestion = exports.createTechnologyQuestion = function createTechnologyQuestion(data) {
  return (0, _defineProperty2["default"])({}, _api.CALL_API, {
    types: [CREATE_TECHNOLOGY_QUESTION_REQUEST, CREATE_TECHNOLOGY_QUESTION_SUCCESS, CREATE_TECHNOLOGY_QUESTION_FAILURE],
    authenticated: true,
    endpoint: "technologyQuestions",
    method: 'POST',
    payload: data
  });
};
var saveTechnologyQuestion = exports.saveTechnologyQuestion = function saveTechnologyQuestion(id, data) {
  return (0, _defineProperty2["default"])({}, _api.CALL_API, {
    types: [SAVE_TECHNOLOGY_QUESTION_REQUEST, SAVE_TECHNOLOGY_QUESTION_SUCCESS, SAVE_TECHNOLOGY_QUESTION_FAILURE],
    authenticated: true,
    endpoint: "technologyQuestions/".concat(id),
    method: 'PUT',
    payload: data
  });
};
var deleteTechnologyQuestion = exports.deleteTechnologyQuestion = function deleteTechnologyQuestion(id) {
  return (0, _defineProperty2["default"])({}, _api.CALL_API, {
    types: [DELETE_TECHNOLOGY_QUESTION_REQUEST, DELETE_TECHNOLOGY_QUESTION_SUCCESS, DELETE_TECHNOLOGY_QUESTION_FAILURE],
    authenticated: true,
    endpoint: "technologyQuestions/".concat(id),
    method: 'DELETE',
    payload: id
  });
};
var getTechnologyStatus = exports.getTechnologyStatus = function getTechnologyStatus() {
  return (0, _defineProperty2["default"])({}, _api.CALL_API, {
    types: [GET_TECHNOLOGY_STATUS_REQUEST, GET_TECHNOLOGY_STATUS_SUCCESS, GET_TECHNOLOGY_STATUS_FAILURE],
    authenticated: true,
    endpoint: "technologyStatus",
    method: 'GET'
  });
};