import { findNumbers } from 'libphonenumber-js';
import { isArray } from 'lodash';

export const validationRulesList = [
  { name: 'Email Address', validatorFunctionName: 'isEmail', errorMessage: 'Please enter a valid email address' },
  {
    name: 'Phone Number',
    validatorFunctionName: 'isPhoneNumber',
    errorMessage: 'Please enter a valid phone number'
  },
  { name: 'Max Answer Length', validatorFunctionName: 'maxStringLength', errorMessage: 'Maximum field length exceeded' },
  { name: 'Select', validatorFunctionName: 'isSelectionValid', errorMessage: 'This field is required' },
  { name: 'Number', validatorFunctionName: 'isFloat', errorMessage: 'Must be a numeric value' }
];

const phoneNumberValidation = number => {
  try {
    // Remove any beginning zeros because the library doesn't know how to parse them.
    number = number.toString().replace(/^0+/, '');
    // See if there is a valid phone number here, starting with US numbers.
    let num = findNumbers(number, 'US', { v2: true });
    // If there isn't a matching number, try adding a plus at the beginning. Sometimes it helps the library to find international numbers.
    if (!num.length) {
      number = `+${number}`;
    }
    // Try again. If we still fail then the number probably isn't valid.
    num = findNumbers(number, 'US', { v2: true });
    return num.length;
  } catch (e) {
    console.log(e);
    return false;
  }
};

const selectValidation = value => {
  const obj = JSON.parse(value);

  if (isArray(obj)) {
    return (
      obj.length > 0 &&
      obj.every(selection => {
        return typeof selection === 'object' && 'label' in selection && 'value' in selection;
      })
    );
  } else if (typeof obj === 'object') {
    return 'label' in obj && 'value' in obj;
  }

  return false;
};

/* This is an empty function becuase we do the actual validation in the
   AddressField component. We just need this to do nothing and trigger
   the validate call that is handled by the component. */
const addressValidation = value => {
  return true;
};
export const customValidationRules = {
  isPhoneNumber: phoneNumberValidation,
  isSelectionValid: selectValidation,
  isAddressValid: addressValidation
};
