import { Paper, Button, Table, TableBody, TableCell, TableHead, TableRow, TextField } from '@mui/material';
import { withStyles } from "@mui/styles";
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import CustomSelect from '@survey/common/dist/components/form-controls/CustomSelect';
import { EDIT_DATA_MAPPINGS, EDIT_QUESTIONS } from '../auth/permissions';
import { hasPermission } from '../auth/authOperations';
import { convertAttrToValue, convertAttrToValues, convertMapGroupsToValues, convertMapGroupToValue } from '../dataMappings/utils';
import MatrixQuestionTranslation from '@survey/common/dist/components/dialogs/MatrixQuestionTranslation';

const styles = theme => ({});

class MatrixQuestionsSelector extends Component {
  constructor(props) {
    super(props);

    this.state = {
      childQuestions: {},
      newQuestion: '',
      mappingTo: {},
      mappings: {},
      permissions: {},
      showMatrixTranslationDialog: false,
      matrixQuestion: {},
    };

    this.addQuestion = this.addQuestion.bind(this);
    this.removeQuestion = this.removeQuestion.bind(this);

    ['handleSelectChange', 'updateMatrixTranslation'].map((item) => (this[item] = this[item].bind(this)));
  }

  setNewQuestion = event => {
    this.setState({
      newQuestion: event.target.value
    });
  };

  componentDidMount() {
    const { childQuestions } = this.props;
    this.setState({ childQuestions });
  }

  addQuestion() {
    let { childQuestions } = this.props;
    const newQuestion = this.state.newQuestion;
    if (newQuestion.length > 0) {
      if (this.props.mappingTo === 'HA Wizard' || this.props.mappingTo === 'CSV') {
        childQuestions.push({text: newQuestion, value: null, mappingGroupAttributeWizard: `${this.props.mappingGroupAttribute}: ${newQuestion}`});
      } else {
        childQuestions.push({text: newQuestion, value: null});
      }
      this.props.updateChildQuestions(childQuestions);
      this.setState({childQuestions: childQuestions, newQuestion: ''});
    }
  }

  removeQuestion(questionTableIndex) {
    let { childQuestions } = this.props;
    childQuestions.splice(questionTableIndex,1);
    this.props.updateChildQuestions(childQuestions);
    this.setState({childQuestions: childQuestions});
  }

  handleSelectChange(name) {
    return (event, value) => {
      let { childQuestions } = this.props;
      const modifiedQuestion = childQuestions.find(q => q.text === name);

      if (modifiedQuestion) {
        modifiedQuestion.mappingGroupAttribute = value != null ? value.value : '';
        this.props.updateChildQuestions(childQuestions);
        this.setState({childQuestions: childQuestions});
      }
    }
  }

  updateMatrixTranslation(data) {
    let question = this.props.childQuestions.find(x => x.text === data.text);
    question = data;
    this.setState({ showMatrixTranslationDialog: false });
  }

  render() {
    const { childQuestions, permissions, mappings, mappingTo, mappingGroupName } = this.props;
    const disabled = !hasPermission(EDIT_QUESTIONS, permissions);

    return (
      <Paper>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Question</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            { childQuestions && childQuestions.map((question, index) => {
              return (
                <TableRow key={ index }>
                  <TableCell>{ question.text }</TableCell>
                  {(mappingTo === 'HA Database') && (
                    <TableCell>
                      <CustomSelect
                        label="Attribute"
                        value={convertAttrToValue(mappings, mappingGroupName, question.mappingGroupAttribute)}
                        onChange={this.handleSelectChange}
                        options={convertAttrToValues(mappings, mappingGroupName)}
                        disabled={!(disabled || (!isEmpty(question) && !hasPermission(EDIT_DATA_MAPPINGS, permissions))) && mappingTo && mappingGroupName !== '' ? false : true}
                        placeholder="Select Attribute..."
                        name={question.text}
                        required={true}
                      />
                    </TableCell>
                  )}
                  <TableCell align="right">
                    <Button color="primary" onClick={() => this.setState({ showMatrixTranslationDialog: true, matrixQuestion: question })}>
                      Translations
                    </Button>
                    <MatrixQuestionTranslation
                      onClose={() => this.setState({ showMatrixTranslationDialog: false })}
                      question={this.state.matrixQuestion}
                      onConfirm={this.updateMatrixTranslation}
                      open={this.state.showMatrixTranslationDialog}
                    />
                    <Button onClick={ () => this.removeQuestion(index) } color="primary">Delete</Button>
                  </TableCell>
                </TableRow>
              )
            })}
            <TableRow>
              <TableCell>
                <TextField
                  onChange={this.setNewQuestion}
                  value={this.state.newQuestion}
                >
                </TextField>
              </TableCell>
              <TableCell align="right">
                <Button onClick={ this.addQuestion } disabled={ !this.state.newQuestion } color="primary">Add Question</Button>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Paper>
    );
  }
}

MatrixQuestionsSelector.propTypes = {
  childQuestions: PropTypes.array,
  question: PropTypes.object,
  permissions: PropTypes.array,
  mappings: PropTypes.array,
}

export default withRouter(
  withStyles(styles, { withTheme: true })(MatrixQuestionsSelector)
);