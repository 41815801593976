import React, { PureComponent, Fragment } from 'react';
import { Getter } from '@devexpress/dx-react-core';
import { EditingState, GroupingState, IntegratedGrouping } from '@devexpress/dx-react-grid';
import { Grid as DevGrid, Table, TableHeaderRow, TableGroupRow, TableEditRow, TableEditColumn, Toolbar, GroupingPanel } from '@devexpress/dx-react-grid-material-ui';
import { Button, TextField, Switch, FormControlLabel } from '@mui/material';
import { withStyles } from '@mui/styles';
import { ChevronRight, ExpandMore } from '@mui/icons-material';
import { cloneDeep, find, findIndex, findLastIndex, isArray, isEmpty, isEqual, sortBy, groupBy, values } from 'lodash';
import { ValidatorForm } from 'react-material-ui-form-validator';
import Confirm from '@survey/common/dist/components/dialogs/Confirm';
import CustomSelect from '@survey/common/dist/components/form-controls/CustomSelect';

const findByValue = (options, value) => {
  const result = find(options, { value: value });
  if (result) {
    return result;
  } else {
    return {};
  }
};

const makeEditingRowIds = rows => {
  const editingRowIds = [];
  for (let i = 0; i < rows.length; i++) {
    editingRowIds.push(i);
  }
  return editingRowIds;
};

const DropdownEditor = ({ name, options, value, onValueChange, isMulti = false, style = {}, disabled = false }) => {
  return (
    <ValidatorForm onSubmit={() => {}} style={style}>
      <CustomSelect disabled={disabled} name={name} isMulti={isMulti} value={value} onChange={onValueChange} options={options} label="" />
    </ValidatorForm>
  );
};

/* Define what an empty row should be. */
const emptyRow = {
  active: true,
  scoreID: null,
  groupID: null,
  questionID: null,
  answerID: null,
  value: null,
  points: null,
  stage: 0,
  weight: null,
  bonus: false
};

/* This flattens the scoring rows so it's easier to use for the scoring table. */
const flattenRows = rows => {
  if (!rows) return [];
  return rows.reduce((all, row) => {
    let newRow = cloneDeep(row);
    delete newRow.questions;

    row.questions.forEach(question => {
      question.answers.forEach(answers => {
        console.log('testing', newRow, question, answers);
        all.push({ ...newRow, ...question, ...answers });
      });
    });

    return all;
  }, []);
};

/* Convert from the flattened rows we use to the inflated rows used by the API */
const inflateRow = (row, rows) => {
  const matchingRows = rows.filter(r => r.groupID === row.groupID);
  let newRow = cloneDeep(row);
  console.log('matchingRows', matchingRows);
  /* If this row has an empty scoreID there are other matching rows, use that row's scoreID. All rows with matching questions have to have the same scoreID. */
  if (row.scoreID === null && matchingRows.length > 1) {
    const updatedRow = find(matchingRows, r => r.scoreID !== null);
    if (updatedRow) {
      newRow = cloneDeep(updatedRow);
      console.log('new rows', newRow);
    }
  }

  /* Remove fields that will cause the API to reject the request. */
  delete newRow.bsonId;

  const questionsGroup = groupBy(matchingRows, m => m.questionID);
  const questions = Object.keys(questionsGroup).map(qId => {
    let answers = questionsGroup[qId].map(a => {
      return {
        answerID: a.answerID,
        value: a.value,
        points: a.points,
        stage: a.stage,
        active: a.active,
        bonus: a.bonus
      };
    });
    return {
      questionID: Number(qId),
      answers
    };
  });
  /* Remove fields that are now in the answers array. */
  delete newRow.answerID;
  delete newRow.value;
  delete newRow.points;
  delete newRow.active;
  delete newRow.stage;
  delete newRow.questionID;
  delete newRow.bonus;
  newRow['questions'] = questions;

  return newRow;
};

class ScoringTable extends PureComponent {
  constructor(props) {
    super(props);
    const rows = flattenRows(props.scores);
    this.state = {
      editingRowIds: makeEditingRowIds(rows),
      expandedGroups: [],
      rowChanges: {},
      origRows: cloneDeep(rows),
      rows,
      columns: [
        { name: 'groupID', title: 'Tab' },
        { name: 'questionID', title: 'Question' },
        { name: 'value', title: 'Criteria' },
        { name: 'stage', title: 'Stage' },
        { name: 'points', title: 'Points' },
        { name: 'active', title: 'Status' },
        { name: 'bonus', title: 'Bonus Answer' }
      ],
      showRowDeleteDialog: false,
      showPageDeleteDialog: false,
      rowToDelete: {},
      pageRowToDelete: {}
    };

    [
      'Cell',
      'getOptions',
      'Grouping',
      'changeRowChanges',
      'updateRows',
      'CustomSaveTableRow',
      'changeAddedRows',
      'onCommitChanges',
      'onDeleteRow',
      'ExpandGroupBtn',
      'updateWeight',
      'getWeight'
    ].forEach(k => {
      this[k] = this[k].bind(this);
    });
  }

  ExpandGroupBtn({ expanded }) {
    const props = {
      onClick: () => this.setState({ clickedButton: true }),
      className: this.props.classes.icon
    };
    if (expanded) {
      return <ExpandMore {...props} />;
    } else {
      return <ChevronRight {...props} />;
    }
  }
  changeRowChanges(rowChanges) {
    let newRows = [...this.state.rows];
    Object.keys(rowChanges).forEach(id => {
      Object.keys(rowChanges[id]).forEach(field => {
        const value = rowChanges[id][field];
        if (typeof value === 'object') {
          if (isArray(value)) {
            if (newRows[id]) {
              newRows[id][field] = value.map(v => v.value);
            } else {
              console.error('Failed setting', newRows, 'id:', id, 'field:', field);
            }
          } else {
            newRows[id][field] = value.value;
          }
        } else {
          newRows[id][field] = value;
        }
      });
    });

    this.updateRows(newRows, { rowChanges });
  }

  /* Update the rows, the editingRowIds if necessary, and also update the state with any other changes the caller may want. */
  updateRows(rows, otherUpdates = {}) {
    if (this.state.rows.length !== rows.length) {
      const editingRowIds = makeEditingRowIds(rows);
      this.setState({ ...otherUpdates, editingRowIds, rows });
    } else {
      this.setState({ ...otherUpdates, rows });
    }
  }

  onDeleteRow() {
    const row = this.state.rowToDelete;
    let rows = [...this.state.rows];
    const idx = findIndex(rows, row);
    if (idx > -1) {
      rows = [...rows.slice(0, idx), ...rows.slice(idx + 1)];
    }
    this.updateRows(rows, { showRowDeleteDialog: false });
  }

  getOptions(columnName, questionID) {
    switch (columnName) {
      case 'stage':
        return Array.from(new Array(7), (x, i) => ({ label: `Stage ${i + 1}`, value: `${i + 1}` }));
      case 'value':
        const question = find(this.props.questions, { questionID });
        if (!question) {
          return [];
        }
        const answer = find(this.props.answers, { answerID: question.answerID });
        if (answer) {
          return answer.answers;
        } else {
          return [];
        }
      default:
        return [];
    }
  }
  saveRow(groupID) {
    const rows = [...this.state.rows];
    const row = find(rows, { groupID });
    const data = inflateRow(row, this.state.rows);
    console.log('saving data', data);
    if (data.scoreID) {
      this.props.updateScore(data.scoreID, data);
    } else {
      data.scoreID = 0;
      this.props.createScore(data);
    }
  }
  CustomSaveTableRow({ row, ...restProps }) {
    const buttons = [...restProps.children];
    const editButtons = buttons.pop();
    const save = (
      <Table.Cell key={editButtons.key} props={editButtons.props}>
        <Button color="primary" size="small" onClick={() => this.setState({ showRowDeleteDialog: true, rowToDelete: row })}>
          Delete
        </Button>
      </Table.Cell>
    );

    return (
      <TableEditRow.Row {...restProps}>
        {buttons}
        {save}
      </TableEditRow.Row>
    );
  }

  // Remove all rows of groupID
  onDeletePage() {
    const rowsToDelete = [];
    const rows = this.state.rows.filter(r => {
      if (r.groupID === this.state.pageToDelete) {
        rowsToDelete.push(r);
        return false;
      }
      return true;
    });

    this.updateRows(rows, { pageToDelete: {}, showPageDeleteDialog: false });

    /* Delete any rows in the database that have already been saved. */
    rowsToDelete.forEach(r => {
      if ('scoreID' in r && r.scoreID > 0) this.props.deleteScore(r.scoreID);
    });
  }

  getWeight(groupID) {
    const row = find(this.state.rows, { groupID });
    return row.weight;
  }

  updateWeight(groupID, val) {
    const rows = [...this.state.rows];
    const match = rows.filter(r => r.groupID == groupID);
    match.forEach(r => (r.weight = val));
    this.setState({ rows });
  }

  Grouping(props) {
    const { column, row } = props;

    let options = [];
    let groupID;
    if (column.name === 'groupID') {
      options = this.props.tabs.map(t => {
        let page = this.props.pages.find(p => p.pageId === t.pageId);
        return { label: `${page && page.pageName} | ${t.tabName}`, value: t.tabId };
      });
    } else if (column.name === 'questionID') {
      /* Get the tab ID from this row key */
      groupID = row.compoundKey.match(/^(.*)\|/);
      if (groupID) {
        groupID = Number(groupID[1]);
      }

      options = this.props.questions.filter(q => groupID === q.tabID).map(q => ({ label: `${q.questionDescription} (${q.questionID})`, value: q.questionID }));
    }
    options = sortBy(options, [option => option.label.toLowerCase()]);

    const update = (key, oldValue, newValue) => {
      const rows = this.state.rows.map(r => {
        const pageMatch = new RegExp(`^${r.groupID}`);

        /* If this row is of the correct groupID or if the groupID is being changed and the current row has the old value, set it to the new value. */
        if ((pageMatch.test(row.compoundKey) || key === 'groupID') && r[key] === oldValue) {
          r[key] = newValue;
          /* If the questionID changes, also change the answerID */
          if (key === 'questionID') {
            const question = find(this.props.questions, { questionID: newValue });
            if (question) {
              r['answerID'] = question.answerID;
            }
          }
        }

        return r;
      });
      this.updateRows(rows);
    };

    /* Disable the dropdown if this is a tab and it has questions. This prevents people from changing the tab after setting questions. */
    const isDisabled = column.name === 'groupID' && this.state.rows.filter(r => r.groupID === row.value && r.questionID).length ? true : false;

    return (
      <div style={{ display: 'inline-block', width: 'calc(100% - 50px)' }}>
        <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
          <div style={{ marginRight: '0.75rem' }}>
            <strong>{column.title}</strong>:
          </div>
          <DropdownEditor
            name={column.name}
            disabled={isDisabled}
            options={options}
            value={findByValue(options, row.value)}
            onValueChange={field => newElement => update(field, row.value, newElement.value)}
            style={{ verticalAlign: 'top', marginBottom: '0.5rem', minWidth: '15rem', flex: 2 }}
          />
          {column.name === 'groupID' && (
            <Fragment>
              <TextField disabled={!row.value || row.value === ''} style={{ marginLeft: '1rem' }} value={this.getWeight(row.value)} onChange={e => this.updateWeight(row.value, e.target.value)} />
              <div style={{ flex: 1, display: 'flex', marginLeft: '2rem' }}>
                <div style={{ marginLeft: 'auto' }}>
                  <Button disabled={!row.value || row.value === ''} color="primary" size="small" onClick={() => this.saveRow(row.value)}>
                    Save
                  </Button>
                  <Button color="primary" size="small" onClick={e => this.setState({ showPageDeleteDialog: true, pageToDelete: row.value })}>
                    Delete
                  </Button>
                  <Button color="primary" size="small" onClick={e => this.changeAddedRows([{}], row.value)}>
                    Add Rule
                  </Button>
                </div>
              </div>
            </Fragment>
          )}
          {column.name === 'questionID' && (
            <Button disabled={!row.value || row.value === ''} color="primary" size="small" onClick={() => this.addAnswer([{}], groupID, row.value)}>
              Add Answer
            </Button>
          )}
        </div>
      </div>
    );
  }

  Cell({ value, ...props }) {
    let myCell;

    /* Setup the props for each cell */
    const cellProps = cloneDeep(props);
    delete cellProps.onValueChange;
    delete cellProps.editingEnabled;

    const columnName = props.column.name;
    switch (columnName) {
      case 'points':
        myCell = (
          <Table.Cell {...cellProps}>
            <TextField value={value || value === 0 ? value : ''} onChange={e => props.onValueChange(e.target.value)} style={{ marginTop: '1rem' }} />
          </Table.Cell>
        );
        break;
      case 'bonus':
        myCell = (
          <Table.Cell {...cellProps}>
            <FormControlLabel control={<Switch checked={value} onChange={e => props.onValueChange(e.target.checked)} />} label="Bonus" />
          </Table.Cell>
        );
        break;
      case 'active':
        myCell = (
          <Table.Cell {...cellProps}>
            <Button color="primary" size="small" onClick={() => props.onValueChange(!props.row.active)}>
              {props.row.active ? 'DE-' : ''}ACTIVATE
            </Button>
          </Table.Cell>
        );
        break;

      default:
        let dropdownValue = props.row[columnName];
        let isMulti = false;
        if (dropdownValue && typeof dropdownValue !== 'object') {
          dropdownValue = [dropdownValue];
        }

        const options = this.getOptions(columnName, props.row.questionID);
        let val = [];
        if (options && dropdownValue) {
          val = dropdownValue.map(v => find(options, { value: v.toString() }));
        }

        myCell = (
          <Table.Cell {...cellProps}>
            <DropdownEditor
              key={columnName}
              name={columnName}
              options={options}
              value={val}
              isMulti={isMulti}
              onValueChange={() => v => props.onValueChange(v)}
              /* Set the style so multi and single selects sit on the same line horizontally */
              style={{ marginBottom: isMulti ? '0.5rem' : '-0.33rem' }}
            />
          </Table.Cell>
        );
        break;
    }
    return myCell;
  }

  changeAddedRows(newRows, groupID = null) {
    if (newRows.length === 1) {
      let rows = [...this.state.rows];
      let newRow = { ...emptyRow };

      /* If a groupID was provided, add the row at the end of the other rows with that technologyID. Otherwise, added it at the very end of the table. */
      if (groupID !== null) {
        newRow['groupID'] = groupID;
        newRow['weight'] = this.getWeight(groupID);
        const idx = findLastIndex(rows, { groupID });
        rows = [...rows.slice(0, idx + 1), newRow, ...rows.slice(idx + 1)];
      } else {
        rows.push(newRow);
      }

      this.updateRows(rows);
    } else {
      console.error('changeAddedRows newRows is not 1!', newRows.length, newRows);
    }
  }
  addAnswer(newRows, groupID, questionID) {
    if (newRows.length === 1) {
      let rows = [...this.state.rows];
      let newRow = { ...emptyRow };

      /* If a groupID was provided, add the row at the end of the other rows with that technologyID. Otherwise, added it at the very end of the table. */
      newRow['groupID'] = groupID;
      newRow['weight'] = this.getWeight(groupID);
      newRow['questionID'] = questionID;
      const question = find(this.props.questions, { questionID });
      newRow['answerID'] = question.answerID;
      const idx = findLastIndex(rows, { groupID });
      rows = [...rows.slice(0, idx + 1), newRow, ...rows.slice(idx + 1)];

      this.updateRows(rows);
    } else {
      console.error('changeAddedRows newRows is not 1!', newRows.length, newRows);
    }
  }
  onCommitChanges(value) {
    console.log('ON COMMIT CHANGES FUNC');
    if (!isEmpty(value.added)) {
      console.log('ADDED!');
      this.setState({ rowChanges: {} });
    } else if (!isEmpty(value.changed)) {
      console.log('change this manually', value);
    } else {
      console.log('delete this manually', value);
      let rows = [...this.state.rows];
      value.deleted.forEach(idx => {
        rows = [...rows.slice(0, idx), ...rows.slice(idx + 1)];
      });

      this.updateRows(rows);
    }
  }

  render() {
    const { editingRowIds, rowChanges, rows, columns } = this.state;

    return (
      <Fragment>
        <DevGrid rows={rows} columns={columns}>
          <GroupingState grouping={[{ columnName: 'groupID' }, { columnName: 'questionID' }]} />
          <Getter
            name="expandedGroups"
            computed={({ expandedGroups }) => {
              /* Only expand the group on a button click, don't open otherwise. This prevents automatic opening and closing when a select is opened or closed. */
              if (this.state.clickedButton) {
                const nullRegExp = new RegExp('null');
                /* Only open for groups that have a tab and or question defined. */
                const filteredGroups = expandedGroups.filter(g => {
                  if (nullRegExp.test(g)) {
                    return false;
                  }
                  return true;
                });
                /* If the values are different, update the groups. If not, don't do anything. */
                if (!isEqual(filteredGroups, this.state.expandedGroups)) {
                  this.setState({ expandedGroups: filteredGroups, clickedButton: false });
                  return filteredGroups;
                }
              }
              return this.state.expandedGroups;
            }}
          />
          <IntegratedGrouping />
          <Table />
          <TableHeaderRow />
          <TableGroupRow contentComponent={this.Grouping} iconComponent={this.ExpandGroupBtn} />
          <Toolbar />
          <GroupingPanel itemComponent={() => <span />} />
          <EditingState
            editingRowIds={editingRowIds}
            onEditingRowIdsChange={a => {
              // XXX: CHANGE ME
              console.log('on editing row ids change', a);
            }}
            rowChanges={rowChanges}
            onRowChangesChange={this.changeRowChanges}
            addedRows={[]}
            onAddedRowsChange={this.changeAddedRows}
            onCommitChanges={this.onCommitChanges}
          />
          <TableEditRow rowComponent={this.CustomSaveTableRow} cellComponent={this.Cell} />
          <TableEditColumn showAddCommand showEditCommand showDeleteCommand />
          <Getter
            name="tableColumns"
            computed={({ tableColumns }) => {
              const groups = [...tableColumns.filter(c => c.type.toString().match(/group/))];
              const columns = [...tableColumns.filter(c => !c.type.toString().match(/select/) && !c.type.toString().match(/group/))];
              columns[1].width = 750;
              // Reorder the columns to put the edit commands at the end.
              const updatedColumns = [...columns.slice(1), columns[0]];
              const select = [...tableColumns.filter(c => c.type.toString().match(/select/))];
              return [...groups, ...updatedColumns, ...select];
            }}
          />
        </DevGrid>
        <Confirm
          title="Delete All Rows for this Tab?"
          onClose={() => this.setState({ showPageDeleteDialog: false, pageToDelete: {} })}
          onConfirm={() => this.onDeletePage()}
          contentText={`Please confirm you want to delete ALL rows for this tab.`}
          open={this.state.showPageDeleteDialog}
        />
        <Confirm
          title="Delete Scoring Row?"
          onClose={() => this.setState({ showRowDeleteDialog: false, rowToDelete: {} })}
          onConfirm={() => this.onDeleteRow()}
          contentText={`Please confirm that you would like to delete this row.`}
          open={this.state.showRowDeleteDialog}
        />
      </Fragment>
    );
  }
}

const styles = theme => ({
  icon: {
    fill: '#5a5b5c',
    margin: theme.spacing(1),
    marginBottom: 1.5 * theme.spacing(1),
    fontSize: theme.fontSize,
    verticalAlign: 'middle'
  },
  '&:hover': {
    cursor: 'pointer'
  }
});

export default withStyles(styles)(ScoringTable);
