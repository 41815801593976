"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _immutable = _interopRequireDefault(require("immutable"));
var _lodash = require("lodash");
var _technologyQuestions3 = require("../actions/technologyQuestions.actions");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2["default"])(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var initialState = _immutable["default"].Map({
  technologyQuestions: [],
  technologyStatus: [],
  isLoading: false,
  isApiError: false,
  errors: [],
  selectedId: null
});
var _default = exports["default"] = function _default() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case _technologyQuestions3.GET_TECHNOLOGY_QUESTIONS_REQUEST:
      return state.set('technologyQuestions', []).set('errors', []).set('isLoading', true);
    case _technologyQuestions3.GET_TECHNOLOGY_QUESTIONS_SUCCESS:
      return state.set('technologyQuestions', action.response).set('isLoading', false);
    case _technologyQuestions3.GET_TECHNOLOGY_QUESTIONS_FAILURE:
      return state.set('isLoading', false).set('error', action.messages);
    case _technologyQuestions3.CREATE_TECHNOLOGY_QUESTION_REQUEST:
      return state.set('isLoading', true);
    case _technologyQuestions3.CREATE_TECHNOLOGY_QUESTION_SUCCESS:
      var newTechnologyQuestion = action.response;
      var technologyQuestions = [].concat(state.get('technologyQuestions'));
      technologyQuestions.push(newTechnologyQuestion);
      return state.set('technologyQuestions', technologyQuestions).set('isLoading', false);
    case _technologyQuestions3.CREATE_TECHNOLOGY_QUESTION_FAILURE:
      return state.set('isLoading', false).set('error', action.messages);
    case _technologyQuestions3.SAVE_TECHNOLOGY_QUESTION_REQUEST:
      return state.set('isLoading', true);
    case _technologyQuestions3.SAVE_TECHNOLOGY_QUESTION_SUCCESS:
      {
        var _technologyQuestions = [].concat(state.get('technologyQuestions'));
        var indexToEdit = (0, _lodash.findIndex)(_technologyQuestions, _objectSpread({}, action.payload));
        if (indexToEdit > -1) {
          _technologyQuestions[indexToEdit].b = action.payload.message;
          _technologyQuestions[indexToEdit].e = true;
          return state.set('technologyQuestions', _technologyQuestions).set('isLoading', false);
        }
        return state.set('isLoading', false);
      }
    case _technologyQuestions3.SAVE_TECHNOLOGY_QUESTION_FAILURE:
      return state.set('isLoading', false).set('error', action.messages);
    case _technologyQuestions3.DELETE_TECHNOLOGY_QUESTION_SUCCESS:
      {
        var _technologyQuestions2 = [].concat(state.get('technologyQuestions'));
        var indexToDelete = (0, _lodash.findIndex)(_technologyQuestions2, {
          id: state.selectedId
        });
        if (indexToDelete > -1) {
          _technologyQuestions2.splice(indexToDelete, 1);
        }
        return state.set('technologyQuestions', _technologyQuestions2).set('isLoading', false).set('selectedId', null);
      }
    case _technologyQuestions3.DELETE_TECHNOLOGY_QUESTION_FAILURE:
      return state.set('errors', action.messages).set('isLoading', false);
    case _technologyQuestions3.DELETE_TECHNOLOGY_QUESTION_REQUEST:
      return state.set('errors', []).set('isLoading', true).set('selectedId', action.payload);
    case _technologyQuestions3.GET_TECHNOLOGY_STATUS_REQUEST:
      return state.set('technologyStatus', []);
    case _technologyQuestions3.GET_TECHNOLOGY_STATUS_SUCCESS:
      return state.set('technologyStatus', action.response);
    case _technologyQuestions3.GET_TECHNOLOGY_STATUS_FAILURE:
      return state.set('errors', action.messages);
    case _technologyQuestions3.UPLOAD_TECHNOLOGY_QUESTION_SUCCESS:
      {
        return state.set('technologyQuestions', action.response).set('technologyQuestionUploadComplete', true).set('isLoading', false);
      }
    case _technologyQuestions3.UPLOAD_TECHNOLOGY_QUESTION_FAILURE:
      return state.set('errors', action.messages).set('technologyQuestionUploadComplete', true).set('isLoading', false);
    case _technologyQuestions3.UPLOAD_TECHNOLOGY_QUESTION_REQUEST:
      return state.set('errors', []).set('technologyQuestionUploadComplete', false).set('isLoading', true);
    default:
      return state;
  }
};