import { CALL_API } from '@survey/common/dist/middleware/api';

export const GET_DATA_MAPPINGS_REQUEST = 'GET_DATA_MAPPINGS_REQUEST';
export const GET_DATA_MAPPINGS_SUCCESS = 'GET_DATA_MAPPINGS_SUCCESS';
export const GET_DATA_MAPPINGS_FAILURE = 'GET_DATA_MAPPINGS_FAILURE';

export const getDataMappings = () => {
  return {
    [CALL_API]: {
      types: [GET_DATA_MAPPINGS_REQUEST, GET_DATA_MAPPINGS_SUCCESS, GET_DATA_MAPPINGS_FAILURE],
      authenticated: true,
      endpoint: `dataMappings`,
      method: 'GET'
    }
  };
};
