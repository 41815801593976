import Immutable from 'immutable';
import { COPY_DATA_REQUEST, COPY_DATA_SUCCESS, COPY_DATA_FAILURE } from './copyData.actions';

const initialState = Immutable.Map({
  isSaving: false,
  errors: []
});

export default (state = initialState, action) => {
  switch (action.type) {
    case COPY_DATA_REQUEST:
      return state.set('errors', []).set('isSavingg', true);
    case COPY_DATA_SUCCESS:
      return state.set('isSaving', false);
    case COPY_DATA_FAILURE:
      return state.set('isSaving', false).set('error', action.messages);

    default:
      return state;
  }
};
