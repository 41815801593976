import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AppBar, Avatar, Button, Paper, Tab, Tabs, Toolbar, Typography } from '@mui/material';
import { withStyles } from "@mui/styles";
import { isEmpty, uniqBy, orderBy } from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { hasPermission } from '../auth/authOperations';
import { EDIT_VENDORS } from '../auth/permissions';
import { handleToastMessage } from '../layout/layout.actions';
import Products from '../products/products.container';
import VendorAcquireDialog from './vendorAcquireDialog';
import VendorAcquireProductsDialog from './vendorAcquireProductsDialog';
import VendorForm from './vendorForm.component';
import LoadingOverlay from '../layout/loadingOverlay.component';

import Confirm from '@survey/common/dist/components/dialogs/Confirm';
import ConfirmWithInput from '@survey/common/dist/components/dialogs/ConfirmWithInput';
import {
  acquireProduct,
  acquireVendor,
  createVendor,
  //getAcquireVendors,
  getVendorProducts,
  saveVendor,
  selectVendor,
  updateActivationVendor,
  getVendors,
} from '@survey/common/dist/actions/vendors.actions';
import { getTechnologies } from '@survey/common/dist/actions/technologies.actions';
import { getCountriesList, getCountries, getRegionsList } from '@survey/common/dist/actions/countries.actions';
import { getProductsList } from '@survey/common/dist/actions/products.actions';

const styles = (theme) => ({
  toolBar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  vendor: {
    display: 'flex',
    flexDirection: 'row',
  },
  tabs: {
    margin: theme.spacing(2),
  },
  avatar: {
    margin: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(1),
  },
});

class VendorContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      confirmActivationContent: '',
      value: 0,
      showAcquireVendorDialog: false,
      showAcquireProductsDialog: false,
      showActivationDialog: false,
      showSaveDialog: false,
      vendor: {},
    };

    [
      'handleUpdateActivation',
      'handleTabChange',
      'onSubmit',
      'handleAcquireVendor',
      'handleAcquireVendorClose',
      'handleAcquireProducts',
      'handleAcquireProductsClose',
      'handleCloseActivation',
      'handleOpenActivation',
      'handleOpenConfirmDialog',
      'updateVendorProducts',
    ].map((name) => (this[name] = this[name].bind(this)));
  }

  // static getDerivedStateFromProps(props, state) {
  //   if (!isEmpty(props.vendor) && props.vendor.vendorID !== state.vendor.vendorID) {
  //     return {
  //       vendor: props.vendor
  //     };
  //   }

  //   if (!isEmpty(props.vendors)) {
  //     return {
  //       vendors: props.vendors
  //     };
  //   }

  //   return null;
  // }

  async loadProductsAndTechnologies(type) {
    this.props.getProductsList().then((response) => {
      this.setState({ productsList: uniqBy(response.response, 'value') });
    });

  }

  componentDidMount() {
    this.props.getTechnologies();
    this.props.getCountriesList();
    this.props.getRegionsList();
    this.props.getCountries();
    this.loadProductsAndTechnologies();

    //MIKE DROY HERE

    if (this.props.match.params.vendorId !== 'new') {
      this.props.selectVendor(this.props.match.params.vendorId);
      this.props.getVendorProducts(this.props.match.params.vendorId);
    }

    //if (isEmpty(this.props.vendorsForAcquisition)) {
    //  this.props.getAcquireVendors(this.props.match.params.vendorId);
    //}
  }

  async handleAcquireVendor(data) {
    this.setState({ showAcquireVendorDialog: false });
    const acquireResp = await this.props.acquireVendor(this.props.vendor.vendorID, data);
    if (acquireResp.type === 'ACQUIRE_VENDOR_SUCCESS') {
      this.props.getVendorProducts(this.props.match.params.vendorId);
      this.props.handleToastMessage('SUCCESS', false);
    } else {
      this.props.handleToastMessage('ERROR', true);
    }
  }

  handleAcquireVendorClose() {
    this.setState({ showAcquireVendorDialog: false });
  }

  async handleAcquireProducts(data) {
    this.setState({ showAcquireProductsDialog: false });

    const acquireResp = await this.props.acquireProduct(this.props.vendor.vendorID, data);

    if (acquireResp.type === 'ACQUIRE_PRODUCT_SUCCESS') {
      this.props.handleToastMessage('SUCCESS', false);
    } else {
      this.props.handleToastMessage('ERROR', true);
    }
  }

  handleAcquireProductsClose() {
    this.setState({ showAcquireProductsDialog: false });
  }

  handleCloseActivation() {
    this.setState({ showActivationDialog: false });
  }

  handleOpenActivation(type) {
    if (type === 'DELETE') {
      this.setState({
        showActivationDialog: true,
        confirmActivationTitle: 'De-Activate Vendor',
        confirmActivationContent: 'Are you sure you want to de-activate this vendor?',
      });
    } else {
      this.setState({
        showActivationDialog: true,
        confirmActivationTitle: 'Activate Vendor',
        confirmActivationContent: 'Are you sure you want to activate this vendor?',
      });
    }
  }

  handleUpdateActivation() {
    const vendorCopy = { ...this.props.vendor };

    if (this.state.confirmActivationTitle === 'Activate Vendor') {
      vendorCopy.status = 'ACTIVE';
    } else {
      vendorCopy.status = 'DELETE';
    }

    this.onSubmit(vendorCopy);
  }

  async handleOpenConfirmDialog(vendor) {
    await this.setState({ vendor, showSaveDialog: true });
  }

  async onSubmit(data) {
    if (!isEmpty(data)) {
      delete data.createdBy;
      delete data.createdDate;
      delete data.modifiedBy;
      delete data.modifiedDate;

      const saveResponse = await this.props.updateActivationVendor(data.vendorID, data);

      if (saveResponse.type === 'UPDATE_ACTIVATION_SUCCESS') {
        this.setState({ showActivationDialog: false });
        this.props.handleToastMessage('SUCCESSFUL', false);
      } else {
        this.props.handleToastMessage('ERROR!', true);
      }

      this.setState({ vendor: {} });
    } else if (this.props.match.params.vendorId === 'new') {
      const vendorData = { ...this.state.vendor };

      delete vendorData.vendorID;

      const createResponse = await this.props.createVendor(vendorData);

      if (createResponse.type === 'CREATE_VENDOR_SUCCESS') {
        this.props.handleToastMessage('SUCCESSFUL', false);
        //this.props.history.goBack();
        this.props.history.push(`/vendors/` + createResponse.response.vendorID);
      } else {
        this.props.handleToastMessage('ERROR!', true);
      }
    } else {
      const saveResponse = await this.props.saveVendor(this.props.vendor.vendorID, this.state.vendor);

      if (saveResponse.type === 'SAVE_VENDOR_SUCCESS') {
        this.props.handleToastMessage('SUCCESSFUL', false);
        //AFTER WE SAVE WE NEED TO RESET THE STATE PASSED TO PROPS
        /*this.setState({
            vendor: {}
        },
            () => this.props.history.goBack()
        );*/

        //this.props.history.goBack();
        this.props.history.push(`/vendors/` + saveResponse.response.vendorID);
      } else {
        this.props.handleToastMessage('ERROR!', true);
      }
    }
  }

  handleTabChange(event, value) {
    this.setState({ value });
  }

  async updateVendorProducts() {
    await this.props.getVendorProducts(this.props.match.params.vendorId);

    if (this.state.reload) {
      this.setState({ reload: false });
    } else {
      this.setState({ reload: true });
    }
  }

  render() {
    let { classes, permissions, technologies, countries, vendors, isLoading, vendorProducts, countriesList, regionsList, vendorsForAcquisition } = this.props;
    const { value, showAcquireVendorDialog, showAcquireProductsDialog, showActivationDialog, confirmActivationContent, confirmActivationTitle, showSaveDialog, vendorList } = this.state;

    countriesList = orderBy(countriesList, ['name'], ['desc']);

    let vendor = this.props.vendor;

    if (this.props.match.params.vendorId === 'new') {
      vendor = this.state.vendor;
    }

    return isLoading ? (
      <LoadingOverlay />
    ) : (
      <Paper className={classes.paper} square>
        <AppBar position="static" color="default">
          <Toolbar className={classes.toolBar}>
            <div className={classes.vendor}>
              <Avatar className={classes.avatar}>
                <FontAwesomeIcon icon="edit" />
              </Avatar>
              <div>
                <Typography variant="subtitle1" color="inherit">
                  Vendor Name: {!isEmpty(vendor) ? vendor.name : 'New'}
                </Typography>
              </div>
            </div>
            {this.props.match.params.vendorId !== 'new' && (
              <div>
                <Button
                  disabled={!hasPermission(EDIT_VENDORS, permissions)}
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  onClick={() => this.setState({ showAcquireVendorDialog: true })}
                >
                  Acquire Vendor
                </Button>
                <Button
                  disabled={!hasPermission(EDIT_VENDORS, permissions)}
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  onClick={() => this.setState({ showAcquireProductsDialog: true })}
                >
                  Acquire Product
                </Button>
                {this.props.match.params.vendorId !== 'new' &&
                  (vendor.status == 'ACTIVE' || vendor.status == 'Active' ? (
                    <Button disabled={!hasPermission(EDIT_VENDORS, permissions)} variant="contained" color="primary" className={classes.button} onClick={() => this.handleOpenActivation('DELETE')}>
                      Delete / De-Activate
                    </Button>
                  ) : (
                    <Button disabled={!hasPermission(EDIT_VENDORS, permissions)} variant="contained" color="primary" className={classes.button} onClick={() => this.handleOpenActivation('Active')}>
                      Activate
                    </Button>
                  ))}
              </div>
            )}
          </Toolbar>
        </AppBar>
        <div className={classes.tabs}>
          <AppBar position="static">
            <Tabs value={value} onChange={this.handleTabChange}>
              <Tab label="Vendor Profile" />
              <Tab label="Products" />
            </Tabs>
          </AppBar>
          {value === 0 && (
            <VendorForm
              vendor={this.props.match.params.vendorId !== 'new' ? vendor : {}}
              permissions={permissions}
              onSubmit={this.handleOpenConfirmDialog}
              onError={(errors) => console.log(errors)}
              technologies={technologies}
              countriesList={countriesList}
              countries={countries}
              regionsList={regionsList}
            />
          )}
          {value === 1 && (
            <Products
              handleTabChange={this.handleTabChange}
              vendor={vendor}
              permissions={permissions}
              updateVendorProducts={this.updateVendorProducts}
              products={vendorProducts}
              technologies={technologies}
              countries={countries}
            />
          )}
        </div>
        <VendorAcquireDialog vendorID={this.props.match.params.vendorId} open={showAcquireVendorDialog} onAcquireVendor={this.handleAcquireVendor} onClose={this.handleAcquireVendorClose} />
        <VendorAcquireProductsDialog
          vendor={vendor}
          vendors={vendors}
          products={this.state.productsList}
          open={showAcquireProductsDialog}
          onAcquireProducts={this.handleAcquireProducts}
          onClose={this.handleAcquireProductsClose}
        />
        <ConfirmWithInput
          contentText={confirmActivationContent}
          title={confirmActivationTitle}
          onClose={this.handleCloseActivation}
          onConfirm={this.handleUpdateActivation}
          open={showActivationDialog}
          label="Comments"
          isMulti
        />
        <Confirm
          title="Confirm Save"
          onClose={() => this.setState({ showSaveDialog: false })}
          onConfirm={this.onSubmit}
          contentText={`Please confirm that you would like to save the vendor: ${this.state.vendor.name}`}
          open={showSaveDialog}
        />
      </Paper>
    );
  }
}

function mapStateToProps(state, props) {
  return {
    vendor: state.vendors.get('vendor'),
    vendors: state.vendors.get('vendors'),
    vendorProducts: state.vendors.get('vendorProducts'),
    isLoading: state.vendors.get('isLoading') || state.countries.get('isLoading') || state.technologies.get('isLoading'),
    permissions: state.auth.get('permissions'),
    countriesList: state.countries.get('countriesList'),
    countries: state.countries.get('countries'),
    regionsList: state.countries.get('regionsList'),
    technologies: state.technologies.get('technologies'),
    //vendorsForAcquisition: state.vendors.get('acquireVendors'),
  };
}

export default withStyles(styles)(
  connect(mapStateToProps, {
    selectVendor,
    saveVendor,
    createVendor,
    getTechnologies,
    getProductsList,
    getCountriesList,
    getCountries,
    handleToastMessage,
    updateActivationVendor,
    acquireProduct,
    acquireVendor,
    //getAcquireVendors,
    getVendorProducts,
    getRegionsList,
    getVendors,
  })(VendorContainer)
);
