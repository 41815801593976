import { Button, FormControl, Grid, List, ListItem, ListItemText, Paper, TextField, Typography, FormControlLabel, Checkbox } from '@mui/material';
import { withStyles } from '@mui/styles';
import { find, isEmpty, isEqual } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { ValidatorForm } from 'react-material-ui-form-validator';
import TextValidator from 'react-material-ui-form-validator/lib/TextValidator';
import { connect } from 'react-redux';
import {
  convertAnswerToValue,
  convertEntityTypesToValues,
  convertOrganizationTypesToValues,
  convertTechnologiesToValues,
  convertTechStatusToValues,
  convertYearToValue,
} from '../../utilities/convertValues';
import { hasPermission } from '../auth/authOperations';
import { EDIT_DATA_MAPPINGS, EDIT_TECHNOLOGY_QUESTIONS } from '../auth/permissions';
import { convertAttrToValue, convertAttrToValues, convertMapGroupsToValues, convertMapGroupToValue } from '../dataMappings/utils';
import BranchingQuestionsSelector from '../questions/branchingQuestionsSelector';
import CustomSelect from '@survey/common/dist/components/form-controls/CustomSelect';
import CustomTextField from '@survey/common/dist/components/form-controls/CustomTextField';
import { customValidationRules, validationRulesList } from '@survey/common/dist/utilities/answerValidation';
import ParentQuestionsSelector from './parentQuestionsSelector';
import AnswerKeysSelector from './answerKeysSelector';

const styles = (theme) => ({
  form: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(5),
    marginRight: theme.spacing(5),
    display: 'flex',
  },
  formControl: {
    display: 'block !important',
  },
  textField: {
    width: 600,
    fontSize: 12,
  },
  select: {
    marginTop: theme.spacing(2),
    width: 600,
  },
  years: {
    width: 600,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  selectInline: {
    marginTop: theme.spacing(2),
    width: 250,
  },
  button: {
    margin: theme.spacing(1),
  },
  displayIfTechnologyQuestionsAnswered: {
    width: 600,
    margin: theme.spacing(4),
    padding: theme.spacing(1),
  },
  surveyTypesPaper: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
  },
  gridPaper: {
    width: 600,
    marginTop: theme.spacing(6),
  },
  labelText: {
    marginBottom: '0.5rem',
    marginTop: '1rem',
  },
});

const typeThatCanBranch = new RegExp(/(CheckBoxList|DropDownList|RadioButtonList|TechnologyInstallation|DiamTechnologyInstallation_NoIntegrationLevel|DigitalRadarTechnologyInstallation|DigitalRadarTechnologyInstallation_NoIntegrationLevel)/i);
const typeThatSelectAll = new RegExp(/(CheckBoxList)/i);
const mappingToOptions = [
  { value: 'HA Database', label: 'HA Database' },
  { value: 'HA Wizard', label: 'HA Wizard' },
  { value: 'CSV', label: 'CSV' },
];
const controlTypes = [
  { label: 'Horizontal Check Box List', value: 'HorizontalCheckBoxList' },
  { label: 'Vertical Check Box List', value: 'VerticalCheckBoxList' },
  { label: 'Single Value Drop Down List', value: 'SingleDropDownList' },
  { label: 'Multi Value Drop Down List', value: 'MultiDropDownList' },
  { label: 'Vertical Radio Button List', value: 'VerticalRadioButtonList' },
  { label: 'Horizontal Radio Button List', value: 'HorizontalRadioButtonList' },
  { label: 'Address', value: 'Address' },
  { label: 'Date', value: 'Date' },
  { label: 'Text Box', value: 'TextBox' },
  { label: 'Text Area', value: 'TextArea' },
  { label: 'Description', value: 'Description' },
  { label: 'Technology Installation', value: 'TechnologyInstallation' },
  { label: 'Technology Installation (No Integration Level)', value: 'DiamTechnologyInstallation_NoIntegrationLevel' },
  { label: 'Technology Installation (DigitalRadar)', value: 'DigitalRadarTechnologyInstallation' },
  { label: 'Technology Installation (DigitalRadar No Integration Level)', value: 'DigitalRadarTechnologyInstallation_NoIntegrationLevel' },
  { label: 'Single Vendor/Product', value: 'VendorProductSelect' },
  { label: 'Multi Vendor/Product', value: 'MultiVendorProductSelect' },
  { label: 'Label', value: 'CustomLabel' },
];
const years = Array(100)
  .fill()
  .map((item, index) => {
    let year = 2000 + index;
    return { label: year + '', value: year };
  });

class TechnologyQuestionForm extends Component {
  constructor(props) {
    super(props);

    let question = props.technologyQuestion;

    this.state = {
      // Be default a question should be active. Use the 'in' operator so a question that isn't active will have the correct value.
      active: question && 'active' in question ? question.active : true,
      questionDescription: question && question.questionDescription ? question.questionDescription : '',
      additionalDescription: question && question.additionalDescription ? question.additionalDescription : '',
      hint: question && question.hint ? question.hint : '',
      sortOrder: question && question.sortOrder ? question.sortOrder : '',
      haEntityTypes: question && question.haEntityTypes ? question.haEntityTypes : [],
      entityTypes: question && question.entityTypes ? question.entityTypes : [],
      controlType: question && question.controlType ? question.controlType : '',
      endYear: question && question.endYear ? question.endYear : '',
      startYear: question && question.startYear ? question.startYear : '',
      answerID: question && question.answerID ? question.answerID : '',
      questionID: question && question.questionID ? question.questionID : '',
      branchingQuestions: question && question.branchingQuestions ? question.branchingQuestions : {},
      countries: question && question.countries ? question.countries : [],
      mappingTo: question && question.mappingTo ? question.mappingTo : '',
      mappingGroupName: question && question.mappingGroupName ? question.mappingGroupName : '',
      mappingGroupAttribute: question && question.mappingGroupAttribute ? question.mappingGroupAttribute : '',
      technologies: question && question.technologies ? question.technologies : [],
      technologyStatus: question && question.technologyStatus ? question.technologyStatus : [],
      validationRule: question && question.validationRule && question.validationRule.validatorFunctionName !== null ? question.validationRule : { validatorFunctionName: null, value: null },
      isBranching: false,
      answer: '',
      prevQuestionState: {},
      allowSelectAll: question && question.allowSelectAll ? question.allowSelectAll : false,
      parentQuestions: question && question.parentQuestions ? question.parentQuestions : {},
      answerKeys: question && question.answerKeys ? question.answerKeys : [],
      translations: question && question.translations ? question.translations : {},
      hintTranslations: question && question.hintTranslations ? question.hintTranslations : {},
      enableComments: question && question.enableComments ? question.enableComments : false,
    };

    ['onSubmit', 'updateBranchingQuestions', 'updateParentQuestions', 'updateSelectedValueField', 'handleSelectChange', 'updateAnswerKeys'].map((item) => (this[item] = this[item].bind(this)));
  }

  static getDerivedStateFromProps(props, state) {
    const { technologyQuestion } = props;
    let newState = { ...state };

    if (!isEmpty(technologyQuestion) && !isEqual(technologyQuestion, state.prevQuestionState)) {
      Object.keys(technologyQuestion).forEach((k) => {
        if (technologyQuestion[k]) {
          newState[k] = technologyQuestion[k];
        }
      });

      newState.prevQuestionState = { ...technologyQuestion };
    }

    /* Add all of the custom validation rules */
    Object.keys(customValidationRules).forEach((ruleName) => {
      ValidatorForm.addValidationRule(ruleName, customValidationRules[ruleName]);
    });

    // HS-161: Start year should be the current year if this is a new question.
    if (newState.startYear === '' && newState.questionID === '') {
      newState.startYear = new Date().getFullYear();
    }

    return { ...newState };
  }

  onSubmit() {
    if (this.state.isBranching) {
      this.props.handleToastMessage('You must finish your changes in branching questions before saving', true);
      return;
    }

    let state = { ...this.state };

    /* Set some values to null if they are blank */
    ['answerID', 'endYear', 'startYear'].forEach((item) => {
      if (state[item] === '') {
        state[item] = null;
      }
    });

    // In the case where the control type switched from a type that allows branching questions to one that doesn't, remove all of the branching questions.
    if (
      (state.controlType === 'TextBox' && this.props.question && this.props.question.controlType !== 'TextBox') ||
      (state.controlType === 'TextArea' && this.props.question && this.props.question.controlType !== 'TextArea')
    ) {
      state.branchingQuestions = {};
    }

    /* Delete some values from the state before submitting */
    ['createdDate', 'bsonId', 'initialQuestionState', 'prevQuestionState'].forEach((val) => delete state[val]);

    this.props.onSubmit(state);
  }

  updateSelectedValueField(field) {
    return (event, val) => {
      if (val instanceof Array) {
        this.setState({ [field]: [...val.map((v) => v.value)] });
      } else {
        this.setState({
          [field]: val,
        });
      }
    };
  }

  updateBranchingQuestions(technologyQuestions) {
    this.setState({ branchingQuestions: technologyQuestions });
  }
  updateParentQuestions(questions) {
    this.setState({ parentQuestions: questions });
  }
  updateAnswerKeys(answerKeys) {
    this.setState({ answerKeys });
  }
  handleSelectChange(name) {
    return (event, value) => {
      if (value === null) {
        this.setState({
          [name]: '',
        });
      } else {
        switch (name) {
          case 'controlType':
            if(value && value.value && value.value.toString().match(typeThatCanBranch)) {
              this.setState({ controlType: value.value });
            } else {
              this.setState({
                controlType: value.value,
                answerID: '',
              });
            }
            break;

          case 'validationRule':
            this.setState({
              validationRule: { ...this.state.validationRule, validatorFunctionName: value.value },
            });
            break;

          case 'mappingTo':
            this.setState({
              [name]: value.value,
              mappingGroupAttribute: '',
              mappingGroupName: '',
            });
            break;

          default:
            this.setState({
              [name]: value.value,
            });
        }
      }
    };
  }

  render() {
    const { technologyQuestion, technologyQuestions, technologyQuestionReferences, branchingQuestionReferences, answers, technologies, classes, permissions, regularQuestions } = this.props;
    const disabled = !hasPermission(EDIT_TECHNOLOGY_QUESTIONS, permissions);
    const answerOptions = [];

    //Sort the names for the dropdown
    technologies.sort((a, b) => {
      var x = a.technologyName;
      var y = b.technologyName;
      if (x < y) {
        return -1;
      }
      if (x > y) {
        return 1;
      }
      return 0;
    });

    answers.forEach((a) => {
      answerOptions.push({ label: a.answerName, value: a.answerID });
    });
    return (
      <ValidatorForm onSubmit={this.onSubmit} onError={this.props.onError} className={classes.form}>
        <Grid container spacing={10}>
          <Grid item xs={12} md={6}>
            <FormControl className={classes.formControl}>
              <CustomSelect
                name="technologies"
                label="Technologies"
                className={classes.select}
                value={convertTechnologiesToValues(this.state.technologies, technologies)}
                options={technologies.map((item) => ({
                  value: item.technologyID,
                  label: item.technologyName,
                }))}
                disabled={disabled}
                onChange={this.updateSelectedValueField}
                isMulti={true}
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <CustomTextField
                disabled={disabled}
                key="questionDescription"
                name="questionDescription"
                fullWidth
                margin="dense"
                multiline={true}
                maxRows="4"
                label="Technology Question Description"
                value={this.state.questionDescription}
                onChange={(event) => this.setState({ questionDescription: event.target.value })}
                required={true}
              />
            </FormControl>

            {this.state.controlType === 'Description' && (
              <Paper className={classes.surveyTypesPaper}>
                <Typography variant="subtitle1" component="div">
                  <div dangerouslySetInnerHTML={{ __html: this.state.questionDescription }} />
                </Typography>
              </Paper>
            )}

            <FormControl className={classes.formControl}>
              <CustomTextField
                disabled={disabled}
                key="additionalDescription"
                name="additionalDescription"
                fullWidth
                margin="dense"
                multiline={true}
                maxRows="4"
                label="Technology Question Additional Description"
                value={this.state.additionalDescription}
                onChange={(event) => this.setState({ additionalDescription: event.target.value })}
                required={false}
              />
            </FormControl>

            {this.state.controlType === 'Description' && (
              <Paper className={classes.surveyTypesPaper}>
                <Typography variant="subtitle1" component="div">
                  <div dangerouslySetInnerHTML={{ __html: this.state.additionalDescription }} />
                </Typography>
              </Paper>
            )}

            <FormControl className={classes.formControl}>
              <TextField variant='standard' disabled={disabled} key="hint" name="hint" fullWidth margin="dense" label="Hint" value={this.state.hint} onChange={(event) => this.setState({ hint: event.target.value })} />
            </FormControl>
            <CustomSelect
              label="Control Type"
              value={this.state.controlType ? find(controlTypes, { value: this.state.controlType }) : ''}
              onChange={this.handleSelectChange}
              options={controlTypes}
              disabled={disabled}
              name="controlType"
              required={true}
            />
            {this.state.controlType.match(typeThatSelectAll) && (
              <FormControlLabel
                control={<Checkbox checked={this.state.allowSelectAll} onChange={(event) => this.setState({ allowSelectAll: event.target.checked })} name="allowSelectAll" color="primary" />}
                label="Enable Select All / Deselect All"
              />
            )}
            {this.state.controlType.match(typeThatCanBranch) && (
              <Fragment>
                <CustomSelect
                  label="Answer Key"
                  value={convertAnswerToValue(this.state.answerID, answers)}
                  onChange={this.handleSelectChange}
                  options={answerOptions}
                  disabled={disabled}
                  name="answerID"
                  required={true}
                />

                <Typography variant="subtitle1" className={classes.labelText}>
                  Answer Key Selector
                </Typography>
                <AnswerKeysSelector
                  disabled={false}
                  questions={technologyQuestions}
                  answerKeys={this.state.answerKeys}
                  updateAnswerKeys={this.updateAnswerKeys}
                  handleToastMessage={this.props.handleToastMessage}
                  toggleBranchingEditState={(isBranching) => this.setState({ isBranching: isBranching })}
                />
              </Fragment>
            )}
            {this.state.controlType === 'TextBox' && (
              <CustomSelect
                label="Validation Rule"
                value={
                  !isEmpty(this.state.validationRule) && this.state.validationRule.validatorFunctionName !== null
                    ? {
                        label: find(validationRulesList, { validatorFunctionName: this.state.validationRule.validatorFunctionName }).name,
                        value: this.state.validationRule.validatorFunctionName,
                      }
                    : {}
                }
                onChange={this.handleSelectChange}
                options={validationRulesList.map((v) => ({ value: v.validatorFunctionName, label: v.name }))}
                disabled={disabled}
                name="validationRule"
              />
            )}
            {this.state.controlType === 'TextBox' && this.state.validationRule.validatorFunctionName === 'maxStringLength' && (
              <TextField
                label="Validation Rule Value"
                name="validationRuleValue"
                value={this.state.validationRule.value}
                onChange={(event) => this.setState({ validationRule: { ...this.state.validationRule, value: event.target.value } })}
                variant='standard'
              />
            )}
            <Grid container spacing={8}>
              <Grid item xs={12} md={6}>
                <CustomSelect
                  label="Start Year"
                  value={convertYearToValue(this.state.startYear)}
                  onChange={this.handleSelectChange}
                  options={years}
                  disabled={disabled}
                  name="startYear"
                  required={true}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomSelect label="End Year" value={convertYearToValue(this.state.endYear)} onChange={this.handleSelectChange} options={years} disabled={disabled} name="endYear" />
              </Grid>
            </Grid>
            <Grid container spacing={8}>
                <Grid item xs={12} md={6}>
                  <FormControlLabel
                  control={<Checkbox checked={this.state.enableComments} onChange={(event) => this.setState({ enableComments: event.target.checked })} name="enableComments" color="primary" />}
                  label="Enable Comments"
                />
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControl className={classes.formControl}>
                    <TextValidator
                      fullWidth
                      label="Sort Order"
                      key="order"
                      name="sortOrder"
                      type="number"
                      margin="dense"
                      value={this.state.sortOrder}
                      onChange={(event) => this.setState({ sortOrder: event.target.value })}
                      validators={['required', 'isNumber']}
                      errorMessages={['This field is required', 'Sort Order must be an integer']}
                      disabled={disabled}
                      variant='standard'
                    />
                  </FormControl>
                </Grid>
            </Grid>
            <CustomSelect
              options={this.props.entityTypes.map((item) => ({
                value: item.entityTypeId,
                label: item.name,
              }))}
              label="Entity Type(s)"
              disabled={disabled}
              className={classes.select}
              name="entityTypes"
              onChange={this.updateSelectedValueField}
              value={convertEntityTypesToValues(this.state.entityTypes, this.props.entityTypes)}
              isMulti={true}
              required={true}
            />
            <CustomSelect
              options={this.props.organizationTypes.map((item) => ({
                value: item.organizationTypeID,
                label: item.organizationTypeName,
              }))}
              label="Organization Type(s)"
              name="haEntityTypes"
              className={classes.select}
              onChange={this.updateSelectedValueField}
              value={convertOrganizationTypesToValues(this.state.haEntityTypes, this.props.organizationTypes)}
              isMulti={true}
              disabled={disabled}
              required={true}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid container style={{ height: '100%' }} direction="column">
              <FormControl>
                <CustomSelect
                  name="technologyStatus"
                  label="Technology Status"
                  className={classes.select}
                  value={convertTechStatusToValues(this.state.technologyStatus, this.props.technologyStatus)}
                  options={this.props.technologyStatus.map((item, index) => ({
                    value: item.statusID,
                    label: item.name,
                  }))}
                  onChange={this.updateSelectedValueField}
                  isMulti={true}
                  disabled={disabled}
                />
              </FormControl>
              <FormControl>
                <CustomSelect
                  options={this.props.countriesList}
                  label="Countries"
                  name="countries"
                  className={classes.select}
                  isMulti={true}
                  onChange={this.updateSelectedValueField}
                  value={this.state.countries.map((v) => find(this.props.countriesList, { value: v }))}
                  disabled={disabled}
                />
              </FormControl>
              {this.state.controlType !== 'Description' && (
                <FormControl>
                  <Paper className={classes.surveyTypesPaper}>
                    <Grid container spacing={0}>
                      <Grid item xs={12} sm={3} style={{ alignSelf: 'center' }}>
                        <Typography variant="subtitle1">Data Mapping</Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <CustomSelect
                          label=""
                          value={this.state.mappingTo ? { value: this.state.mappingTo, label: this.state.mappingTo } : ''}
                          onChange={this.handleSelectChange}
                          options={mappingToOptions}
                          disabled={disabled || (!isEmpty(technologyQuestion) && !hasPermission(EDIT_DATA_MAPPINGS, permissions))}
                          name="mappingTo"
                          className={classes.select}
                          required={true}
                        />
                      </Grid>
                    </Grid>
                    {(this.state.mappingTo === 'HA Database' || this.state.mappingTo === 'HA Wizard') && (
                      <div>
                        <CustomSelect
                          label="Name"
                          value={convertMapGroupToValue(this.props.mappings, this.state.mappingGroupName)}
                          onChange={this.handleSelectChange}
                          options={convertMapGroupsToValues(this.props.mappings)}
                          disabled={!(disabled || (!isEmpty(technologyQuestion) && !hasPermission(EDIT_DATA_MAPPINGS, permissions))) && this.state.mappingTo ? false : true}
                          name="mappingGroupName"
                          required={true}
                        />
                        <CustomSelect
                          label="Attribute"
                          value={convertAttrToValue(this.props.mappings, this.state.mappingGroupName, this.state.mappingGroupAttribute)}
                          onChange={this.handleSelectChange}
                          options={convertAttrToValues(this.props.mappings, this.state.mappingGroupName)}
                          disabled={
                            !(disabled || (!isEmpty(technologyQuestion) && !hasPermission(EDIT_DATA_MAPPINGS, permissions))) && this.state.mappingTo && this.state.mappingGroupName !== ''
                              ? false
                              : true
                          }
                          name="mappingGroupAttribute"
                          required={true}
                        />
                      </div>
                    )}
                    {this.state.mappingTo === 'CSV' && (
                      <FormControl className={classes.formControl}>
                        <CustomTextField
                          required={true}
                          disabled={disabled || (!isEmpty(technologyQuestion) && !hasPermission(EDIT_DATA_MAPPINGS, permissions))}
                          fullWidth
                          label="Attribute"
                          key="attribute"
                          name="mappingGroupAttribute"
                          type="text"
                          margin="dense"
                          multiline={true}
                          maxRows="4"
                          value={this.state.mappingGroupAttribute}
                          onChange={(event) => this.setState({ mappingGroupAttribute: event.target.value })}
                        />
                      </FormControl>
                    )}
                  </Paper>
                </FormControl>
              )}
              {branchingQuestionReferences.length > 0 && (
                <Grid item>
                  <Paper className={classes.surveyTypesPaper}>
                    <Typography variant="subtitle1">Technology Question References</Typography>
                    <List dense={true}>
                      {branchingQuestionReferences.map((i, index) => (
                        <ListItem button key={index}>
                          <ListItemText primary={`${i.questionDescription} (${i.questionID})`} onClick={() => this.props.history.push(`/technologyQuestions/${i.questionID}`)} />
                        </ListItem>
                      ))}
                    </List>
                  </Paper>
                </Grid>
              )}
              {this.state.controlType !== 'Description' && technologyQuestions && technologyQuestion && (
                <div>
                  <Typography variant="subtitle1" className={classes.labelText}>
                    Display if Technology Question is Answered
                  </Typography>
                  <BranchingQuestionsSelector
                    disabled={this.state.answerID === '' || this.state.controlType === ''}
                    questions={technologyQuestions.filter((q) => q.questionID !== technologyQuestion.questionID)}
                    selectedAnswer={answers && answers.length ? answers.filter((a) => a.answerID === this.state.answerID)[0] : {}}
                    branchingQuestions={this.state.branchingQuestions}
                    updateBranchingQuestions={this.updateBranchingQuestions}
                    handleToastMessage={this.props.handleToastMessage}
                    toggleBranchingEditState={(isBranching) => this.setState({ isBranching: isBranching })}
                  />
                </div>
              )}
              <div>
                <Typography variant="subtitle1" className={classes.labelText}>
                  Display if Parent Question is Answered
                </Typography>
                <ParentQuestionsSelector
                  disabled={false}
                  questions={regularQuestions}
                  parentQuestions={this.state.parentQuestions}
                  updateParentQuestions={this.updateParentQuestions}
                  handleToastMessage={this.props.handleToastMessage}
                  toggleBranchingEditState={(isBranching) => this.setState({ isBranching: isBranching })}
                />
              </div>
              {technologyQuestionReferences.length > 0 && (
                <Grid item>
                  <Paper className={classes.surveyTypesPaper}>
                    <Typography variant="subtitle1">Survey Types</Typography>
                    <List dense={true}>
                      {technologyQuestionReferences.map((i, index) => (
                        <ListItem button key={index}>
                          <ListItemText primary={i.name} onClick={() => this.props.history.push(`/surveyTypes/${i.surveyTypeID}`)} />
                        </ListItem>
                      ))}
                    </List>
                  </Paper>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
            <Button variant="contained" color="primary" className={classes.button} onClick={() => this.props.history.goBack()}>
              Back
            </Button>
            <Button type="submit" disabled={disabled} variant="contained" color="primary" className={classes.button}>
              Save
            </Button>
          </Grid>
        </Grid>
      </ValidatorForm>
    );
  }
}

TechnologyQuestionForm.propTypes = {
  technologyQuestion: PropTypes.object,
  technologyQuestions: PropTypes.array,
  technologyQuestionReferences: PropTypes.array,
  branchingQuestionReferences: PropTypes.array,
  regularQuestions: PropTypes.array,
};

function mapStateToProps(state, props) {
  return {
    permissions: state.auth.get('permissions'),
    mappings: state.mappings.get('mappings'),
  };
}

export default withStyles(styles)(connect(mapStateToProps, {})(TechnologyQuestionForm));
