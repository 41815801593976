// XXX: CHANGEME!!
let BASE_URL = 'https://staging.spectrum.pitneybowes.com/rest/';
// BASE_URL = 'https://api-sandbox.pitneybowes.com/shippingservices/v1/';

BASE_URL = 'http://localhost:3333/';
export const baseURL = BASE_URL;

const authToken = 'SElNU1M5NTYxOjl7blRGOW9f';

function callApi(endpoint, authenticated, method = 'GET', payload) {
  let headers = { Host: 'staging.spectrum.pitneybowes.com' };
  // XXX: REMOVE ME!!
  headers = {};

  if (authenticated) {
    if (authToken) {
      headers['Authorization'] = `Basic ${authToken}`;
      headers['Access-Control-Allow-Methods'] = 'GET,OPTIONS';
      // headers['Access-Control-Allow-Headers'] = 'X-HTTP-Method-Override, Content-Type, x-requested-with, Authorization';
      // headers['Access-Control-Allow-Origin'] = '*';
      headers['Access-Control-Allow-Headers'] = 'Authorization';
      // headers['Access-Control-Allow-Credentials'] = true;
      console.log('appending to headers', headers);
    } else {
      throw new Error('No token saved!');
    }
  }
  let config = { method, headers };
  // config['credentials'] = 'include';
  if (typeof payload !== 'undefined') {
    //Is this form data?
    if (Object.prototype.toString.call(payload) === '[object FormData]') {
      config.body = payload;
    } else {
      config.body = JSON.stringify(payload);
      headers['Content-Type'] = 'application/json';
    }
  }

  let url = '';

  if (endpoint.startsWith('http')) {
    url = endpoint;
  } else {
    url = BASE_URL + endpoint;
  }
  return fetch(url, config)
    .then(response => {
      if (config.method === 'PUT' || config.method === 'DELETE') {
        return { response };
      } else {
        // response.headers['Access-Control-Allow-Credentials'] = true;
        // response.headers['Access-Control-Allow-Headers'] = 'Authorization';
        // response.headers['Access-Control-Allow-Methods'] = 'GET,OPTIONS';
        // response.headers['Access-Control-Allow-Origin'] = '*';
        // response.headers['Authorization'] = `Basic ${authToken}`;
        return response.json().then(json => {
          return { json, response };
        });
      }
    })
    .then(({ json, response }) => {
      console.log('RESPONSE', response);
      if (!response.ok) {
        json.status = response.status;
        return Promise.reject(json);
      }
      let metadata = {};
      if (response.headers.get('X-Total-Count')) {
        metadata.totalCount = Number(response.headers.get('X-Total-Count'));
      }
      return { json, metadata };
    });
}

export const CALL_ADDRESS_VALIDATION_API = Symbol('Call Address Validation API');

export default ({ dispatch, getState }) => next => action => {
  const callAddressValidationAPI = action[CALL_ADDRESS_VALIDATION_API];

  // So the middleware doesn't get applied to every single action
  if (typeof callAddressValidationAPI === 'undefined') {
    return next(action);
  }

  let { endpoint, types, authenticated, method, payload, options } = callAddressValidationAPI;

  const [requestType, successType, errorType] = types;

  next({
    type: requestType,
    payload
  });

  // Passing the authenticated boolean back in our data will let us distinguish
  // between normal and secret quotes
  return callApi(endpoint, authenticated, method, payload).then(
    response => {
      return next({
        response: response.json,
        metadata: response.metadata,
        authenticated,
        payload,
        type: successType,
        options
      });
    },
    (error, response) => {
      let resp = {
        type: errorType
      };

      if (error.status === 409) {
        // dispatch(showToastNotification('This data is out of date. Please refresh and try again!', 'ERROR', null, 5000));
      }
      if (error.messages) {
        resp.messages = error.messages;
      } else if (error.message) {
        resp.messages = [error.message];
      } else {
        resp.messages = ['There was an error.'];
      }
      return next(resp);
      //TODO: We need a special way to handle 401s caused by expired tokens
      //That way the user will get kicked back to the login screen if needed
    }
  );
};
